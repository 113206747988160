/**
 * @param {string} targetId Id of the target intersecting element
 * @param {string} inflection Threshold where callback should trigger
 * @param {string} setState Update State
 * @param {string} stateValue Value that should update the state
 */
const initiateIntersectionObserver = (
  targetId,
  inflection,
  setState,
  stateValue
) => {
  const options = {
    root: null,
    threshold: inflection,
  };
  const observer = new IntersectionObserver((entries) => {
    if (entries[0].intersectionRatio >= inflection) {
      setState(stateValue);
    }
  }, options);
  const target = targetId;
  observer.observe(target);
};

export default initiateIntersectionObserver;
