import { spawn } from "redux-saga/effects";
import TestSpecificSaga from "./TestSpecific.saga";
import ComprehensiveNotesSaga from "./ComprehensiveNotes.saga";
import ComprehensiveTestSaga from "./ComprehensiveTest.saga";
import UserProfileSaga from "./UserProfile.saga";
import InstructionsSaga from "./Instructions.saga";
import GenerateAssessmentsSaga from "./GenerateAssessment.saga";
import TestSubmissionSaga from "./TestSubmission.saga";
import TestTakingSaga from "./TestTaking.saga";
import MockTestSaga from "./MockTest.saga";
import NotificationSaga from "./NotificationCenter.saga";
import InternetConnectionSaga from "./InternetConnection.saga";
import BnatTestSaga from "./BNAT.saga";

function* rootSaga() {
  yield spawn(TestSpecificSaga);
  yield spawn(ComprehensiveNotesSaga);
  yield spawn(ComprehensiveTestSaga);
  yield spawn(UserProfileSaga);
  yield spawn(InstructionsSaga);
  yield spawn(GenerateAssessmentsSaga);
  yield spawn(TestSubmissionSaga);
  yield spawn(TestTakingSaga);
  yield spawn(MockTestSaga);
  yield spawn(NotificationSaga);
  yield spawn(InternetConnectionSaga);
  yield spawn(BnatTestSaga);
}

export default rootSaga;
