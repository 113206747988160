/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { makeStyles, withStyles, Switch } from "@material-ui/core";
import PropTypes from "prop-types";
import { NotificationLabels } from "../Utils/useNotification";
import { TRAIL_COLORS } from "../../AppConfig";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: "4px 4px",
    margin: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  switchBase: {
    color: "#B062FB",
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#c8dff5",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: TRAIL_COLORS.GREEN_REGULAR,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
    color: "#B062FB",
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: TRAIL_COLORS.REGULAR_GREY,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles(() => ({
  titleText: {
    fontSize: 10,
    textAlign: "justify",
    color: TRAIL_COLORS.DARK_GREY,
  },
  elementText: {
    fontSize: 12,
    fontFamily: "gotham",
    color: TRAIL_COLORS.DARK_GREY,
  },
  container: {
    padding: 24,
    textAlign: "center",
    backgroundColor: "white",
    borderRadius: 16,
    width: 200,
  },
  notificationElement: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boldText: {
    color: TRAIL_COLORS.DARK_GREY,
  },
}));

const NotificationDropdown = ({ data, onChangeValue }) => {
  const user = localStorage.getItem("user");
  const { mobile, verified_mobile } = user ? JSON.parse(user) : {};
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.titleText}>
        Switch the toggle button to
        <b className={classes.boldText}> Start/ Stop </b>
        receiving notifications of your upcoming AITS on your WhatsApp number
        {` ${mobile || verified_mobile}`}
      </div>
      <>
        {data &&
          data.map(({ label, isEnabled }) => (
            <div className={classes.notificationElement} key={label}>
              <span className={classes.elementText}>
                {NotificationLabels[label]}
              </span>
              <span>
                <IOSSwitch
                  checked={isEnabled}
                  onChange={() =>
                    onChangeValue({ label, isEnabled: !isEnabled })
                  }
                />
              </span>
            </div>
          ))}
      </>
    </div>
  );
};

NotificationDropdown.propTypes = {
  data: PropTypes.arrayOf({
    label: PropTypes.string,
    isEnabled: PropTypes.bool,
  }).isRequired,
};

export default NotificationDropdown;
