import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ClassNames from "classnames";
import { TRAIL_COLORS } from "../../AppConfig";

const genStyle = ({ color }) => {
  return {
    backgroundColor: color,
    padding: "10px 16px",
    margin: "0 12px 0 0",
    fontSize: 13,
    borderRadius: "5px",
    fontWeight: "bold",
    border: "0px",
    color: "white",
    cursor: "pointer",
    transition: "0.2s",
    "&:hover": {
      filter: "contrast(0.8)",
      transition: "0.2s",
    },
    "&:disabled": {
      filter: "grayscale(100%)",
    },
    "&:focus": {
      transition: "0.2s",
      outline: "none",
    },
  };
};

const useStyle = makeStyles((theme) => ({
  purpleOutline: {
    ...genStyle({ color: TRAIL_COLORS.WHITE }),
    border: "0.5px solid #e6beff",
    color: TRAIL_COLORS.PURPLE_LIGHT,
    paddingLeft: "35px",
    marginTop: "10px",
    paddingRight: "35px",
    [theme.breakpoints.down("xs")]: {
      width: ({ extend }) => (extend ? "95%" : ""),
      fontSize: 11,
    },
  },
  purple: {
    ...genStyle({ color: TRAIL_COLORS.PURPLE_REGULAR }),
    paddingLeft: "35px",
    marginTop: "10px",
    paddingRight: "35px",
    [theme.breakpoints.down("xs")]: {
      width: ({ extend }) => (extend ? "95%" : ""),
      fontSize: 11,
    },
  },
  green: {
    ...genStyle({ color: "#60b760" }),
    [theme.breakpoints.down("xs")]: {
      width: ({ extend }) => (extend ? "95%" : ""),
      fontSize: 9,
      padding: "8px 16px",
    },
  },
  orange: {
    ...genStyle({ color: "#efac57" }),
    [theme.breakpoints.down("xs")]: {
      width: ({ extend }) => (extend ? "95%" : ""),
      fontSize: 10,
      padding: "8px 16px",
    },
  },
  blue: {
    ...genStyle({ color: "#377bb5" }),
    [theme.breakpoints.down("xs")]: {
      width: ({ extend }) => (extend ? "95%" : ""),
      fontSize: 10,
      padding: "8px 16px",
    },
  },
  white: {
    backgroundColor: "#ffffff",
    padding: "10px 16px",
    margin: "0 12px 0 0",
    fontSize: 13,
    cursor: "pointer",
    borderRadius: "5px",
    fontWeight: "bold",
    color: ({ selected }) => (selected ? "#1899db" : "#333333"),
    border: ({ selected }) => `solid 1px ${selected ? "#1899db" : "#cccccc"}`,
    transition: "0.2s",
    "&:hover": {
      filter: "contrast(1.2)",
      transition: "0.2s",
    },
    "&:disabled": {
      filter: "grayscale(100%)",
      color: "grey",
    },
    [theme.breakpoints.down("xs")]: {
      width: ({ extend }) => (extend ? "95%" : ""),
      fontSize: 9,
      padding: "8px 10px",
    },

    "&:focus": {
      transition: "0.2s",
      outline: "none",
    },
  },
}));

const Button = ({
  type,
  children,
  selected,
  onClick,
  extend,
  disabled,
  customStyle,
}) => {
  const classes = useStyle({ selected, extend });
  return (
    <button
      className={ClassNames(classes[type], customStyle)}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  extend: PropTypes.bool,
};

Button.defaultProps = {
  type: "green",
  extend: false,
};

export default Button;
