/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Modal from "@material-ui/core/Modal";
import { Backdrop, makeStyles } from "@material-ui/core";
import { ICONS, TRAIL_COLORS } from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    paddingTop: 20,
    borderRadius: 20,
    backgroundColor: TRAIL_COLORS.LIGHT_GREY,
    width: 600,
    outline: "none",
    position: "relative",
  },
  modalTitle: {
    padding: "0px 0px 0px 20px",
  },
  modalBody: {
    borderRadius: 20,
    minHeight: 350,
    padding: 20,
    marginTop: 10,
    backgroundColor: "white",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    top: 15,
    right: 20,
    cursor: "pointer",
  },
  [theme.breakpoints.down("sm")]: {
    modalContainer: {
      width: "90%",
    },
    modalBody: {
      borderRadius: 20,
      minHeight: 300,
      padding: 20,
      marginTop: 10,
      backgroundColor: "white",
    },
  },
}));

const CustomModal = ({ title, children, open, onClose }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      className={classes.centered}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      closeAfterTransition
    >
      <div className={classes.modalContainer}>
        <img
          src={ICONS.CLOSE}
          className={classes.closeButton}
          alt="Notification"
          onClick={() => onClose("close")}
        />
        <span className={classes.modalTitle}>{title}</span>
        <div className={classes.modalBody}>{children}</div>
      </div>
    </Modal>
  );
};
export default CustomModal;
