export default {
  bnatTest: {
    classAndResultDescriptions: [
      {
        class: 4,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNAT Assessment Report will have Analysis on (a)Mathematical Ability (b)Verbal Ability (c)Logical Ability (d)Environmental Science",
      },
      {
        class: 5,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNAT Assessment Report will have Analysis on (a)Mathematical Ability (b)Verbal Ability (c)Logical Ability (d)Environmental Science",
      },
      {
        class: 6,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNAT Assessment Report will have Analysis on (a)Mathematical Ability (b)Verbal Ability (c)Logical Ability (d)Science (e)Social Science",
      },
      {
        class: 7,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNAT Assessment Report will have Analysis on (a)Mathematical Ability (b)Verbal Ability (c)Logical Ability (d)Science (e)Social Science",
      },
      {
        class: 8,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNAT Assessment Report will have Analysis on (a)Mathematical Ability (b)Verbal Ability (c)Logical Ability (d)Science (e)Social Science",
      },
      {
        class: 9,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNAT Assessment Report will have Analysis on (a)Mathematical Ability (b)Verbal Ability (c)Logical Ability (d)Science (e)Social Science",
      },
      {
        class: 10,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNAT Assessment Report will have Analysis on (a)Mathematical Ability (b)Verbal Ability (c)Logical Ability (d)Science (e)Social Science",
      },
      {
        class: 14,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Mental Ability",
      },
    ],
  },
  bnatTestJee: {
    classAndResultDescriptions: [
      {
        class: 11,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack JEE. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Mathematics",
      },
      {
        class: 12,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack JEE. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Mathematics",
      },
      {
        class: 13,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack JEE. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Mathematics",
      },
    ],
  },
  bnatTestNeet: {
    classAndResultDescriptions: [
      {
        class: 11,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack NEET. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Biology",
      },
      {
        class: 12,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack NEET. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Biology",
      },
      {
        class: 13,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack NEET. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Biology",
      },
    ],
  },
  bnatTestJeeNeet: {
    classAndResultDescriptions: [
      {
        class: 11,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack JEE/NEET. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Mathematics (d)Biology",
      },
      {
        class: 12,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack JEE/NEET. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Mathematics (d)Biology",
      },
      {
        class: 13,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Aptitude Test (BNAT). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them in order to crack JEE/NEET. <br><br>The BNAT Assessment Report will have Analysis on (a)Physics (b)Chemistry (c)Mathematics (d)Biology",
      },
    ],
  },
  bnatTestIASEnglish: {
    classAndResultDescriptions: [
      {
        class: 15,
        description:
          "This report will provide an in-depth description of your responses to the BYJU'S National Scholarship Test (BNST | IAS). It is designed to help you analyze and understand your Learning Skills. It will help you in identifying gaps in your Learning Skills and remedying them. <br><br>The BNST Assessment Report will have Analysis on (a)Polity (b)History (c)Geography (d)Economy (e)Environment and Science & Tech (f)Current Affairs",
      },
    ],
  },
  bnatTestIASHindi: {
    classAndResultDescriptions: [
      {
        class: 15,
        description:
          "यह रिपोर्ट BYJU'S नेशनल स्कॉलरशिप टेस्ट (BNST | IAS) में आपके प्रदर्शन पर विस्तृत विवरण प्रदान करेगी। इसे आपके अधिगम कौशल का विश्लेषण करने और इसे समझने में आपकी मदद करने के लिए प्रारूपित किया गया है। यह आपके अधिगम कौशल में विद्यमान अंतराल की पहचान करने और उन्हें दूर करने में आपकी मदद करेगा।",
      },
    ],
  },
};
