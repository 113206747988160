import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "../Button/Button";
// import { useViewOlap } from "../Utils/UseOlapHooks";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.5rem",
    fontWeight: 500,
    paddingTop: "60px",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.2rem",
    },
  },
  examSummayContainer: {
    backgroundColor: "white",
    paddingBottom: "30px",
  },
  thead: {
    whiteSpace: "nowrap",
  },
  htext: {
    fontWeight: 700,
    borderRight: "1px solid #dddddd",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("lg")]: {
      fontWeight: 500,
    },
  },
  hltext: {
    fontWeight: 700,
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("lg")]: {
      fontWeight: 500,
    },
  },
  rtext: {
    borderRight: "1px solid #dddddd",
    fontFamily: '"GothamBold"',
  },
  rltext: {
    fontFamily: '"GothamBold"',
  },
  table: {
    width: "80%",
    marginLeft: "8%",
    marginBottom: "20px",
    border: "1px solid #dddddd",
  },
  tContainer: {
    marginTop: "30px",
  },
  hrStyle: {
    width: "83%",
    border: "0",
    height: "0",
    borderTop: "1px solid #dddddd",
  },
  confirmBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    paddingTop: "40px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "20%",
      paddingRight: "20%",
    },
  },
  confirmText: {
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.9rem",
    },
  },
  confirmBtn: {
    textTransform: "uppercase",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.9rem",
    },
  },
  btnGrpStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "20px",
  },
  btnConfirm: {
    borderRadius: "0px",
    width: "120px",
  },
}));

function createData(
  totalQuestion,
  answered,
  notAnswered,
  markedForReview,
  AnsweredAndMarked,
  notVisited
) {
  return {
    totalQuestion,
    answered,
    notAnswered,
    markedForReview,
    AnsweredAndMarked,
    notVisited,
  };
}

function ExamSummary({
  totalQuestion,
  answered,
  notAnswered,
  markedForReview,
  AnsweredAndMarked,
  notVisited,
  onConfirmAccept,
  onConfirmReject,
  postTestViewCallback,
}) {
  const classes = useStyles();
  const rows = [
    createData(
      totalQuestion,
      answered,
      notAnswered,
      markedForReview,
      AnsweredAndMarked,
      notVisited
    ),
  ];

  // useViewOlap("postExamSummary", postTestViewCallback);

  return (
    <div id="postExamSummary" className={classes.examSummayContainer}>
      <Typography className={classes.title}>Exam Summary</Typography>
      <TableContainer className={classes.tContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.thead}>
              <TableCell className={classes.htext}>No of Questions</TableCell>
              <TableCell align="center" className={classes.htext}>
                Answered
              </TableCell>
              <TableCell align="center" className={classes.htext}>
                Not Answered
              </TableCell>
              <TableCell align="center" className={classes.htext}>
                Marked for Review
              </TableCell>
              <TableCell align="center" className={classes.htext}>
                Answered & Marked for Review (will be considered for evaluation)
              </TableCell>
              <TableCell align="center" className={classes.hltext}>
                Not Visited
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  className={classes.rtext}
                >
                  {row.totalQuestion}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.answered}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.notAnswered}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.markedForReview}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.AnsweredAndMarked}
                </TableCell>
                <TableCell align="center" className={classes.rltext}>
                  {row.notVisited}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.confirmBox}>
        <Typography className={classes.confirmText}>
          Are you sure you want to submit for final marking?
        </Typography>
        <Typography className={classes.confirmText}>
          No changes will be allowed after submission.
        </Typography>
      </div>
      <div className={classes.btnGrpStyle}>
        <Button
          type="white"
          customStyle={classes.btnConfirm}
          onClick={onConfirmAccept}
        >
          <Typography className={classes.confirmBtn}>Yes</Typography>
        </Button>
        <Button
          type="white"
          customStyle={classes.btnConfirm}
          onClick={onConfirmReject}
        >
          <Typography className={classes.confirmBtn}>No</Typography>
        </Button>
      </div>
    </div>
  );
}
ExamSummary.propTypes = {
  totalQuestion: PropTypes.number.isRequired,
  answered: PropTypes.number.isRequired,
  notAnswered: PropTypes.number.isRequired,
  markedForReview: PropTypes.number.isRequired,
  AnsweredAndMarked: PropTypes.number.isRequired,
  notVisited: PropTypes.number.isRequired,
  onConfirmAccept: PropTypes.func.isRequired,
  onConfirmReject: PropTypes.func.isRequired,
};

export default ExamSummary;
