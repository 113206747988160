import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import InfoButton from "../Button/InfoButton";
import "../../assets/stylesheets/QuestionStepper.css";
import { COLORS } from "../Utils/Constants";
import { changeSubject } from "../Utils/JeeAdvancedTestTaking";

const useStyles = makeStyles((theme) => ({
  infoButtonContainer: {
    overflowX: "auto",
    width: "100%",
    display: "flex",
  },
  sectionContainer: {
    display: "flex",
  },
  button: {
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.GREY}`,
    cursor: "pointer",
    margin: 0,
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function SectionTabs({
  Component,
  subjects,
  onChangeSection,
  currentSubject,
  handleToolTipClick,
}) {
  const classes = useStyles();
  const scrollRef = useRef(null);

  const scrollElement = (direction) => {
    scrollRef.current.scrollTo({
      left:
        direction === "left"
          ? scrollRef.current.scrollLeft - 200
          : scrollRef.current.scrollLeft + 200,
      behavior: "smooth",
    });
  };

  return (
    <div className={classes.sectionContainer}>
      <button
        className={classes.button}
        type="button"
        onClick={() => scrollElement("left")}
      >
        <ChevronLeftRoundedIcon />
      </button>
      <div ref={scrollRef} className={classes.infoButtonContainer}>
        {subjects &&
          subjects.map(({ title, id }) => (
            <InfoButton
              selected={currentSubject && currentSubject.id === id}
              component={Component}
              onToolTipOpen={() => handleToolTipClick(title)}
              key={id}
              onClick={() => changeSubject(id, onChangeSection, subjects)}
            >
              {title}
            </InfoButton>
          ))}
      </div>
      <button
        className={classes.button}
        type="button"
        onClick={() => scrollElement("right")}
      >
        <ChevronRightRoundedIcon />
      </button>
    </div>
  );
}

SectionTabs.propTypes = {
  component: PropTypes.instanceOf(),
};

SectionTabs.defaultProps = {
  component: {},
};

export default SectionTabs;
