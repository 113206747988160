import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import "../../assets/stylesheets/QuizTimer.css";
import { ICONS } from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  timerContainer: {
    padding: "5px",
  },
  timer: {
    padding: "5px",
    height: "45px",
    fontSize: "24px",
    backgroundColor: "rgb(240, 245, 249)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      height: "41px",
    },
  },
}));

function Timer({ time }) {
  const classes = useStyles();
  return (
    <div className={classes.timerContainer}>
      <Chip
        label={`${time}`}
        avatar={<img alt="Timer" src={ICONS.HOURGLASS} />}
        className={classes.timer}
      />
    </div>
  );
}

export default Timer;
