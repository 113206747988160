import React, { useState, useEffect, lazy, Suspense } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import ErrorPage from "./ErrorPage";
import Header from "../components/UtilComponents/Header/Header";
import "../assets/stylesheets/TestSpecific.css";
import {
  FETCH_ASSIGNMENT,
  CLEAR_ASSIGNMENT,
} from "../redux/TestSpecific/TestSpecific.types";
import Loader from "../components/UtilComponents/Loader/Loader";
import { callAssessViewOlapApi } from "../components/Utils/OlapHelpers";
import { navigateToLearn } from "../components/Utils/CommonFunctions";
import error from "../components/Utils/Error";
import "../App.css";
import { EXAM_TYPES } from "../AppConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 2rem",
    [theme.breakpoints.down("md")]: {
      padding: "0 0.75rem",
    },
  },
  rankCard: {
    marginTop: "15px",
  },
}));

const RankCard = lazy(() =>
  import("../components/TestSpecific/OverallResult/RankCard")
);
const SubjectsOverview = lazy(() =>
  import("../components/TestSpecific/SubjectsOverview/SubjectsOverview")
);
const TimeComparision = lazy(() =>
  import("../components/TestSpecific/TimeComparision/TimeComparision")
);
const AnswerReview = lazy(() =>
  import("../components/TestSpecific/SpecificSubject/Question/AnswerReview")
);
const SpecificSubject = lazy(() =>
  import("../components/TestSpecific/SpecificSubject/SpecificSubject")
);

export default function BnatResult() {
  const dispatch = useDispatch();
  const presentState = useSelector((state) => state.testSpecific);
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState("All");
  const [loader, setLoader] = useState(false);
  const bnatGrade = parseInt(sessionStorage.getItem("bnat_grade"), 10);

  document.title = `Byju's | ${
    bnatGrade === 15
      ? EXAM_TYPES.BNST.toUpperCase()
      : EXAM_TYPES.BNAT.toUpperCase()
  } Analysis`;

  const changeSelectedTab = (tabName) => {
    setLoader(true);
    setTimeout(() => setSelectedTab(tabName), 0);
  };

  useEffect(() => {
    setLoader(false);
  }, [selectedTab]);

  useEffect(() => {
    const assignmentId = localStorage.getItem("user_assignment_id");
    const userToken = localStorage.getItem("user_token");
    if (assignmentId === null || userToken === null) {
      navigateToLearn();
    } else {
      dispatch({ type: CLEAR_ASSIGNMENT });
      dispatch({ type: FETCH_ASSIGNMENT });
      localStorage.setItem("NavbarId", assignmentId);
    }
  }, [dispatch]);

  useEffect(() => {
    if (presentState.assignment_id !== undefined) {
      callAssessViewOlapApi(
        presentState.assessment_client_id,
        presentState.assessment_type,
        1
      );
    }
  }, [presentState]);

  const isOnline = useSelector((state) => state.navigator.isOnline);

  return (
    <>
      {isOnline && (
        <div className="container">
          {presentState.assignment_id === undefined && <Loader />}
          {presentState.assignment_id !== undefined && (
            <>
              <Header
                disableLink
                noNotes
                noLanguageSelector
                examType={bnatGrade === 15 ? EXAM_TYPES.BNST : EXAM_TYPES.BNAT}
              />
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.root}
              >
                <Grid item xs={12} lg={12} md={12} className={classes.rankCard}>
                  <Suspense fallback={<Loader />}>
                    <RankCard
                      userRank={presentState.rank}
                      percentile={presentState.percentile}
                      cutOffScore={presentState.cutoff_score}
                      cutOffMsg={presentState.cutoff_message}
                      assessmentType={presentState.assessment_type}
                      assessmentCategory={presentState.assessment_category}
                      assessmentId={presentState.assessment_client_id}
                      totalScore={presentState.score}
                      noCutOffScore
                      noRankInfo
                      noRank={bnatGrade <= 10}
                    />
                  </Suspense>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <Suspense fallback={<Loader />}>
                    <SubjectsOverview />
                  </Suspense>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <Suspense fallback={<Loader />}>
                    <TimeComparision presentState={presentState} />
                  </Suspense>
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={10}
                  md={10}
                  style={{ backgroundColor: "#cee1f1" }}
                />
              </Grid>
              <div
                className="container"
                style={{ backgroundColor: "#cee1f1", display: "flex" }}
              >
                <Suspense fallback={<Loader />}>
                  <AnswerReview
                    presentState={presentState}
                    changeSelectedTab={changeSelectedTab}
                    selectedTab={selectedTab}
                  />
                </Suspense>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  className={classes.root}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    style={{ backgroundColor: "#cee1f1" }}
                  >
                    {!loader ? (
                      <Suspense fallback={<Loader />}>
                        <SpecificSubject selectedTab={selectedTab} noNotes />
                      </Suspense>
                    ) : (
                      <Loader />
                    )}
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </div>
      )}
      {!isOnline && (
        <div className="ErrorPage">
          <ErrorPage
            code="No Internet"
            title={error["No Internet"].title}
            description={error["No Internet"].description}
            ctaTitle={error["No Internet"].cta.title}
            ctaAction={error["No Internet"].cta.action}
          />
        </div>
      )}
    </>
  );
}
