/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { getIntersectionInfo, isElementScrollable } from "./OlapHelpers";

const intitalValue = {
  verticallyScrollable: false,
  horizontallyScrollable: false,
};

export default function useScrollableElement(elementId) {
  const [isScrollable, setIsElementScrollable] = useState(intitalValue);
  useEffect(() => {
    if (elementId) {
      const scrollable = isElementScrollable(elementId);
      setIsElementScrollable(scrollable);
    }
  }, [elementId]);
  return isScrollable;
}

export function useViewOlap(elementId, callback) {
  useEffect(() => {
    const element = document.getElementById(elementId);
    const observer = getIntersectionInfo(
      null,
      elementId,
      "0.5px",
      0.5,
      callback
    );
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);
}
