import { create } from "apisauce";
import { PRODUCTION_DATA, STAGING_DATA, DEVELOPMENT_DATA } from "../AppConfig";

let baseURL;

if (process.env.REACT_APP_BUILD_ENV === "staging") {
  baseURL = STAGING_DATA.BASE_URL;
} else if (process.env.REACT_APP_BUILD_ENV === "production") {
  baseURL = PRODUCTION_DATA.BASE_URL;
} else {
  baseURL = DEVELOPMENT_DATA.BASE_URL;
}

const api = create({
  baseURL,
  headers: {
    Accept: "application/json",
    Authorization: localStorage.getItem("user_token"),
  },
});

export default api;
