import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "../../assets/stylesheets/MockDetail.css";
import ReactHTMLParser from "react-html-parser";
import { BNAT_TEST_CONSTANTS, MOCK_TEST_CONSTANTS } from "../../AppConfig";
import initiateIntersectionObserver from "../Utils/IntersectionObserver";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: "#6d7278",
    fontSize: "2rem",
    fontWeight: "600",
    marginBottom: "1.5rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  desCardTitle: {
    paddingBottom: "0.5rem",
    fontSize: "1rem",
    borderBottom: "1px solid #d8d8d8",
    marginBottom: "1rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  descCardDesc: {
    fontSize: "0.7rem",
  },
  OrngClr: {
    color: "#c254f5",
  },
}));
export default function FaqSection({ isMockTest, setActiveNav }) {
  const classes = useStyles();

  useEffect(() => {
    initiateIntersectionObserver(
      document.querySelector("#faqSection"),
      0.45,
      setActiveNav,
      "3"
    );
  }, [setActiveNav]);

  return (
    <div id="faqSection">
      <Typography variant="h4" className={classes.sectionTitle}>
        FAQs
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            What if I am not able to login on time?
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            The link to start the test will remain active for 30 mins post the
            scheduled start time. Please ensure to login within this window to
            avail the full test duration for attempting the test.
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            *In case of disconnection due to internet problems and power
            failure, test will resume from where you left.
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {`What if I want to appear for ${
              isMockTest ? "BYJU'S Mock Test" : "BNAT"
            } at a physical
            center?`}
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              {`${
                isMockTest ? "BYJU'S Mock Test" : "BNAT"
              } is online. You can choose any place as per
              your convenience to login on your phone, tablet, or laptop to
              appear for the test.`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {`On which device should I log in to appear in ${
              isMockTest ? "BYJU'S Mock Test" : "BNAT"
            } ?`}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            Any Android, iOS, Windows or Mac device supporting Chrome, Safari or
            Mozilla Firefox browser can be used to appear in the exam. We
            currently do not support any version of Internet Explorer or Edge.
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            Though, desktops and laptops are preferred as they offer a stable
            internet connectivity, and an easier user interface to take an
            online test.
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            What if I forget my Login Username and Password?
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              Only way to login is using a registered Mobile number and a system
              generated password. You can click on forget password to resend the
              password to you.
            </Typography>
            <Typography paragraph className={classes.descCardDesc}>
              {`Send an email to `}
              <span>
                <a
                  href={`mailto:${
                    isMockTest
                      ? MOCK_TEST_CONSTANTS.JEE_CUSTOMER_SUPPORT_EMAIL
                      : BNAT_TEST_CONSTANTS.BNAT_CUSTOMER_SUPPORT_EMAIL
                  }`}
                >
                  <b className={classes.OrngClr}>
                    {isMockTest
                      ? MOCK_TEST_CONSTANTS.JEE_CUSTOMER_SUPPORT_EMAIL
                      : BNAT_TEST_CONSTANTS.BNAT_CUSTOMER_SUPPORT_EMAIL}
                  </b>
                </a>
              </span>
              {` if you're unable to
              access with your registered mobile number.`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {`I am an existing student at BYJU'S, Can I appear for
             ${isMockTest ? "BYJU'S Mock Test" : "BNAT"} ?`}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {`Yes. All eligible students, even BYJU'S students, can appear
            for ${
              isMockTest
                ? "BYJU'S Mock Test. But, they'll have to register for this test on the learn portal."
                : "BNAT. But the scholarship availed can only be used for enrolling in fresh courses."
            }`}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {`Can I take ${
              isMockTest ? "BYJU'S Mock Test" : "BNAT"
            } from multiple devices?`}
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              To ensure a transparent and fair experience to all participants,
              any attempt to change the device or browser will result in exam
              termination.
            </Typography>
            <Typography paragraph className={classes.descCardDesc}>
              One student can appear for the exam on only one device and one
              browser. Therefore, before the exam make sure your device,
              internet connectivity, browser, etc. are functioning properly.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            Dos and Don&apos;ts on Laptop/Desktop
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {ReactHTMLParser(
              `<ol style="padding-left:20px;">
            <li>Do not exit Full Screen (the test will end)</li>
            <li>Do not press Esc (the test will end)</li>
            <li>Alt+TAB will also end the test</li> 
            <li>Test your internet connection and browser on your device before starting the test</li>
            <li>Do not change the browser on the device (the test will end)</li> 
            <li>Do not click on any pop up on your system not related to ${
              isMockTest ? "BYJU'S Mock Test" : "BNAT"
            }. All your activities will be tracked during the test</li>
            </ol>`
            )}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            Dos and Don&apos;ts on Mobile/Tablet
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              {ReactHTMLParser(
                `<ol style="padding-left:20px;">
                <li>Do not press Home/Back (the test will end)</li> 
                <li>Do not switch OFF the device (the test will end)</li>
                <li>Please ensure that your device's memory and storage are sufficient, battery is charged, and the
              internet is functioning well, before the test starts</li>
              <li>Do not change the browser on the device (the test will end)</li> 
              <li>Do not click on any pop up on your system not related to ${
                isMockTest ? "BYJU'S Mock Test" : "BNAT"
              }. All your activities will be tracked during the test.</li>
              </ol>`
              )}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            Can I use a calculator?
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {isMockTest
              ? "No, you can not."
              : "Yes, you can, But questions in the test will not be so simple to solve."}
          </Typography>
        </Grid>
        {!isMockTest && (
          <Grid item md={6}>
            <Typography className={classes.desCardTitle}>
              Will I get a scholarship on top of my existing discount?
            </Typography>
            <Typography paragraph className={classes.descCardDesc}>
              No. A student can avail only one scholarship or discount at a
              time. Lowest calculated fee after availing a scholarship/discount
              will be applicable.
            </Typography>
          </Grid>
        )}
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            What if I have any further query?
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              {`Please send your queries to `}
              <span>
                <a
                  href={`mailto:${
                    isMockTest
                      ? MOCK_TEST_CONSTANTS.JEE_CUSTOMER_SUPPORT_EMAIL
                      : BNAT_TEST_CONSTANTS.BNAT_CUSTOMER_SUPPORT_EMAIL
                  }`}
                >
                  <b className={classes.OrngClr}>
                    {isMockTest
                      ? MOCK_TEST_CONSTANTS.JEE_CUSTOMER_SUPPORT_EMAIL
                      : BNAT_TEST_CONSTANTS.BNAT_CUSTOMER_SUPPORT_EMAIL}
                  </b>
                </a>
              </span>
              {` or call on `}
              <span>
                <a
                  href={`tel:+91${
                    isMockTest
                      ? MOCK_TEST_CONSTANTS.JEE_CUSTOMER_SUPPORT_TELEPHONE
                      : BNAT_TEST_CONSTANTS.BNAT_CUSTOMER_SUPPORT_TELEPHONE
                  }`}
                >
                  <b className={classes.OrngClr}>
                    {isMockTest
                      ? MOCK_TEST_CONSTANTS.JEE_CUSTOMER_SUPPORT_TELEPHONE
                      : BNAT_TEST_CONSTANTS.BNAT_CUSTOMER_SUPPORT_TELEPHONE}
                  </b>
                </a>
              </span>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
