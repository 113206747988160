import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import TestSpecific from "./components/TestSpecific/TestSpecific";
import MockTestDetail from "./components/MockTestDetail/MockTestDetail";
import ComprehensiveNotes from "./components/Notes/ComprehensiveNotes/ComprehensiveNotes";
import Authenticate from "./components/UtilComponents/Authenticate/Authenticate";
import SWOT from "./components/SWOT/SWOT";
import OAuth from "./components/UtilComponents/OAuth/OAuth";
import StartTest from "./components/UtilComponents/StartTest/StartTest";
import WebTech from "./components/UtilComponents/WebTech/WebTech";
import QuizOfTheDay from "./pages/QuizOfTheDay";
import QuizResult from "./pages/QuizResult";
import ErrorPage from "./pages/ErrorPage";
import error from "./components/Utils/Error";
import {
  INTERNET_CONNECTION_DISCONNECTED,
  INTERNET_CONNECTION_RESTORED,
} from "./redux/InternetConnection/InternetConnection.types";
import BNAT from "./pages/BNAT";
import TestTakingPageRouter from "./router/TestTakingPageRouter";
import BnatResult from "./pages/BnatResult";

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Gotham"',
    fontWeightLight: 100,
    fontWeightMedium: 200,
    fontWeightBold: 500,
  },
});

function App() {
  const dispatch = useDispatch();

  window.addEventListener("offline", () => {
    dispatch({ type: INTERNET_CONNECTION_DISCONNECTED });
  });

  window.addEventListener("online", () => {
    dispatch({ type: INTERNET_CONNECTION_RESTORED });
  });

  return (
    <Router>
      <Switch>
        <Route path="/take-test">
          <MuiThemeProvider theme={theme}>
            <div className="TestPage">
              <TestTakingPageRouter />
            </div>
          </MuiThemeProvider>
        </Route>
        <Route exact path="/daily-quiz">
          <MuiThemeProvider theme={theme}>
            <div className="QuizPage">
              <QuizOfTheDay />
            </div>
          </MuiThemeProvider>
        </Route>
        <Route exact path="/daily-quiz/result/:assignment">
          <MuiThemeProvider theme={theme}>
            <div className="QuizResult">
              <QuizResult />
            </div>
          </MuiThemeProvider>
        </Route>
        <Route path="/notes">
          <MuiThemeProvider theme={theme}>
            <ComprehensiveNotes />
          </MuiThemeProvider>
        </Route>
        <Route path="/mocktest-detail">
          <MuiThemeProvider theme={theme}>
            <div className="MockDetailPage">
              <MockTestDetail />
            </div>
          </MuiThemeProvider>
        </Route>
        <Route path={["/bnat-detail", "/bnst-detail"]}>
          <MuiThemeProvider theme={theme}>
            <div className="BnatDetailPage">
              <BNAT />
            </div>
          </MuiThemeProvider>
        </Route>
        <Route path={["/bnat-result", "/bnst-result"]}>
          <MuiThemeProvider theme={theme}>
            <div className="App">
              <BnatResult />
            </div>
          </MuiThemeProvider>
        </Route>

        <Route path="/authenticate" component={Authenticate} />
        <Route path="/redirect" component={OAuth} />
        <Route path="/start_test" component={StartTest} />
        <Route
          path="/webtech_mocktest"
          component={() => <WebTech isMockTest />}
        />
        <Route
          path="/webtech-bnat"
          component={() => <WebTech isMockTest={false} />}
        />

        <Route path="/test-analysis">
          <MuiThemeProvider theme={theme}>
            <div className="App">
              <TestSpecific />
            </div>
          </MuiThemeProvider>
        </Route>

        <Route exact path="/">
          <MuiThemeProvider theme={theme}>
            <div className="App">
              <SWOT />
            </div>
          </MuiThemeProvider>
        </Route>
        <Route path="/error">
          <MuiThemeProvider theme={theme}>
            <div className="ErrorPage">
              <ErrorPage />
            </div>
          </MuiThemeProvider>
        </Route>
        <Route>
          <MuiThemeProvider theme={theme}>
            <div className="ErrorPage">
              <ErrorPage
                code={404}
                title={error[404].title}
                description={error[404].description}
                ctaTitle={error[404].cta.title}
                ctaAction={error[404].cta.action}
              />
            </div>
          </MuiThemeProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
