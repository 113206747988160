/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { ICONS } from "../../AppConfig";
import advancedStaticData from "../../assets/js/advancedStaticData";
import Box from "../Box/Box";

const AdvancedInstructions = ({ classes }) => {
  return (
    <>
      <li className={classes.text}>
        The on-screen computer countdown timer on the top right corner of
        computer screen will display the remaining time (in minutes) available
        to you for completing the paper. When the on-screen countdown timer
        reaches zero, the paper will end by itself – No input from your side
        will be accepted after the timer reaches zero and whatever answers have
        been saved by you will automatically be submitted for evaluation.
      </li>
      <li className={classes.text}>
        The Question Palette displayed on the right side of screen will show the
        status of each question as per one of the following symbols (the
        question numbers appear inside the symbols):
      </li>
      <li className={classes.text}>
        You can click on the "&gt;" arrow symbol, which appears to the left of
        question palette, to collapse the question palette thereby maximizing
        the question window. To view the question palette again, you can click
        on "&lt;" symbol which appears on the right side of question window.
      </li>
      <li className={classes.text}>
        The Question Palette displayed on the right side of screen will show the
        status of each question as per one of the following symbols (the
        question numbers appear inside the symbols):
        <table>
          {advancedStaticData.instructionsBoxTable.map(
            ({ id, status, text1, text2 }) => (
              <>
                <tr key={id}>
                  <td>
                    <Box status={status} />
                  </td>
                  <td>
                    <b className={classes.titleInstruction}>"{text1}"</b>-{" "}
                    {text2}
                  </td>
                </tr>
              </>
            )
          )}
        </table>
      </li>
      <li className={classes.text}>
        You can click on downward arrow symbol 
        <span>
          <img
            src={ICONS.ARROW_CIRCLE}
            className={classes.arrowCircle}
            alt="Down Cirlce"
          />
        </span>
        to navigate to the bottom and upward arrow
        <span>
          <img
            src={ICONS.ARROW_CIRCLE}
            className={classes.arrowCircleUp}
            alt="Up Cirlce"
          />
        </span>
          to navigate to the top of the question area, without scrolling.
      </li>
      <li className={classes.text}>
        You can also use the computer mouse to scroll up/down the question
        viewing area to view complete contents of the question viewing area.
      </li>
      <li className={classes.text}>
        At the end of the "Instructions to Candidates" section, you must click
        on the checkbox beside the 
        <q>I have read all the instructions and shall abide by them </q>
         and then only you will be able to proceed to view and answer the
        questions at the start of the paper.
      </li>
      <p className={classes.boldText}>
        NAVIGATING THROUGH PARTS/SECTIONS OF QUESTION PAPER:
      </p>
      <li className={classes.text}>
        Parts and sections of the parts thereof in the question paper are
        displayed on the top of the screen. Questions within a section can be
        viewed by clicking on the corresponding section name. The section which
        you will be viewing will be highlighted.
      </li>
      <li className={classes.text}>
        After clicking the “Save & Next” button on the last question of a
        part/section, you will automatically be taken to the first question of
        the next part/section.
      </li>
      <li className={classes.text}>
        You can navigate between parts/sections and questions within
        parts/sections anytime during the paper as per your convenience.
      </li>
      <li className={classes.text}>
        You can view the corresponding section summary which will be visible in
        every section above the question palette.
      </li>
      <p className={classes.boldText}>Navigating to a Question:</p>
      <div className={classes.text}>
        To navigate between questions, you need to do the following:
      </div>
      <li className={classes.text}>
        Click on the question number in the Question Palette at the right of the
        screen to go to that numbered question directly. Note that using this
        option does NOT save the answer (if it is answered) to the current
        question. To save the answer, you must click on “Save & Next” button.
      </li>
      <li className={classes.text}>
        Click on “Save & Next” button to save the answer for the current
        question and then go to the next question.
      </li>
      <li className={classes.text}>
        Click on “Mark for Review & Next” button to mark it for review (with or
        without answering the question) and go to the next question.
      </li>
      <p className={classes.boldText}>ANSWERING A QUESTION:</p>
      <li className={classes.text}>
        Follow the procedure, given in “Instructions to
        Candidates” section(click on 'Next' below), for answering a particular
        type of question.
      </li>
      <li className={classes.text}>
        To change the answer of a question that has already been answered, if
        required, first click on the “Clear Response” button to clear the saved
        answer and then follow the procedure for answering that type of
        question.
      </li>
      <li className={classes.text}>
        To mark a question ONLY for review (i.e. without answering it), click on
        the “Mark for Review & Next” button.
      </li>
      <li className={classes.text}>
        To mark a question for review (after answering it), click on “Mark for
        Review & Next” button – the answered question which is also marked for
        review will be evaluated.
      </li>
      <li className={classes.text}>
        To save the answer, click on the “Save & Next” button - the answered
        question will be evaluated.
      </li>
      <p className={classes.boldText}>END OF PAPER:</p>
      <li className={classes.text}>
        When the on-screen computer countdown timer on the top right corner of
        computer screen reaches zero, the paper will end by itself – No input
        from your side will be accepted after the timer reaches zero and
        whatever answers have been saved by you will be automatically submitted
        for evaluation. Note that It is required to click on “Submit” button to
        end the paper before the timer has reached zero.Saved responses will be
        automatically submitted.
      </li>
    </>
  );
};

export default AdvancedInstructions;
