/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
import Header from "../components/UtilComponents/Header/Header";
import QuestionStepper from "../components/Quiz/QuestionStepper";
import Timer from "../components/Quiz/Timer";
import Question from "../components/Question/Question";
import PopUp from "../components/UtilComponents/PopUp/PopUp";
import {
  showNoSelectionAlert,
  closePopup,
  startTimer,
  timeFormatter,
  countdown,
} from "../components/Utils/quiz";
import { GENERATE_ASSESSMENT } from "../redux/GenerateAssessment/GenerateAssessment.types";
import {
  LOAD_ASSIGNMENT,
  ATTEMPT_QUESTION,
  UPDATE_QUESTIONS,
} from "../redux/TestTaking/TestTaking.types";
import {
  formatBoxContainerQuestions,
  formatAttemptPayload,
  updateTransformQuestion,
} from "../components/Utils/TestTaking";
import Loader from "../components/UtilComponents/Loader/Loader";
import { SUBMIT_TEST_USER_REQUEST } from "../redux/PostTest/PostTest.types";
import { quizViewOlap, submitQuizOlap } from "../components/Utils/OlapCallback";
import { navigateToLearn } from "../components/Utils/CommonFunctions";
import { NETWORK } from "../AppConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  newButton: {
    width: "144px",
    height: "40px",
    borderRadius: "1.5rem",
    boxShadow: "0 3px 6px 0 rgba(91, 22, 170, 0.17)",
    backgroundImage: "linear-gradient(to right, #9752ff, #5e93ff)",
    textAlign: "center",
    color: "white",
    border: "none",
    cursor: "pointer",
    padding: ".5rem 1rem",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "120px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      paddingLeft: "25px",
      paddingRight: "25px",
    },
  },
  quizBody: {
    padding: "40px",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      padding: "100px 200px",
    },
    overflowX: "scroll",
  },
  questionButtonContainer: {
    width: "100%",
    borderRadius: "12px",
    boxShadow: "0 7px 19px 0 rgba(129, 129, 129, 0.24)",
    border: "solid 1.5px rgba(151, 151, 151, 0)",
    backgroundColor: "white",
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
}));

function QuizOfTheDay() {
  document.title = "Byju's | Daily Quiz";

  /* States */
  const [activeStep, setActiveStep] = React.useState(0);
  const [popup, setPopup] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [timer, setTimer] = React.useState({
    timerStart: !sessionStorage.getItem("timer_start_epoch")
      ? Date.now()
      : Number(sessionStorage.getItem("timer_start_epoch")),
    timerTime: 0,
  });
  const [currentQuestion, setCurrentQuestion] = React.useState();
  const [currentAnswer, setCurrentAnswer] = React.useState();
  const [questionTimer, setQuestionTimer] = React.useState();
  const [showResult, setShowResult] = React.useState(false);
  const [startCountdown, setStartCountdown] = React.useState(false);
  const [totalQuizTimeRemaining, setTotalQuizTimeRemaining] = React.useState();
  const [examType, setExamType] = React.useState("");
  const [timerStartEpoch, setTimerStartEpoch] = React.useState(0);

  /* Constants */
  const classes = useStyles();
  const dispatch = useDispatch();
  const testSubmitted = sessionStorage.getItem("test_submitted") || "";
  const questionSubmitted = sessionStorage.getItem("question_submitted") || "";
  const testClientId = sessionStorage.getItem("test_client_id") || "";
  const userToken = localStorage.getItem("user_token");
  /* Selectors */
  const assignment = useSelector((state) => state.assignment);
  const questions = useSelector((state) =>
    formatBoxContainerQuestions(state.testTaking.questions)
  );
  const quizMeta = useSelector((state) => state.testTaking);
  const isOnline = useSelector((state) => state.navigator.isOnline);

  /* Functions */
  const redirectToResultPage = () => {
    setShowResult(true);
  };

  const submitCall = () => {
    dispatch({
      type: SUBMIT_TEST_USER_REQUEST,
      payload: assignment.assignment.id,
    });
    sessionStorage.setItem("test_submitted", "true");
    submitQuizOlap(testClientId, examType, questions.length);
  };

  const handleButtonClick = () => {
    if (!currentAnswer || !currentAnswer.value || currentAnswer.length === 0) {
      showNoSelectionAlert(setPopup, setPopupMessage);
    } else {
      const timeSpent = (Date.now() - questionTimer) / 1000;
      dispatch({
        type: ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer: currentAnswer.value,
          assignmentID: quizMeta.subjects[0].id,
          time: timeSpent,
          type: "answered",
          questionID: currentQuestion.id,
        }),
      });
      const updatedQuestions = updateTransformQuestion({
        type: currentAnswer.type,
        questions,
        answer: currentAnswer.value,
        id: currentQuestion.id,
        flag: "answered",
      });
      dispatch({ type: UPDATE_QUESTIONS, payload: updatedQuestions });
      if (activeStep < questions.length - 1) {
        setCurrentAnswer(questions[activeStep + 1].answer);
        setCurrentQuestion(questions[activeStep + 1]);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setQuestionTimer(Date.now());
      }
    }
  };

  const onSelectAnswer = (answer) => {
    setCurrentAnswer(answer);
  };

  React.useEffect(() => {
    if (userToken === null || testClientId === "" || testClientId === null) {
      navigateToLearn();
    } else {
      dispatch({
        type: GENERATE_ASSESSMENT,
        payload: testClientId,
      });
    }
  }, []);

  React.useEffect(() => {
    if (assignment.assignment !== undefined) {
      setExamType(assignment.assignment.assessment.type);
      setTimerStartEpoch(assignment.assignment.started_at);
      dispatch({
        type: LOAD_ASSIGNMENT,
        payload: assignment.assignment.id,
        meta: { isAdvanced: false },
      });
    }
  }, [assignment]);

  React.useEffect(() => {
    if (!currentQuestion && questions.length) {
      if (testSubmitted === "true") {
        setShowResult(true);
      }
      const startIndex = questions.findIndex((ques) => ques.answer === null);
      setActiveStep(startIndex);
      setCurrentQuestion(questions[startIndex]);
      startTimer(timer, setTimer, timerStartEpoch);
      setQuestionTimer(Date.now());
      quizViewOlap(testClientId, examType, questions.length);
    }
  }, [questions]);

  React.useEffect(() => {
    if (!currentAnswer && currentQuestion && currentQuestion.answer) {
      setCurrentAnswer(currentQuestion.answer);
    }
  }, [currentAnswer, currentQuestion]);

  React.useEffect(() => {
    if (quizMeta.timeRemaining !== undefined) {
      setTotalQuizTimeRemaining(quizMeta.timeRemaining);
      setStartCountdown(true);
    }
  }, [quizMeta]);

  React.useEffect(() => {
    if (startCountdown === true) {
      countdown(setTotalQuizTimeRemaining, handleButtonClick);
    }
  }, [startCountdown]);

  React.useEffect(() => {
    if (totalQuizTimeRemaining < 1) {
      submitCall();
    }
  }, [totalQuizTimeRemaining]);

  React.useEffect(() => {
    if (questions.length > 1) {
      if (questionSubmitted === questions[questions.length - 1].id) {
        submitCall();
        sessionStorage.removeItem("question_submitted");
      }
    }
  }, [questionSubmitted]);

  React.useEffect(() => {
    if (quizMeta && quizMeta.redirect === true) {
      if (sessionStorage.getItem("test_submitted") === "true") {
        redirectToResultPage();
      }
    }
  }, [quizMeta]);

  React.useEffect(() => {
    if (assignment && assignment.loading === "redirect") {
      if (sessionStorage.getItem("test_submitted") === "true") {
        navigateToLearn();
      }
    }
  }, [assignment]);

  React.useEffect(() => {
    if (!isOnline) {
      setPopup(true);
      setPopupMessage(NETWORK.OFFLINE_MESSAGE);
    } else {
      setPopup(false);
    }
  }, [isOnline]);

  if (showResult === true) {
    return <Redirect to={`/daily-quiz/result/${assignment.assignment.id}`} />;
  }

  return (
    <>
      {questions.length === 0 && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
      {questions.length !== 0 && (
        <div className={classes.root}>
          <Header disableLink noNotes examType={examType} quiz />
          <PopUp
            message={popupMessage}
            open={popup}
            close={() => {
              closePopup(setPopup);
            }}
          />
          <Grid
            container
            direction="column"
            justify="center"
            className={classes.quizBody}
          >
            <Grid item>
              <Timer time={timeFormatter(timer.timerTime)} />
            </Grid>
            <Grid item xs={10} md={10} lg={10} style={{ width: "100%" }}>
              <QuestionStepper
                questionCount={questions.length}
                activeStep={activeStep}
              />
            </Grid>
            <Grid className={classes.questionButtonContainer}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{
                  width: "100%",
                  overflowX: "scroll",
                }}
              >
                <Question
                  onChangeOption={(option) => {
                    onSelectAnswer(option);
                  }}
                  question={currentQuestion && currentQuestion.question}
                  options={currentQuestion && currentQuestion.options}
                  type={currentQuestion && currentQuestion.type}
                  value={currentAnswer}
                  sequence={currentQuestion && currentQuestion.questionNumber}
                  quiz
                />
              </Grid>
              <Grid item className={classes.buttonContainer}>
                <button
                  type="button"
                  className={classes.newButton}
                  onClick={handleButtonClick}
                >
                  {activeStep < questions.length - 1 ? "Next" : "Submit"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default QuizOfTheDay;
