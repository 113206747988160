import { ICONS } from "../../AppConfig";

export default {
  400: {
    icon: ICONS.ERROR_400,
    title: "Invalid Session",
    description:
      "The request could not be understood by the server due to technical issues. Kindly close the current tab and try to sign-in again.",
    cta: {
      title: null,
      action: null,
    },
  },
  401: {
    icon: ICONS.ERROR_401,
    title: "Authorization Required",
    description: "Looks like you aren't authenticated to access the page.",
    cta: {
      title: null,
      action: null,
    },
  },
  403: {
    icon: ICONS.ERROR_403,
    title: "Access Denied",
    description: "You don't have permission to access the page.",
    cta: {
      title: null,
      action: null,
    },
  },
  404: {
    icon: ICONS.ERROR_404,
    title: "Page Not Found",
    description: "Not your fault but we could not find what you are looking.",
    cta: {
      title: "Go Home",
      action: "/",
    },
  },
  406: {
    icon: ICONS.ERROR_406,
    title: "Invalid Session",
    description:
      "The request could not be understood by the server due to technical issues. Kindly close the current tab and try to sign-in again.",
    cta: {
      title: null,
      action: null,
    },
  },
  500: {
    icon: ICONS.ERROR_500,
    title: "Internal Server Error",
    description:
      "The server encountered a temporary error, please try again after sometime.",
    cta: {
      title: null,
      action: null,
    },
  },
  "No Internet": {
    icon: ICONS.ERROR_NO_NETWORK,
    title: "No Internet",
    description:
      "Please check your internet connection settings and try again.",
    cta: {
      title: null,
      action: null,
    },
  },
  fallback: {
    title: "Something Went Wrong",
    description: "Seems like there is a technical issue, try again later.",
    cta: {
      title: null,
      action: null,
    },
  },
};
