import {
  OLAPEventIds,
  OLAPKingdoms,
  callNotesOlapApi,
  createOlapQuestionData,
  olapPayloadGenerator,
  callScrollOlapApi,
  getUserInfo,
} from "./OlapHelpers";
import { postOLAP } from "../../services/OLAP.api";

export const instructionsViewCallback = (assessmentId, examType, e) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      OLAPEventIds.VIEW_INSTRUCTIONS,
      "view",
      "view instructions page",
      OLAPKingdoms.VIEW_INSTRUCTIONS,
      "view_instructions_page",
      null,
      null,
      null,
      null,
      assessmentId,
      null,
      null,
      examType,
      null,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  }
};

export const noteEditorViewCallback = (
  subject,
  comprehensive,
  question,
  assessmentId,
  examType,
  numNotes,
  e
) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    callNotesOlapApi(
      OLAPEventIds.VIEW_NOTE_EDITOR,
      "view note editor",
      "view_note_editor",
      OLAPKingdoms.VIEW_NOTE_EDITOR,
      subject,
      !comprehensive && question ? createOlapQuestionData(question) : null,
      assessmentId,
      examType,
      comprehensive ? "comprehensive" : "assessment",
      numNotes,
      subject ? [subject] : [],
      0,
      0,
      "view"
    );
  }
};

export const notePageViewCallback = (
  subject,
  comprehensive,
  question,
  assessmentId,
  examType,
  numNotes,
  e
) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    callNotesOlapApi(
      OLAPEventIds.VIEW_NOTES,
      "view notes page",
      "view_notes",
      OLAPKingdoms.VIEW_NOTES,
      subject,
      !comprehensive && question ? createOlapQuestionData(question) : null,
      assessmentId,
      examType,
      comprehensive ? "comprehensive" : "assessment",
      numNotes,
      subject ? [subject] : [],
      0,
      0,
      "view"
    );
  }
};

export const navbarScrollCallback = (isNavbarScrollable, scrollInfo) => {
  callScrollOlapApi(
    OLAPEventIds.SCROLL_NAVIGATION,
    "scrolling navbar",
    OLAPKingdoms.SCROLL_NAVIGATION,
    "scroll_navbar",
    null,
    null,
    null,
    {
      ...isNavbarScrollable,
      ...scrollInfo,
    }
  );
};

export const rankChartViewCallback = (e) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      OLAPEventIds.VIEW_RANKCHART,
      "view",
      "view rank chart",
      OLAPKingdoms.VIEW_RANKCHART,
      "view_rank_chart",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  }
};

export const rankChartScrollCallback = (isRankchartScrollable, scrollInfo) => {
  callScrollOlapApi(
    OLAPEventIds.SCROLL_RANKCHART,
    "scrolling rank chart",
    OLAPKingdoms.SCROLL_RANKCHART,
    "scroll_rankchart",
    null,
    null,
    null,
    {
      ...isRankchartScrollable,
      ...scrollInfo,
    }
  );
};

export const solutionViewCallback = (assessmentId, examType, question, e) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      OLAPEventIds.VIEW_SOLUTION,
      "view",
      "view question solution",
      OLAPKingdoms.VIEW_SOLUTION,
      "view_question_solution",
      null,
      null,
      createOlapQuestionData(question),
      null,
      assessmentId,
      null,
      null,
      examType,
      null,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  }
};

const callRankcardViewOlap = (
  uEventId,
  description,
  kingdom,
  counter,
  record,
  variety,
  param
) => {
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    uEventId,
    "view",
    description,
    kingdom,
    counter,
    null,
    null,
    null,
    null,
    record,
    null,
    null,
    variety,
    param,
    null,
    null,
    null
  );
  postOLAP(olapPayload);
};

export const rankCardViewCallback = (
  parent,
  assessmentId,
  assessmentType,
  userRank,
  percentile,
  cutOffScore,
  e
) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    callRankcardViewOlap(
      parent === "overall"
        ? OLAPEventIds.VIEW_GREETING_CARD
        : OLAPEventIds.VIEW_RANK_CARD,
      parent === "overall" ? "view greeting card" : "view rank card",
      parent === "overall"
        ? OLAPKingdoms.VIEW_GREETING_CARD
        : OLAPKingdoms.VIEW_RANK_CARD,
      parent === "overall" ? "view_greeting_card" : "view_rank_card",
      parent === "overall" ? null : assessmentId,
      parent === "overall" ? null : assessmentType,
      parent === "overall"
        ? null
        : {
            rank: userRank,
            percentile: parseFloat(percentile),
            cutoff_score: cutOffScore,
          }
    );
  }
};

export const timeComparisonViewCallback = (assessmentId, assessmentType, e) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      OLAPEventIds.VIEW_TIME_COMPARISON,
      "view",
      "view time comparison chart",
      OLAPKingdoms.VIEW_TIME_COMPARISON,
      "view_time_comparison",
      null,
      null,
      null,
      null,
      assessmentId,
      null,
      null,
      assessmentType,
      null,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  }
};

const postTestOlapApi = (
  uEventId,
  description,
  kingdom,
  counter,
  record,
  variety
) => {
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    uEventId,
    "view",
    description,
    kingdom,
    counter,
    null,
    null,
    null,
    null,
    record,
    null,
    null,
    variety,
    null,
    null,
    null,
    null
  );
  postOLAP(olapPayload);
};

export const postTestViewCallback = (
  finalPage,
  postTestScreen,
  assessmentId,
  assessmentType,
  e
) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    const postTestCondition = finalPage || postTestScreen;
    postTestOlapApi(
      postTestCondition
        ? OLAPEventIds.VIEW_EXAM_COMPLETION
        : OLAPEventIds.VIEW_EXAM_SUMMARY,
      postTestCondition ? "view exam completion page" : "view exam summary",
      postTestCondition
        ? OLAPKingdoms.VIEW_EXAM_COMPLETION
        : OLAPKingdoms.VIEW_EXAM_SUMMARY,
      postTestCondition ? "view_exam_completion" : "view_exam_summary",
      assessmentId,
      assessmentType
    );
  }
};

export const answerReviewScrollCallback = (
  isAnswerreviewScrollable,
  assessmentId,
  assessmentType,
  scrollInfo
) => {
  callScrollOlapApi(
    OLAPEventIds.SCROLL_FILTER,
    "scrolling question filters",
    OLAPKingdoms.SCROLL_FILTER,
    "scroll_question_filter",
    null,
    assessmentId,
    assessmentType,
    {
      ...isAnswerreviewScrollable,
      ...scrollInfo,
    }
  );
};

const callScorecardViewOlap = (
  uEventId,
  description,
  kingdom,
  counter,
  record,
  variety
) => {
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    uEventId,
    "view",
    description,
    kingdom,
    counter,
    null,
    null,
    null,
    null,
    record,
    null,
    null,
    variety,
    null,
    null,
    null,
    null
  );
  postOLAP(olapPayload);
};

export const scorecardViewCallback = (
  parent,
  assessmentId,
  assessmentType,
  e
) => {
  if (Array.isArray(e) && e[0].isIntersecting) {
    callScorecardViewOlap(
      parent === "overall"
        ? OLAPEventIds.VIEW_ATTEMPTED_QUESTIONS
        : OLAPEventIds.VIEW_SCORE_CARD,
      parent === "overall"
        ? "view attempted questions"
        : "view subject score card",
      parent === "overall"
        ? OLAPKingdoms.VIEW_ATTEMPTED_QUESTIONS
        : OLAPKingdoms.VIEW_SCORE_CARD,
      parent === "overall" ? "view_attempted_questions" : "view_score_card",
      parent === "overall" ? null : assessmentId,
      parent === "overall" ? null : assessmentType
    );
  }
};

export const scorecardScrollCallback = (
  isScrorecardScrollable,
  parent,
  assessmentId,
  assessmentType,
  scrollInfo
) => {
  callScrollOlapApi(
    parent === "overall"
      ? OLAPEventIds.SCROLL_ATTEMPTED_QUESTIONS
      : OLAPEventIds.SCROLL_SCORECARD,
    parent === "overall"
      ? "scrolling attempted questions"
      : "scrolling subject score card",
    parent === "overall"
      ? OLAPKingdoms.SCROLL_ATTEMPTED_QUESTIONS
      : OLAPKingdoms.SCROLL_SCORECARD,
    parent === "overall" ? "scroll_attempted_questions" : "scroll_score_card",
    null,
    parent === "overall" ? null : assessmentId,
    parent === "overall" ? null : assessmentType,
    {
      ...isScrorecardScrollable,
      ...scrollInfo,
    }
  );
};

export const quizViewOlap = (assessmentId, examType, numQues) => {
  const { userCohortId, premium } = getUserInfo();
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    OLAPEventIds.VIEW_QUIZ,
    "view",
    "view quiz of the day",
    OLAPKingdoms.VIEW_QUIZ,
    "view_quiz",
    null,
    premium ? "premium" : "free",
    { num_ques: numQues },
    null,
    assessmentId,
    null,
    null,
    examType,
    userCohortId,
    null,
    null,
    null
  );
  postOLAP(olapPayload);
};

export const submitQuizOlap = (assessmentId, examType, numQues) => {
  const { userCohortId, premium } = getUserInfo();
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    OLAPEventIds.CLICK_SUBMIT_QUIZ,
    "click",
    "submit quiz of the day",
    OLAPKingdoms.CLICK_SUBMIT_QUIZ,
    "click_submit_quiz",
    null,
    premium ? "premium" : "free",
    { num_ques: numQues },
    null,
    assessmentId,
    null,
    null,
    examType,
    userCohortId,
    null,
    null,
    null
  );
  postOLAP(olapPayload);
};

export const quizResultViewOlap = (
  assessmentId,
  examType,
  numQues,
  duration,
  score
) => {
  const { userCohortId, premium } = getUserInfo();
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    OLAPEventIds.VIEW_QUIZ_RESULT,
    "view",
    "view quiz of the day result",
    OLAPKingdoms.VIEW_QUIZ_RESULT,
    "view_quiz_result",
    null,
    premium ? "premium" : "free",
    {
      num_ques: numQues,
      duration,
      perentage_score: parseInt(score, 10),
    },
    null,
    assessmentId,
    null,
    null,
    examType,
    userCohortId,
    null,
    null,
    null
  );
  postOLAP(olapPayload);
};

export const playEmbededQuizVideoOlap = (
  assessmentId,
  examType,
  numQues,
  duration,
  score,
  videoId
) => {
  const { userCohortId, premium } = getUserInfo();
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    OLAPEventIds.PLAY_EMBEDED_VIDEO,
    "click",
    "click to play embeded video for quiz of the day",
    OLAPKingdoms.PLAY_EMBEDED_VIDEO,
    "play_embeded_video",
    null,
    premium ? "premium" : "free",
    {
      num_ques: numQues,
      duration,
      perentage_score: parseInt(score, 10),
    },
    null,
    assessmentId,
    null,
    null,
    examType,
    userCohortId,
    null,
    null,
    null,
    { video_id: videoId }
  );
  postOLAP(olapPayload);
};
