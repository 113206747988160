import { takeLatest, put } from "redux-saga/effects";
import {
  FETCH_COMPREHENSIVE_TEST,
  UPDATE_COMPREHENSIVE_TEST_DATA,
  FETCH_TEST_SPECIFIC_SWOT,
  UPDATE_TEST_SPECIFIC_SWOT,
} from "../redux/ComprehensiveTest/ComprehensiveTest.types";
import {
  fetchComprehensiveTestData,
  fetchTestSpecificSwot,
} from "../services/ComprehensiveTest.api";

import data from "../assets/js/swotStaticData";

function* ApiComprehensiveTest() {
  let payload;
  const apiRes = yield fetchComprehensiveTestData();
  if (apiRes.aits.length === 0) {
    payload = data;
  } else {
    payload = apiRes;
  }
  yield put({ type: UPDATE_COMPREHENSIVE_TEST_DATA, payload });
}

function* ApiSwot(action) {
  const payload = yield fetchTestSpecificSwot(action.payload);
  yield put({ type: UPDATE_TEST_SPECIFIC_SWOT, payload });
}

function* ComprehensiveTestSaga() {
  yield takeLatest(FETCH_COMPREHENSIVE_TEST, ApiComprehensiveTest);
  yield takeLatest(FETCH_TEST_SPECIFIC_SWOT, ApiSwot);
}

export default ComprehensiveTestSaga;
