import React from "react";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";
import Typography from "@material-ui/core/Typography";
import ClassNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import "../../../assets/stylesheets/MockDetail.css";
import { LANGUAGE } from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  navPill: {
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  liStyle: {
    cursor: "pointer",
  },
  wlcStyle: {
    padding: "1rem 1rem",
    color: "#6D7278",
  },
  activeNavCss: {
    backgroundColor: "#ededee",
    color: "#000000",
  },
  activeTxt: {
    color: "#000000",
  },
  marginFav: {
    color: "#ffffff",
    backgroundImage: "linear-gradient(to left, #c254f5, #842dda)",
    textTransform: "capitalize",
    margin: 0,
    top: "auto",
    left: 20,
    bottom: "3vh",
    right: "auto",
    position: "fixed !important",
    minWidth: "100px !important",
  },
  favGrid: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
      position: "absolute",
      zIndex: "100",
      paddingBottom: "30px !important",
    },
  },
}));

export default function SideMenu({
  activeNav,
  executeScroll,
  toggleDrawer,
  language,
  isIas,
  isBnat,
}) {
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.favGrid}
        onClick={toggleDrawer("bottom", true)}
        role="presentation"
      >
        <Fab
          variant="extended"
          size="small"
          aria-label="add"
          className={classes.marginFav}
        >
          <NavigationIcon />
          {language === LANGUAGE.ENGLISH ? "Topics" : "विषय"}
        </Fab>
      </div>
      <Grid item xs={12} md={3} className={classes.navPill}>
        <div className="layout-menu-content">
          <ul className="nav nav-pills nav-stacked">
            <li className="topics" role="presentation">
              {language === LANGUAGE.ENGLISH ? "Topics" : "विषय"}
            </li>
            <li
              role="presentation"
              className={ClassNames(
                classes.liStyle,
                activeNav === "1" && classes.activeNavCss
              )}
              onClick={executeScroll.bind(this, "1")}
              key="1"
            >
              <Typography
                className={ClassNames(
                  classes.wlcStyle,
                  activeNav === "1" && classes.activeTxt
                )}
              >
                {isBnat &&
                  language === LANGUAGE.ENGLISH &&
                  `Welcome to ${isIas ? "BNST" : "BNAT"}`}
                {isBnat &&
                  language === LANGUAGE.HINDI &&
                  "BNST में आपका स्वागत है"}
                {!isBnat && `Welcome to BYJU'S Mock Test`}
              </Typography>
            </li>
            <li
              role="presentation"
              className={ClassNames(
                classes.liStyle,
                activeNav === "2" && classes.activeNavCss
              )}
              onClick={executeScroll.bind(this, "2")}
              key="2"
            >
              <Typography
                className={ClassNames(
                  classes.wlcStyle,
                  activeNav === "2" && classes.activeTxt
                )}
              >
                {language === LANGUAGE.ENGLISH ? "Syllabus" : "पाठ्यक्रम"}
              </Typography>
            </li>
            <li
              role="presentation"
              className={ClassNames(
                classes.liStyle,
                activeNav === "3" && classes.activeNavCss
              )}
              onClick={executeScroll.bind(this, "3")}
              key="3"
            >
              <Typography
                className={ClassNames(
                  classes.wlcStyle,
                  activeNav === "3" && classes.activeTxt
                )}
              >
                {language === LANGUAGE.ENGLISH
                  ? "FAQs"
                  : "प्रायः पूछे जाने वाले प्रश्न"}
              </Typography>
            </li>
            <li
              role="presentation"
              className={ClassNames(
                classes.liStyle,
                activeNav === "4" && classes.activeNavCss
              )}
              onClick={executeScroll.bind(this, "4")}
              key="4"
            >
              <Typography
                className={ClassNames(
                  classes.wlcStyle,
                  activeNav === "4" && classes.activeTxt
                )}
              >
                {language === LANGUAGE.ENGLISH
                  ? "BYJU'S Videos"
                  : "BYJU'S वीडियो"}
              </Typography>
            </li>
            <li
              role="presentation"
              className={ClassNames(
                classes.liStyle,
                activeNav === "5" && classes.activeNavCss
              )}
              onClick={executeScroll.bind(this, "5")}
              key="5"
            >
              <Typography
                className={ClassNames(
                  classes.wlcStyle,
                  activeNav === "5" && classes.activeTxt
                )}
              >
                {language === LANGUAGE.ENGLISH ? "Result" : "परिणाम"}
              </Typography>
            </li>
          </ul>
        </div>
      </Grid>
    </>
  );
}
