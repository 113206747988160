import React from "react";
import { Select, MenuItem, withStyles, InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { LANGUAGE } from "../../../AppConfig";

const renderStyledMenuItem = (currentLanguage, itemLanguage, label) => {
  const StyledMenuItem = withStyles({
    root: {
      paddingTop: "0px",
      paddingBottom: "0px",
      margin: "0px",
      minHeight: "auto",
    },
  })(MenuItem);
  return (
    <StyledMenuItem
      style={{ display: currentLanguage === itemLanguage ? "none" : "block" }}
      value={itemLanguage}
    >
      {label}
    </StyledMenuItem>
  );
};

const StyledInputBase = withStyles({
  input: {
    width: "68px",
    paddingLeft: "12px",
    border: "1px solid",
    borderRadius: "5px",
    "&:focus": {
      border: "1px solid",
      borderRadius: "5px",
    },
  },
})(InputBase);

const useStyles = makeStyles(() => ({
  selectContainer: {
    float: "right",
  },
}));

export default function LanguageSelector({ language, setLanguage }) {
  const classes = useStyles();
  return (
    <Grid item className={classes.selectContainer}>
      <Select
        value={language}
        onChange={(evt) => setLanguage(evt.target.value)}
        label=""
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        input={<StyledInputBase />}
      >
        {renderStyledMenuItem(language, LANGUAGE.ENGLISH, LANGUAGE.ENGLISH)}
        {renderStyledMenuItem(language, LANGUAGE.HINDI, "हिंदी")}
      </Select>
    </Grid>
  );
}
