import { takeLatest, put } from "redux-saga/effects";
import {
  fetchAssignmentDetails,
  fetchAssignmentandQuestionVideoDetails,
  fetchQuestionNotes,
} from "../services/TestSpecific.api";
import {
  UPDATE_ASSIGNMENT_STATE,
  FETCH_ASSIGNMENT,
  FETCH_QUESTION_NOTES,
  FETCH_QUESTION_NOTES_DONE,
  FETCH_VIDEO_SOLUTION,
  FETCH_VIDEO_SOLUTION_DONE,
} from "../redux/TestSpecific/TestSpecific.types";

async function populatePaperTitle(data) {
  return data.subjects.map((paper) => {
    const modSubject = [];
    paper.subjects.forEach((sub) => {
      const subCopy = { ...sub };
      subCopy.paper_title = paper.title;
      modSubject.push(subCopy);
    });
    return modSubject;
  });
}

function* ApiAssignment() {
  const assignmentDetails = yield fetchAssignmentDetails();
  let payload = {};
  if (assignmentDetails.assessment_type === "JeeAdvanced") {
    const modifiedSubjects = yield populatePaperTitle(assignmentDetails);

    const allSub = [];
    for (let i = 0; i < modifiedSubjects[0].length; i += 1) {
      for (let j = 0; j < modifiedSubjects.length; j += 1) {
        allSub.push(modifiedSubjects[j][i]);
      }
    }
    payload = {
      ...assignmentDetails,
      subjects: allSub,
      papers: assignmentDetails.subjects,
    };
  } else {
    payload = { ...assignmentDetails };
  }
  yield put({ type: UPDATE_ASSIGNMENT_STATE, payload });
}

function* ApiVideoSolution(action) {
  const payload = yield fetchAssignmentandQuestionVideoDetails(action.payload);
  yield put({ type: FETCH_VIDEO_SOLUTION_DONE, payload });
}

function* ApiQuestionNotes(action) {
  const payload = yield fetchQuestionNotes(action.payload);
  yield put({ type: FETCH_QUESTION_NOTES_DONE, payload });
}

function* TestSpecificSaga() {
  yield takeLatest(FETCH_ASSIGNMENT, ApiAssignment);
  yield takeLatest(FETCH_QUESTION_NOTES, ApiQuestionNotes);
  yield takeLatest(FETCH_VIDEO_SOLUTION, ApiVideoSolution);
}

export default TestSpecificSaga;
