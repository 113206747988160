import React, { useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import api from "../../../services/api";
import { showErrorPage } from "../../Utils/CommonFunctions";
import Loader from "../Loader/Loader";

function WebTech({ isMockTest }) {
  const location = useLocation();
  const [mockHomePage, showMockHomePage] = React.useState(false);
  const [bnatHomePage, showBnatHomePage] = React.useState(false);
  const [isBnst, setBnst] = React.useState(false);

  useEffect(() => {
    const authenticate = () => {
      const param = new URLSearchParams(location.search);
      const webtechToken = param.get("access_token");

      if (!isMockTest) {
        const webTechLoginSource = param.get("login_source");
        if (webTechLoginSource === "bnst-ias") {
          setBnst(true);
        }
        sessionStorage.setItem("webtech_login_source", webTechLoginSource);
      }

      api
        .get(`/webtech_authenticate?access_token=${webtechToken}`)
        .then((resp) => {
          if (resp.status === 200) {
            const user = {
              id: resp.data.user_id,
              full_name: resp.data.username,
              user_profiles: [{ avatar: "placeholder", cohort: { id: null } }],
            };
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("user_token", resp.data.user_token);
            localStorage.setItem("is_free_user", resp.data.is_free_user);
            if (isMockTest) {
              showMockHomePage(true);
            } else {
              showBnatHomePage(true);
            }
          } else {
            showErrorPage(resp.status);
          }
        })
        .catch((err) => err);
    };
    authenticate();
  }, [location.search, isMockTest]);

  return (
    <div>
      {((isMockTest === true && mockHomePage === false) ||
        (!isMockTest && bnatHomePage === false)) && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
      {mockHomePage === true && <Redirect to="/mocktest-detail" />}
      {bnatHomePage === true && (
        <Redirect to={`/${isBnst ? "bnst" : "bnat"}-detail`} />
      )}
    </div>
  );
}

export default WebTech;
