/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ModalVideo from "react-modal-video";
import { useParams } from "react-router-dom";
import { FETCH_ASSIGNMENT } from "../redux/TestSpecific/TestSpecific.types";
import Loader from "../components/UtilComponents/Loader/Loader";
import Header from "../components/UtilComponents/Header/Header";
import SpecificSubject from "../components/TestSpecific/SpecificSubject/SpecificSubject";
import ResultCard from "../components/Quiz/ResultCard";
import {
  playEmbededQuizVideoOlap,
  quizResultViewOlap,
} from "../components/Utils/OlapCallback";
import { secConvertor } from "../components/Utils/TimeConvertor";
import { navigateToLearn } from "../components/Utils/CommonFunctions";
import ErrorPage from "./ErrorPage";
import error from "../components/Utils/Error";

const useStyles = makeStyles((theme) => ({
  quizResultContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  quizResultBody: {
    width: "100%",
    padding: "10px",
    [theme.breakpoints.up("md")]: {
      width: "60%",
      display: "flex",
      justifyContent: "center",
    },
  },
  answerSolutionText: {
    width: "100%",
    height: "20px",
    margin: "32px 175px 20px 0px",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "normal",
    textAlign: "left",
    color: "var(--black-two)",
  },
  video: {
    backgroundColor: "black",
    height: "212px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "5px",
    borderRadius: "20px",
    [theme.breakpoints.up("sm")]: {
      height: "290px",
    },
    [theme.breakpoints.up("md")]: {
      height: "350px",
    },
  },
  quizTitle: {
    height: "29px",
    margin: "12px 0 0",
    backgroundImage: "linear-gradient(to right, #9752ff, #5e93ff)",
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    "-webkit-background-clip": "text",
    "background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
}));

const QuizResult = () => {
  document.title = "Byju's | Daily Quiz Result";

  /* States */
  const [isOpen, setOpen] = React.useState(false);
  const [videoId, setVideoId] = React.useState();

  /* Constants */
  const dispatch = useDispatch();
  const classes = useStyles();
  const { assignment } = useParams();
  localStorage.setItem("user_assignment_id", assignment);
  const userToken = localStorage.getItem("user_token");

  /* Selectors */
  const presentState = useSelector((state) => state.testSpecific);
  const isOnline = useSelector((state) => state.navigator.isOnline);

  /* Functions */
  React.useEffect(() => {
    if (assignment === null || assignment === "null" || userToken === null) {
      navigateToLearn();
    } else {
      dispatch({ type: FETCH_ASSIGNMENT });
    }
  }, [dispatch, assignment]);

  React.useEffect(() => {
    if (presentState.assignment_id !== undefined) {
      if (presentState.youtube_solution_url !== null) {
        const id = presentState.youtube_solution_url.split("=")[1];
        setVideoId(id);
      }
      quizResultViewOlap(
        presentState.assessment_client_id,
        presentState.assessment_type,
        presentState.total_questions,
        secConvertor(Number(presentState.time_taken)),
        presentState.percentage_score
      );
    }
  }, [presentState]);

  const openVideo = () => {
    setOpen(true);
    playEmbededQuizVideoOlap(
      presentState.assessment_client_id,
      presentState.assessment_type,
      presentState.total_questions,
      secConvertor(Number(presentState.time_taken)),
      presentState.percentage_score,
      videoId
    );
  };

  return (
    <>
      {isOnline && (
        <>
          {presentState.assignment_id === undefined && (
            <div style={{ textAlign: "center" }}>
              <Loader />
            </div>
          )}

          {presentState.assignment_id !== undefined && (
            <div className={classes.quizResultContainer}>
              <Header
                disableLink
                noNotes
                examType={presentState.assessment_type}
                quiz
              />
              <Grid
                container
                direction="column"
                justify="center"
                className={classes.quizResultBody}
              >
                <Grid item style={{ width: "100%" }}>
                  <ResultCard
                    timeTaken={presentState.time_taken}
                    score={`${
                      Number.isInteger(Number(presentState.score)) === true
                        ? Number(presentState.score)
                        : presentState.score
                    }/${Number(presentState.total)}`}
                    availableStarting={presentState.available_starting}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.answerSolutionText}>
                    Answers & Solutions
                  </Typography>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={isOpen}
                    videoId={videoId}
                    onClose={() => setOpen(false)}
                  />
                </Grid>
                {presentState.youtube_solution_url !== null && (
                  <Grid item style={{ width: "100%" }}>
                    <div
                      className={classes.video}
                      onClick={openVideo}
                      onKeyDown={openVideo}
                      role="presentation"
                    >
                      <img
                        className="quiz-video-thumbnail-play-icon"
                        height="57"
                        src="https://cdn1.byjus.com/byjusweb/img/bnat/play-btn.svg"
                        width="57"
                        alt="img"
                      />
                    </div>
                  </Grid>
                )}
                <Grid item style={{ width: "100%" }}>
                  <SpecificSubject selectedTab="All" quiz />
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
      {!isOnline && (
        <ErrorPage
          code="No Internet"
          title={error["No Internet"].title}
          description={error["No Internet"].description}
          ctaTitle={error["No Internet"].cta.title}
          ctaAction={error["No Internet"].cta.action}
        />
      )}
    </>
  );
};

export default QuizResult;
