import { takeLatest, put } from "redux-saga/effects";
import {
  FETCH_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "../redux/Profile/Profile.types";
import getProfileData from "../services/ProfileApi";

function* ApiProfile() {
  const payload = yield getProfileData();
  yield put({ type: UPDATE_USER_PROFILE, payload });
}

function* UserProfileSaga() {
  yield takeLatest(FETCH_USER_PROFILE, ApiProfile);
}

export default UserProfileSaga;
