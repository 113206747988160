import { takeLatest, put } from "redux-saga/effects";
import {
  GENERATE_ASSESSMENT,
  GENERATE_ASSESSMENT_FAILURE,
  GENERATE_ASSESSMENT_START,
  GENERATE_ASSESSMENT_SUCCESS,
  GENERATE_ASSESSMENT_ALREADY_SUBMITTED,
} from "../redux/GenerateAssessment/GenerateAssessment.types";
import {
  FETCH_ADVANCED_INSTRUCTIONS,
  FETCH_INSTRUCTIONS,
} from "../redux/Instructions/Instructions.types";
import generateAssessment from "../services/GenerateAssessment.api";

function* generateAssessments(data) {
  try {
    const assessmentId = data.payload;
    if (assessmentId === null) {
      window.location.replace(window.location.origin);
      return;
    }
    yield put({ type: GENERATE_ASSESSMENT_START });
    const response = yield generateAssessment({ assessmentId });
    if (response.error === "This test is no longer available") {
      sessionStorage.setItem("test_submitted", "true");
      sessionStorage.setItem("test_no_longer_available", "true");
      yield put({
        type: GENERATE_ASSESSMENT_ALREADY_SUBMITTED,
        payload: response.error,
      });
      return;
    }
    const { status } = response.assignment;
    if (status === "Completed" || status === "Graded") {
      sessionStorage.setItem("test_submitted", "true");
      yield put({
        type: GENERATE_ASSESSMENT_ALREADY_SUBMITTED,
        payload: status,
      });
    } else {
      const testAssessmentId = response.assignment.assessment.id;
      const testAssignmentId = response.assignment.id;
      sessionStorage.setItem("test_assessment_id", testAssessmentId);
      sessionStorage.setItem("test_assignment_id", testAssignmentId);
      yield put({
        type: GENERATE_ASSESSMENT_SUCCESS,
        payload: response.assignment,
      });
      if (data.meta === "JeeAdvanced") {
        yield put({
          type: FETCH_ADVANCED_INSTRUCTIONS,
          payload: testAssessmentId,
        });
      } else {
        yield put({
          type: FETCH_INSTRUCTIONS,
          payload: testAssessmentId,
        });
      }
    }
  } catch (err) {
    yield put({ type: GENERATE_ASSESSMENT_FAILURE, payload: err.message });
  }
}

function* generateAssessmentsSaga() {
  yield takeLatest(GENERATE_ASSESSMENT, generateAssessments);
}
export default generateAssessmentsSaga;
