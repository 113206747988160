import React, { useState, useEffect, lazy, Suspense } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Header from "../UtilComponents/Header/Header";
import "../../assets/stylesheets/TestSpecific.css";
import {
  FETCH_ASSIGNMENT,
  CLEAR_ASSIGNMENT,
} from "../../redux/TestSpecific/TestSpecific.types";
import { FETCH_COMPREHENSIVE_TEST } from "../../redux/ComprehensiveTest/ComprehensiveTest.types";
import Loader from "../UtilComponents/Loader/Loader";
import { callAssessViewOlapApi } from "../Utils/OlapHelpers";
import { navigateToLearn } from "../Utils/CommonFunctions";
import ErrorPage from "../../pages/ErrorPage";
import error from "../Utils/Error";
import "../../App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 2rem",
    [theme.breakpoints.down("md")]: {
      padding: "0 0.75rem",
    },
  },
}));

const RankCard = lazy(() => import("./OverallResult/RankCard"));
const NavBar = lazy(() => import("../SWOT/NavBar/NavBar"));
const SubjectsOverview = lazy(() =>
  import("./SubjectsOverview/SubjectsOverview")
);
const TimeComparision = lazy(() => import("./TimeComparision/TimeComparision"));
const AnswerReview = lazy(() =>
  import("./SpecificSubject/Question/AnswerReview")
);
const SpecificSubject = lazy(() => import("./SpecificSubject/SpecificSubject"));

function TestSpecific() {
  const dispatch = useDispatch();
  const presentState = useSelector((state) => state.testSpecific);
  const overallState = useSelector((state) => state.comprehensiveTest);
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState("All");
  const [loader, setLoader] = useState(false);
  document.title = "Byju's | Test Analysis";
  const changeSelectedTab = (tabName) => {
    setLoader(true);
    setTimeout(() => setSelectedTab(tabName), 0);
  };

  useEffect(() => {
    setLoader(false);
  }, [selectedTab]);

  useEffect(() => {
    const assignmentId = localStorage.getItem("user_assignment_id");
    const userToken = localStorage.getItem("user_token");
    if (assignmentId === null || userToken === null) {
      navigateToLearn();
    } else {
      dispatch({ type: CLEAR_ASSIGNMENT });
      dispatch({ type: FETCH_ASSIGNMENT });
      dispatch({ type: FETCH_COMPREHENSIVE_TEST });
      localStorage.setItem("NavbarId", assignmentId);
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      presentState.assignment_id !== undefined &&
      overallState.aits !== undefined
    ) {
      callAssessViewOlapApi(
        presentState.assessment_client_id,
        presentState.assessment_type,
        1
      );
    }
  }, [presentState, overallState]);

  const isOnline = useSelector((state) => state.navigator.isOnline);

  return (
    <>
      {isOnline && (
        <div className="container">
          {(presentState.assignment_id === undefined ||
            overallState.aits === undefined) && <Loader />}
          {presentState.assignment_id !== undefined &&
            overallState.aits !== undefined && (
              <>
                <Header showNotificationCenter />
                <Grid
                  container
                  direction="row"
                  justify="center"
                  className={classes.root}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    style={{ marginTop: "10px" }}
                  >
                    <Suspense fallback={<Loader />}>
                      <NavBar data={overallState.aits} navRole="navigation" />
                    </Suspense>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Suspense fallback={<Loader />}>
                      <RankCard
                        userRank={presentState.rank}
                        percentile={presentState.percentile}
                        cutOffScore={presentState.cutoff_score}
                        cutOffMsg={presentState.cutoff_message}
                        assessmentType={presentState.assessment_type}
                        assessmentCategory={presentState.assessment_category}
                        assessmentId={presentState.assessment_client_id}
                        totalScore={presentState.score}
                      />
                    </Suspense>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Suspense fallback={<Loader />}>
                      <SubjectsOverview />
                    </Suspense>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Suspense fallback={<Loader />}>
                      <TimeComparision presentState={presentState} />
                    </Suspense>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={10}
                    md={10}
                    style={{ backgroundColor: "#cee1f1" }}
                  />
                </Grid>
                <div
                  className="container"
                  style={{ backgroundColor: "#cee1f1", display: "flex" }}
                >
                  <Suspense fallback={<Loader />}>
                    <AnswerReview
                      presentState={presentState}
                      changeSelectedTab={changeSelectedTab}
                      selectedTab={selectedTab}
                    />
                  </Suspense>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    className={classes.root}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      style={{ backgroundColor: "#cee1f1" }}
                    >
                      {!loader ? (
                        <Suspense fallback={<Loader />}>
                          <SpecificSubject selectedTab={selectedTab} />
                        </Suspense>
                      ) : (
                        <Loader />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
        </div>
      )}
      {!isOnline && (
        <div className="ErrorPage">
          <ErrorPage
            code="No Internet"
            title={error["No Internet"].title}
            description={error["No Internet"].description}
            ctaTitle={error["No Internet"].cta.title}
            ctaAction={error["No Internet"].cta.action}
          />
        </div>
      )}
    </>
  );
}

export default TestSpecific;
