import { makeStyles } from "@material-ui/core";
import React from "react";
import ReactHTMLParser from "react-html-parser";
import { COLORS } from "../Utils/Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ type }) => {
      return type === "instructions" ? COLORS.WHITE : COLORS.ADVANCED_ACCENT;
    },
    borderRadius: ({ type }) => {
      return type === "instructions" ? 0 : 8;
    },
    padding: "12px 12px 12px 12px",
    border: `solid 1px ${COLORS.GREY}`,
    width: "100%",
    boxSizing: "border-box",
  },
  boldTitle: {
    textAlign: "center",
    fontFamily: '"GothamBold"',
    fontSize: 12,
  },
  text: {
    fontWeight: 100,
    marginTop: 12,
    fontSize: 12,
  },
  [theme.breakpoints.down("sm")]: {
    text: {
      fontSize: 8,
    },
  },
}));

const QuestionInstruction = ({ title, data, type }) => {
  const classes = useStyles({ type });
  return (
    <div className={classes.root}>
      <p className={classes.boldTitle}>{title}</p>
      <div className={classes.text}>{ReactHTMLParser(data)}</div>
    </div>
  );
};

export default QuestionInstruction;
