import api from "./api";
import { showErrorPage } from "../components/Utils/CommonFunctions";

export function* fetchComprehensiveTestData() {
  api.setHeader("Authorization", localStorage.getItem("user_token"));
  let data;
  yield api
    .get(`/assignments`)
    .then((response) =>
      response.status > 399 ? showErrorPage(response.status) : response.data
    )
    .then((json) => {
      data = json;
    });
  yield localStorage.setItem("user_id", data.assignee_id);
  return data;
}

export function* fetchTestSpecificSwot(assignmentId) {
  let data;
  yield api
    .get(`/swot_info/${assignmentId}`)
    .then((response) =>
      response.status > 399 ? showErrorPage(response.status) : response.data
    )
    .then((json) => {
      data = json;
    });
  return data;
}
