import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ExamSummary from "../ExamSummary/ExamSummary";
import PostTestScreen from "../PostTestScreen/PostTestScreen";
import { SUBMIT_TEST_USER_REQUEST } from "../../redux/PostTest/PostTest.types";
import { postTestViewCallback } from "../Utils/OlapCallback";

const TestSubmissionPage = ({
  userDetails,
  finalPage,
  noSubmission,
  submissionDone,
  assessmentId,
  examType,
  webSocket,
}) => {
  const [postTestScreen, enablePostTestScreen] = React.useState(false);
  const assignmentObject = useSelector((state) => state.assignment);
  const dispatch = useDispatch();

  let totalQuestions = 0;
  if (userDetails) {
    totalQuestions =
      userDetails.answered +
      userDetails.notanswered +
      userDetails.markedforreview +
      userDetails.answeredandmarked +
      userDetails.notvisited;
  }

  const confirmTestSubmission = () => {
    const assignmentId = assignmentObject.assignment.id;
    dispatch({
      type: SUBMIT_TEST_USER_REQUEST,
      payload: assignmentId,
    });
    enablePostTestScreen(true);
  };

  const olapViewCallback = (e) => {
    postTestViewCallback(finalPage, postTestScreen, assessmentId, examType, e);
  };

  return (
    <Grid>
      {finalPage && (
        <PostTestScreen
          postTestViewCallback={olapViewCallback}
          webSocket={webSocket}
        />
      )}
      {!finalPage && (
        <>
          {postTestScreen && (
            <PostTestScreen
              postTestViewCallback={olapViewCallback}
              webSocket={webSocket}
            />
          )}
          {!postTestScreen && (
            <ExamSummary
              totalQuestion={totalQuestions}
              answered={userDetails.answered}
              notAnswered={userDetails.notanswered}
              markedForReview={userDetails.markedforreview}
              AnsweredAndMarked={userDetails.answeredandmarked}
              notVisited={userDetails.notvisited}
              onConfirmReject={noSubmission}
              onConfirmAccept={() => {
                confirmTestSubmission();
                submissionDone();
              }}
              postTestViewCallback={postTestViewCallback}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default TestSubmissionPage;
