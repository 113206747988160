/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ClassNames from "classnames";
import "../../assets/stylesheets/MockDetail.css";
import qs from "querystring";
import moment from "moment";
import { useSelector } from "react-redux";
import { extractDateTime } from "../Utils/TimeConvertor";
import { ICONS, SOCIAL_SHARE, EXAM_TYPES } from "../../AppConfig";
import {
  olapPayloadGenerator,
  OLAPEventIds,
  OLAPKingdoms,
  getUserInfo,
} from "../Utils/OlapHelpers";
import { postOLAP } from "../../services/OLAP.api";
import initiateIntersectionObserver from "../Utils/IntersectionObserver";

const useStyles = makeStyles((theme) => ({
  welcomeTxt: {
    fontweight: 600,
    fontSize: "2rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      marginTop: "10%",
    },
  },
  testActiveTxt: {
    color: "#c254f5",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  refreshMsg: {
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  OrngClr: {
    fontWeight: "700",
    color: "#c254f5",
    fontFamily: '"GothamBold"',
  },
  priorTxt: {
    fontWeight: "700",
    fontFamily: '"GothamBold"',
  },
  startBtn: {
    paddingBottom: "20px",
  },
  greetTxt: {
    marginTop: "1%",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  greetTxt1: {
    marginBottom: "3%",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  greetTxtBold: {
    fontSize: "2rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  regularTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  mobDateTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.3rem !important",
    },
  },
  mobTimeTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.9rem !important",
    },
  },
  mobTimeStyl: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "25% !important",
    },
  },
  mobDateStyl: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-15% !important",
    },
  },
  startTestMobBtn: {
    color: "#ffffff !important",
    textTransform: "Capitalize",
    minWidth: "18rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.82rem",
    },
  },
  mobList: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem !important",
    },
  },
  shareBtnGrp: {
    display: "flex",
    marginTop: "1%",
  },
  imgShare: {
    padding: "10px",
    cursor: "pointer",
  },
  sampleTestAttempLeft: {
    fontSize: "0.7rem",
    paddingBottom: "10px",
    fontStyle: "italic",
  },
  infoIcon: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  disableBtn: {
    cursor: "not-allowed !important",
    boxShadow: "none",
    opacity: 0.65,
    pointerEvents: "all !important",
  },
}));
export default function WelcomeSection({ examType, setActiveNav }) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user"));
  const userToken = localStorage.getItem("user_token");
  const [testStatusTxt, setTestStatusTxt] = React.useState("Start Test");
  const [sampleTestStatusTxt, setSampleTestStatusTxt] = React.useState(
    "Take Sample Mock Now"
  );
  const [ifmockTestBtnDisabled, setIfmockTestBtnDisabled] = React.useState(
    false
  );
  const [ifSampleTestBtnDisabled, setIfSampleTestBtnDisabled] = React.useState(
    false
  );
  let mockTest;
  if (examType === EXAM_TYPES.JEE_MAIN) {
    mockTest = "JEE Main";
  } else if (examType === EXAM_TYPES.NEET) {
    mockTest = "NEET";
  } else if (examType === EXAM_TYPES.BITSAT) {
    mockTest = "BITSAT";
  }

  const presentState = useSelector((state) => state.mockTest);

  const mockStartDatetimeArr = presentState.mock_test.start_date_time.split(
    " "
  );
  const mockResultDatetimeArr = presentState.mock_test.result_date_time.split(
    " "
  );
  const sampleEndDatetimeArr = presentState.sample_test.end_date_time.split(
    " "
  );

  const mockStartDatetime = extractDateTime(mockStartDatetimeArr);
  const mockResultDatetime = extractDateTime(mockResultDatetimeArr);
  const sampleEndDatetime = extractDateTime(sampleEndDatetimeArr);
  const mockStatus = presentState.mock_test.status;
  const sampleStatus = presentState.sample_test.status;
  const sampleAttemptLeft = presentState.sample_test.attempts_left;

  const callOlapApi = (
    uEventId,
    description,
    kingdom,
    counter,
    record,
    variety
  ) => {
    const { premium } = getUserInfo();
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      uEventId,
      "click",
      description,
      kingdom,
      counter,
      null,
      null,
      null,
      null,
      record,
      null,
      null,
      variety,
      premium ? null : JSON.parse(localStorage.getItem("user")).id,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  };

  const btnState = () => {
    if (mockStatus === "Pending") {
      setIfmockTestBtnDisabled(true);
    }

    if (mockStatus === "Inprogress") {
      setTestStatusTxt("Resume");
    }

    if (
      Date.now() <
        moment(
          presentState.mock_test.end_date_time,
          "YYYY-MM-DD HH:mm:ss"
        ).valueOf() &&
      mockStatus === "Submitted"
    ) {
      setIfmockTestBtnDisabled(true);
      setTestStatusTxt("Test Submitted");
    }

    if (
      Date.now() >
      moment(
        presentState.mock_test.end_date_time,
        "YYYY-MM-DD HH:mm:ss"
      ).valueOf()
    ) {
      setIfmockTestBtnDisabled(true);
      setTestStatusTxt("Test Ended");
    }

    if (sampleStatus === "Pending") {
      setIfSampleTestBtnDisabled(true);
    }

    if (
      sampleStatus === "Inprogress" ||
      (sampleStatus === "Started" &&
        sampleAttemptLeft < 5 &&
        sampleAttemptLeft >= 0)
    ) {
      setSampleTestStatusTxt("Resume");
    } else if (sampleStatus === "Submitted" && sampleAttemptLeft > 0) {
      setSampleTestStatusTxt("Retake Sample Mock Now");
    }

    if (
      moment(
        presentState.sample_test.end_date_time,
        "YYYY-MM-DD HH:mm:ss"
      ).valueOf() < Date.now()
    ) {
      setIfSampleTestBtnDisabled(true);
      setSampleTestStatusTxt("Sample Test Ended");
    }

    if (sampleAttemptLeft === 0 && sampleStatus === "Submitted") {
      setIfSampleTestBtnDisabled(true);
      setSampleTestStatusTxt("Total Attempts Exhausted");
    }
  };

  useEffect(() => {
    btnState();
  }, []);

  const shareOn = (socialType) => {
    let shareUrl = "";
    const title = SOCIAL_SHARE.TITLE;
    let url;
    if (examType === EXAM_TYPES.JEE_MAIN) {
      url = SOCIAL_SHARE.JEE_MOCK_TEST_BASE_URL;
    } else if (examType === EXAM_TYPES.NEET) {
      url = SOCIAL_SHARE.NEET_MOCK_TEST_BASE_URL;
    } else if (examType === EXAM_TYPES.BITSAT) {
      url = SOCIAL_SHARE.BITSAT_MOCK_TEST_BASE_URL;
    }
    let utmObj = {
      utm_source: socialType,
      utm_medium: "referral",
    };
    switch (socialType) {
      case "whatsapp":
        utmObj = { utm_campaign: `Mock-Share-${socialType}`, ...utmObj };
        shareUrl = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          navigator.userAgent.toLowerCase()
        )
          ? `${SOCIAL_SHARE.WHATSAPP_MOBILE_URL}${encodeURIComponent(
              url + qs.stringify(utmObj)
            )}`
          : `${SOCIAL_SHARE.WHATSAPP_WEB_URL}${encodeURIComponent(
              url + qs.stringify(utmObj)
            )}`;
        break;
      case "facebook":
        utmObj = { utm_campaign: `Mock-Share-${socialType}`, ...utmObj };
        shareUrl = `${SOCIAL_SHARE.FACEBOOK_URL}${encodeURIComponent(
          url + qs.stringify(utmObj)
        )}&${qs.stringify({ title })}`;
        break;
      case "twitter":
        utmObj = { utm_campaign: `Mock-Share-${socialType}`, ...utmObj };
        shareUrl = `${SOCIAL_SHARE.TWITTER_URL}${encodeURIComponent(
          url + qs.stringify(utmObj)
        )}&${qs.stringify({ via: title })}`;
        break;
      case "telegram":
        utmObj = { utm_campaign: `Mock-Share-${socialType}`, ...utmObj };
        shareUrl = `${SOCIAL_SHARE.TELEGRAM_URL}${encodeURIComponent(
          url + qs.stringify(utmObj)
        )}&${qs.stringify({ via: title })}`;
        break;
      case "message":
        utmObj = { utm_campaign: `Mock-Share-${socialType}`, ...utmObj };
        shareUrl = `${SOCIAL_SHARE.SMS_URL}${url}${qs.stringify(utmObj)}`;
        break;
      default:
    }
    window.open(
      shareUrl,
      `BYJUS ${mockTest} ${
        mockStartDatetime && mockStartDatetime.year
      } Mock Test Social Sharing`,
      `width=550, height=420, left=${
        (window.screen.availWidth - 500) / 2
      }, top=${
        (window.screen.availHeight - 500) / 2
      }, location=0, menubar=0, toolbar=0, status=0, scrollbars=1, resizable=1`
    );
  };

  const openTest = (testType) => {
    const baseURL = window.location.origin;
    let assessmentId;
    let clickAction;
    let kingdom;
    let uEventId;
    let variety = null;
    if (testType === "mock") {
      setTestStatusTxt("Resume");
      assessmentId = presentState.mock_test.assessment_client_id;
      variety = presentState.mock_test.exam_type;
      if (mockStatus === "Inprogress" || testStatusTxt === "Resume") {
        clickAction = "resume";
        kingdom = OLAPKingdoms.CLICK_RESUME_MOCK_TEST;
        uEventId = OLAPEventIds.CLICK_RESUME_MOCK_TEST;
      } else {
        clickAction = "start";
        kingdom = OLAPKingdoms.CLICK_START_MOCK_TEST;
        uEventId = OLAPEventIds.CLICK_START_MOCK_TEST;
      }
    } else if (testType === "bnat") {
      setTestStatusTxt("Resume");
      assessmentId = presentState.bnat.assessment_client_id;
      variety = presentState.bnat.exam_type;
      if (mockStatus === "Inprogress" || testStatusTxt === "Resume") {
        clickAction = "resume";
        kingdom = OLAPKingdoms.CLICK_RESUME_BNAT_TEST;
        uEventId = OLAPEventIds.CLICK_RESUME_BNAT_TEST;
      } else {
        clickAction = "start";
        kingdom = OLAPKingdoms.CLICK_START_BNAT_TEST;
        uEventId = OLAPEventIds.CLICK_START_BNAT_TEST;
      }
    } else {
      setSampleTestStatusTxt("Resume");
      assessmentId = presentState.sample_test.assessment_client_id;
      if (sampleStatus === "Inprogress" || sampleTestStatusTxt === "Resume") {
        clickAction = "resume";
        kingdom = OLAPKingdoms.CLICK_RESUME_SAMPLE_TEST;
        uEventId = OLAPEventIds.CLICK_RESUME_SAMPLE_TEST;
      } else if (sampleStatus === "Submitted" && sampleAttemptLeft > 0) {
        clickAction = "retake";
        kingdom = OLAPKingdoms.CLICK_RETAKE_SAMPLE_TEST;
        uEventId = OLAPEventIds.CLICK_RETAKE_SAMPLE_TEST;
      } else {
        clickAction = "start";
        kingdom = OLAPKingdoms.CLICK_START_SAMPLE_TEST;
        uEventId = OLAPEventIds.CLICK_START_SAMPLE_TEST;
      }
    }
    const counter = `${clickAction}_${testType}_test`;
    const description = `click ${clickAction} ${testType} test`;
    callOlapApi(uEventId, description, kingdom, counter, assessmentId, variety);
    const url = `${baseURL}/start_test?access_token=${userToken}&assessment_id=${assessmentId}&is_free_user=true&assessment_type=${examType}`;
    window.open(url, "mock_test");
  };

  useEffect(() => {
    initiateIntersectionObserver(
      document.querySelector("#welcomeSection"),
      0.7,
      setActiveNav,
      "1"
    );
  }, [setActiveNav]);

  return (
    <div id="welcomeSection">
      <Typography className={classes.greetTxt}>
        Hello
        {` ${user.full_name},`}
      </Typography>
      <Typography className={classes.greetTxt1}>
        {`Thank you for registering for `}
        <span className={classes.greetTxtBold}>
          BYJU&apos;S All India Mock Test for
        </span>
        <span style={{ color: "#c254f5" }} className={classes.greetTxtBold}>
          {` ${mockTest} ${mockStartDatetime && mockStartDatetime.year}`}
        </span>
      </Typography>
      <div className={ClassNames(classes.regularTxt, "scheduled-card")}>
        The test is scheduled on
        <div className="date-time">
          <div className={ClassNames(classes.mobTimeStyl, "date")}>
            <div
              className={ClassNames(classes.mobTimeStyl, "month")}
              id="test_start_month"
            >
              {mockStartDatetime && mockStartDatetime.month}
            </div>
            <div
              className={ClassNames(
                classes.mobDateTxt,
                classes.mobDateStyl,
                "number"
              )}
              id="test_start_date"
            >
              {mockStartDatetime && mockStartDatetime.date}

              <sup>{mockStartDatetime && mockStartDatetime.dateOrdinal}</sup>
            </div>
            <div
              className={ClassNames(
                classes.regularTxt,
                classes.mobDateStyl,
                "year"
              )}
              id="test_start_year"
            >
              {mockStartDatetime && mockStartDatetime.year}
            </div>
          </div>
          <div className="time">
            <div className="time-text">TIME</div>
            <div
              className={ClassNames(classes.mobTimeTxt, "number-minute")}
              id="test_start_time"
            >
              {mockStartDatetime && mockStartDatetime.time}
            </div>
            <div className="am" id="test_start_am_pm">
              {mockStartDatetime && mockStartDatetime.ampm}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.startBtn}>
        <Button
          onClick={() => openTest("mock")}
          className={ClassNames(classes.startTestMobBtn, "mocktest-btn", {
            [classes.disableBtn]: ifmockTestBtnDisabled,
          })}
          disabled={ifmockTestBtnDisabled}
        >
          {testStatusTxt}
        </Button>
      </div>
      <Typography className={classes.testActiveTxt}>
        {`Test button will be active on ${
          mockStartDatetime && mockStartDatetime.date
        }`}
        <span>
          <sup>{mockStartDatetime && mockStartDatetime.dateOrdinal}</sup>
        </span>
        {` ${mockStartDatetime && mockStartDatetime.month} ${
          mockStartDatetime && mockStartDatetime.year
        }, ${mockStartDatetime && mockStartDatetime.time} ${
          mockStartDatetime && mockStartDatetime.ampm
        }`}
      </Typography>
      <Typography className={classes.refreshMsg}>
        (Kindly refresh the page if the button is not enabled on the test date)
      </Typography>
      <ul className={ClassNames(classes.mobList, "list")}>
        <li>
          You will receive an alert
          <span className={classes.priorTxt}>
            {` 3 hours prior to the test.`}
          </span>
        </li>
        <li>
          Your result will be accessible here on
          <span className={classes.OrngClr}>
            {` ${mockResultDatetime && mockResultDatetime.date}`}
            <sup>{mockResultDatetime && mockResultDatetime.dateOrdinal}</sup>
            {` ${mockResultDatetime && mockResultDatetime.month} ${
              mockResultDatetime && mockResultDatetime.year
            }, ${mockResultDatetime && mockResultDatetime.time} ${
              mockResultDatetime && mockResultDatetime.ampm
            }`}
          </span>
        </li>
      </ul>
      <div className={classes.startBtn}>
        <Button
          onClick={() => openTest("sample")}
          className={ClassNames(classes.startTestMobBtn, "mocktest-btn", {
            [classes.disableBtn]: ifSampleTestBtnDisabled,
          })}
          disabled={ifSampleTestBtnDisabled}
        >
          {sampleTestStatusTxt}
        </Button>
      </div>
      <Typography className={classes.sampleTestAttempLeft}>
        {`* Total attempts left for Sample Mock Test : ${sampleAttemptLeft}`}
      </Typography>
      <Typography className={classes.sampleTestAttempLeft}>
        * Sample Mock Tests are provided for you to get comfortable with using
        the Testing Interface.
      </Typography>
      <Typography className={classes.sampleTestAttempLeft}>
        {`* They will be available till ${
          sampleEndDatetime && sampleEndDatetime.date
        }`}
        <span>
          <sup>{sampleEndDatetime && sampleEndDatetime.dateOrdinal}</sup>
        </span>
        {` ${sampleEndDatetime && sampleEndDatetime.month} ${
          sampleEndDatetime && sampleEndDatetime.year
        }, ${sampleEndDatetime && sampleEndDatetime.time} ${
          sampleEndDatetime && sampleEndDatetime.ampm
        }. Result will not
        be declared for Sample Mock Test.`}
      </Typography>
      <Typography>
        {`Share with your friends and invite them to take BYJU'S ${mockTest}
        ${mockStartDatetime && mockStartDatetime.year} Mock Test.`}
      </Typography>
      <div className={classes.shareBtnGrp}>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "whatsapp")}
          role="presentation"
        >
          <img alt="whatsapp" height="50" width="45" src={ICONS.WHATSAPP} />
        </div>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "facebook")}
          role="presentation"
        >
          <img alt="facebook" height="50" width="45" src={ICONS.FACEBOOK} />
        </div>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "twitter")}
          role="presentation"
        >
          <img alt="twitter" height="50" width="45" src={ICONS.TWITTER} />
        </div>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "telegram")}
          role="presentation"
        >
          <img alt="telegram" height="50" width="45" src={ICONS.TELEGRAM} />
        </div>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "message")}
          role="presentation"
        >
          <img alt="message" height="50" width="45" src={ICONS.SMS} />
        </div>
      </div>
    </div>
  );
}
