import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "../../assets/stylesheets/MockDetail.css";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import "../../assets/stylesheets/ModalVideoOverride.css";
import { useSelector } from "react-redux";
import videosData from "../../assets/js/videoAndthumbnails";
import {
  MOCK_TEST_CONSTANTS,
  EXAM_TYPES,
  BNAT_TEST_CONSTANTS,
  LANGUAGE,
  COHORT_TYPES,
} from "../../AppConfig";
import initiateIntersectionObserver from "../Utils/IntersectionObserver";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: "#6d7278",
    fontSize: "2rem",
    fontWeight: "600",
    marginBottom: "1.5rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  desCardTitle: {
    paddingBottom: "0.5rem",
    fontSize: "1rem",
    borderBottom: "1px solid #d8d8d8",
    marginBottom: "1rem",
    fontFamily: '"GothamBold"',
  },
  descCardDesc: {
    fontSize: "0.7rem",
  },
  OrngClr: {
    fontWeight: "700",
    color: "#c254f5",
    fontFamily: '"GothamBold"',
  },
  boldTxt: {
    fontWeight: "700",
    fontFamily: '"GothamBold"',
  },
  regularTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  videoContainer: {
    cursor: "pointer",
  },
}));

export default function VideoSection({
  examType,
  language,
  isMockTest,
  setActiveNav,
}) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const [videoId, setVideoId] = useState("");
  const presentState = useSelector((state) =>
    isMockTest ? state.mockTest : state.bnat
  );

  let videos = {};
  let youtubeChannel;
  let secondChannel = null;
  let secondaryText = "";
  const checkIfLanguageisEnglish = language === LANGUAGE.ENGLISH;
  const openVideo = (vId) => {
    setVideoId(vId);
    setOpen(true);
  };

  const getVideoandChannel = () => {
    if (examType === EXAM_TYPES.BNAT) {
      let bnatVideo;
      let bnatYoutubeChannel = BNAT_TEST_CONSTANTS.OTHER_YOUTUBE_CHANNEL;
      const { grade } = presentState.bnat;
      if (grade >= 4 && grade <= 5) {
        bnatVideo = videosData.bnatTestGrade4to5;
      } else if (grade >= 6 && grade <= 7) {
        bnatVideo = videosData.bnatTestGrade6to7;
      } else if (grade >= 8 && grade <= 9) {
        bnatVideo = videosData.bnatTestGrade8to9;
      } else if (grade === 10) {
        bnatVideo = videosData.bnatTestGrade10;
      } else if (grade >= 11 && grade <= 13) {
        const cohort = presentState.bnat.cohort_type;
        if (cohort === COHORT_TYPES.JEE) {
          bnatVideo = videosData.bnatTestJee;
          bnatYoutubeChannel = BNAT_TEST_CONSTANTS.JEE_YOUTUBE_CHANNEL;
          secondaryText = `${COHORT_TYPES.JEE} `;
        } else if (cohort === COHORT_TYPES.NEET) {
          bnatVideo = videosData.bnatTestNeet;
          bnatYoutubeChannel = BNAT_TEST_CONSTANTS.NEET_YOUTUBE_CHANNEL;
          secondaryText = `${COHORT_TYPES.NEET} `;
        } else if (cohort === COHORT_TYPES.JeeNeet) {
          bnatVideo = videosData.bnatTestJeeNeet;
          bnatYoutubeChannel = BNAT_TEST_CONSTANTS.JEE_YOUTUBE_CHANNEL;
          secondChannel = BNAT_TEST_CONSTANTS.NEET_YOUTUBE_CHANNEL;
        }
      } else if (grade === 14) {
        bnatVideo = videosData.bnatTestMoving10to11;
      } else if (grade === 15) {
        if (language === LANGUAGE.ENGLISH) {
          bnatVideo = videosData.bnatTestIASEnglish;
        } else if (language === LANGUAGE.HINDI) {
          bnatVideo = videosData.bnatTestIASHindi;
        }
      }
      if (
        Object.keys(bnatVideo).length > 0 &&
        Array.isArray(bnatVideo.row1) &&
        Array.isArray(bnatVideo.row2)
      ) {
        videos = bnatVideo;
        youtubeChannel = bnatYoutubeChannel;
      }
    }
  };

  const renderVideos = (row) => (
    <>
      {row.map((video) => (
        <Grid item xs={12} md={4} key={video.thumbnail}>
          <div
            className="bnat-video-thumbnail"
            onClick={openVideo.bind(this, video.videoId)}
            onKeyDown={openVideo.bind(this, video.videoId)}
            role="presentation"
          >
            <img
              className="bnat-video-thumbnail-img"
              src={video.thumbnail}
              alt="img"
            />
            <img
              className="bnat-video-thumbnail-play-icon"
              height="57"
              src="https://cdn1.byjus.com/byjusweb/img/bnat/play-btn.svg"
              width="57"
              alt="img"
            />
          </div>
          <Typography className={classes.regularTxt}>{video.title}</Typography>
        </Grid>
      ))}
    </>
  );

  if (examType === EXAM_TYPES.JEE_MAIN) {
    videos = videosData.mockTestJee;
    youtubeChannel = MOCK_TEST_CONSTANTS.JEE_YOUTUBE_CHANNEL;
  } else if (examType === EXAM_TYPES.NEET) {
    videos = videosData.mockTestNeet;
    youtubeChannel = MOCK_TEST_CONSTANTS.NEET_YOUTUBE_CHANNEL;
  } else if (examType === EXAM_TYPES.BITSAT) {
    videos = videosData.mockTestBitsat;
    youtubeChannel = MOCK_TEST_CONSTANTS.BITSAT_YOUTUBE_CHANNEL;
  } else if (examType === EXAM_TYPES.BNAT) {
    getVideoandChannel();
  }

  React.useEffect(() => {
    initiateIntersectionObserver(
      document.querySelector("#videoSection"),
      0.8,
      setActiveNav,
      "4"
    );
  }, [setActiveNav]);

  return (
    <div id="videoSection">
      <Typography variant="h4" className={classes.sectionTitle}>
        {`BYJU'S ${checkIfLanguageisEnglish ? "Videos" : "वीडियो"}`}
      </Typography>
      <Typography className={classes.regularTxt}>
        {checkIfLanguageisEnglish
          ? "Let us dive down into the world of"
          : "आइए, यहाँ दिए गए वीडियो को देखकर"}
        <span className={classes.boldTxt}>{` BYJU'S `}</span>
        {checkIfLanguageisEnglish
          ? "by watching the videos given here."
          : "की दुनिया से परिचित होते हैं।"}
      </Typography>
      <Typography className={classes.regularTxt}>
        {checkIfLanguageisEnglish ? "Visit" : "अधिक जानकारी के लिए"}
        <a href={youtubeChannel} target="_blank" rel="noopener noreferrer">
          <span className={classes.OrngClr}>
            {secondChannel !== null
              ? " BYJU'S JEE "
              : ` BYJU'S ${secondaryText}`}
          </span>
        </a>
        {secondChannel != null && (
          <>
            <span>and</span>
            <a href={secondChannel} target="_blank" rel="noopener noreferrer">
              <span className={classes.OrngClr}>{` BYJU'S NEET `}</span>
            </a>
          </>
        )}
        {checkIfLanguageisEnglish
          ? "YouTube channel for more."
          : "YouTube चैनल पर जाएँ ।"}
      </Typography>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />
      {videos.row1 && (
        <Grid container spacing={3}>
          {renderVideos(videos.row1)}
        </Grid>
      )}
      {videos.row2 && (
        <Grid container spacing={3}>
          {renderVideos(videos.row2)}
        </Grid>
      )}
    </div>
  );
}
