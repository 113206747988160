/* eslint-disable react/jsx-one-expression-per-line */
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import CustomModal from "../UtilComponents/Modal/Modal";
import { ICONS, NOTIFICATION_MODAL, TRAIL_COLORS } from "../../AppConfig";
import Button from "../Button/Button";

const useStyles = makeStyles((theme) => ({
  centered: {
    textAlign: "center",
  },
  image: {
    width: 350,
  },
  text: {
    color: TRAIL_COLORS.BLACK_TWO,
    fontSize: 14,
  },
  buttonContainer: {
    marginTop: 10,
  },
  [theme.breakpoints.down("xs")]: {
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const NotificationContent = ({ onActionClick }) => {
  const user = localStorage.getItem("user");
  const { mobile, verifiedMobile } = user ? JSON.parse(user) : {};
  const classes = useStyles();
  return (
    <div className={classes.centered}>
      <div>
        <img
          src={ICONS.NOTIFICATION_POPUP_ICON}
          className={classes.image}
          alt="Notification"
        />
      </div>
      <span className={classes.text}>
        {NOTIFICATION_MODAL.NOTIFICATION_POP_UP}
        <div>{mobile || verifiedMobile} ?</div>
      </span>
      <div className={classes.buttonContainer}>
        <Button type="purple" onClick={() => onActionClick("yes")}>
          Yes
        </Button>
        <Button type="purpleOutline" onClick={() => onActionClick("no")}>
          No
        </Button>
      </div>
    </div>
  );
};

const FinalMessage = ({ handleModalClose }) => {
  const classes = useStyles();
  return (
    <div className={classes.centered}>
      <div>
        <img
          src={ICONS.WHATSAPP_NOTIFiCATION}
          className={classes.image}
          alt="Notification"
        />
      </div>
      <span className={classes.text}>
        {NOTIFICATION_MODAL.NOTIFICATION_POPUP_FINAL_MESSAGE}
      </span>
      <div className={classes.buttonContainer}>
        <Button
          type="purple"
          onClick={() => {
            sessionStorage.setItem("whatsapp_notification_popup", false);
            handleModalClose();
          }}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

const NotificationModal = ({
  open,
  mobileNumber,
  handleAction,
  handleModalClose,
}) => {
  const [isFirstScreen, setFirst] = useState(true);

  const handleModalAction = (action) => {
    handleAction(action);
    if (action === "yes") {
      setFirst(false);
    } else if (action === "no") {
      handleModalClose();
    }
  };
  const handleModalCloseAction = (action) => {
    sessionStorage.setItem("whatsapp_notification_popup", false);
    handleModalClose(isFirstScreen ? action : null);
  };
  return (
    <CustomModal
      open={open}
      title={isFirstScreen ? "Never miss an AITS Test!" : "Thank you!"}
      onClose={handleModalCloseAction}
    >
      {isFirstScreen ? (
        <NotificationContent
          mobileNumber={mobileNumber}
          onActionClick={handleModalAction}
        />
      ) : (
        <FinalMessage handleModalClose={handleModalCloseAction} />
      )}
    </CustomModal>
  );
};

export default NotificationModal;
