/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../components/Button/Button";
import InfoButton from "../components/Button/InfoButton";
import Instructions from "../components/Instructions/Instructions";
import PaperSpecific from "../components/Instructions/PaperSpecificInstructions";
import Legend from "../components/Legend/Legend";
import Question from "../components/Question/Question";
import QuestionInstruction from "../components/QuestionInstruction/QuestionInstruction";
import QuestionNavigator from "../components/QuestionNavigator/QuestionNavigator";
import SectionTabs from "../components/TestSpecific/SectionTabs";
import UserDetail from "../components/UserDetail/UserDetail";
import Header from "../components/UtilComponents/Header/Header";
import PopUp from "../components/UtilComponents/PopUp/PopUp";
import JeeAdvancedTestSubmissionPage from "../components/TestSubmissionPage/JeeAdvancedTestSubmission";
import Loader from "../components/UtilComponents/Loader/Loader";
import {
  clearQuestionTimeSpent,
  loadAssignment,
  submitAssignment,
  useAssignmentLoader,
  useChangeCurrentSubject,
  useGenerateAssignment,
  useQuestionTimeSpent,
  useSetTestTimerandAutoSubmit,
  getSectionQuestion,
  isLastQuestion,
  useCurrentPage,
  useSectionAttempts,
  useSectionMeta,
  useSectionQuestions,
  useTestTakingSelectors,
  useExamSummaryData,
  calculateSectionLegends,
  isLastQuestionLastSection,
} from "../components/Utils/JeeAdvancedTestTaking";
import { COLORS } from "../components/Utils/Constants";
import {
  getNextSubject,
  updateTransformQuestion,
  formatTime,
  formatAttemptPayload,
} from "../components/Utils/TestTaking";
import * as TestTakingTypes from "../redux/TestTaking/TestTaking.types";
import {
  establishSocketConnection,
  redirectToSessionClosePage,
  startTimer,
} from "../components/Utils/WebSocket";
import { WEB_SOCKET, NETWORK } from "../AppConfig";

const useStyles = makeStyles((theme) => ({
  subheader: {
    padding: "8px 16px",
    backgroundColor: COLORS.LIGHT_GREY,
    [theme.breakpoints.down("sm")]: {
      padding: "10px 12px",
      display: "flex",
    },
  },
  phoneTimer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
      fontSize: 12,
    },
    "& span": {
      backgroundColor: COLORS.DARK_GREY,
      padding: "4px 9px",
      marginLeft: 4,
      borderRadius: 4,
      color: COLORS.WHITE,
      fontSize: 12,
    },
  },
  timerSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    position: "relative",
    "& p": {
      marginTop: 0,
      marginBottom: 0,
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonGroup: {
    position: "fixed",
    zIndex: 2,
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `1px solid ${COLORS.LIGHT_GREY}`,
    backgroundColor: COLORS.WHITE,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 0px 8px 12px",
    },
  },
  buttonInnerGroup: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "5px 0",
      "& button": {
        flexGrow: 1,
      },
    },
  },
  timer: {
    marginLeft: "auto",
  },
  timeLeftSection: {
    position: "absolute",
    right: "86px",
  },
  phoneTimeLeftSection: {
    position: "absolute",
    right: "92px",
  },
  questionWrap: {
    padding: "0 30px",
    position: "relative",
    overflow: "auto",
    height: "calc(100vh - 282px)",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      height: "calc(100vh - 302px)",
    },
  },
  [theme.breakpoints.down("sm")]: {
    hideForPhone: {
      display: "none",
    },
  },
  showJustForPhone: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

const Advanced = () => {
  // Timers
  const questionTimeSpentTimer = useRef(null);
  const testTimeRemainingTimer = useRef(null);

  const dispatch = useDispatch();
  const classes = useStyles();
  /* Selectors */
  const [
    instructions,
    // eslint-disable-next-line no-unused-vars
    assignment,
    subjects,
    questions,
    testTakingRedirect,
    testTimeRemaining,
    isOnline,
  ] = useTestTakingSelectors();
  /* States */
  const [currentPage, setCurrentPage] = useCurrentPage();
  const [timeRemaining, setTimeRemaining] = useState();
  const [questionTimeSpent, setQuestionTimeSpent] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState();
  const [currentSectionId, setCurrentSectionId] = useState();
  const [popup, setPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState();
  const [currentSubject, setCurrentSubject] = useState();
  const [prevSubject, setPrevSubject] = useState();
  const [
    currentSectionQuestions,
    setCurrentSectionQuestions,
  ] = useSectionQuestions(currentSubject, questions);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [questionsBySections, setQuestionsBySections] = useSectionAttempts();
  const [selectedSectionMeta, setSelectedQuestionMeta] = useState();
  const mySelectedSectionMeta = useSectionMeta(selectedSectionMeta);
  const currentSubjectMeta = useSectionMeta(currentSubject);
  const examSummaryData = useExamSummaryData();
  const [finalScreen, setFinalScreen] = useState(false);
  const [ws, setWs] = useState(null);
  const [newSession, setNewSession] = useState(false);
  const [heartbeatTimer, setHeartbeatTimer] = useState(
    WEB_SOCKET.HEART_BEAT_TIME
  );
  const [heartbeatInterval, setHeartbeatInterval] = useState(null);
  const [popupSubMessage, setPopupSubMessage] = useState("");
  const [socketTimeoutTimer, setSocketTimeoutTimer] = useState(
    WEB_SOCKET.SOCKET_TIMEOUT_TIME
  );
  const [socketTimeoutInterval, setSocketTimeoutInterval] = useState(null);
  const testSubmitted = sessionStorage.getItem("test_submitted") || "";
  const loader = assignment.loading;
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const paperTitle =
    assignment && assignment.assignment && assignment.assignment.assessment
      ? assignment.assignment.assessment.title
      : "";
  let userName = "";
  let userProfileUrl = "";
  if (userDetails) {
    userName = userDetails.full_name;
    if (
      Array.isArray(userDetails.user_profiles) &&
      userDetails.user_profiles.length > 0
    ) {
      userProfileUrl = userDetails.user_profiles[0].avatar;
    }
  }
  const component = (
    <Legend
      answered={mySelectedSectionMeta && mySelectedSectionMeta.answered}
      answeredAndMarked={
        mySelectedSectionMeta && mySelectedSectionMeta.answeredandmarked
      }
      markedForReview={
        mySelectedSectionMeta && mySelectedSectionMeta.markedforreview
      }
      notAnswered={mySelectedSectionMeta && mySelectedSectionMeta.notanswered}
      notVisited={mySelectedSectionMeta && mySelectedSectionMeta.notvisited}
      type="questionActivity"
      header="JEE (Advanced 2020)"
      iconType="advanced"
    />
  );

  /* Functions */
  const clearTimers = () => {
    clearInterval(testTimeRemainingTimer.current);
    clearInterval(questionTimeSpentTimer.current);
  };

  const onChangeSubject = (subject) => {
    setCurrentQuestion();
    setCurrentSubject(subject);
    setQuestionsBySections({
      sectionId: currentSubject.id,
      questionId: currentQuestion.id,
    });
    clearQuestionTimeSpent(setQuestionTimeSpent);
  };

  const updateQuestions = ({ type, next, id }) => {
    if (type === "MarkForReview") {
      if (currentAnswer && typeof currentAnswer.value === "string") {
        currentAnswer.value = currentAnswer.value.trim();
      }
      if (
        !currentAnswer ||
        !currentAnswer.value ||
        currentAnswer.length === 0
      ) {
        const updatedQuestions = updateTransformQuestion({
          questions,
          id,
          flag: "markedforreview",
          type: currentQuestion.type,
        });
        dispatch({
          type: TestTakingTypes.ATTEMPT_QUESTION,
          payload: formatAttemptPayload({
            isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
            isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
            answer: null,
            assignmentID: currentSubject.id,
            time: questionTimeSpent / 1000,
            type: "markedforreview",
            questionID: currentQuestion.id,
          }),
        });
        dispatch({
          type: TestTakingTypes.UPDATE_QUESTIONS,
          payload: updatedQuestions,
        });
        setCurrentSectionQuestions((q) => {
          return q.map((item) => {
            if (item.id === currentQuestion.id) {
              return { ...item, status: "markedforreview", answer: null };
            }
            return item;
          });
        });
        const isLastSection = isLastQuestionLastSection({
          currentSubject,
          currentQuestion,
          subjects,
          currentSectionQuestions,
        });
        if (isLastSection) {
          clearQuestionTimeSpent(setQuestionTimeSpent);
          return;
        }
        setCurrentAnswer();
        const nextQuestion = currentSectionQuestions.filter(
          (item) => item.questionNumber === currentQuestion.questionNumber + 1
        )[0];
        setCurrentQuestion(nextQuestion);
      } else {
        const updatedQuestions = updateTransformQuestion({
          questions,
          id,
          flag: "answeredandmarked",
          answer: currentAnswer.value,
          type: currentQuestion.type,
        });
        dispatch({
          type: TestTakingTypes.ATTEMPT_QUESTION,
          payload: formatAttemptPayload({
            isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
            isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
            answer: currentAnswer.value,
            assignmentID: currentSubject.id,
            time: questionTimeSpent / 1000,
            type: "answeredandmarked",
            questionID: currentQuestion.id,
          }),
        });
        dispatch({
          type: TestTakingTypes.UPDATE_QUESTIONS,
          payload: updatedQuestions,
        });
        setCurrentSectionQuestions((q) => {
          return q.map((item) => {
            if (item.id === currentQuestion.id) {
              return {
                ...item,
                status: "answeredandmarked",
                answer: currentAnswer,
              };
            }
            return item;
          });
        });
        const isLastSection = isLastQuestionLastSection({
          currentSubject,
          currentQuestion,
          subjects,
          currentSectionQuestions,
        });
        if (isLastSection) {
          clearQuestionTimeSpent(setQuestionTimeSpent);
          return;
        }
        setCurrentAnswer();
        const nextQuestion = currentSectionQuestions.filter(
          (item) => item.questionNumber === currentQuestion.questionNumber + 1
        )[0];
        setCurrentQuestion(nextQuestion);
      }
      const nextSubject = getNextSubject({
        subjects,
        currentSubject,
        action: "next",
      });
      const isLast = isLastQuestion({
        currentQuestion,
        currentSectionQuestions,
      });
      setPrevSubject();
      if (isLast) {
        setPrevSubject(currentSubject);
        onChangeSubject(nextSubject);
      }
      clearQuestionTimeSpent(setQuestionTimeSpent);
    }
    if (type === "SaveAndNext") {
      if (currentAnswer && typeof currentAnswer.value === "string") {
        currentAnswer.value = currentAnswer.value.trim();
      }
      if (
        !currentAnswer ||
        !currentAnswer.value ||
        currentAnswer.length === 0
      ) {
        setPopupMessage("Please answer the question before saving");
        setPopup(true);
        return;
      }
      const updatedQuestions = updateTransformQuestion({
        questions,
        id,
        flag: "answered",
        answer: currentAnswer.value,
        type: currentQuestion.type,
      });

      dispatch({
        type: TestTakingTypes.UPDATE_QUESTIONS,
        payload: updatedQuestions,
      });
      dispatch({
        type: TestTakingTypes.ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer: currentAnswer.value,
          assignmentID: currentSubject.id,
          time: questionTimeSpent / 1000,
          type: "answered",
          questionID: currentQuestion.id,
        }),
      });
      setCurrentSectionQuestions((q) => {
        return q.map((item) => {
          if (item.id === currentQuestion.id) {
            return { ...item, status: "answered", answer: currentAnswer };
          }
          return item;
        });
      });
      const isLastSection = isLastQuestionLastSection({
        currentSubject,
        currentQuestion,
        subjects,
        currentSectionQuestions,
      });
      if (isLastSection) {
        clearQuestionTimeSpent(setQuestionTimeSpent);
        return;
      }
      setCurrentAnswer();
      const nextQuestion = currentSectionQuestions.filter(
        (item) => item.questionNumber === currentQuestion.questionNumber + 1
      )[0];
      const nextSubject = getNextSubject({
        subjects,
        currentSubject,
        action: "next",
      });
      const isLast = isLastQuestion({
        currentQuestion,
        currentSectionQuestions,
      });
      setPrevSubject();
      if (isLast) {
        setPrevSubject(currentSubject);
        onChangeSubject(nextSubject);
      }
      setCurrentQuestion(nextQuestion);
      clearQuestionTimeSpent(setQuestionTimeSpent);
    } else if (type === "Jump") {
      if (next.questionNumber === currentQuestion.questionNumber) {
        return;
      }
      const currentStatus =
        currentQuestion.status === "notvisited"
          ? "notanswered"
          : currentQuestion.status;
      setCurrentSectionQuestions((q) => {
        return q.map((item) => {
          if (item.id === currentQuestion.id) {
            return {
              ...item,
              status: currentStatus,
              answer:
                currentQuestion.status !== "notvisited" &&
                currentQuestion.answer &&
                currentQuestion.answer
                  ? currentQuestion.answer
                  : "",
            };
          }
          return item;
        });
      });
      const updatedQuestions = updateTransformQuestion({
        type: currentQuestion.type,
        questions,
        id: currentQuestion.id,
        flag: currentStatus,
        answer:
          currentQuestion.status !== "notvisited" &&
          currentQuestion.answer &&
          currentQuestion.answer.value
            ? currentQuestion.answer.value
            : "",
      });
      dispatch({
        type: TestTakingTypes.ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer:
            currentQuestion.status !== "notvisited" &&
            currentQuestion.answer &&
            currentQuestion.answer.value
              ? currentQuestion.answer.value
              : "",
          assignmentID: currentSubject.id,
          time: questionTimeSpent / 1000,
          type: currentStatus,
          questionID: currentQuestion.id,
        }),
      });
      dispatch({
        type: TestTakingTypes.UPDATE_QUESTIONS,
        payload: updatedQuestions,
      });
      setCurrentQuestion(next);
      setCurrentAnswer();
      clearQuestionTimeSpent(setQuestionTimeSpent);
    } else if (type === "Clear") {
      setCurrentAnswer();
      setCurrentQuestion((q) => ({
        ...q,
        answer: null,
        status: "notanswered",
      }));
      setCurrentSectionQuestions((q) => {
        return q.map((item) => {
          if (item.id === currentQuestion.id) {
            return { ...item, status: "notanswered", answer: null };
          }
          return item;
        });
      });
      const updatedQuestions = updateTransformQuestion({
        questions,
        id: currentQuestion.id,
        flag: "cleared",
        type: currentQuestion.type,
      });
      dispatch({
        type: TestTakingTypes.ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer: null,
          assignmentID: currentSubject.id,
          time: questionTimeSpent / 1000,
          type: "cleared",
          questionID: currentQuestion.id,
        }),
      });
      dispatch({
        type: TestTakingTypes.UPDATE_QUESTIONS,
        payload: updatedQuestions,
      });
    }
  };

  const onAcceptInstructions = () => {
    setCurrentPage("testTaking");
    setQuestionTimeSpent(0);
    sessionStorage.setItem("time", 0);
    loadAssignment(dispatch, assignment.assignment.id);
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  const handleSubmitClick = () => {
    setCurrentPage("examSummary");
  };

  const handleCancelClick = () => {
    setCurrentPage("testTaking");
  };

  const handleExamSubmission = () => {
    clearQuestionTimeSpent(setQuestionTimeSpent);
    clearTimers();
  };

  const onAutoSubmitExam = () => {
    setCurrentPage("examSummary");
    submitAssignment(dispatch);
    setFinalScreen(true);
  };

  const handleTestTimer = () => {
    testTimeRemainingTimer.current = setInterval(() => {
      setTimeRemaining((value) => {
        if (value) {
          if (value <= 1) {
            onAutoSubmitExam();
          }
          return value - 1;
        }
        return value;
      });
    }, 1000);
  };

  const handleQuestionTimer = () => {
    questionTimeSpentTimer.current = setInterval(() => {
      setQuestionTimeSpent((t) => {
        const myTime = t + 100;
        sessionStorage.setItem("time", myTime);
        return myTime;
      });
    }, 100);
  };

  const getInstructionSectionIds = () => {
    const instructionSectionIds = [];
    if (
      instructions &&
      instructions.adv_instructions &&
      instructions.adv_instructions
    ) {
      if (Array.isArray(instructions.adv_instructions.sections)) {
        instructions.adv_instructions.sections.forEach((section) => {
          instructionSectionIds.push(section.id);
        });
      }
    }
    return instructionSectionIds;
  };

  const getInstructionSectionTitle = () => {
    const sectionIds = getInstructionSectionIds();
    let sectionIndex = 1;
    if (Array.isArray(sectionIds) && sectionIds.length > 0) {
      const idx = sectionIds.findIndex((id) => id === currentSectionId);
      if (idx >= 0) {
        sectionIndex = (idx % 3) + 1;
      }
    }
    return `Section ${sectionIndex}`;
  };

  const closePopup = () => {
    setPopup(false);
  };

  const reload = () => {
    setPopup(false);
    ws.close();
    window.location.reload();
  };

  const initiateSocketConnection = () => {
    const data = {
      action: WEB_SOCKET.REGISTER_ACTION_STRING,
      assignment_id: assignment.assignment.id,
    };
    establishSocketConnection(
      JSON.stringify(data),
      setNewSession,
      setHeartbeatTimer,
      setWs,
      setHeartbeatInterval,
      setSocketTimeoutTimer,
      setSocketTimeoutInterval
    );
  };

  const recycleHeartbeat = () => {
    clearInterval(heartbeatInterval);
    setHeartbeatTimer(WEB_SOCKET.HEART_BEAT_TIME);
  };

  const recycleSocketTimeout = () => {
    clearInterval(socketTimeoutInterval);
    setSocketTimeoutTimer(WEB_SOCKET.SOCKET_TIMEOUT_TIME);
  };

  // Effects and hooks
  useGenerateAssignment(dispatch);
  useEffect(() => {
    if (
      instructions &&
      instructions.adv_instructions &&
      Array.isArray(instructions.adv_instructions.sections) &&
      instructions.adv_instructions.sections.length > 0
    ) {
      setCurrentSectionId(instructions.adv_instructions.sections[0].id);
    }
  }, [instructions]);
  useEffect(() => {
    if (currentSectionQuestions.length) {
      const legendData = calculateSectionLegends(currentSectionQuestions);
      dispatch({
        type: TestTakingTypes.UPDATE_ADVANCED_LEGEND,
        payload: {
          title:
            prevSubject && prevSubject.title
              ? prevSubject.title
              : currentSubject.title,
          data: legendData,
        },
      });
      if (prevSubject && prevSubject.title) {
        setPrevSubject();
      }
    }
  }, [currentSectionQuestions]);

  useEffect(() => {
    if (
      !currentAnswer &&
      currentQuestion &&
      currentQuestion.answer &&
      currentQuestion.answer.value
    ) {
      setCurrentAnswer(currentQuestion.answer);
    }
  }, [currentAnswer, currentQuestion]);

  useEffect(() => {
    if (subjects && subjects.length && !currentSubject) {
      setCurrentSubject(subjects[0]);
    }
    if (!currentQuestion && currentSectionQuestions.length) {
      if (currentSubject.title === currentSectionQuestions[0].subject) {
        const isCached = questionsBySections.filter(
          (item) => item.sectionId === currentSubject.id
        )[0];
        const cachedQuestion = getSectionQuestion(
          currentSectionQuestions,
          isCached && isCached.questionId
        );
        if (isCached) {
          setCurrentQuestion(cachedQuestion);
          setCurrentAnswer(cachedQuestion.answer);
          return;
        }
        setCurrentQuestion(currentSectionQuestions[0]);
        if (
          currentSectionQuestions[0].answer &&
          currentSectionQuestions[0].answer.value
        ) {
          setCurrentAnswer(currentSectionQuestions[0].answer);
        }
      }
    }
  });

  useEffect(() => {
    if (!timeRemaining && testTimeRemaining) {
      setTimeRemaining(testTimeRemaining);
    }
  }, [testTimeRemaining]);

  useEffect(() => {
    if (testTakingRedirect) {
      setCurrentPage("examSummary");
    }
  }, [testTakingRedirect]);

  useSetTestTimerandAutoSubmit(
    testTakingRedirect,
    onAutoSubmitExam,
    handleTestTimer,
    clearTimers
  );

  useQuestionTimeSpent(currentPage, handleQuestionTimer);

  useChangeCurrentSubject(
    currentSubject,
    subjects,
    getInstructionSectionIds(),
    setCurrentSectionId
  );

  useAssignmentLoader(loader, testSubmitted, setCurrentPage, setFinalScreen);

  useEffect(() => {
    if (
      assignment.loading === false &&
      assignment.assignment.id &&
      testSubmitted === ""
    ) {
      initiateSocketConnection();
    }
  }, [assignment.loading]);

  useEffect(() => {
    if (newSession === true) {
      setPopup(true);
      setPopupMessage(WEB_SOCKET.POPUP_MESSAGE);
      setPopupSubMessage(WEB_SOCKET.POPUP_SUB_MESSAGE);
    }
  }, [newSession]);

  useEffect(() => {
    if (heartbeatTimer < 1) {
      recycleHeartbeat();
      ws.send(JSON.stringify({ action: WEB_SOCKET.HEARTBEAT_ACTION_STRING }));
      startTimer(setHeartbeatTimer, setHeartbeatInterval);
    }
  }, [heartbeatTimer]);

  useEffect(() => {
    if (socketTimeoutTimer < 1) {
      recycleHeartbeat();
      recycleSocketTimeout();
      ws.close();
      initiateSocketConnection();
    }
  }, [socketTimeoutTimer]);

  useEffect(() => {
    if (!isOnline) {
      setPopup(true);
      setPopupMessage(NETWORK.OFFLINE_MESSAGE);
    } else {
      setPopup(false);
    }
  }, [isOnline]);

  const instructionComponent = (
    <QuestionInstruction
      data={
        instructions &&
        instructions.adv_instructions &&
        Array.isArray(instructions.adv_instructions.sections)
          ? instructions.adv_instructions.sections.find(
              (section) => section.id === currentSectionId
            ) &&
            instructions.adv_instructions.sections.find(
              (section) => section.id === currentSectionId
            ).instructions
          : ""
      }
      title={getInstructionSectionTitle()}
    />
  );

  return (
    <>
      {(loader === undefined || loader === true) && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
      {(loader === false || loader === "redirect") && (
        <div>
          <Header examType="advanced" disableLink noNotes />
          <PopUp
            message={popupMessage}
            open={popup}
            close={closePopup}
            window={newSession}
            reload={reload}
            cancelSession={() => {
              redirectToSessionClosePage(ws);
            }}
            subMessage={popupSubMessage}
          />
          {currentPage === "instructions1" ? (
            <Instructions
              isAdvanced
              submitInstructions={() => setCurrentPage("instructions2")}
            />
          ) : null}
          {currentPage === "instructions2" ? (
            <PaperSpecific
              instructions={
                instructions && instructions.adv_instructions
                  ? instructions.adv_instructions.instructions
                  : ""
              }
              sectionsData={
                instructions &&
                instructions.adv_instructions &&
                Array.isArray(instructions.adv_instructions.sections)
                  ? instructions.adv_instructions.sections.slice(0, 3)
                  : []
              }
              onSubmit={() => onAcceptInstructions()}
              onPrevious={() => setCurrentPage("instructions1")}
              paperTitle={paperTitle}
            />
          ) : null}
          {currentPage === "testTaking" ? (
            <Grid container>
              <Grid item md={9} xs={12}>
                <div className={classes.subheader}>
                  <InfoButton
                    component={component}
                    type="popup"
                    onToolTipOpen={() => {
                      setSelectedQuestionMeta({ title: "all" });
                    }}
                  >
                    {paperTitle}
                  </InfoButton>
                  <div className={classes.phoneTimer}>
                    <div className={classes.phoneTimeLeftSection}>
                      Time Left:
                    </div>
                    <span>{formatTime(timeRemaining)}</span>
                  </div>
                </div>
                <div className={classes.timerSection}>
                  <p>Section</p>
                  <p className={classes.timeLeftSection}>Time Left:</p>
                  <p className={classes.timer}>{formatTime(timeRemaining)}</p>
                </div>
                <SectionTabs
                  Component={component}
                  currentSubject={currentSubject}
                  subjects={subjects}
                  handleToolTipClick={(e) => {
                    const selectedSubject = subjects.filter(
                      (item) => item.title === e
                    )[0];
                    setSelectedQuestionMeta(selectedSubject);
                  }}
                  onChangeSection={(subject) => onChangeSubject(subject)}
                />
                <div className={classes.showJustForPhone}>
                  <Legend
                    answered={currentSubjectMeta && currentSubjectMeta.answered}
                    answeredAndMarked={
                      currentSubjectMeta && currentSubjectMeta.answeredandmarked
                    }
                    markedForReview={
                      currentSubjectMeta && currentSubjectMeta.markedforreview
                    }
                    notAnswered={
                      currentSubjectMeta && currentSubjectMeta.notanswered
                    }
                    notVisited={
                      currentSubjectMeta && currentSubjectMeta.notvisited
                    }
                    iconType="advanced"
                    inLine
                  />
                </div>
                <div className={classes.questionWrap}>
                  <Question
                    instructions={instructionComponent}
                    sequence={currentQuestion && currentQuestion.questionNumber}
                    type={currentQuestion && currentQuestion.type}
                    options={currentQuestion && currentQuestion.options}
                    question={currentQuestion && currentQuestion.question}
                    value={
                      currentAnswer ||
                      (currentQuestion && currentQuestion.answer)
                    }
                    onChangeOption={(e) => {
                      setCurrentAnswer(e);
                    }}
                  />
                </div>
                <div className={classes.buttonGroup}>
                  <Grid container>
                    <Grid item md={10} xs={12}>
                      <Grid container>
                        <Grid item md={8} xs={12}>
                          <div className={classes.buttonInnerGroup}>
                            <Button
                              type="white"
                              onClick={() =>
                                updateQuestions({
                                  type: "MarkForReview",
                                  id: currentQuestion.id,
                                })}
                            >
                              Mark for Review & Next
                            </Button>
                            <Button
                              type="white"
                              onClick={() =>
                                updateQuestions({
                                  type: "Clear",
                                  id: currentQuestion.id,
                                })}
                            >
                              Clear Response
                            </Button>
                          </div>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <div className={classes.buttonInnerGroup}>
                            <Button
                              type="blue"
                              onClick={() => {
                                updateQuestions({
                                  type: "SaveAndNext",
                                  id: currentQuestion.id,
                                });
                              }}
                            >
                              Save & Next
                            </Button>
                            <Button type="blue" onClick={handleSubmitClick}>
                              Submit
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <div className={classes.hideForPhone}>
                  <UserDetail
                    candidateName={userName}
                    picture={userProfileUrl || "placeholder"}
                    type="advanced"
                  />
                  <hr />
                  <Legend
                    answered={currentSubjectMeta && currentSubjectMeta.answered}
                    answeredAndMarked={
                      currentSubjectMeta && currentSubjectMeta.answeredandmarked
                    }
                    markedForReview={
                      currentSubjectMeta && currentSubjectMeta.markedforreview
                    }
                    notAnswered={
                      currentSubjectMeta && currentSubjectMeta.notanswered
                    }
                    notVisited={
                      currentSubjectMeta && currentSubjectMeta.notvisited
                    }
                    iconType="advanced"
                  />
                </div>
                <QuestionNavigator
                  currentQuestion={currentQuestion}
                  onAttempt={(e) => updateQuestions({ type: "Jump", next: e })}
                  questionData={currentSectionQuestions}
                  section={currentSubject && currentSubject.title}
                />
              </Grid>
            </Grid>
          ) : null}
          {currentPage === "examSummary" ? (
            <JeeAdvancedTestSubmissionPage
              examSummaryData={examSummaryData}
              finalPage={finalScreen || testTakingRedirect}
              handleCancelClick={handleCancelClick}
              handleExamSubmission={handleExamSubmission}
              paperTitle={paperTitle}
              webSocket={ws}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default Advanced;
