export const COLORS = {
  ADVANCED_MAIN: "#40aae9",
  ADVANCED_ACCENT: "#e6f6fc",
  WHITE: "#fff",
  DARK_GREY: "#333",
  BLACK: "#000",
  GREY: "#ccc",
  LIGHT_GREY: "#eee",
  EXTRA_LIGHT_GREY: "#f3f3f3",
};

export const STRINGS = {};
