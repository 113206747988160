export const LOAD_ASSIGNMENT = "LOAD_ASSIGNMENT";
export const LOAD_ASSIGNMENT_START = "LOAD_ASSIGNMENT_START";
export const LOAD_ASSIGNMENT_SUCCESS = "LOAD_ASSIGNMENT_SUCCESS";
export const LOAD_ASSIGNMENT_FAILURE = "LOAD_ASSIGNMENT_FAILURE";
export const LOAD_ASSIGNMENT_ADDITIONAL_QUESTION_UNLOCK_START =
  "LOAD_ASSIGNMENT_ADDITIONAL_QUESTION_UNLOCK_START";

export const REMAINING_TIME = "REMAINING_TIME";
export const REMAINING_TIME_START = "REMAINING_TIME_START";
export const REMAINING_TIME_SUCCESS = "REMAINING_TIME_SUCCESS";
export const REMAINING_TIME_FAILURE = "REMAINING_TIME_FAILURE";

export const UPDATE_QUESTIONS = "UPDATE_QUESTIONS";
export const UPDATE_QUESTIONS_START = "UPDATE_QUESTIONS_START";
export const UPDATE_QUESTIONS_SUCCESS = "UPDATE_QUESTIONS_SUCCESS";
export const UPDATE_QUESTIONS_FAILURE = "UPDATE_QUESTIONS_FAILURE";

export const ATTEMPT_QUESTION = "ATTEMPT_QUESTION";
export const ATTEMPT_QUESTION_START = "ATTEMPT_QUESTION_START";
export const ATTEMPT_QUESTION_SUCCESS = "ATTEMPT_QUESTION_SUCCESS";
export const ATTEMPT_QUESTION_FAILURE = "ATTEMPT_QUESTION_FAILURE";

export const REDIRECT_ON_FINISH = "REDIRECT_ON_FINISH";
export const UPDATE_REMAINING_TIME = "UPDATE_REMAINING_TIME";

export const UPDATE_ADVANCED_LEGEND = "UPDATE_ADVANCED_LEGEND";
export const UPDATE_ADVANCED_LEGEND_SUCCESS = "UPDATE_ADVANCED_LEGEND_SUCCESS";
