import api from "./api";
import { showErrorPage } from "../components/Utils/CommonFunctions";

export function* fetchAssignmentDetails() {
  let data;
  const assignmentId = yield localStorage.getItem("user_assignment_id");
  api.setHeader("Authorization", localStorage.getItem("user_token"));

  yield api
    .get(`/assignments/${assignmentId}`)
    .then((response) =>
      response.status > 399 ? showErrorPage(response.status) : response.data
    )
    .then((json) => {
      data = json.assignment;
    });
  yield localStorage.setItem("user_id", data.assignee_id);
  return data;
}

export function* fetchAssignmentandQuestionVideoDetails(videoData) {
  let data;
  api.setHeader("Authorization", localStorage.getItem("user_token"));
  const { assessmentId, params } = videoData;
  yield api
    .get(`/assessments/${assessmentId}/video_solution`, params)
    .then((response) =>
      response.status > 399 ? showErrorPage(response.status) : response.data
    )
    .then((json) => {
      data = json;
    });
  return data;
}

export const fetchVideoDrmDetails = (url) => {
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

export function* fetchQuestionNotes(id) {
  let data;
  yield api
    .get(`/notes/1/question_attempts/${id}`)
    .then((response) => response.data)
    .then((json) => {
      data = json.results;
    });

  return data;
}
