// Fix the eslint warnings in next sprint
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Box from "../Box/Box";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    fontFamily: "gotham",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  [theme.breakpoints.between(200, 320)]: {
    container: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("md")]: {
    container: {
      fontSize: "12px",
    },
  },
}));

const BoxContainer = ({ data, onAttempt, currentQuestion, size, iconType }) => {
  const classes = useStyles();
  const [currQuestion, setCurrQuestion] = useState();
  useEffect(() => {
    if (!currQuestion || currentQuestion.questionNumber) {
      setCurrQuestion({ questionNumber: currentQuestion.questionNumber });
    }
  }, [currentQuestion.questionNumber]);
  return (
    <div className={classes.container}>
      {data &&
        data.map((item) => (
          <Box
            size={size || 55}
            key={item.id}
            onClick={() => {
              onAttempt(item);
            }}
            questionNumber={item.questionNumber}
            status={item.status}
            margin={1}
            selected={
              currQuestion &&
              currQuestion.questionNumber === item.questionNumber
            }
            iconType={iconType}
          />
        ))}
    </div>
  );
};

BoxContainer.propTypes = {
  data: PropTypes.arrayOf({
    questionNumber: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  onAttempt: PropTypes.func,
  currentQuestion: PropTypes.objectOf({
    questionNumber: PropTypes.number,
    status: PropTypes.string,
  }),
};
BoxContainer.defaultProps = {
  onAttempt: () => {},
  currentQuestion: [],
};

export default BoxContainer;
