import api from "./api";
import { showErrorPage } from "../components/Utils/CommonFunctions";

export default function* getMockTestData() {
  api.setHeader("Authorization", localStorage.getItem("user_token"));
  let resp;
  yield api
    .get(`/mock_tests/get_details`)
    .then((response) =>
      response.status > 399 ? showErrorPage(response.status) : response
    )
    .then((response) => {
      resp = response;
    });
  return resp;
}
