import api from "./api";
import { showErrorPage } from "../components/Utils/CommonFunctions";

function* fetchComprehensiveNotes() {
  let data;
  const id = localStorage.getItem("user_id");
  yield api
    .get(`/notes/${id}`)
    .then((response) =>
      response.status > 399 ? showErrorPage(response.status) : response.data
    )
    .then((json) => {
      data = json;
    });
  return data;
}

export default fetchComprehensiveNotes;
