/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactHTMLParser from "react-html-parser";
import Button from "../Button/Button";
import QuestionInstruction from "../QuestionInstruction/QuestionInstruction";

const useStyles = makeStyles((theme) => ({
  titleInstruction: {
    fontFamily: '"GothamBold"',
    textAlign: "center",
    marginTop: "1.5rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.up("md")]: {
      marginBottom: "2.5rem",
    },
  },
  text: {
    fontWeight: 100,
    marginTop: 12,
    fontSize: 12,
  },
  listContainer: {
    paddingLeft: 20,
  },
  examInstructions: {
    display: "flex",
    justifyContent: "center",
  },
  boldText: {
    textDecoration: "underline",
    fontFamily: '"GothamBold"',
    marginBottom: 5,
  },
  bottomMessageContainer: {
    bottom: 0,
  },
  instructions: {
    width: "70%",
  },
  warningMessage: {
    color: "#fc0d1b",
    fontSize: 12,
  },
  [theme.breakpoints.down("sm")]: {
    text: {
      fontSize: 8,
    },
    warningMessage: {
      color: "#fc0d1b",
      fontSize: 8,
    },
    boldText: {
      fontSize: 10,
    },
    titleInstruction: {
      fontSize: 10,
    },
  },
}));

const PaperSpecific = ({
  paperTitle,
  sectionsData,
  instructions,
  onPrevious,
  onSubmit,
}) => {
  const classes = useStyles();
  const [accepted, setAccepted] = useState(false);
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);
  return (
    <div className={classes.examInstructions}>
      <div className={classes.instructions}>
        <p className={classes.titleInstruction}>
          {`${paperTitle} : INSTRUCTIONS TO CANDIDATES`}
        </p>
        {instructions && (
          <div className={classes.text}>{ReactHTMLParser(instructions)}</div>
        )}
        <p className={classes.boldText}>
          Type of Questions and Marking Schemes
        </p>
        {Array.isArray(sectionsData) &&
          sectionsData.map((elem, index) => (
            <QuestionInstruction
              title={`Section ${index + 1}`}
              data={elem.instructions}
              type="instructions"
            />
          ))}
        <p className={classes.boldText}>Answering Questions</p>
        <ul className={`${classes.text} ${classes.listContainer}`}>
          <li>
            To select the option(s), use the mouse to click on the corresponding
            button(s) of the option(s).
          </li>
          <li>
            To deselect the chosen option for the questions of SECTION 1, click
            on the button of the chosen option again or click on the Clear
            Response button to clear the chosen option.
          </li>
          <li>
            To deselect the chosen option(s) for the questions of SECTION 2,
            click on the button(s) of the chosen option(s) again or click on
            the Clear Response button to clear all the chosen options.
          </li>
          <li>
            To answer questions of SECTION 3, use the mouse to click on numbers
            (and/or symbols) on the on-screen virtual numeric keypad to enter
            the numerical value in the space provided for answer.
          </li>
          <li>
            To change the answer of the questions of SECTION 3, first click on
            the Clear Response button to clear the entered answer and then enter
            the new numerical value.
          </li>
          <li>
            To mark a question ONLY for review (i.e. without answering it),
            click on the Mark for Review & Next button.
          </li>
          <li>
            To mark a question for review (after answering it), click on Mark
            for Review & Next button – the answered question which is also
            marked for review will be evaluated.
          </li>
          <li>
            To save the answer, click on the Save & Next button - the answered
            question will be evaluated.
          </li>
        </ul>
        <hr />
        <div className={classes.bottomMessageContainer}>
          <p className={classes.warningMessage}>
            Please note all questions will appear in your default language. This
            language can be changed for a particular question later on.
          </p>
          <p className={classes.text} onClick={() => setAccepted(true)}>
            <span>
              <input
                type="checkbox"
                checked={accepted}
                onChange={(data) => setAccepted(data.target.checked)}
              />
            </span>
            I have read and understood the instructions. All computer hardware
            allotted to me are in proper working condition. I declare that I am
            not in possession of / not wearing / not carrying any prohibited
            gadget like mobile phone, bluetooth devices etc. /any prohibited
            material with me into the Examination Hall.I agree that in case of
            not adhering to the instructions, I shall be liable to be debarred
            from this Test and/or to disciplinary action, which may include ban
            from future Tests / Examinations
          </p>
          <hr />
          <div>
            <Button type="white" onClick={() => onPrevious()}>
              Previous
            </Button>
            <Button type="blue" disabled={!accepted} onClick={() => onSubmit()}>
              I am ready to begin
            </Button>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default PaperSpecific;
