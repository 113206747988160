export const FETCH_INSTRUCTIONS = "FETCH_INSTRUCTIONS";
export const FETCH_INSTRUCTIONS_START = "FETCH_INSTRUCTIONS_START";
export const FETCH_INSTRUCTIONS_SUCCESS = "FETCH_INSTRUCTIONS_SUCCESS";
export const FETCH_INSTRUCTIONS_FAILURE = "FETCH_INSTRUCTIONS_FAILURE";

export const FETCH_ADVANCED_INSTRUCTIONS = "FETCH_ADVANCED_INSTRUCTIONS";
export const FETCH_ADVANCED_INSTRUCTIONS_START =
  "FETCH_ADVANCED_INSTRUCTIONS_START";
export const FETCH_ADVANCED_INSTRUCTIONS_SUCCESS =
  "FETCH_ADVANCED_INSTRUCTIONS_SUCCESS";
export const FETCH_ADVANCED_INSTRUCTIONS_FAILURE =
  "FETCH_ADVANCED_INSTRUCTIONS_FAILURE";
