import { showErrorPage } from "../components/Utils/CommonFunctions";
import api from "./api";

export const getNotificationPreferences = async () => {
  api.setHeader("Authorization", localStorage.getItem("user_token"));
  const response = await api.get("/notification/preferences");
  if (response.status > 399) {
    return showErrorPage(response.status);
  }
  return response;
};

export const updateNotificationPreferences = async (data) => {
  api.setHeader("Authorization", localStorage.getItem("user_token"));
  const response = await api.put("notification/preferences", data);
  if (response.status > 399) {
    return showErrorPage(response.status);
  }
  return response;
};
