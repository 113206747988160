/* eslint-disable no-case-declarations */
import {
  LOAD_ASSIGNMENT_FAILURE,
  LOAD_ASSIGNMENT_START,
  LOAD_ASSIGNMENT_SUCCESS,
  REMAINING_TIME_START,
  REMAINING_TIME_SUCCESS,
  REMAINING_TIME_FAILURE,
  UPDATE_QUESTIONS,
  REDIRECT_ON_FINISH,
  UPDATE_REMAINING_TIME,
  LOAD_ASSIGNMENT_ADDITIONAL_QUESTION_UNLOCK_START,
  UPDATE_ADVANCED_LEGEND_SUCCESS,
} from "./TestTaking.types";

const initialState = {
  loading: false,
  subjects: [],
  data: [],
  redirect: false,
};
const TestTakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ASSIGNMENT_START:
      return { ...state, loading: true };
    case LOAD_ASSIGNMENT_ADDITIONAL_QUESTION_UNLOCK_START:
      return { ...initialState, loading: true };
    case LOAD_ASSIGNMENT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { isAdvanced, legendData } = action.meta;
      return {
        ...state,
        loading: true,
        questions: action.payload.questions,
        subjects: action.payload.subjects,
        extraSectionsEnabled: action.extraSectionsEnabled,
        legends: isAdvanced ? legendData : null,
      };
    case LOAD_ASSIGNMENT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case REMAINING_TIME_START:
      return { ...state, loading: true };
    case REMAINING_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        timeRemaining: action.payload,
      };
    case REMAINING_TIME_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_QUESTIONS:
      return { ...state, questions: action.payload };
    case REDIRECT_ON_FINISH:
      return { ...state, redirect: true };
    case UPDATE_REMAINING_TIME:
      return { ...state, timeRemaining: action.payload };
    case UPDATE_ADVANCED_LEGEND_SUCCESS:
      return { ...state, legends: action.payload };
    default:
      return state;
  }
};

export default TestTakingReducer;
