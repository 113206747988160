import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    height: "100%",
    backgroundColor: "#ffffff",
  },
  questionContainer: {
    height: 400,
    overflow: "scroll",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: 350,
    },
  },
  rowReverse: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  boxContainer: {
    marginTop: 20,
    display: "flex",
    flexDirection: "row-reverse",
  },
  navButtonsContainer: {
    padding: 10,
    backgroundColor: "#f5f5f5",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    border: "1px #dddddd solid",
    marginBottom: 10,
  },
  btnGroup: { display: "inherit" },
  centeredCss: {
    display: "flex",
    justifyContent: "center",
  },
  [theme.breakpoints.down("xs")]: {
    boxContainer: {
      flexDirection: "row",
      justifyContent: "center",
    },
    btnGroup: {
      display: "flex",
    },
  },
  marginButton: {
    marginTop: 8,
  },
  showHide: {
    alignSelf: "center",
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ruler: {
    border: "none",
    height: 1,
    backgroundColor: "#e3e3e3",
  },
}));

export default useStyles;
