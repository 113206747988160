import { takeLatest, put } from "redux-saga/effects";
import {
  SUBMIT_TEST_USER_REQUEST,
  SUBMIT_TEST,
} from "../redux/PostTest/PostTest.types";
import { REDIRECT_ON_FINISH } from "../redux/TestTaking/TestTaking.types";
import submitTest from "../services/SubmitTest.api";

function* ApiTestSubmission(action) {
  const payload = yield submitTest(action.payload);
  sessionStorage.setItem("test_submitted", "true");
  yield put({ type: REDIRECT_ON_FINISH });
  yield put({ type: SUBMIT_TEST, payload });
}

function* TestSubmissionSaga() {
  yield takeLatest(SUBMIT_TEST_USER_REQUEST, ApiTestSubmission);
}

export default TestSubmissionSaga;
