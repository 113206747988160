/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Loader from "../UtilComponents/Loader/Loader";
import { FETCH_MOCK_TEST_DETAIL } from "../../redux/MockTest/MockTest.types";
import Header from "../UtilComponents/Header/Header";
import "../../assets/stylesheets/MockDetail.css";
import WelcomeSection from "./WelcomeSection";
import FaqSection from "./FaqSection";
import VideoSection from "./VideoSection";
import ResultSection from "./ResultSection";
import SyllabusSection from "./SyllabusSection";
import { showErrorPage } from "../Utils/CommonFunctions";
import { LANGUAGE } from "../../AppConfig";
import SideMenu from "../UtilComponents/SideMenu/SideMenu";

const useStyles = makeStyles((theme) => ({
  vThumbnail: {
    width: "30px",
    height: "30px",
  },
  sections: {
    marginTop: "5%",
    paddingRight: "5%",
    paddingLeft: "10%",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
    },
  },
  detailContainer: {
    width: "100%",
  },
  fullList: {
    width: "auto",
  },
  drawerTxt: {
    textAlign: "center",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
}));

export default function MockTestDetail() {
  document.title = "Byju's | Mock Test Detail";
  const dispatch = useDispatch();
  const presentState = useSelector((state) => state.mockTest);
  const userToken = localStorage.getItem("user_token");

  useEffect(() => {
    if (userToken === null) {
      showErrorPage(401);
    } else {
      dispatch({ type: FETCH_MOCK_TEST_DETAIL });
    }
  }, [dispatch]);

  const classes = useStyles();

  const [activeNav, setActiveNav] = React.useState("1");
  const [drawerState, setdrawerState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setdrawerState({ ...drawerState, [anchor]: open });
  };

  const executeScroll = (sectionId) => {
    let section = "";
    switch (sectionId) {
      case "1":
        section = "welcome-section";
        break;
      case "2":
        section = "syllabus-section";
        break;
      case "3":
        section = "faq-section";
        break;
      case "4":
        section = "video-section";
        break;
      case "5":
        section = "results-section";
        break;
      default:
    }
    const element = document.getElementById(section);
    const offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const executeScrollAndToggleDrawer = (sectionId) => {
    executeScroll(sectionId);
    setdrawerState({ ...drawerState, bottom: false });
  };

  const list = (anchor) => (
    <div
      className={classes.fullList}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          "Welcome to BYJU's Mock Test",
          "Syllabus",
          "FAQs",
          "BYJU's Videos",
          "Result",
        ].map((text, index) => (
          <ListItem
            button
            key={text}
            onClick={() => executeScrollAndToggleDrawer(`${index + 1}`)}
          >
            <ListItemText primary={text} className={classes.drawerTxt} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  useEffect(() => {
    if (
      Object.keys(presentState).length !== 0 &&
      presentState.mock_test.result_enabled === true
    ) {
      setTimeout(() => executeScroll("5"), 100);
    }
  }, [presentState]);

  return (
    <Grid>
      {Object.keys(presentState).length === 0 && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
      {Object.keys(presentState).length !== 0 && (
        <>
          <Header disableLink noNotes examType="mock" />
          <Grid container>
            <SideMenu
              activeNav={activeNav}
              executeScroll={executeScroll}
              toggleDrawer={toggleDrawer}
              language={LANGUAGE.ENGLISH}
            />
            <Grid item xs={12} md={9}>
              <div className={classes.sections}>
                <section id="welcome-section" className="section">
                  <WelcomeSection
                    examType={presentState.mock_test.exam_type}
                    setActiveNav={setActiveNav}
                  />
                </section>
                <section id="syllabus-section" className="section">
                  <SyllabusSection
                    examType={presentState.mock_test.exam_type}
                    setActiveNav={setActiveNav}
                  />
                </section>
                <section id="faq-section" className="section">
                  <FaqSection isMockTest setActiveNav={setActiveNav} />
                </section>
                <section id="video-section" className="section">
                  <VideoSection
                    isMockTest
                    examType={presentState.mock_test.exam_type}
                    language={LANGUAGE.ENGLISH}
                    setActiveNav={setActiveNav}
                  />
                </section>
                <section id="results-section" className="section">
                  <ResultSection
                    isMockTest
                    language={LANGUAGE.ENGLISH}
                    setActiveNav={setActiveNav}
                  />
                </section>
              </div>
            </Grid>
          </Grid>
          <Drawer
            anchor="bottom"
            open={drawerState.bottom}
            onClose={toggleDrawer("bottom", false)}
          >
            {list("bottom")}
          </Drawer>
        </>
      )}
    </Grid>
  );
}
