export const showNoSelectionAlert = (setPopup, setPopupMessage) => {
  setPopup(true);
  setPopupMessage("Please provide answer before proceeding.");
};

export const closePopup = (setPopup) => {
  setPopup(false);
};

export const startTimer = (timer, setTimer, timerStartEpoch) => {
  const startedAt = timerStartEpoch === 0 ? Date.now() : timerStartEpoch * 1000;
  if (!sessionStorage.getItem("timer_start_epoch")) {
    sessionStorage.setItem("timer_start_epoch", startedAt);
  }

  const timerInterval = setInterval(() => {
    setTimer({ ...timer, timerTime: Date.now() - startedAt });
  }, 1000);

  return timerInterval;
};

export const timeFormatter = (time) => {
  const seconds = `0${Math.floor(time / 1000) % 60}`.slice(-2);
  const minutes = `0${Math.floor(time / 60000) % 60}`.slice(-2);
  const hours = `0${Math.floor(time / 3600000)}`.slice(-2);
  return `${hours}:${minutes}:${seconds}`;
};

export const countdown = (setTotalQuizTimeRemaining) => {
  const countdownInterval = setInterval(() => {
    setTotalQuizTimeRemaining((sec) => sec - 1);
  }, 1000);

  return countdownInterval;
};
