/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../../../assets/stylesheets/Header.css";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { EXAM_TYPES, ICONS } from "../../../AppConfig";
import {
  OLAPEventIds,
  OLAPKingdoms,
  olapPayloadGenerator,
} from "../../Utils/OlapHelpers";
import { postOLAP } from "../../../services/OLAP.api";
import NotificationDropdown from "../../NotificationDropdown/NotificationDropdown";
import {
  useNotificationState,
  useModalState,
} from "../../Utils/useNotification";
import * as NotificationTypes from "../../../redux/NotificationCenter/NotificationCenter.types";
import NotificationModal from "../../Modal/NotificationModal";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

const useStyles = makeStyles((theme) => ({
  logoMobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  logoDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  dropdownContainer: {
    position: "absolute",
  },
  text: {
    fontSize: "37px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "0.8em",
    color: "#333333",
    letterSpacing: "normal",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "21px",
      marginLeft: ".5rem",
      lineHeight: "1em",
    },
  },
  noteIcon: {
    height: "60px",
    width: "60px",
    [theme.breakpoints.down("md")]: {
      height: "36px",
      width: "36px",
    },
  },
  notificationCenter: {
    display: "inline-block",
    cursor: "pointer",
  },
  disableLink: {
    pointerEvents: "none",
  },
}));
function Header({
  comprehensive,
  disableLink,
  examType,
  noNotes,
  quiz,
  showNotificationCenter,
  language,
  languageChange,
  noLanguageSelector,
  bnatGrade,
}) {
  const [showNotification, setNotification] = useState(false);
  const [anchorEl, setAnchorElement] = useState(false);
  const notificationPreferences = useNotificationState(showNotificationCenter);
  const [modalOpen, setModalOpen] = useModalState(notificationPreferences);
  const dispatch = useDispatch();

  const classes = useStyles();
  let examName = "AITS";

  if (examType) {
    if (quiz) {
      if (examType.match(/main/i)) {
        examName = "Daily Quiz - JEE";
      } else if (examType.match(/neet/i)) {
        examName = "Daily Quiz - NEET";
      }
    } else if (examType.match(/main/i)) {
      examName = "JEE MAIN";
    } else if (examType.match(/neet/i)) {
      examName = "NEET";
    } else if (examType.match(/mock/i)) {
      examName = "Mock Test";
    } else if (examType.match(/bnat/i)) {
      examName = "BNAT";
    } else if (examType.match(/bitsat/i)) {
      examName = "BITSAT";
    } else if (examType.match(/bnst/i)) {
      examName = "BNST";
    } else if (examType.match(/advanced/i)) {
      examName = "JEE (Advanced)";
    }
  }

  const callOlapApi = (uEventId, description, counter, kingdom) => {
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      uEventId,
      "click",
      description,
      kingdom,
      counter,
      null,
      null,
      null,
      null,
      null,
      null,
      examType,
      null,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  };

  const callNotesOlapApi = (uEventId, description, counter, kingdom) => {
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      uEventId,
      "click",
      description,
      kingdom,
      counter,
      null,
      null,
      null,
      null,
      null,
      null,
      examType,
      null,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  };

  const updateNotificationPreferences = (data) => {
    const updatedPrefereneces = notificationPreferences.preferences.map(
      (item) => {
        if (item.label === data.label) {
          return data;
        }
        return item;
      }
    );
    dispatch({
      type: NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES,
      payload: updatedPrefereneces,
    });
    if (data.isEnabled) {
      callOlapApi(
        OLAPEventIds.TOGGLEON_WHATSAPP_NOTIICATION,
        "toggle on whatsapp notification",
        "toggleon_whatsapp_notification",
        OLAPKingdoms.TOGGLEON_WHATSAPP_NOTIICATION
      );
    } else {
      callOlapApi(
        OLAPEventIds.TOGGLEOFF_WHATSAPP_NOTIFICATION,
        "toggle off whatsapp notification",
        "toggleoff_whatsapp_notification",
        OLAPKingdoms.TOGGLEOFF_WHATSAPP_NOTIFICATION
      );
    }
  };

  const handleNotificationPopup = (e) => {
    setAnchorElement(e.currentTarget);
    setNotification((val) => !val);
    callOlapApi(
      OLAPEventIds.CLICK_BELLICON,
      "click notification bellicon",
      "click_bellicon",
      OLAPKingdoms.CLICK_BELLICON
    );
  };

  const handleModalCloseAction = (action) => {
    if (action === "close") {
      dispatch({
        type: NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES,
        payload: null,
      });
      setModalOpen(false);
    } else {
      setModalOpen(false);
    }
  };

  const handleClickNoteOlap = () => {
    callNotesOlapApi(
      OLAPEventIds.CLICK_NOTES,
      "click on comprehensive note",
      "click_comprehensive_note",
      OLAPKingdoms.CLICK_COMPREHENSIVE_NOTES,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      "click"
    );
  };
  const handleModalNotification = (data) => {
    if (notificationPreferences) {
      const { preferences } = notificationPreferences;
      if (data === "no") {
        sessionStorage.setItem("whatsapp_notification_popup", false);
        callOlapApi(
          OLAPEventIds.REJECT_WHATSAPP_CONSENT,
          "click no whatsapp consent",
          "reject_whatsapp_consent",
          OLAPKingdoms.REJECT_WHATSAPP_CONSENT
        );
      } else if (data === "yes") {
        callOlapApi(
          OLAPEventIds.ACCEPT_WHATSAPP_CONSENT,
          "click yes whatsapp consent",
          "accept_whatsapp_consent",
          OLAPKingdoms.ACCEPT_WHATSAPP_CONSENT
        );
      }
      const modifiedPreferences = preferences.map((item) => {
        if (item.label === "WHATSAPP") {
          return { ...item, isEnabled: data === "yes" };
        }
        return item;
      });
      dispatch({
        type: NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES,
        payload: modifiedPreferences,
      });
    }
  };

  const notificationCenterEnable = notificationPreferences.length !== 0;

  return (
    <header className="header-container">
      {notificationCenterEnable && (
        <NotificationModal
          handleAction={handleModalNotification}
          open={modalOpen}
          handleModalClose={handleModalCloseAction}
        />
      )}
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container direction="row" alignItems="flex-start">
              <Grid
                item
                className={`${disableLink ? classes.disableLink : "logo"}`}
              >
                <Link
                  to="/"
                  className={`${disableLink ? classes.disableLink : ""}`}
                >
                  <img
                    className={classes.logoMobile}
                    src={ICONS.MOBILE_LOGO_2X}
                    alt="ByjusLogo"
                    height="26px"
                    width="26px"
                  />
                  <img
                    className={classes.logoDesktop}
                    src={ICONS.DESKTOP_LOGO_2X}
                    alt="ByjusLogo"
                    height="48px"
                    width="176px"
                  />
                </Link>
              </Grid>

              <span className={classes.text}>
                {bnatGrade === "15" &&
                examName === EXAM_TYPES.BNAT.toUpperCase()
                  ? EXAM_TYPES.BNST.toUpperCase()
                  : examName}
              </span>
            </Grid>
          </Grid>
          {examName === EXAM_TYPES.BNST.toUpperCase() &&
            !noLanguageSelector && (
              <LanguageSelector
                language={language}
                setLanguage={languageChange}
              />
            )}

          {!noNotes && (
            <Grid item>
              {comprehensive && (
                <img
                  src={ICONS.NOTES_HIGHLIGHTED}
                  alt="Highlighted Notes"
                  className={classes.noteIcon}
                  onClick={handleClickNoteOlap}
                />
              )}
              {!comprehensive && (
                <Link
                  to="/notes"
                  className={`${disableLink ? classes.disableLink : ""}`}
                  onClick={handleClickNoteOlap}
                >
                  <img
                    src={ICONS.NOTES}
                    alt="Notes"
                    className={classes.noteIcon}
                  />
                </Link>
              )}
              {notificationCenterEnable && (
                <div className={classes.notificationCenter}>
                  <img
                    src={ICONS.NOTIFICATION}
                    alt="Notes"
                    onClick={handleNotificationPopup}
                    className={classes.noteIcon}
                  />
                  <Popover
                    style={{ borderRadius: 20 }}
                    open={showNotification}
                    onClose={() => setNotification(false)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <NotificationDropdown
                      data={notificationPreferences.preferences}
                      onChangeValue={updateNotificationPreferences}
                    />
                  </Popover>
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </header>
  );
}

export default Header;
