import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Box from "../Box/Box";
import { COLORS } from "../Utils/Constants";

const displayType = (type, inLine) => {
  if (inLine) {
    return "flex";
  }
  if (type === "questionActivity") {
    return "block";
  }
  return "grid";
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: ({ type, inLine }) => displayType(type, inLine),
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(3, 60px)",
      gridColumnGap: "0px",
      gridRowGap: "0px",
      fontFamily: "gotham",
      fontWeight: "regular",
      color: COLORS.DARK_GREY,
      padding: ({ type }) => (type === "questionActivity" ? 0 : 10),
      borderWidth: ({ type, iconType }) =>
        type === "questionActivity" || iconType === "advanced" ? 0 : 3,
      borderStyle: "dashed",
      borderColor: COLORS.DARK_GREY,
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    text: {
      paddingLeft: 8,
      cursor: "default",
      margin: 0,
      fontSize: 13,
    },
    legend: {
      display: "inline-block",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      legend: {
        width: "100%",
        display: ({ type, inLine }) => displayType(type, inLine),
        justifyContent: "center",
      },
      container: {
        fontSize: 12,
      },
    },
    [theme.breakpoints.down("md")]: {
      container: {
        fontSize: 12,
      },
    },
  };
});

const Legend = ({
  notVisited,
  notAnswered,
  answered,
  markedForReview,
  answeredAndMarked,
  type,
  iconType,
  header,
  inLine,
}) => {
  const classes = useStyles({ type, iconType, inLine });
  return (
    <div className={classes.legend}>
      {header && (
        <>
          <h3>{header}</h3>
          <hr />
        </>
      )}
      <div className={classes.container}>
        <div className={classes.item1}>
          <div className={classes.row}>
            <Box
              iconType={iconType}
              inLine
              status="notvisited"
              questionNumber={notVisited}
            />
            {!inLine && <p className={classes.text}>Not Visited</p>}
          </div>
        </div>
        <div className={classes.item2}>
          <div className={classes.item1}>
            <div className={classes.row}>
              <Box
                iconType={iconType}
                inLine
                status="notanswered"
                questionNumber={notAnswered}
              />
              {!inLine && <p className={classes.text}>Not Answered</p>}
            </div>
          </div>
        </div>
        <div className={classes.item3}>
          <div className={classes.item1}>
            <div className={classes.row}>
              <Box
                iconType={iconType}
                inLine
                status="answered"
                questionNumber={answered}
              />
              {!inLine && <p className={classes.text}>Answered</p>}
            </div>
          </div>
        </div>
        <div className={classes.item4}>
          <div className={classes.item1}>
            <div className={classes.row}>
              <Box
                iconType={iconType}
                inLine
                status="markedforreview"
                questionNumber={markedForReview}
              />
              {!inLine && <p className={classes.text}>Marked For Review</p>}
            </div>
          </div>
        </div>
        <div className={classes.item5}>
          <div className={classes.item1}>
            <div className={classes.row}>
              <Box
                iconType={iconType}
                inLine
                status="answeredandmarked"
                questionNumber={answeredAndMarked}
              />
              {!inLine && (
                <p className={classes.text}>Answered and Marked for Review</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Legend.propTypes = {
  notVisited: PropTypes.number.isRequired,
  notAnswered: PropTypes.number.isRequired,
  answered: PropTypes.number.isRequired,
  markedForReview: PropTypes.number.isRequired,
  answeredAndMarked: PropTypes.number.isRequired,
  header: PropTypes.string,
  iconType: PropTypes.string,
  type: PropTypes.string,
};

Legend.defaultProps = {
  header: "",
  iconType: "mains",
  type: "",
};

Legend.propTypes = {};

export default Legend;
