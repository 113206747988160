/* eslint-disable camelcase */
import { takeLatest, put } from "redux-saga/effects";
import {
  formatNotification,
  formatNotificationReverse,
} from "../components/Utils/useNotification";

import * as NotificationTypes from "../redux/NotificationCenter/NotificationCenter.types";
import * as NotificationAPI from "../services/NotificationCenter.api";

function* getNotificationPreferences() {
  try {
    const response = yield NotificationAPI.getNotificationPreferences();
    if (response.status === 200) {
      const formatted = formatNotification(response.data);
      const { whatsapp_notification_popup } = formatted;
      const notificationConsent = JSON.parse(
        sessionStorage.getItem("whatsapp_notification_popup")
      );
      if (whatsapp_notification_popup && notificationConsent === null) {
        sessionStorage.setItem("whatsapp_notification_popup", true);
      }
      yield put({
        type: NotificationTypes.GET_NOTIFICATION_PREFERENCES_SUCCESS,
        payload: formatted,
      });
    }
  } catch (err) {
    yield put({
      type: NotificationTypes.GET_NOTIFICATION_PREFERENCES_FAILURE,
      payload: err,
    });
  }
}
function* updateNotificationPreferences(data) {
  try {
    const { payload } = data;
    yield NotificationAPI.updateNotificationPreferences({
      preferences: formatNotificationReverse(payload),
    });
    if (payload !== null) {
      yield put({
        type: NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
        payload,
      });
    }
  } catch (err) {
    yield put({
      type: NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES_FAILURE,
      payload: err,
    });
  }
}

function* NotificationSaga() {
  yield takeLatest(
    NotificationTypes.GET_NOTIFICATION_PREFERENCES,
    getNotificationPreferences
  );
  yield takeLatest(
    NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES,
    updateNotificationPreferences
  );
}

export default NotificationSaga;
