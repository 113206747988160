import {
  PRODUCTION_DATA,
  STAGING_DATA,
  DEVELOPMENT_DATA,
  WEB_SOCKET,
} from "../../AppConfig";

let webSocketServer;

if (process.env.REACT_APP_BUILD_ENV === "staging") {
  webSocketServer = STAGING_DATA.WEB_SOCKET_SERVER;
} else if (process.env.REACT_APP_BUILD_ENV === "production") {
  webSocketServer = PRODUCTION_DATA.WEB_SOCKET_SERVER;
} else {
  webSocketServer = DEVELOPMENT_DATA.WEB_SOCKET_SERVER;
}

export const startTimer = (setTimer, setTimerInterval) => {
  const interval = setInterval(() => {
    setTimer((sec) => sec - 1);
  }, 1000);
  setTimerInterval(interval);
  return interval;
};

export const establishSocketConnection = (
  data,
  setNewSession,
  setHeartbeatTimer,
  setWs,
  setHeartbeatInterval,
  setSocketTimeoutTimer,
  setSocketTimeoutInterval
) => {
  const userToken = localStorage.getItem("user_token");
  const socketServer = `${webSocketServer}?token=${userToken}`;
  const ws = new WebSocket(socketServer);
  setWs(ws);

  ws.addEventListener("open", () => {
    ws.send(data);
    startTimer(setHeartbeatTimer, setHeartbeatInterval);
    startTimer(setSocketTimeoutTimer, setSocketTimeoutInterval);
  });

  ws.addEventListener("message", (event) => {
    const message = JSON.parse(event.data);
    if (message.message === WEB_SOCKET.NEW_SESSION_MESSAGE) {
      setNewSession(true);
    }
  });
};

export const redirectToSessionClosePage = (ws) => {
  ws.close();
  window.location.replace(window.origin);
};

export const submitTestWebSocketAction = (ws) => {
  const assignmentId = sessionStorage.getItem("test_assignment_id");
  ws.send(
    JSON.stringify({
      action: WEB_SOCKET.SUBMIT_ACTION_STRING,
      assignment_id: assignmentId,
    })
  );
};
