import { takeLatest, put } from "redux-saga/effects";
import { extractInstructions } from "../components/Utils/JeeAdvancedTestTaking";
import * as InstructionTypes from "../redux/Instructions/Instructions.types";
import {
  getInstructions,
  getAdvancedInstructions,
} from "../services/Instructions.api";

function* fetchInstructions(data) {
  try {
    const assessmentId = data.payload;
    yield put({ type: InstructionTypes.FETCH_INSTRUCTIONS_START });
    const instructions = yield getInstructions({ assessmentId });
    yield put({
      type: InstructionTypes.FETCH_INSTRUCTIONS_SUCCESS,
      payload: instructions,
    });
  } catch (err) {
    yield put({
      type: InstructionTypes.FETCH_INSTRUCTIONS_FAILURE,
      payload: err.message,
    });
  }
}

function* fetchAdvancedInstructions(data) {
  try {
    const assessmentId = data.payload;
    yield put({ type: InstructionTypes.FETCH_ADVANCED_INSTRUCTIONS_START });
    const instructions = yield getAdvancedInstructions({ assessmentId });
    yield put({
      type: InstructionTypes.FETCH_ADVANCED_INSTRUCTIONS_SUCCESS,
      payload: extractInstructions(instructions),
    });
  } catch (err) {
    yield put({
      type: InstructionTypes.FETCH_ADVANCED_INSTRUCTIONS_FAILURE,
      payload: err.message,
    });
  }
}

function* InstructionsSaga() {
  yield takeLatest(InstructionTypes.FETCH_INSTRUCTIONS, fetchInstructions);
  yield takeLatest(
    InstructionTypes.FETCH_ADVANCED_INSTRUCTIONS,
    fetchAdvancedInstructions
  );
}

export default InstructionsSaga;
