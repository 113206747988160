/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatBoxContainerQuestions } from "./TestTaking";
import { GENERATE_ASSESSMENT } from "../../redux/GenerateAssessment/GenerateAssessment.types";
import { LOAD_ASSIGNMENT } from "../../redux/TestTaking/TestTaking.types";
import { SUBMIT_TEST_USER_REQUEST } from "../../redux/PostTest/PostTest.types";

export function useGenerateAssignment(dispatch) {
  useEffect(() => {
    dispatch({
      type: GENERATE_ASSESSMENT,
      payload: sessionStorage.getItem("test_client_id"),
      meta: "JeeAdvanced",
    });
  }, [dispatch]);
}

export function useOrientationChange(elementId) {
  useEffect(() => {
    const element = document.getElementById(elementId);
    window.addEventListener(
      "orientationchange",
      () => {
        if (window.orientation === 90 || window.orientation === -90) {
          element.style.position = "relative";
        } else {
          element.style.position = "fixed";
        }
      },
      false
    );
  }, [elementId]);
}

export function useChangeCurrentSubject(
  currentSubject,
  subjects,
  instructionSectionIds,
  callback
) {
  useEffect(() => {
    if (currentSubject) {
      const subjectIdx = subjects.findIndex(
        (subject) => subject.id === currentSubject.id
      );
      if (subjectIdx >= 0) {
        callback(instructionSectionIds[subjectIdx]);
      }
    }
    /* eslint-disable-next-line */
  }, [currentSubject]);
}

export function useAssignmentLoader(
  loader,
  testSubmitted,
  setCurrentPage,
  setFinalScreen
) {
  useEffect(() => {
    if (loader === undefined || loader === true) {
      setCurrentPage("");
    } else if (loader === "redirect" || testSubmitted === "true") {
      setCurrentPage("examSummary");
      setFinalScreen(true);
    } else {
      setCurrentPage("instructions1");
    }
    /* eslint-disable-next-line */
  }, [loader, testSubmitted]);
}

export function useQuestionTimeSpent(currentPage, handleQuestionTimer) {
  useEffect(() => {
    if (currentPage === "testTaking") {
      handleQuestionTimer();
    }
  }, [currentPage]);
}

export const clearQuestionTimeSpent = (setTimeSpent) => {
  setTimeSpent(0);
  sessionStorage.setItem("time", 0);
};

export const loadAssignment = (dispatch, assignmentid) => {
  dispatch({
    type: LOAD_ASSIGNMENT,
    payload: assignmentid,
    meta: { isAdvanced: true },
  });
};

export const submitAssignment = (dispatch, assignmentId) => {
  dispatch({
    type: SUBMIT_TEST_USER_REQUEST,
    payload: assignmentId,
  });
};

export function useSetTestTimerandAutoSubmit(
  testTakingRedirect,
  autoSubmitTest,
  handleTestTimer,
  clearTimers
) {
  useEffect(() => {
    if (testTakingRedirect) {
      autoSubmitTest();
    } else {
      handleTestTimer();
    }
    return () => {
      clearTimers();
    };
    /* eslint-disable-next-line */
  }, []);
}

export const useTestTakingSelectors = () => {
  const instructions = useSelector((state) => state.instructions);
  const assignmentObj = useSelector((state) => state.assignment);
  const subjects = useSelector((state) => state.testTaking.subjects);
  const questions = useSelector((state) =>
    formatBoxContainerQuestions(state.testTaking.questions)
  );
  const testTakingRedirect = useSelector((state) => state.testTaking.redirect);
  const testTimeRemaining = useSelector(
    (state) => state.testTaking.timeRemaining
  );
  const isOnline = useSelector((state) => state.navigator.isOnline);
  return [
    instructions,
    assignmentObj,
    subjects,
    questions,
    testTakingRedirect,
    testTimeRemaining,
    isOnline,
  ];
};

export const useCurrentPage = () => {
  /* Instuctions can be one of the three types */
  /* 
  1. instruction1
  2. instruction2
  3. testTaking
   */
  const [instructions, setInstructions] = useState("");
  return [instructions, setInstructions];
};

export const useSectionQuestions = (currentSubject, questions) => {
  const [sectionQuestions, setSectionQuestions] = useState([]);
  useEffect(() => {
    const currentSubjectQuestions =
      questions &&
      questions.filter((question) => {
        return question.subject === currentSubject.title;
      });
    setSectionQuestions(currentSubjectQuestions);
  }, [currentSubject]);
  return [sectionQuestions, setSectionQuestions];
};

export const changeSubject = (id, callback, subjects) => {
  const currSubject = subjects.filter((item) => item.id === id);
  callback(currSubject[0]);
};

export const getSectionQuestion = (currentSectionQuestions, questionid) => {
  return currentSectionQuestions.filter((item) => item.id === questionid)[0];
};

export const useSectionAttempts = () => {
  const [sectionQuestions, setSectionQuestions] = useState([]);
  const addToSection = ({ sectionId, questionId }) => {
    const shouldUpdate = sectionQuestions.filter(
      (item) => item.sectionId === sectionId
    )[0];
    if (shouldUpdate) {
      const updated = sectionQuestions.map((item) => {
        if (item.sectionId === sectionId) {
          return { ...item, questionId };
        }
        return item;
      });
      setSectionQuestions(updated);
    } else {
      setSectionQuestions((e) => [...e, { sectionId, questionId }]);
    }
  };
  return [sectionQuestions, addToSection];
};

export const isLastQuestion = ({
  currentQuestion,
  currentSectionQuestions,
}) => {
  const currentQuestionIndex = currentSectionQuestions.findIndex(
    (item) => item.id === currentQuestion.id
  );
  if (currentSectionQuestions.length - 1 === currentQuestionIndex) {
    return true;
  }
  return false;
};
export const extractInstructions = (instructions) => {
  const instructionsObj = {};
  if (instructions && instructions.data) {
    instructionsObj.id = instructions.data.id;
    instructionsObj.instructions = instructions.data.instructions;
    instructionsObj.sections = instructions.data.sections;
  }
  return instructionsObj;
};

export const isLastQuestionLastSection = ({
  currentSubject,
  currentQuestion,
  subjects,
  currentSectionQuestions,
}) => {
  const isLastSubject =
    subjects.findIndex((i) => i.title === currentSubject.title) ===
    subjects.length - 1;
  const lastQuestion = isLastQuestion({
    currentQuestion,
    currentSectionQuestions,
  });
  return isLastSubject && lastQuestion;
};

/**
 *
 * @param {Array} questions
 * @returns
 */
export const calculateAdvancedLegend = (questions) => {
  if (!questions.length) {
    return [];
  }
  const obj = {};
  questions.forEach((item) => {
    if (!obj[item.subject]) {
      obj[item.subject] = [item];
    } else {
      obj[item.subject].push(item);
    }
  });
  const allLegend = questions.reduce(
    (a, c) => {
      if (c.status === "notanswered") {
        return { ...a, notanswered: a.notanswered + 1 };
      }
      if (c.status === "answered") {
        return { ...a, answered: a.answered + 1 };
      }
      if (c.status === "markedforreview") {
        return { ...a, markedforreview: a.markedforreview + 1 };
      }
      if (c.status === "answeredandmarked") {
        return { ...a, answeredandmarked: a.answeredandmarked + 1 };
      }
      if (c.status === "notvisited" || c.status === null) {
        return { ...a, notvisited: a.notvisited + 1 };
      }
      return false;
    },
    {
      notanswered: 0,
      answered: 0,
      markedforreview: 0,
      answeredandmarked: 0,
      notvisited: 0,
    }
  );
  const legendData = Object.keys(obj)
    .map((item) => {
      const subjectLevelLegends = obj[item].reduce(
        (a, c) => {
          if (c.status === "notanswered") {
            return { ...a, notanswered: a.notanswered + 1 };
          }
          if (c.status === "answered") {
            return { ...a, answered: a.answered + 1 };
          }
          if (c.status === "markedforreview") {
            return { ...a, markedforreview: a.markedforreview + 1 };
          }
          if (c.status === "answeredandmarked") {
            return { ...a, answeredandmarked: a.answeredandmarked + 1 };
          }
          if (c.status === "notvisited" || c.status === null) {
            return { ...a, notvisited: a.notvisited + 1 };
          }
          return false;
        },
        {
          title: item,
          notanswered: 0,
          answered: 0,
          markedforreview: 0,
          answeredandmarked: 0,
          notvisited: 0,
        }
      );
      return subjectLevelLegends;
    })
    .reduce((a, c) => {
      const total =
        c.answered +
        c.notanswered +
        c.markedforreview +
        c.answeredandmarked +
        c.notvisited;
      return {
        ...a,
        [c.title]: { ...c, total },
      };
    }, {});
  return { ...legendData, all: allLegend };
};

export const useSectionMeta = (subject) => {
  const [currentSectionMeta, setCurrentSectionMeta] = useState();
  const legends = useSelector(
    (state) => state.testTaking && state.testTaking.legends
  );
  useEffect(() => {
    if (!subject) {
      setCurrentSectionMeta([]);
      return;
    }
    setCurrentSectionMeta(legends[subject.title]);
  }, [subject, legends]);
  return currentSectionMeta;
};

export const useExamSummaryData = () => {
  const [currentLegends, setCurrentLegends] = useState();
  const legends = useSelector(
    (state) => state.testTaking && state.testTaking.legends
  );
  useEffect(() => {
    if (legends) {
      let completeData = [];
      // eslint-disable-next-line array-callback-return
      Object.keys(legends).map((item) => {
        completeData = [...completeData, legends[item]];
      });
      completeData = completeData.filter((item) => item.title);
      setCurrentLegends(completeData);
    }
  }, [legends]);
  return currentLegends;
};

export const calculateSectionLegends = (currentSectionQuestions) => {
  if (currentSectionQuestions) {
    return currentSectionQuestions.reduce(
      (a, c) => {
        if (c.status === "notanswered") {
          return { ...a, notanswered: a.notanswered + 1 };
        }
        if (c.status === "answered") {
          return { ...a, answered: a.answered + 1 };
        }
        if (c.status === "markedforreview") {
          return { ...a, markedforreview: a.markedforreview + 1 };
        }
        if (c.status === "answeredandmarked") {
          return { ...a, answeredandmarked: a.answeredandmarked + 1 };
        }
        if (c.status === "notvisited") {
          return { ...a, notvisited: a.notvisited + 1 };
        }
        return false;
      },
      {
        notanswered: 0,
        answered: 0,
        markedforreview: 0,
        answeredandmarked: 0,
        notvisited: 0,
      }
    );
  }
  return {
    notanswered: 0,
    answered: 0,
    markedforreview: 0,
    answeredandmarked: 0,
    notvisited: 0,
  };
};

/**
 *
 * @param {Array} legends
 */
export const calculateTotalLegend = (legends) => {
  const finalObject = Object.keys(legends)
    .map((item) => legends[item])
    .filter((item) => item.title)
    .reduce(
      (a, c) => {
        return {
          answered: a.answered + c.answered,
          answeredandmarked: a.answeredandmarked + c.answeredandmarked,
          markedforreview: a.markedforreview + c.markedforreview,
          notanswered: a.notanswered + c.notanswered,
          notvisited: a.notvisited + c.notvisited,
        };
      },
      {
        notanswered: 0,
        answered: 0,
        markedforreview: 0,
        answeredandmarked: 0,
        notvisited: 0,
      }
    );
  return finalObject;
};

export const getLegends = (state) => state.testTaking.legends;
