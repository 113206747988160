import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FETCH_USER_PROFILE } from "../../../redux/Profile/Profile.types";
import Loader from "../Loader/Loader";

function OAuth() {
  const [overallView, setOverallViewStatus] = useState(null);
  const dispatch = useDispatch();
  const assignmentId = localStorage.getItem("user_assignment_id");
  useEffect(() => {
    const redirection = () => {
      const param = new URLSearchParams(window.location.hash.split("#")[1]);
      const profileToken = param.get("access_token");
      const tokenType = param.get("token_type");
      sessionStorage.setItem("profile_token", profileToken);
      sessionStorage.setItem("token_type", tokenType);
      const destination = sessionStorage.getItem("destination");
      dispatch({ type: FETCH_USER_PROFILE });
      if (destination === "comprehensive") {
        setOverallViewStatus(true);
      } else if (destination === "test") {
        setOverallViewStatus(false);
      } else if (destination === "start_test") {
        setOverallViewStatus("start_test");
      } else if (destination === "start_quiz") {
        setOverallViewStatus("start_quiz");
      } else if (destination === "quiz_result") {
        setOverallViewStatus("quiz_result");
      }
    };

    redirection();
  }, [dispatch]);
  return (
    <div>
      {overallView === null && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
      {overallView === true && <Redirect to="/" />}
      {overallView === false && <Redirect to="/test-analysis" />}
      {overallView === "start_test" && <Redirect to="/take-test" />}
      {overallView === "start_quiz" && <Redirect to="/daily-quiz" />}
      {overallView === "quiz_result" && (
        <Redirect to={`/daily-quiz/result/${assignmentId}`} />
      )}
    </div>
  );
}

export default OAuth;
