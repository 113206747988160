import { SUBMIT_TEST } from "./PostTest.types";

const INITIAL_STATE = "";

const PostTestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBMIT_TEST:
      return action.payload;
    default:
      return state;
  }
};

export default PostTestReducer;
