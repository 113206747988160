import React, { lazy, Suspense, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import "../../assets/stylesheets/SWOT.css";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Loader from "../UtilComponents/Loader/Loader";
import Header from "../UtilComponents/Header/Header";
import { FETCH_COMPREHENSIVE_TEST } from "../../redux/ComprehensiveTest/ComprehensiveTest.types";
import { callAssessViewOlapApi } from "../Utils/OlapHelpers";
import { navigateToLearn } from "../Utils/CommonFunctions";
import ErrorPage from "../../pages/ErrorPage";
import error from "../Utils/Error";
import "../../App.css";

const NavBar = lazy(() => import("./NavBar/NavBar"));
const RankCard = lazy(() => import("../TestSpecific/OverallResult/RankCard"));
const RankChart = lazy(() => import("../SWOT/RankChart/RankChart"));
const SubjectsOverview = lazy(() =>
  import("../TestSpecific/SubjectsOverview/SubjectsOverview")
);
const SwotCard = lazy(() => import("./SwotCard/SwotCard"));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 2rem",
    [theme.breakpoints.down("md")]: {
      padding: "0 0.75rem",
    },
  },
  box: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "999",
    borderRadius: "16px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    padding: "20px",
    minWidth: "250px",
  },
  blurDisplay: {
    pointerEvents: "none",
  },
  popupText: {
    fontWeight: "900",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
  },
  blurDiv: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    "-webkit-filter": "blur(3px)",
    "-moz-filter": "blur(3px)",
    "-o-filter": "blur(3px)",
    "-ms-filter": "blur(3px)",
    filter: "blur(3px)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "99",
    backdropFilter: "contrast(.8)",
  },
}));

function SWOT() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const presentState = useSelector((state) => state.comprehensiveTest);
  const isStaticData = !!presentState.staticData;
  document.title = "Byju's | Overall Test Analysis";
  useEffect(() => {
    const userToken = localStorage.getItem("user_token");
    if (userToken === null) {
      navigateToLearn();
    } else {
      dispatch({ type: FETCH_COMPREHENSIVE_TEST });
      localStorage.setItem("NavbarId", "comprehensive");
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      Object.keys(presentState).length !== 0 &&
      presentState.aits.length !== 0 &&
      typeof presentState.swot !== "undefined"
    ) {
      callAssessViewOlapApi(null, null, 0);
    }
  }, [presentState]);

  const isOnline = useSelector((state) => state.navigator.isOnline);

  return (
    <>
      {isOnline && (
        <div className="containerSWOT">
          {Object.keys(presentState).length === 0 && <Loader />}
          {Object.keys(presentState).length !== 0 &&
            presentState.aits.length === 0 && <Loader />}
          {Object.keys(presentState).length !== 0 &&
            presentState.aits.length !== 0 && (
              <>
                <Header disableLink={isStaticData} showNotificationCenter />
                {isStaticData && (
                  <div>
                    <div className={classes.blurDiv} />
                    <div className={classes.box}>
                      <Typography className={classes.popupText}>
                        On 18th October, we overhauled the Result & Analysis
                        feature for your tests. Going forward for all new tests,
                        enhanced analytics will help you dive deep into your
                        performance and take actions to improve. Close this tab
                        and take the latest tests to enable this feature
                      </Typography>
                    </div>
                  </div>
                )}

                <Grid
                  container
                  direction="row"
                  justify="center"
                  className={`${isStaticData ? classes.blurDisplay : ""} ${
                    classes.root
                  }`}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    style={{ marginTop: "10px" }}
                  >
                    <Suspense fallback={<Loader />}>
                      <NavBar data={presentState.aits} navRole="navigation" />
                    </Suspense>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    style={{ marginBottom: "20px" }}
                  >
                    <Suspense fallback={<Loader />}>
                      <RankCard parent="overall" />
                    </Suspense>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Suspense fallback={<Loader />}>
                      <RankChart data={presentState.aits} />
                    </Suspense>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <Suspense fallback={<Loader />}>
                      <SubjectsOverview
                        parent="overall"
                        overallData={presentState.aits}
                      />
                    </Suspense>
                  </Grid>
                </Grid>
                <div
                  className="containerSWOT"
                  style={{ backgroundColor: "#e4eff8", display: "flex" }}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    className={`${isStaticData ? classes.blurDisplay : ""} ${
                      classes.root
                    }`}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      style={{ marginTop: "15px" }}
                    >
                      <Suspense fallback={<Loader />}>
                        <SwotCard data={presentState.aits} />
                      </Suspense>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
        </div>
      )}
      {!isOnline && (
        <div className="ErrorPage">
          <ErrorPage
            code="No Internet"
            title={error["No Internet"].title}
            description={error["No Internet"].description}
            ctaTitle={error["No Internet"].cta.title}
            ctaAction={error["No Internet"].cta.action}
          />
        </div>
      )}
    </>
  );
}

export default SWOT;
