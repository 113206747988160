export const secConvertor = (sec) => {
  if (sec === null) {
    return "NA";
  }
  if (sec < 60) {
    if (sec % 1 !== 0) {
      const roundedSec = Math.round(sec);
      return `${roundedSec === 60 ? "1m" : `${roundedSec}s`}`;
    }
    return `${sec}s`;
  }
  if (sec >= 60 && sec < 3600) {
    const secTime = secConvertor(sec % 60);
    if (secTime === "1m") {
      return `${Math.floor(sec / 60) + 1}m`;
    }
    return `${Math.floor(sec / 60)}m${secTime === "0s" ? "" : ` ${secTime}`}`;
  }
  const hrTime = secConvertor(sec % 3600);
  return `${Math.floor(sec / 3600)}h${hrTime === "0s" ? "" : ` ${hrTime}`}`;
};

export const greeting = () => {
  const time = new Date();
  const hr = time.getHours();
  let greet = "";
  if (hr < 12) {
    greet = "Good Morning";
  }
  if (hr >= 12 && hr < 17) {
    greet = "Good Afternoon";
  }
  if (hr >= 17) {
    greet = "Good Evening";
  }
  return greet;
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const getOrdinalNum = (n) => {
  return n > 0
    ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
    : "";
};

export const tConv24 = (time24) => {
  let ts = time24;
  const H = +ts.substr(0, 2);
  const h = H % 12 || 12;
  ts = h + ts.substr(2, 3);
  return ts;
};

export const extractDateTime = (dateTime) => {
  const fullDate = dateTime[0].split("-");
  const year = fullDate[0];
  const month = fullDate[1];
  const date = fullDate[2];
  const fullTime = dateTime[1].split(":");
  const hour = fullTime[0];
  const minute = fullTime[1];
  const dateOrdinal = getOrdinalNum(date);
  let ampm = "PM";
  let time = tConv24(hour);
  if (minute !== "00") {
    time = `${tConv24(hour)}:${minute}`;
  }
  if (hour < 12) {
    ampm = "AM";
  }
  return { year, month: months[month - 1], date, dateOrdinal, time, ampm };
};
