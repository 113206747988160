import { postOLAP } from "../../services/OLAP.api";
/**
 * @param {string} rootId Id of the root element
 * @param {string} elementId Id of the intersecting element
 * @param {string} rootMargin Margin to grow and shrink the root obseving window
 * @param {number | Array} intersection A number or an array consisting value between 0 and 1
 * to specify by how much the element should intersect the root window by
 * @param {function} callback Function to be executed when element has intersceted the root window by intersection no of pixels
 */
export const getIntersectionInfo = (
  rootId,
  elementId,
  rootMargin,
  intersection,
  callback
) => {
  let rootElement = null;
  let intersectingElement = null;
  if (rootId) {
    rootElement = document.getElementById(rootId);
  }
  if (elementId) {
    intersectingElement = document.getElementById(elementId);
  }
  const intersectionOptions = {
    root: rootElement,
    rootMargin,
    threshold: intersection,
  };
  const intersectionObserver = new IntersectionObserver(
    callback,
    intersectionOptions
  );

  // If the intersceting element is present, observe that element with respect to the root element
  if (intersectingElement) {
    intersectionObserver.observe(intersectingElement);
  }
  return intersectionObserver;
};

/**
 * @param {string} elementId Id of the element
 */
export const getScrollInfoforElement = (elementId) => {
  let currentScrollPos = { x: 0, y: 0 };
  if (elementId) {
    const scrollElement = document.getElementById(elementId);
    if (scrollElement) {
      currentScrollPos = {
        x: scrollElement.scrollLeft,
        y: scrollElement.scrollTop,
      };
    }
  }
  return currentScrollPos;
};

/**
 * @param {string} elementId Id of the scrolled element
 * @param {object} prevScrollPos An object to represent the scroll postion of element
 * @param {function} onScrollCallback Function to be executed when element is scrolled
 */
export const onScrollElement = (elementId, prevScrollPos, onScrollCallback) => {
  let scrollLeft = false;
  let scrollRight = false;
  let scrollTop = false;
  let scrollBottom = false;
  const currentScrollPos = getScrollInfoforElement(elementId);
  if (currentScrollPos.x !== prevScrollPos.x) {
    scrollRight = currentScrollPos.x > prevScrollPos.x;
    scrollLeft = !scrollRight;
  }
  if (currentScrollPos.y !== prevScrollPos.y) {
    scrollBottom = currentScrollPos.y > prevScrollPos.y;
    scrollTop = !scrollBottom;
  }
  // If current scroll positon is not equal to previous scroll position call the scroll callback
  if (scrollLeft || scrollRight || scrollTop || scrollBottom) {
    onScrollCallback(currentScrollPos, {
      scrollLeft,
      scrollRight,
      scrollTop,
      scrollBottom,
    });
  }
};

/**
 * @param {string} elementId Id of the element
 * @param {string} axis A string to define vertical or horizontal direction
 */
export const isOverflowHidden = (element, axis = "X") => {
  let overflowHidden = false;
  if (element) {
    const style = getComputedStyle(element);
    overflowHidden =
      style.overflow === "hidden" || axis === "X"
        ? style.overflowX === "hidden"
        : style.overflowY === "hidden";
  }
  return overflowHidden;
};

/**
 * @param {string} elementId Id of the element
 */
export const isElementScrollable = (elementId) => {
  let isScrollable = {
    verticallyScrollable: false,
    horizontallyScrollable: false,
  };
  if (elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const verticallyScrollable =
        element.scrollHeight > element.offsetHeight &&
        !isOverflowHidden(element, "Y");
      const horizontallyScrollable =
        element.scrollWidth > element.offsetWidth &&
        !isOverflowHidden(element, "X");
      isScrollable = { verticallyScrollable, horizontallyScrollable };
    }
  }
  return isScrollable;
};

// Function to get the current date and time
export const getCurrentDateTime = () => {
  const today = new Date();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  if (month < 10) {
    month = `0${month}`;
  } else if (day < 10) {
    day = `0${day}`;
  }
  const date = `${today.getFullYear()}-${month}-${day}`;
  let hr = today.getHours();
  let min = today.getMinutes();
  let sec = today.getSeconds();
  if (hr < 10) {
    hr = `0${hr}`;
  } else if (min < 10) {
    min = `0${min}`;
  } else if (sec < 10) {
    sec = `0${sec}`;
  }
  const time = `${hr}:${min}:${sec}`;
  return `${date} ${time}`;
};

// Function to check whether the device is desktop or mobile
export const getUserAgent = () => {
  const result = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    navigator.userAgent.toLowerCase()
  );
  return result === true ? 1 : 0;
};

export const getGradeInfo = () => {
  const grade = sessionStorage.getItem("bnat_grade") || null;
  if (grade === null || grade === "null") {
    return null;
  }
  return parseInt(grade, 10);
};

export const getCohortInfo = () => {
  const cohort = sessionStorage.getItem("bnat_cohort") || null;
  if (cohort === "null") {
    return null;
  }
  return cohort;
};

export const getMediumInfo = () => {
  const medium = sessionStorage.getItem("bnat_medium") || null;
  if (medium === "null") {
    return null;
  }
  return medium;
};

// Function to get the event id from session storage
export const getEventId = () => {
  if (sessionStorage.getItem("event_id")) {
    const value = sessionStorage.getItem("event_id");
    sessionStorage.setItem("event_id", (parseInt(value, 10) + 1).toString());
    return parseInt(value, 10) + 1;
  }
  sessionStorage.setItem("event_id", "1");
  return 1;
};

// Function to get the ref id from session storage
export const getRefId = () => {
  if (sessionStorage.getItem("ref_id")) {
    const value = sessionStorage.getItem("ref_id");
    sessionStorage.setItem("ref_id", (parseInt(value, 10) + 1).toString());
    return parseInt(value, 10) + 1;
  }
  sessionStorage.setItem("ref_id", "0");
  return 0;
};

// Function to get the u ref id from session storage
export const getURefId = (param) => {
  if (sessionStorage.getItem("u_ref_id")) {
    const value = sessionStorage.getItem("u_ref_id");
    sessionStorage.setItem("u_ref_id", param);
    return parseInt(value, 10);
  }
  sessionStorage.setItem("u_ref_id", param);
  return 51000001;
};

// Function to get the network information
export const getNetworkConnectionInfo = () => {
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  let type = "";
  if (connection) {
    type = connection.effectiveType;
  }
  return type;
};

export const getUserInfo = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userCohortId =
    Array.isArray(user.user_profiles) && user.user_profiles.length > 0
      ? { cohort: user.user_profiles[0].cohort.id }
      : {};
  const premium = user.premium_account_id;
  return { userCohortId, premium };
};

/**
 * @param {string} userId Id of user
 * @param {string} deviceId
 * @param {string} deviceInfo
 * @param {string} networkInfo Network info(Wifi or Cellular)
 * @param {string} date Timestamp
 * @param {string} sessionId Will be introduced in test taking
 * @param {string} uEventId Unique id for each event
 * @param {string} desc Description for event
 * @param {string} kingdom Specify the group to which a particular event belngs
 * @param {string} counter Small unique description sepearted by underscore, max have three words,
 * to give a understanding of what is happening
 * @param {string} tribe
 * @param {string} family Subject
 * @param {string} species Question info in JSON format
 * @param {string} genus Filter info in JSON format
 * @param {string} record Assesment id
 * @param {string} form Feedback info in JSON format
 * @param {string} locale
 * @param {string} param1 Cohort, rank, total score and percentile info in JSON,
 * @param {string} value2 0 for desktop,1 for mobile page
 * @param {string} param2 source
 * @param {string} param3 Scroll info in JSON format
 * @param {string} param4 Notes info in JSON format
 */
export const olapPayloadGenerator = (
  deviceId,
  deviceInfo,
  networkInfo,
  sessionId,
  uEventId,
  phylum,
  desc,
  kingdom,
  counter,
  tribe,
  family,
  species,
  genus,
  record,
  form,
  locale,
  variety,
  param1 = null,
  param2,
  param3,
  param4 = null,
  param5 = null
) => {
  let userId = "";
  if (localStorage.getItem("user_id")) {
    userId = localStorage.getItem("user_id");
  } else {
    userId = JSON.parse(localStorage.getItem("user")).id;
  }
  const isUserIdInteger = /^\d+$/.test(userId);
  const payload = {
    user_id: isUserIdInteger ? parseInt(userId, 10) : null,
    device_id: deviceId,
    device_info: deviceInfo,
    network_info: networkInfo,
    date: getCurrentDateTime(),
    session_id: sessionId,
    ref_id: getRefId(),
    event_id: getEventId(),
    u_event_id: uEventId,
    u_ref_id: getURefId(uEventId),
    phylum,
    m_desc: desc,
    kingdom,
    counter,
    tribe,
    family,
    species,
    genus,
    record,
    form,
    locale,
    variety,
    param1,
    value2: getUserAgent(),
    param2: getCohortInfo(),
    param3: getMediumInfo(),
    param4,
    param5,
    value1: getGradeInfo(),
  };
  return payload;
};

// Function to create the olap data for particular question
export const createOlapQuestionData = (question) => {
  // 1: Correctly answered, 0: Incorrectly answered, 2: Unattempted
  let questionAttempt = 2;
  if (typeof question.is_correct !== "undefined") {
    questionAttempt = question.is_correct ? 1 : 0;
  }
  const questionData = {
    question_id: question.question_client_id,
    question_attempt: questionAttempt,
  };
  return questionData;
};

// Function to create the olap data for note events
const createOlapNoteData = (numNotes, noteTag, numImage, numNoteChar) => {
  const notesData = {
    num_notes: numNotes,
    note_tag: noteTag,
    num_image: numImage,
    num_note_char: numNoteChar,
  };
  return notesData;
};

// Function to call the olap api for scroll events
export const callScrollOlapApi = (
  uEventId,
  description,
  kingdom,
  counter,
  species,
  record,
  variety,
  scrollInfo
) => {
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    uEventId,
    "scroll",
    description,
    kingdom,
    counter,
    null,
    null,
    species,
    null,
    record,
    null,
    null,
    variety,
    null,
    null,
    scrollInfo,
    null
  );
  postOLAP(olapPayload);
};

// Function to call the olap api for note events
export const callNotesOlapApi = (
  uEventId,
  description,
  counter,
  kingdom,
  family,
  species,
  record,
  variety,
  param3,
  numNotes,
  noteTag,
  numImage,
  numNoteChar,
  type
) => {
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    uEventId,
    type,
    description,
    kingdom,
    counter,
    null,
    family,
    species,
    null,
    record,
    null,
    null,
    variety,
    null,
    param3,
    null,
    createOlapNoteData(numNotes, noteTag, numImage, numNoteChar)
  );
  postOLAP(olapPayload);
};

export const debounceScroll = (timerId, callback, wait) => {
  let scrollTimer = timerId;
  if (scrollTimer) {
    clearTimeout(scrollTimer);
  }
  scrollTimer = setTimeout(callback, wait);
  return scrollTimer;
};

// OLAP Event Ids
export const OLAPEventIds = {
  CLICK_VIEW_RESULT: 51000001,
  VIEW_ASSESS_PLATFORM: 51000002,
  VIEW_RANK_CARD: 51000003,
  VIEW_SCORE_CARD: 51000004,
  SCROLL_SCORECARD: 51000005,
  VIEW_TIME_COMPARISON: 51000006,
  VIEW_FILTER: 51000007,
  CLICK_FILTER: 51000008,
  SCROLL_FILTER: 51000009,
  CLICK_DISCUSSION_LINK: 51000010,
  CLICK_NOTES: 51000011,
  VIEW_QUESTION: 51000012,
  CLICK_SOLUTION_DROPDOWN: 51000013,
  VIEW_SOLUTION: 51000014,
  VIEW_NOTES: 51000015,
  ADD_NEW_NOTE: 51000016,
  VIEW_NOTE_EDITOR: 51000017,
  CLEAR_NOTE: 51000019,
  CANCEL_NOTE: 51000020,
  ADD_IMAGE_NOTE: 51000021,
  SAVE_NOTE: 51000022,
  EDIT_NOTE: 51000023,
  REMOVE_NOTE: 51000024,
  REMOVE_NOTE_CONFIRM: 51000025,
  REMOVE_NOTE_CANCEL: 51000026,
  CLICK_NOTES_BACK: 51000027,
  CLICK_FEEDBACK_SUBMIT: 51000028,
  CLICK_FEEDBACK_OPTION: 51000029,
  VIEW_FEEDBACK_STATS: 51000030,
  CLICK_FEEDBACK_MOBILE: 51000031,
  VIEW_GREETING_CARD: 51000032,
  VIEW_RANKCHART: 51000033,
  SCROLL_RANKCHART: 51000034,
  VIEW_ATTEMPTED_QUESTIONS: 51000035,
  SCROLL_ATTEMPTED_QUESTIONS: 51000036,
  VIEW_SWOT: 51000037,
  SCROLL_SWOT_FILTER: 51000038,
  CLICK_SWOT_FILTER: 51000039,
  CLICK_SWOT_INFO: 51000040,
  CLICK_SWOT_DROPDOWN: 51000041,
  SCROLL_NAVIGATION: 51000042,
  CLICK_NAVIGATION_FILTER: 51000043,
  VIEW_INSTRUCTIONS: 51000047,
  CLICK_INSTRUCTION_CHECKBOX: 51000048,
  CLICK_INSTRUCTION_SUBMIT: 51000049,
  VIEW_TEST_TAKING: 51000050,
  CLICK_EXAM_SUBMIT: 51000051,
  VIEW_EXAM_SUMMARY: 51000052,
  CLICK_EXAM_SUBMIT_CANCEL: 51000053,
  CLICK_EXAM_SUBMIT_CONFIRM: 51000054,
  VIEW_EXAM_COMPLETION: 51000055,
  CLICK_START_MOCK_TEST: 51000056,
  CLICK_RESUME_MOCK_TEST: 51000057,
  CLICK_START_SAMPLE_TEST: 51000058,
  CLICK_RESUME_SAMPLE_TEST: 51000059,
  CLICK_RETAKE_SAMPLE_TEST: 51000060,
  CLICK_MOCK_TEST_RESULT: 51000061,
  VIEW_QUIZ: 51000062,
  CLICK_SUBMIT_QUIZ: 51000063,
  VIEW_QUIZ_RESULT: 51000064,
  PLAY_EMBEDED_VIDEO: 51000065,
  CLICK_VIDEO_SOLUTION: 51000066,
  CLICK_START_BNAT_TEST: 51000070,
  CLICK_RESUME_BNAT_TEST: 51000071,
  CLICK_BNAT_TEST_RESULT: 51000072,
  CLICK_BNAT_TEST_REPORT: 51000073,
  CLICK_SUBMIT_BITSAT_POPUP: 51000067,
  CLICK_START_EXTRA_PAPER_BITSAT_POPUP: 51000068,
  CLICK_CANCEL_BITSAT_POPUP: 51000069,
  ACCEPT_WHATSAPP_CONSENT: 51000074,
  REJECT_WHATSAPP_CONSENT: 51000075,
  CLICK_BELLICON: 51000076,
  TOGGLEON_WHATSAPP_NOTIICATION: 51000077,
  TOGGLEOFF_WHATSAPP_NOTIFICATION: 51000078,
};

// OLAP Kingdoms
export const OLAPKingdoms = {
  CLICK_VIEW_RESULT: "user_activation",
  VIEW_ASSESS_PLATFORM: "user_activation",
  VIEW_RANK_CARD: "aggregate_statistics",
  VIEW_SCORE_CARD: "aggregate_statistics",
  SCROLL_SCORECARD: "aggregate_statistics",
  VIEW_TIME_COMPARISON: "aggregate_statistics",
  SCROLL_FILTER: "question",
  CLICK_FILTER: "question",
  VIEW_FILTER: "question",
  CLICK_DISCUSSION_LINK: "discussion_forum",
  CLICK_NOTES: "question",
  VIEW_QUESTION: "question",
  CLICK_SOLUTION_DROPDOWN: "question",
  VIEW_SOLUTION: "question",
  CLICK_COMPREHENSIVE_NOTES: "notes",
  VIEW_NOTES: "notes",
  ADD_NEW_NOTE: "notes",
  VIEW_NOTE_EDITOR: "notes",
  CLEAR_NOTE: "notes",
  CANCEL_NOTE: "notes",
  ADD_IMAGE_NOTE: "notes",
  SAVE_NOTE: "notes",
  EDIT_NOTE: "notes",
  REMOVE_NOTE: "notes",
  REMOVE_NOTE_CONFIRM: "notes",
  REMOVE_NOTE_CANCEL: "notes",
  CLICK_NOTES_BACK: "notes",
  CLICK_FEEDBACK_SUBMIT: "feedback",
  CLICK_FEEDBACK_OPTION: "feedback",
  VIEW_FEEDBACK_STATS: "feedback",
  CLICK_FEEDBACK_MOBILE: "feedback",
  VIEW_GREETING_CARD: "aggregate_statistics",
  VIEW_RANKCHART: "aggregate_statistics",
  SCROLL_RANKCHART: "aggregate_statistics",
  VIEW_ATTEMPTED_QUESTIONS: "aggregate_statistics",
  SCROLL_ATTEMPTED_QUESTIONS: "aggregate_statistics",
  VIEW_SWOT: "swot",
  SCROLL_SWOT_FILTER: "swot",
  CLICK_SWOT_FILTER: "swot",
  CLICK_SWOT_INFO: "swot",
  CLICK_SWOT_DROPDOWN: "swot",
  SCROLL_NAVIGATION: "navigation",
  CLICK_NAVIGATION_FILTER: "navigation",
  VIEW_INSTRUCTIONS: "test_taking",
  CLICK_INSTRUCTION_CHECKBOX: "test_taking",
  CLICK_INSTRUCTION_SUBMIT: "test_taking",
  VIEW_TEST_TAKING: "test_taking",
  CLICK_EXAM_SUBMIT: "test_taking",
  VIEW_EXAM_SUMMARY: "test_taking",
  CLICK_EXAM_SUBMIT_CANCEL: "test_taking",
  CLICK_EXAM_SUBMIT_CONFIRM: "test_taking",
  VIEW_EXAM_COMPLETION: "test_taking",
  CLICK_START_MOCK_TEST: "test_taking",
  CLICK_RESUME_MOCK_TEST: "test_taking",
  CLICK_START_SAMPLE_TEST: "test_taking",
  CLICK_RESUME_SAMPLE_TEST: "test_taking",
  CLICK_RETAKE_SAMPLE_TEST: "test_taking",
  CLICK_MOCK_TEST_RESULT: "test_taking",
  VIEW_QUIZ: "quiz",
  CLICK_SUBMIT_QUIZ: "quiz",
  VIEW_QUIZ_RESULT: "quiz",
  PLAY_EMBEDED_VIDEO: "quiz",
  CLICK_VIDEO_SOLUTION: "quiz",
  CLICK_START_BNAT_TEST: "test_taking",
  CLICK_RESUME_BNAT_TEST: "test_taking",
  CLICK_BNAT_TEST_RESULT: "test_taking",
  CLICK_BNAT_TEST_REPORT: "test_taking",
  CLICK_SUBMIT_BITSAT_POPUP: "test_taking",
  CLICK_START_EXTRA_PAPER_BITSAT_POPUP: "test_taking",
  CLICK_CANCEL_BITSAT_POPUP: "test_taking",
  ACCEPT_WHATSAPP_CONSENT: "user_activation",
  REJECT_WHATSAPP_CONSENT: "user_activation",
  CLICK_BELLICON: "user_activation",
  TOGGLEON_WHATSAPP_NOTIICATION: "user_activation",
  TOGGLEOFF_WHATSAPP_NOTIFICATION: "user_activation",
};

export const callAssessViewOlapApi = (record, variety, source) => {
  const olapPayload = olapPayloadGenerator(
    null,
    null,
    null,
    null,
    OLAPEventIds.VIEW_ASSESS_PLATFORM,
    "view",
    "view assess platform",
    OLAPKingdoms.VIEW_ASSESS_PLATFORM,
    "view_analysis",
    source ? "test_analysis" : "overall_analysis",
    null,
    null,
    null,
    record,
    null,
    null,
    variety,
    null,
    source,
    null,
    null
  );
  postOLAP(olapPayload);
};

export const scrollOlapDelay = 2000;
