import { UPDATE_COMPREHENSIVE_NOTES_STATE } from "./ComprehensiveNotes.types";

const INITIAL_STATE = {};

const ComprehensiveReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COMPREHENSIVE_NOTES_STATE:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default ComprehensiveReducer;
