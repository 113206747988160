import React, { lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import Loader from "../../UtilComponents/Loader/Loader";

const Subject = lazy(() => import("./Subject/Subject"));

const useStyles = makeStyles(() => ({
  root: {
    // padding: "1em",
    paddingTop: "1em",
  },
  title: {
    padding: "5px",
  },
  grid: {
    paddingBottom: "10px",
  },
  header: {
    paddingTop: "10px",
    fontWeight: 900,
  },
}));

function SpecificSubject({ selectedTab, quiz, noNotes }) {
  const classes = useStyles();
  const presentState = useSelector((state) => state.testSpecific);
  const getStatus = () => {
    let status;
    if (selectedTab === "Correct") {
      status = true;
    } else if (selectedTab === "Incorrect") {
      status = false;
    } else {
      status = undefined;
    }
    return status;
  };

  const filteredSubject = () => {
    if (selectedTab === "All") {
      return presentState.subjects;
    }
    if (selectedTab === "All1") {
      return presentState.papers[0].subjects;
    }
    if (selectedTab === "All2") {
      return presentState.papers[1].subjects;
    }
    const status = getStatus();
    let filteredSub;
    if (presentState.papers) {
      filteredSub = presentState.subjects.filter(
        (subject) => subject.title + subject.paper_title === selectedTab
      );
    } else {
      filteredSub = presentState.subjects.filter(
        (subject) => subject.title === selectedTab
      );
    }

    if (filteredSub.length === 0) {
      filteredSub = presentState.subjects.map((subject) => {
        return {
          ...subject,
          questions: subject.questions.filter((item) => {
            return item.is_correct === status;
          }),
        };
      });
    }
    return filteredSub;
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        {filteredSubject().map((subject) => {
          return (
            <Grid container justify="center" className={classes.grid}>
              <Suspense fallback={<Loader />}>
                <Subject
                  subject={subject}
                  selectedTab={selectedTab}
                  isJeeAdv={!!presentState.papers}
                  quiz={quiz}
                  noNotes={noNotes}
                />
              </Suspense>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default SpecificSubject;
