import { combineReducers } from "redux";
import TestSpecificReducer from "./TestSpecific/TestSpecific.reducer";
import ComprehensiveReducer from "./ComprehensiveNotes/ComprehensiveNotes.reducer";
import ComprehensiveTestReducer from "./ComprehensiveTest/ComprehensiveTest.reducer";
import UserProfileReducer from "./Profile/Profile.reducer";
import InstructionsReducer from "./Instructions/Instructions.reducer";
import GenerateAssessmentsReducer from "./GenerateAssessment/GenerateAssessment.reducer";
import PostTestReducer from "./PostTest/PostTest.reducer";
import TestTakingReducer from "./TestTaking/TestTaking.reducer";
import MockTestReducer from "./MockTest/MockTest.reducer";
import NotificationReducer from "./NotificationCenter/NotificationCenter.reducer";
import InternetConnectionReducer from "./InternetConnection/InternetConnection.reducer";
import BnatReducer from "./BNAT/BNAT.reducer";

const rootReducer = combineReducers({
  testSpecific: TestSpecificReducer,
  comprehensiveNotes: ComprehensiveReducer,
  comprehensiveTest: ComprehensiveTestReducer,
  user: UserProfileReducer,
  instructions: InstructionsReducer,
  assignment: GenerateAssessmentsReducer,
  testSubmitted: PostTestReducer,
  testTaking: TestTakingReducer,
  mockTest: MockTestReducer,
  notificationCenter: NotificationReducer,
  navigator: InternetConnectionReducer,
  bnat: BnatReducer,
});

export default rootReducer;
