import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../UtilComponents/Header/Header";
import { FETCH_COMPREHENSIVE_NOTES } from "../../../redux/ComprehensiveNotes/ComprehensiveNotes.types";
import Loader from "../../UtilComponents/Loader/Loader";
import { navigateToLearn } from "../../Utils/CommonFunctions";
import ErrorPage from "../../../pages/ErrorPage";
import error from "../../Utils/Error";
import "../../../App.css";

const Notes = lazy(() => import("../Notes"));

function ComprehensiveNotes() {
  const dispatch = useDispatch();
  const presentState = useSelector((state) => state.comprehensiveNotes);
  document.title = "Byju's | Notes";

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    const userToken = localStorage.getItem("user_token");
    if (userToken === null || userId === null) {
      navigateToLearn();
    } else {
      dispatch({ type: FETCH_COMPREHENSIVE_NOTES });
    }
  }, [dispatch]);

  const isOnline = useSelector((state) => state.navigator.isOnline);

  return (
    <>
      {isOnline && (
        <div className="comprehensiveNotes" style={{ textAlign: "center" }}>
          {presentState.results === undefined && <Loader />}
          {presentState.results !== undefined && (
            <>
              <Header comprehensive />
              <Suspense fallback={<Loader />}>
                <Notes comprehensive />
              </Suspense>
            </>
          )}
        </div>
      )}
      {!isOnline && (
        <div className="ErrorPage">
          <ErrorPage
            code="No Internet"
            title={error["No Internet"].title}
            description={error["No Internet"].description}
            ctaTitle={error["No Internet"].cta.title}
            ctaAction={error["No Internet"].cta.action}
          />
        </div>
      )}
    </>
  );
}

export default ComprehensiveNotes;
