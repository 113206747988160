/* eslint-disable camelcase */
import api from "./api";
import { showErrorPage } from "../components/Utils/CommonFunctions";

export const loadAssignment = async ({ assignmentId, enableExtraSection }) => {
  try {
    api.setHeader("Authorization", localStorage.getItem("user_token"));
    const response = await api.get(
      `assignments/${assignmentId}/load${
        enableExtraSection ? "?enable_extra_sections=true" : ""
      }`
    );
    if (response.status > 399) {
      return showErrorPage(response.status);
    }
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getRemainingTime = async ({ assignmentId }) => {
  try {
    const response = await api.get(
      `assignments/${assignmentId}/remaining_time`
    );
    if (response.status > 399) {
      return showErrorPage(response.status);
    }
    return response;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const attemptQuestionAPI = async ({
  assignmentId,
  questionId,
  meta,
}) => {
  try {
    const response = await api.put(
      `assignments/${assignmentId}/questions/${questionId}`,
      {
        ...meta,
      }
    );
    if (response.status > 399) {
      return showErrorPage(response.status);
    }
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
};
