/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactHTMLParser from "react-html-parser";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Input,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { ICONS } from "../../AppConfig";
import Loader from "../UtilComponents/Loader/Loader";
import "../../assets/stylesheets/mjax-web.css";

const useStyles = makeStyles((theme) => {
  return {
    optionsContainer: {
      marginTop: 20,
    },
    optionBorder: {
      paddingLeft: "10px",
      margin: "12px 0 0",
      borderRadius: "8px",
      border: "solid 1px #e9edee",
      backgroundColor: "#ffffff",
    },
    option: {
      marginTop: 8,
    },
    divider: {
      border: "none",
      height: 1,
      backgroundColor: "#e3e3e3",
    },
    questionContainer: {
      fontSize: 14,
      backgroundColor: "#ffffff",
      padding: 10,
      position: "inherit",
    },
    radioOptions: {
      marginRight: 32,
    },
    questionHeader: {
      fontSize: 20,
    },
    arrowDown: {
      display: "inline-block",
      top: 10,
      right: 10,
      position: "absolute",
      "&:hover": {
        transform: "scale(1.05)",
        transition: "0.2s",
      },
      transition: "0.2s",
    },
    optionLabel: {
      fontSize: 14,
    },
    instructionsWrap: {
      marginTop: 30,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    arrowUp: {
      display: "inline-block",
      bottom: 10,
      right: 10,
      position: "absolute",
      transform: "rotate(180deg)",
      "&:hover": {
        transform: "scale(1.05) rotate(180deg)",
        transition: "0.2s",
      },
      transition: "0.2s",
    },
    [theme.breakpoints.down("xs")]: {
      questionContainer: {
        fontSize: 12,
      },
      optionLabel: {
        fontSize: 12,
      },
      questionHeader: {
        fontSize: 15,
      },
    },
    text: {
      marginTop: 20,
      marginBottom: 0,
    },
    questionContainerBorder: {
      borderRadius: "20px",
      fontSize: 14,
      backgroundColor: "#ffffff",
      padding: 20,
      position: "inherit",
    },
  };
});

const questionTypes = {
  multipleSelect: "MultiSelectQuestion",
  multipleChoice: "MultipleChoiceQuestion",
  fillInTheBlank: "FillInTheBlankQuestion",
};

const mapMultiSelectOptionsChecked = (options) => {
  const checked = {};
  if (Array.isArray(options)) {
    options.forEach((option) => {
      checked[option.id] = false;
    });
  }
  return checked;
};

const checkIfMultiSelectCheckedisObject = (obj) => {
  return typeof obj === "object" && obj !== null;
};

const Question = ({
  sequence,
  question,
  options,
  type,
  onChangeOption,
  value,
  quiz,
  instructions,
}) => {
  const [selectedOption, setCurrentOption] = useState("");
  const [fibAnswer, setCurrentFibAnswer] = useState("");
  const [multiSelectOptionsChecked, setMultiSelectOptionsChecked] = useState(
    type === questionTypes.multipleSelect
      ? mapMultiSelectOptionsChecked(options)
      : null
  );
  const classes = useStyles();
  const [selectedDiv, setSelectedDiv] = useState(null);
  useEffect(() => {
    const currentMultiSelectOptionsChecked =
      type === questionTypes.multipleSelect
        ? mapMultiSelectOptionsChecked(options)
        : null;
    if (value && value.value) {
      if (value.type === questionTypes.fillInTheBlank) {
        setCurrentFibAnswer(value.value);
      } else if (value.type === questionTypes.multipleChoice) {
        const myValue = parseInt(value.value, 10);
        setCurrentOption(myValue);
      } else if (
        value.type === questionTypes.multipleSelect &&
        Array.isArray(value.value) &&
        currentMultiSelectOptionsChecked
      ) {
        value.value.forEach((answer) => {
          currentMultiSelectOptionsChecked[answer] = true;
        });
      }
      setMultiSelectOptionsChecked(currentMultiSelectOptionsChecked);
    } else {
      setCurrentOption("");
      setCurrentFibAnswer("");
      setMultiSelectOptionsChecked(currentMultiSelectOptionsChecked);
    }
  }, [value, options, type]);

  useEffect(() => {
    if (sequence) {
      setSelectedDiv(null);
    }
  }, [sequence]);

  const handleValueChange = (answerType, newValue) => {
    if (answerType === questionTypes.multipleChoice) {
      const nextValue = parseInt(newValue, 10);
      setCurrentOption(nextValue);
      onChangeOption({ type: answerType, value: newValue });
    } else if (answerType === questionTypes.multipleSelect) {
      const newAnswerArr = [];
      const newCheckedObj = multiSelectOptionsChecked;
      newCheckedObj[newValue] = !multiSelectOptionsChecked[newValue];
      Object.entries(newCheckedObj).forEach((entry) => {
        if (entry[1]) {
          newAnswerArr.push(entry[0]);
        }
      });
      setMultiSelectOptionsChecked(newCheckedObj);
      onChangeOption({ type: answerType, value: newAnswerArr });
    } else if (answerType === questionTypes.fillInTheBlank) {
      setCurrentFibAnswer(`${newValue}`);
      onChangeOption({ type: answerType, value: newValue });
    }
  };

  const scrollDiv = (position) => {
    const elem = document.getElementById(position);
    elem.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const changeRadioDivColor = (id) => {
    if (selectedDiv === null) {
      setSelectedDiv(`Radio${id}`);
    } else {
      const div = document.getElementById(selectedDiv);
      div.style = null;
      div.firstChild.firstChild.style.color = "rgba(0, 0, 0, 0.54)";
      setSelectedDiv(`Radio${id}`);
    }
    const div = document.getElementById(`Radio${id}`);
    div.style.backgroundColor = "#c67dff";
    div.style.color = "#ffffff";
    const radio = div.firstChild.firstChild;
    radio.style.color = "#ffffff";
  };
  return (
    <>
      {question === undefined && (
        <div style={{ textAlign: "center" }}>
          <Loader padding="10%" />
        </div>
      )}
      {instructions && (
        <div className={classes.instructionsWrap}>{instructions}</div>
      )}
      {question !== undefined && (
        <div
          className={
            quiz ? classes.questionContainerBorder : classes.questionContainer
          }
        >
          {!quiz && (
            <>
              <div
                className={classes.arrowDown}
                onClick={() => scrollDiv("bottom")}
                id="top"
              >
                <img src={ICONS.ARROW_CIRCLE} alt="go down" />
              </div>
              <div>
                <p className={classes.questionHeader}>
                  Question No.
                  {` ${sequence}`}
                </p>
              </div>
              <hr className={classes.divider} />
            </>
          )}
          <div>{ReactHTMLParser(question)}</div>
          {type === questionTypes.multipleSelect ? (
            <div>
              <FormGroup className={classes.optionsContainer}>
                {options.map((item) => (
                  <div
                    className={
                      quiz ? classes.optionBorder : classes.radioOptions
                    }
                    key={item.id}
                    id={`Checkbox${item.id}`}
                  >
                    <FormControlLabel
                      value={item.id}
                      control={
                        <Checkbox
                          checked={
                            (checkIfMultiSelectCheckedisObject(
                              multiSelectOptionsChecked
                            ) &&
                              multiSelectOptionsChecked[item.id]) ||
                            false
                          }
                          onChange={(e) => {
                            handleValueChange(
                              questionTypes.multipleSelect,
                              e.target.value
                            );
                          }}
                        />
                      }
                      label={
                        <div className={classes.optionLabel}>
                          {ReactHTMLParser(item.value)}
                        </div>
                      }
                    />
                  </div>
                ))}
              </FormGroup>
            </div>
          ) : null}
          {type === questionTypes.multipleChoice ? (
            <div>
              <RadioGroup
                name="answer"
                value={selectedOption}
                onChange={(e) => {
                  handleValueChange(
                    questionTypes.multipleChoice,
                    e.target.value
                  );
                  if (quiz) {
                    changeRadioDivColor(e.target.value);
                  }
                }}
                className={classes.optionsContainer}
              >
                {options.map((item) => (
                  <div
                    className={
                      quiz ? classes.optionBorder : classes.radioOptions
                    }
                    key={item.id}
                    id={`Radio${item.id}`}
                  >
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label={
                        <div className={classes.optionLabel}>
                          {ReactHTMLParser(item.value)}
                        </div>
                      }
                    />
                  </div>
                ))}
              </RadioGroup>
            </div>
          ) : null}
          {type === questionTypes.fillInTheBlank ? (
            <>
              <p className={classes.text}>Your Answer:</p>
              <Input
                value={fibAnswer}
                onChange={(e) => {
                  handleValueChange(
                    questionTypes.fillInTheBlank,
                    e.target.value
                  );
                }}
              />
            </>
          ) : null}
          {!quiz && (
            <div
              className={classes.arrowUp}
              onClick={() => scrollDiv("top")}
              id="bottom"
            >
              <img src={ICONS.ARROW_CIRCLE} alt="go down" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    questionTypes.fillInTheBlank,
    questionTypes.multipleChoice,
    questionTypes.multipleSelect,
  ]).isRequired,
  options: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    html_title: PropTypes.string.isRequired,
  }),
  sequence: PropTypes.number,
};

Question.defaultProps = {
  sequence: 0,
  options: [],
};

export default Question;
