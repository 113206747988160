import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { TEST_TAKING_COLORS } from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  examSummayContainer: {
    display: "flex",
    flexDirection: "column",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: TEST_TAKING_COLORS.LIGHT_BLUE,
    padding: "14px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 500,
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.2rem",
    },
  },
  tContainer: {
    margin: "8% 10%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "140px",
    },
    overflow: "scroll",
  },
  table: {
    width: "80%",
    border: "1px solid",
  },
  thead: {
    whiteSpace: "nowrap",
    border: "1px solid",
  },
  htext: {
    background: TEST_TAKING_COLORS.CYAN_BLUE,
    fontWeight: 700,
    border: "1px solid",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("lg")]: {
      fontWeight: 500,
    },
  },
  rtext: {
    border: "1px solid",
    fontFamily: '"GothamBold"',
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    zIndex: 2,
    padding: "16px 0px",
    borderTop: `1px solid ${TEST_TAKING_COLORS.LIGHT_GREY}`,
    backgroundColor: `${TEST_TAKING_COLORS.WHITE}`,
  },
  confirmBtn: {
    textTransform: "none",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.9rem",
    },
  },
  btnCancel: {
    borderRadius: "0px",
    width: "120px",
    marginRight: "14px",
  },
  btnConfirm: {
    borderRadius: "0px",
    width: "120px",
  },
}));

function JeeAdvancedExamSummary({
  examData,
  onSubmitExam,
  onSubmitCancel,
  postTestViewCallback,
  paperTitle,
}) {
  const classes = useStyles();

  // useViewOlap("postExamSummary", postTestViewCallback);

  return (
    <div id="postExamSummary" className={classes.examSummayContainer}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>
          {`Exam Summary ${paperTitle}`}
        </Typography>
      </div>
      <Box className={classes.tContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell className={classes.htext}>Section Name</TableCell>
              <TableCell className={classes.htext}>No of Questions</TableCell>
              <TableCell align="center" className={classes.htext}>
                Answered
              </TableCell>
              <TableCell align="center" className={classes.htext}>
                Not Answered
              </TableCell>
              <TableCell align="center" className={classes.htext}>
                Marked for Review
              </TableCell>
              <TableCell align="center" className={classes.htext}>
                Answered & Marked for Review (will be considered for evaluation)
              </TableCell>
              <TableCell align="center" className={classes.htext}>
                Not Visited
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examData.map((row) => (
              <TableRow key={row.title}>
                <TableCell align="center" className={classes.rtext}>
                  {row.title}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.total || 0}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.answered}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.notanswered}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.markedforreview}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.answeredandmarked}
                </TableCell>
                <TableCell align="center" className={classes.rtext}>
                  {row.notvisited}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <div id="nextButton" className={classes.buttonContainer}>
        <Button
          type="white"
          customStyle={classes.btnCancel}
          onClick={onSubmitCancel}
        >
          <Typography className={classes.confirmBtn}>Cancel</Typography>
        </Button>
        <Button
          type="white"
          customStyle={classes.btnConfirm}
          onClick={onSubmitExam}
        >
          <Typography className={classes.confirmBtn}>Submit</Typography>
        </Button>
      </div>
    </div>
  );
}
JeeAdvancedExamSummary.propTypes = {
  examData: PropTypes.arrayOf({
    sectionName: PropTypes.string.isRequired,
    totalQuestion: PropTypes.number.isRequired,
    answered: PropTypes.number.isRequired,
    notAnswered: PropTypes.number.isRequired,
    markedForReview: PropTypes.number.isRequired,
    answeredAndMarked: PropTypes.number.isRequired,
    notVisited: PropTypes.number.isRequired,
  }).isRequired,
  onSubmitExam: PropTypes.func.isRequired,
};

export default JeeAdvancedExamSummary;
