import * as InstructionTypes from "./Instructions.types";

const initialState = {
  loading: false,
};

const InstructionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case InstructionTypes.FETCH_INSTRUCTIONS_START:
      return { ...state, loading: true };
    case InstructionTypes.FETCH_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        instructions: action.payload.instructions,
      };
    case InstructionTypes.FETCH_INSTRUCTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case InstructionTypes.FETCH_ADVANCED_INSTRUCTIONS_START:
      return { ...state, loading: true };
    case InstructionTypes.FETCH_ADVANCED_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        adv_instructions: action.payload,
      };
    case InstructionTypes.FETCH_ADVANCED_INSTRUCTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default InstructionsReducer;
