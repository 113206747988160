const enableExtraBitsatQuestions = (userDetails) => {
  const totalQuestions =
    userDetails.answered +
    userDetails.notanswered +
    userDetails.markedforreview +
    userDetails.answeredandmarked +
    userDetails.notvisited;
  if (totalQuestions === userDetails.answered + userDetails.answeredandmarked) {
    return true;
  }
  return false;
};

export default enableExtraBitsatQuestions;
