import {
  UPDATE_ASSIGNMENT_STATE,
  FETCH_SOLUTION_DONE,
  FETCH_QUESTION_NOTES_DONE,
  FETCH_QUESTION_FEEDBACK_DONE,
  DELETE_NOTE_DONE,
  POST_NEW_NOTE_DONE,
  EDIT_NOTE_DONE,
  CLEAR_ASSIGNMENT,
  FETCH_VIDEO_SOLUTION_DONE,
  CLEAR_VIDEO_SOLUTION,
} from "./TestSpecific.types";

const INITIAL_STATE = {};

const TestSpecificReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ASSIGNMENT_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_ASSIGNMENT:
      return {};
    case FETCH_SOLUTION_DONE:
      return {
        ...state,
        solution: action.payload,
      };
    case FETCH_VIDEO_SOLUTION_DONE:
      return {
        ...state,
        video_solution: action.payload,
      };
    case CLEAR_VIDEO_SOLUTION:
      return {
        ...state,
        video_solution: null,
      };
    case FETCH_QUESTION_NOTES_DONE:
      return {
        ...state,
        notes: [...action.payload],
      };
    case FETCH_QUESTION_FEEDBACK_DONE:
      return {
        ...state,
      };
    case DELETE_NOTE_DONE:
      return {
        ...state,
      };
    case POST_NEW_NOTE_DONE:
      return {
        ...state,
      };
    case EDIT_NOTE_DONE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default TestSpecificReducer;
