import {
  GENERATE_ASSESSMENT_FAILURE,
  GENERATE_ASSESSMENT_START,
  GENERATE_ASSESSMENT_SUCCESS,
  GENERATE_ASSESSMENT_ALREADY_SUBMITTED,
} from "./GenerateAssessment.types";

const initialState = {
  loading: undefined,
};

const GenerateAssessmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_ASSESSMENT_START:
      return { ...state, loading: true };
    case GENERATE_ASSESSMENT_SUCCESS:
      return { ...state, loading: false, assignment: action.payload };
    case GENERATE_ASSESSMENT_FAILURE:
      return { ...state, loading: false };
    case GENERATE_ASSESSMENT_ALREADY_SUBMITTED:
      return { ...state, loading: "redirect" };
    default:
      return state;
  }
};
export default GenerateAssessmentsReducer;
