import { takeLatest, put } from "redux-saga/effects";

import {
  INTERNET_CONNECTION_DISCONNECTED,
  INTERNET_CONNECTION_RESTORED,
  SET_INTERNET_CONNECTION_DISCONNECTED,
  SET_INTERNET_CONNECTION_RESTORED,
} from "../redux/InternetConnection/InternetConnection.types";

function* setDisconnect() {
  yield put({ type: SET_INTERNET_CONNECTION_DISCONNECTED });
}

function* setconnect() {
  yield put({ type: SET_INTERNET_CONNECTION_RESTORED });
}

function* InternetConnectionSaga() {
  yield takeLatest(INTERNET_CONNECTION_RESTORED, setconnect);
  yield takeLatest(INTERNET_CONNECTION_DISCONNECTED, setDisconnect);
}

export default InternetConnectionSaga;
