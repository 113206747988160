/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ClassNames from "classnames";
import "../../assets/stylesheets/MockDetail.css";
import qs from "querystring";
import moment from "moment";
import { extractDateTime } from "../Utils/TimeConvertor";
import { ICONS, SOCIAL_SHARE, EXAM_TYPES, LANGUAGE } from "../../AppConfig";
import {
  olapPayloadGenerator,
  OLAPEventIds,
  OLAPKingdoms,
  getUserInfo,
} from "../Utils/OlapHelpers";
import { postOLAP } from "../../services/OLAP.api";
import initiateIntersectionObserver from "../Utils/IntersectionObserver";

const useStyles = makeStyles((theme) => ({
  welcomeTxt: {
    fontweight: 600,
    fontSize: "2rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      marginTop: "10%",
    },
  },
  testActiveTxt: {
    color: "#c254f5",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  refreshMsg: {
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  OrngClr: {
    fontWeight: "700",
    color: "#c254f5",
    fontFamily: '"GothamBold"',
  },
  priorTxt: {
    fontWeight: "700",
    fontFamily: '"GothamBold"',
  },
  startBtn: {
    paddingBottom: "20px",
  },
  greetTxt: {
    marginTop: "1%",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  greetTxt1: {
    marginBottom: "3%",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  greetTxtBold: {
    fontSize: "2rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  regularTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  mobDateTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.3rem !important",
    },
  },
  mobTimeTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.9rem !important",
    },
  },
  mobTimeStyl: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "25% !important",
    },
  },
  mobDateStyl: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-15% !important",
    },
  },
  startTestMobBtn: {
    color: "#ffffff !important",
    textTransform: "Capitalize",
    minWidth: "18rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.82rem",
    },
  },
  mobList: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem !important",
    },
  },
  shareBtnGrp: {
    display: "flex",
    marginTop: "1%",
  },
  imgShare: {
    padding: "10px",
    cursor: "pointer",
  },
  sampleTestAttempLeft: {
    fontSize: "0.7rem",
    paddingBottom: "10px",
    fontStyle: "italic",
  },
  infoIcon: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  disableBtn: {
    cursor: "not-allowed !important",
    boxShadow: "none",
    opacity: 0.65,
    pointerEvents: "all !important",
  },
  dateTimePostfixHindi: {
    textAlign: "center",
    marginTop: "10px",
  },
}));
export default function BnatWelcomeSection({
  examType,
  examState,
  language,
  setActiveNav,
}) {
  const classes = useStyles();
  const presentState = examState;
  const user = JSON.parse(localStorage.getItem("user"));
  const userToken = localStorage.getItem("user_token");

  const [testStatusTxt, setTestStatusTxt] = React.useState("Start Test");
  const [ifBnatTestBtnDisabled, setIfBnatTestBtnDisabled] = React.useState(
    false
  );

  let bnatTest;

  if (examType === EXAM_TYPES.JEE_MAIN) {
    bnatTest = "JEE Main";
  } else if (examType === EXAM_TYPES.NEET) {
    bnatTest = "NEET";
  } else if (examType === EXAM_TYPES.BITSAT) {
    bnatTest = "BITSAT";
  } else if (examType === EXAM_TYPES.BNAT) {
    if (presentState.bnat.grade === 15) {
      bnatTest = "BNST | IAS";
    } else {
      bnatTest = "BNAT";
    }
  }

  const bnatStartDatetimeArr = presentState.bnat.start_date_time.split(" ");
  const bnatResultDatetimeArr = presentState.bnat.result_date_time.split(" ");

  const bnatStartDatetime = extractDateTime(bnatStartDatetimeArr);
  const bnatResultDatetime = extractDateTime(bnatResultDatetimeArr);
  const bnatStatus = presentState.bnat.status;

  const callOlapApi = (
    uEventId,
    description,
    kingdom,
    counter,
    record,
    variety
  ) => {
    const { premium } = getUserInfo();
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      uEventId,
      "click",
      description,
      kingdom,
      counter,
      null,
      null,
      null,
      null,
      record,
      null,
      null,
      variety,
      premium ? null : JSON.parse(localStorage.getItem("user")).id,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  };

  const btnState = () => {
    if (bnatStatus === "Pending") {
      setIfBnatTestBtnDisabled(true);
    }

    if (bnatStatus === "Inprogress") {
      setTestStatusTxt("Resume");
    }

    if (
      Date.now() <
        moment(
          presentState.bnat.end_date_time,
          "YYYY-MM-DD HH:mm:ss"
        ).valueOf() &&
      bnatStatus === "Submitted"
    ) {
      setIfBnatTestBtnDisabled(true);
      setTestStatusTxt("Test Submitted");
    }

    if (
      Date.now() >
      moment(presentState.bnat.end_date_time, "YYYY-MM-DD HH:mm:ss").valueOf()
    ) {
      setIfBnatTestBtnDisabled(true);
      setTestStatusTxt("Test Ended");
    }
  };

  useEffect(() => {
    btnState();
  }, []);

  useEffect(() => {
    initiateIntersectionObserver(
      document.querySelector("#bnatWelcomeSection"),
      0.6,
      setActiveNav,
      "1"
    );
  }, [setActiveNav]);

  const shareOn = (socialType) => {
    let shareUrl = "";
    const title = SOCIAL_SHARE.TITLE;
    let url;

    if (presentState.bnat.grade >= 4 && presentState.bnat.grade <= 10) {
      url = SOCIAL_SHARE.BNAT_K10_BASE_URL;
    } else if (presentState.bnat.grade >= 11 && presentState.bnat.grade <= 14) {
      url = SOCIAL_SHARE.BNAT_K12_BASE_URL;
    } else if (presentState.bnat.grade === 15) {
      url = SOCIAL_SHARE.BNAT_IAS_BASE_URL;
    }

    let utmObj = {
      utm_source: socialType,
      utm_medium: "referral",
    };

    switch (socialType) {
      case "whatsapp":
        utmObj = { utm_campaign: `BNAT-Share-${socialType}`, ...utmObj };
        shareUrl = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          navigator.userAgent.toLowerCase()
        )
          ? `${SOCIAL_SHARE.WHATSAPP_MOBILE_URL}${encodeURIComponent(
              url + qs.stringify(utmObj)
            )}`
          : `${SOCIAL_SHARE.WHATSAPP_WEB_URL}${encodeURIComponent(
              url + qs.stringify(utmObj)
            )}`;
        break;
      case "facebook":
        utmObj = { utm_campaign: `BNAT-Share-${socialType}`, ...utmObj };
        shareUrl = `${SOCIAL_SHARE.FACEBOOK_URL}${encodeURIComponent(
          url + qs.stringify(utmObj)
        )}&${qs.stringify({ title })}`;
        break;
      case "twitter":
        utmObj = { utm_campaign: `BNAT-Share-${socialType}`, ...utmObj };
        shareUrl = `${SOCIAL_SHARE.TWITTER_URL}${encodeURIComponent(
          url + qs.stringify(utmObj)
        )}&${qs.stringify({ via: title })}`;
        break;
      case "telegram":
        utmObj = { utm_campaign: `BNAT-Share-${socialType}`, ...utmObj };
        shareUrl = `${SOCIAL_SHARE.TELEGRAM_URL}${encodeURIComponent(
          url + qs.stringify(utmObj)
        )}&${qs.stringify({ via: title })}`;
        break;
      case "message":
        utmObj = { utm_campaign: `BNAT-Share-${socialType}`, ...utmObj };
        shareUrl = `${SOCIAL_SHARE.SMS_URL}${url}${qs.stringify(utmObj)}`;
        break;
      default:
    }
    window.open(
      shareUrl,
      `BYJUS ${bnatTest} ${
        bnatStartDatetime && bnatStartDatetime.year
      } BNAT Social Sharing`,
      `width=550, height=420, left=${
        (window.screen.availWidth - 500) / 2
      }, top=${
        (window.screen.availHeight - 500) / 2
      }, location=0, menubar=0, toolbar=0, status=0, scrollbars=1, resizable=1`
    );
  };

  const openTest = (testType) => {
    const baseURL = window.location.origin;
    let assessmentId;
    let clickAction;
    let kingdom;
    let uEventId;
    let variety = null;
    if (testType === "bnat") {
      setTestStatusTxt("Resume");
      assessmentId = presentState.bnat.assessment_client_id;
      variety = presentState.bnat.exam_type;
      if (bnatStatus === "Inprogress" || testStatusTxt === "Resume") {
        clickAction = "resume";
        kingdom = OLAPKingdoms.CLICK_RESUME_MOCK_TEST;
        uEventId = OLAPEventIds.CLICK_RESUME_BNAT_TEST;
      } else {
        clickAction = "start";
        kingdom = OLAPKingdoms.CLICK_START_MOCK_TEST;
        uEventId = OLAPEventIds.CLICK_START_BNAT_TEST;
      }
    }

    const counter = `${clickAction}_${testType}_test`;
    const description = `click ${clickAction} ${testType} test`;
    callOlapApi(uEventId, description, kingdom, counter, assessmentId, variety);
    const url = `${baseURL}/start_test?access_token=${userToken}&assessment_id=${assessmentId}&is_free_user=true&assessment_type=${examType}&bnat_grade=${presentState.bnat.grade}&bnat_cohort=${presentState.bnat.cohort_type}&bnat_medium=${presentState.bnat.medium}`;
    window.open(url, "bnat_test");
  };

  return (
    <div id="bnatWelcomeSection">
      <Typography className={classes.greetTxt}>
        {language === LANGUAGE.ENGLISH ? "Hello" : "हेलो"}
        {` ${user.full_name},`}
      </Typography>
      <Typography className={classes.greetTxt1}>
        {language === LANGUAGE.ENGLISH
          ? `Thank you for registering for `
          : `आईएएस परीक्षा हेतु BYJU'S के नेशनल स्कॉलरशिप टेस्ट में पंजीकरण कराने के लिए धन्यवाद।`}
        {presentState.bnat.grade < 15 && (
          <span className={classes.greetTxtBold}>
            {`BYJU'S National Aptitude Test ${
              bnatStartDatetime && bnatStartDatetime.year
            }`}
          </span>
        )}
        {presentState.bnat.grade === 15 && language === LANGUAGE.ENGLISH && (
          <>
            <span className={classes.greetTxtBold}>
              {`BYJU'S National Scholarship Test ${
                bnatStartDatetime && bnatStartDatetime.year
              }`}
            </span>
            <span> for</span>
            <span className={classes.greetTxtBold}> IAS</span>
          </>
        )}
      </Typography>
      <div className={ClassNames(classes.regularTxt, "scheduled-card")}>
        {language === LANGUAGE.ENGLISH
          ? "The test is scheduled on "
          : "टेस्ट का आयोजन, दिनांक "}
        <div className="date-time">
          <div className={ClassNames(classes.mobTimeStyl, "date")}>
            <div
              className={ClassNames(classes.mobTimeStyl, "month")}
              id="test_start_month"
            >
              {bnatStartDatetime && bnatStartDatetime.month}
            </div>
            <div
              className={ClassNames(
                classes.mobDateTxt,
                classes.mobDateStyl,
                "number"
              )}
              id="test_start_date"
            >
              {bnatStartDatetime && bnatStartDatetime.date}

              <sup>{bnatStartDatetime && bnatStartDatetime.dateOrdinal}</sup>
            </div>
            <div
              className={ClassNames(
                classes.regularTxt,
                classes.mobDateStyl,
                "year"
              )}
              id="test_start_year"
            >
              {bnatStartDatetime && bnatStartDatetime.year}
            </div>
          </div>
          <div className="time">
            <div className="time-text">TIME</div>
            <div
              className={ClassNames(classes.mobTimeTxt, "number-minute")}
              id="test_start_time"
            >
              {bnatStartDatetime && bnatStartDatetime.time}
            </div>
            <div className="am" id="test_start_am_pm">
              {bnatStartDatetime && bnatStartDatetime.ampm}
            </div>
          </div>
        </div>
        {language === LANGUAGE.HINDI && (
          <div className={classes.dateTimePostfixHindi}>को किया जाएगा।</div>
        )}
      </div>
      <div className={classes.startBtn}>
        <Button
          onClick={() => openTest("bnat")}
          className={ClassNames(classes.startTestMobBtn, "mocktest-btn", {
            [classes.disableBtn]: ifBnatTestBtnDisabled,
          })}
          disabled={ifBnatTestBtnDisabled}
        >
          {testStatusTxt}
        </Button>
      </div>
      <Typography className={classes.testActiveTxt}>
        {`${
          language === LANGUAGE.ENGLISH
            ? "Test button will be active on "
            : "टेस्ट बटन "
        } ${bnatStartDatetime && bnatStartDatetime.date}`}
        <span>
          <sup>{bnatStartDatetime && bnatStartDatetime.dateOrdinal}</sup>
        </span>
        {` ${bnatStartDatetime && bnatStartDatetime.month} ${
          bnatStartDatetime && bnatStartDatetime.year
        }, ${bnatStartDatetime && bnatStartDatetime.time} ${
          bnatStartDatetime && bnatStartDatetime.ampm
        }`}
        {language === LANGUAGE.HINDI && " बजे सक्रिय होगा "}
      </Typography>
      <Typography className={classes.refreshMsg}>
        {language === LANGUAGE.ENGLISH
          ? "(Kindly refresh the page if the button is not enabled on the test date)"
          : "(यदि टेस्ट की तिथि को बटन सक्रिय नहीं हो तो कृपया पेज को रिफ्रेश करें)"}
      </Typography>
      <ul className={ClassNames(classes.mobList, "list")}>
        {language === LANGUAGE.HINDI && (
          <li>
            आपको
            <span className={classes.priorTxt}> टेस्ट से 3 घंटे पहले </span>
            एलर्ट प्राप्त होगा।
          </li>
        )}
        {language === LANGUAGE.ENGLISH && (
          <li>
            You will receive an alert
            <span className={classes.priorTxt}>
              {` 3 hours prior to the test.`}
            </span>
          </li>
        )}
        <li>
          {language === LANGUAGE.ENGLISH
            ? "Your result will be accessible here on "
            : "आपका परिणाम "}
          <span className={classes.OrngClr}>
            {` ${bnatResultDatetime && bnatResultDatetime.date}`}
            <sup>{bnatResultDatetime && bnatResultDatetime.dateOrdinal}</sup>
            {` ${bnatResultDatetime && bnatResultDatetime.month} ${
              bnatResultDatetime && bnatResultDatetime.year
            }, ${bnatResultDatetime && bnatResultDatetime.time} ${
              bnatResultDatetime && bnatResultDatetime.ampm
            }`}
          </span>
          {language === LANGUAGE.HINDI && " यहाँ  उपलब्ध होगा।"}
        </li>
      </ul>
      <Typography>
        {`${
          language === LANGUAGE.ENGLISH
            ? "Share with your friends and invite them to take "
            : "इसे अपने मित्रों के साथ साझा करें और उन्हें "
        } ${bnatTest}
        ${bnatStartDatetime && bnatStartDatetime.year}`}
        {language === LANGUAGE.HINDI && " में भाग लेने के लिए आमंत्रित करें।"}
      </Typography>
      <div className={classes.shareBtnGrp}>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "whatsapp")}
          role="presentation"
        >
          <img alt="whatsapp" height="50" width="45" src={ICONS.WHATSAPP} />
        </div>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "facebook")}
          role="presentation"
        >
          <img alt="facebook" height="50" width="45" src={ICONS.FACEBOOK} />
        </div>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "twitter")}
          role="presentation"
        >
          <img alt="twitter" height="50" width="45" src={ICONS.TWITTER} />
        </div>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "telegram")}
          role="presentation"
        >
          <img alt="telegram" height="50" width="45" src={ICONS.TELEGRAM} />
        </div>
        <div
          className={classes.imgShare}
          onClick={shareOn.bind(this, "message")}
          role="presentation"
        >
          <img alt="message" height="50" width="45" src={ICONS.SMS} />
        </div>
      </div>
    </div>
  );
}
