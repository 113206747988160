/* Update Notification Preferences Types */
export const UPDATE_NOTIFICATION_PREFERENCES =
  "UPDATE_NOTIFICATION_PREFERENCES";
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS =
  "UPDATE_NOTIFICATION_PREFERENCES_SUCCESS";
export const UPDATE_NOTIFICATION_PREFERENCES_FAILURE =
  "UPDATE_NOTIFICATION_PREFERENCES_SUCCESS";

/* Get Notification Preferences Types */
export const GET_NOTIFICATION_PREFERENCES = "GET_NOTIFICATION_PREFERENCES";
export const GET_NOTIFICATION_PREFERENCES_SUCCESS =
  "GET_NOTIFICATION_PREFERENCES_SUCCESS";
export const GET_NOTIFICATION_PREFERENCES_FAILURE =
  "GET_NOTIFICATION_PREFERENCES_FAILURE";
