/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ClassNames from "classnames";
import { ClickAwayListener } from "@material-ui/core";
import { ICONS } from "../../AppConfig";
import { COLORS } from "../Utils/Constants";

const genStyle = ({ color }) => {
  return {
    backgroundColor: color,
    padding: "10px 16px",
    whiteSpace: "nowrap",
    margin: 0,
    fontSize: 13,
    fontWeight: "bold",
    border: "0px",
    color: "white",
    cursor: "pointer",
    transition: "0.2s",
    "&:disabled": {
      filter: "grayscale(100%)",
    },
    "&:focus": {
      transition: "0.2s",
      outline: "none",
    },
  };
};

const useStyle = makeStyles((theme) => ({
  popup: {
    ...genStyle({ color: COLORS.ADVANCED_MAIN }),
    position: "relative",
    borderRadius: "5px",
    textAlign: "left",
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: "-8px",
      left: "50%",
      transform: "translate(-50%)",
      borderTop: `8px solid ${COLORS.ADVANCED_MAIN}`,
      borderRight: "8px solid transparent",
      borderLeft: "8px solid transparent",
    },
    [theme.breakpoints.down("xs")]: {
      width: ({ extend }) => (extend ? "95%" : "110px"),
      fontSize: 16,
      padding: 0,
      display: "flex",
      alignItems: "center",
      backgroundColor: "transparent",
      color: COLORS.BLACK,
      "& span": {
        whiteSpace: "nowrap",
        width: "calc(100% - 30px)",
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "&::before": {
        content: '""',
        display: "none",
        fontSize: "16px",
      },
    },
  },
  section: {
    ...genStyle({
      color: ({ selected }) => (selected ? COLORS.ADVANCED_MAIN : COLORS.WHITE),
    }),
    padding: "16px 25px 16px 14px",
    border: `1px solid ${COLORS.GREY}`,
    color: ({ selected }) => (selected ? COLORS.WHITE : COLORS.ADVANCED_MAIN),
    [theme.breakpoints.down("sm")]: {
      padding: "12px 25px 12px 14px",
      fontSize: 12,
    },
  },
  info: {
    width: "16px",
    marginBottom: "-3px",
    [theme.breakpoints.down("xs")]: {
      width: 18,
    },
  },
  infoButton: {
    border: 0,
    padding: 0,
    backgroundColor: "transparent",
    marginLeft: "8px",
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
  },
  tooltip: {
    backgroundColor: COLORS.ADVANCED_ACCENT,
    top: ({ type }) => (type === "section" ? 30 : 0),
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "8px",
    width: 200,
    position: "relative",
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${COLORS.ADVANCED_MAIN}`,
    boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.25)",
    textAlign: "left",
    whiteSpace: "normal",
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "-10px",
      left: "50%",
      transform: "translate(-50%)",
      borderBottom: `10px solid ${COLORS.ADVANCED_MAIN}`,
      borderRight: "10px solid transparent",
      borderLeft: "10px solid transparent",
    },
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "-9px",
      left: "50%",
      transform: "translate(-50%)",
      borderBottom: `10px solid ${COLORS.ADVANCED_ACCENT}`,
      borderRight: "10px solid transparent",
      borderLeft: "10px solid transparent",
    },
  },
}));

const Button = ({
  type,
  children,
  selected,
  onClick,
  extend,
  disabled,
  component,
  customStyle,
  onToolTipOpen,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    onToolTipOpen();
    setOpen(true);
  };
  const classes = useStyle({ selected, extend, type });
  return (
    <button
      className={ClassNames(classes[type], customStyle)}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      <span>{children}</span>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={component || ""}
        >
          <button
            className={ClassNames(classes.infoButton)}
            type="button"
            onMouseOver={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}
          >
            <img
              className={ClassNames(classes.info)}
              src={ICONS.INFO_ITALIC}
              alt="info"
            />
          </button>
        </Tooltip>
      </ClickAwayListener>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  extend: PropTypes.bool,
};

Button.defaultProps = {
  type: "section",
  extend: false,
};

export default Button;
