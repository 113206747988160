import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../Button/Button";

const useStyle = makeStyles(() => ({
  subjectNavigationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    overflowX: "scroll",
    padding: "15px 10px 10px 15px",
    backgroundColor: "#ffffff",
  },
  hrLine: {
    border: "none",
    height: 1,
    backgroundColor: "#e3e3e3",
  },
}));

const SubjectNavigation = ({ subjectArray, examType, selected, onChange }) => {
  const classes = useStyle();
  return (
    <>
      <div
        id="testTakingSubjectNavigation"
        className={classes.subjectNavigationContainer}
      >
        {examType &&
          subjectArray.map((subject) => {
            return (
              <Button
                type="white"
                key={subject.id}
                selected={subject.id === selected}
                onClick={() => onChange(subject)}
              >
                {subject.title}
              </Button>
            );
          })}
      </div>
      <hr className={classes.hrLine} />
    </>
  );
};

SubjectNavigation.propTypes = {
  subjectArray: PropTypes.arrayOf({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  examType: PropTypes.string,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

SubjectNavigation.defaultProps = {
  subjectArray: [
    {
      id: "1",
      title: "Physics",
      selected: true,
    },
  ],
  //   Need to finalize exam-type values from backend
  examType: "",
  onChange: () => {},
};

export default SubjectNavigation;
