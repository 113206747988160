import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loader({ upload, padding }) {
  return (
    <div style={{ paddingTop: padding || "30%" }}>
      <CircularProgress />
      <p>{upload ? "Uploading and Saving Note" : "Loading"}</p>
    </div>
  );
}

export default Loader;
