/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/UtilComponents/Header/Header";
import error from "../components/Utils/Error";
import Loader from "../components/UtilComponents/Loader/Loader";

const useStyles = makeStyles((theme) => ({
  errorBody: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  errorCode: {
    fontSize: "96px",
    fontWeight: 900,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "var(--black)",
    padding: "18px 29px 10px 31px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "80px",
    },
  },
  errorTitle: {
    margin: "24px 80px 8px 78px",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "var(--black-two)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  errorDescription: {
    margin: "8px 39px 20px 38px",
    width: "75%",
    alignSelf: "center",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#666666",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
  },
  ctaButton: {
    alignSelf: "center",
    width: "160px",
    height: "48px",
    flexGrow: 0,
    marginTop: "60px",
    marginBottom: "10px",
    padding: "17px 38px",
    boxShadow: "0 4px 12px 0 rgba(172, 93, 251, 0.44)",
    backgroundImage: "linear-gradient(to top, #c254f5, #842dda)",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "100px",
    },
  },
  buttonText: {
    fontSize: "12px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
  },
  link: {
    alignSelf: "center",
  },
  errorIcon: {
    marginTop: "10px",
    width: "460px",
    height: "280px",
    objectFit: "contain",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "230px",
      height: "140px",
      objectFit: "contain",
    },
  },
}));

function ErrorPage({ code, title, description, ctaTitle, ctaAction }) {
  /* States */
  const [errorMeta, setErrorMeta] = React.useState(null);

  /* Constants */
  const classes = useStyles();
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const handledErrors = [400, 401, 403, 404, 406, 500];

  /* Functions */
  const buildErrorMeta = () => {
    if (code === undefined) {
      let errorCodeParam = param.get("code");
      if (errorCodeParam !== null) {
        errorCodeParam = Number(errorCodeParam);
        if (handledErrors.includes(errorCodeParam)) {
          setErrorMeta({
            code: errorCodeParam,
            title: error[errorCodeParam].title,
            description: error[errorCodeParam].description,
            cta: {
              title: error[errorCodeParam].cta.title,
              action: error[errorCodeParam].cta.action,
            },
          });
          document.title = `Byju's | ${error[errorCodeParam].title}`;
        } else {
          setErrorMeta({
            code: "Oops!",
            title: error.fallback.title,
            description: error.fallback.description,
            cta: {
              title: error.fallback.cta.title,
              action: error.fallback.cta.action,
            },
          });
          document.title = `Byju's | ${error.fallback.title}`;
        }
      } else {
        setErrorMeta({
          code: 403,
          title: error[403].title,
          description: error[403].description,
          cta: {
            title: error[403].cta.title,
            action: error[403].cta.action,
          },
        });
        document.title = `Byju's | ${error[403].title}`;
      }
    } else {
      document.title = `Byju's | ${title}`;
      setErrorMeta({
        code,
        title,
        description,
        cta: {
          title: ctaTitle,
          action: ctaAction,
        },
      });
    }
  };

  React.useEffect(() => {
    buildErrorMeta();
  }, []);

  return (
    <div>
      {errorMeta === null && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
      {errorMeta !== null && (
        <>
          <Header noNotes />
          <div className={classes.errorBody}>
            {errorMeta.code !== "Oops!" && errorMeta.code !== "No Internet" && (
              <img
                src={error[errorMeta.code].icon}
                alt="Error Icon"
                className={classes.errorIcon}
              />
            )}
            {(errorMeta.code === "Oops!" ||
              errorMeta.code === "No Internet") && (
              <Typography className={classes.errorCode}>
                {errorMeta.code === "No Internet" ? "Oops!" : errorMeta.code}
              </Typography>
            )}

            <Typography className={classes.errorTitle}>
              {errorMeta.title}
            </Typography>
            <Typography className={classes.errorDescription}>
              {errorMeta.description}
            </Typography>
            {errorMeta.cta.title !== null && (
              <Link to={errorMeta.cta.action} className={classes.link}>
                <Button className={classes.ctaButton}>
                  <Typography className={classes.buttonText}>
                    {errorMeta.cta.title}
                  </Typography>
                </Button>
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ErrorPage;
