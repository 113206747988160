import { takeLatest, put, select } from "redux-saga/effects";
import {
  calculateAdvancedLegend,
  getLegends,
  calculateTotalLegend,
} from "../components/Utils/JeeAdvancedTestTaking";
import { formatQuestions } from "../components/Utils/TestTaking";
import { SUBMIT_TEST_USER_REQUEST } from "../redux/PostTest/PostTest.types";

import {
  ATTEMPT_QUESTION,
  LOAD_ASSIGNMENT,
  LOAD_ASSIGNMENT_FAILURE,
  LOAD_ASSIGNMENT_START,
  LOAD_ASSIGNMENT_SUCCESS,
  REMAINING_TIME,
  REMAINING_TIME_FAILURE,
  REMAINING_TIME_START,
  REMAINING_TIME_SUCCESS,
  ATTEMPT_QUESTION_FAILURE,
  REDIRECT_ON_FINISH,
  LOAD_ASSIGNMENT_ADDITIONAL_QUESTION_UNLOCK_START,
  UPDATE_ADVANCED_LEGEND,
  UPDATE_ADVANCED_LEGEND_SUCCESS,
} from "../redux/TestTaking/TestTaking.types";
import {
  getRemainingTime,
  loadAssignment,
  attemptQuestionAPI,
} from "../services/TestTaking.api";

function* loadAssignments(data) {
  try {
    const assignmentId = data.payload;
    let response;
    if (data.enableExtraSection === true) {
      yield put({ type: LOAD_ASSIGNMENT_ADDITIONAL_QUESTION_UNLOCK_START });
      response = yield loadAssignment({
        assignmentId,
        enableExtraSection: true,
      });
    } else {
      yield put({ type: LOAD_ASSIGNMENT_START });
      response = yield loadAssignment({
        assignmentId,
        enableExtraSection: false,
      });
    }
    const { isAdvanced } = data.meta;
    let legendData;
    const assessmentQuestions = formatQuestions(response.assignment);
    if (isAdvanced) {
      const { questions } = assessmentQuestions;
      legendData = calculateAdvancedLegend(questions);
    }
    if (response.assignment.status === "Assigned") {
      const timeRemaining = response.assignment.assessment.time_allowed;
      yield put({ type: REMAINING_TIME_SUCCESS, payload: timeRemaining });
    } else {
      yield put({ type: REMAINING_TIME, payload: assignmentId });
    }
    yield put({
      type: LOAD_ASSIGNMENT_SUCCESS,
      payload: assessmentQuestions,
      extraSectionsEnabled: response.assignment.extra_sections_enabled,
      meta: { isAdvanced, legendData },
    });
  } catch (err) {
    yield put({ type: LOAD_ASSIGNMENT_FAILURE, payload: err.message });
  }
}

function* remainingTime(data) {
  try {
    const assignmentId = data.payload;
    yield put({ type: REMAINING_TIME_START });
    const response = yield getRemainingTime({ assignmentId });
    if (response.data.time_remaining === 0 || response.status === 403) {
      yield put({
        type: SUBMIT_TEST_USER_REQUEST,
        payload: assignmentId,
      });
      yield put({ type: REDIRECT_ON_FINISH });
    } else {
      const time = response.data.time_remaining;
      yield put({ type: REMAINING_TIME_SUCCESS, payload: time });
    }
  } catch (err) {
    yield put({ type: REMAINING_TIME_FAILURE, payload: err.message });
  }
}

function* attemptQuestion(data) {
  try {
    const { assignmentID, questionID, meta } = data.payload;
    const response = yield attemptQuestionAPI({
      assignmentId: assignmentID,
      questionId: questionID,
      meta,
    });
    sessionStorage.setItem("question_submitted", questionID);
    if (response.has_submitted) {
      yield put({ type: REDIRECT_ON_FINISH });
    }
  } catch (err) {
    yield put({ type: ATTEMPT_QUESTION_FAILURE, payload: err.message });
  }
}

function* updateLegend(action) {
  const { payload } = action;
  const legends = yield select(getLegends);
  const total = Object.keys(payload.data)
    .map((item) => payload.data[item])
    .reduce((a, c) => a + c, 0);
  const updatedLegends = {
    ...legends,
    [payload.title]: {
      title: payload.title,
      total,
      ...payload.data,
    },
  };
  const updatedTotalLegend = calculateTotalLegend(updatedLegends);
  yield put({
    type: UPDATE_ADVANCED_LEGEND_SUCCESS,
    payload: { ...updatedLegends, all: updatedTotalLegend },
  });
}

function* TestTakingSaga() {
  yield takeLatest(LOAD_ASSIGNMENT, loadAssignments);
  yield takeLatest(REMAINING_TIME, remainingTime);
  yield takeLatest(ATTEMPT_QUESTION, attemptQuestion);
  yield takeLatest(UPDATE_ADVANCED_LEGEND, updateLegend);
}

export default TestTakingSaga;
