import { takeLatest, put } from "redux-saga/effects";
import getBnatTestData from "../services/BNAT.api";
import {
  GET_BNAT_DETAILS,
  GET_BNAT_DETAILS_SUCCESS,
} from "../redux/BNAT/BNAT.types";

function* fetchBnatDetails() {
  const response = yield getBnatTestData();
  yield put({
    type: GET_BNAT_DETAILS_SUCCESS,
    payload: response.data,
  });
}

function* BnatTestSaga() {
  yield takeLatest(GET_BNAT_DETAILS, fetchBnatDetails);
}

export default BnatTestSaga;
