import api from "./api";
import { showErrorPage } from "../components/Utils/CommonFunctions";

export const getInstructions = async ({ assessmentId }) => {
  api.setHeader("Authorization", localStorage.getItem("user_token"));
  try {
    if (assessmentId) {
      const response = await api.get(
        `assessments/${assessmentId}/instructions`
      );
      if (response.status > 399) {
        return showErrorPage(response.status);
      }
      return response.data;
    }
    return null;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getAdvancedInstructions = async ({ assessmentId }) => {
  api.setHeader("Authorization", localStorage.getItem("user_token"));
  try {
    if (assessmentId) {
      const response = await api.get(
        `jee_assessments/${assessmentId}/instructions`
      );
      if (response.status > 399) {
        return showErrorPage(response.status);
      }
      return response.data;
    }
    return null;
  } catch (err) {
    throw new Error(err.message);
  }
};
