export default {
  mockTestJee: {
    row1: [
      {
        videoId: "KO64vMGmM6g",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Real+Gases.jpg",
        title: "Real Gases | States of Matter",
      },
      {
        videoId: "06SNELa8HJ0",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Application+of+Circular+Motion.jpg",
        title: "Application of Circular Motion",
      },
      {
        videoId: "nWWEDJOZZ-I",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Cardinality+of+Sets.jpg",
        title: "Cardinality of Sets",
      },
    ],
    row2: [
      {
        videoId: "dUKHZaI8byI",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Spectrum-+Definition%2C+Types+and+More.jpg",
        title: "Spectrum- Definition, Types and More",
      },
      {
        videoId: "HDTQmtDAG7Y",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/FBD+of+Combined+Bodies.jpg",
        title: "FBD of Combined Bodies",
      },
      {
        videoId: "xdGdFT2DHp0",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Inequalities+_+Wavy+Curve+Method+.jpg",
        title: "Wavy Curve Methods",
      },
    ],
  },
  mockTestNeet: {
    row1: [
      {
        videoId: "JAimv7BJmWY",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Real+Gases+_+Compressibility.jpg",
        title: "Real Gases | Compressibility",
      },
      {
        videoId: "o1QmCwgUb6o",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Angular+Momentum+of+Rigid+Body.jpg",
        title: "Angular Momentum of Rigid Body",
      },
      {
        videoId: "KCiLxfCx634",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Aschelminthes+Worms.jpg",
        title: "Aschelminthes Worms",
      },
    ],
    row2: [
      {
        videoId: "CLVgf1bE8NA",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Gay+Lussac_s+Law.jpg",
        title: "Gay Lussac's law",
      },
      {
        videoId: "CNvSFrPqcU8",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Problem+illustration+on+Collision.jpg",
        title: "Collision",
      },
      {
        videoId: "AR7cbMXhmao",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Factors+affecting+Water+potential+.jpg",
        title: "Water Potential",
      },
    ],
  },
  mockTestBitsat: {
    row1: [
      {
        videoId: "KO64vMGmM6g",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Real+Gases.jpg",
        title: "Real Gases | States of Matter",
      },
      {
        videoId: "06SNELa8HJ0",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Application+of+Circular+Motion.jpg",
        title: "Application of Circular Motion",
      },
      {
        videoId: "nWWEDJOZZ-I",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Cardinality+of+Sets.jpg",
        title: "Cardinality of Sets",
      },
    ],
    row2: [
      {
        videoId: "dUKHZaI8byI",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Spectrum-+Definition%2C+Types+and+More.jpg",
        title: "Spectrum- Definition, Types and More",
      },
      {
        videoId: "HDTQmtDAG7Y",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/FBD+of+Combined+Bodies.jpg",
        title: "FBD of Combined Bodies",
      },
      {
        videoId: "xdGdFT2DHp0",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Inequalities+_+Wavy+Curve+Method+.jpg",
        title: "Wavy Curve Methods",
      },
    ],
  },
  bnatTestGrade4to5: {
    row1: [
      {
        videoId: "3DCkOIdsAbE",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-4-and-5/Perimeter+of+Regular+figures.jpg",
        title: "Perimeter of Regular figures",
      },
      {
        videoId: "jSdWGcuDm5E",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-4-and-5/Types+of+Angles.jpg",
        title: "Types of Angles",
      },
      {
        videoId: "7wPKS9JrUHM",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-4-and-5/Human+Ear.jpg",
        title: "Human Ear",
      },
    ],
    row2: [
      {
        videoId: "i0oGoCFxIZ4",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-4-and-5/Representing+Fractions.jpg",
        title: "Representing Fractions",
      },
      {
        videoId: "ZIYvjlFWieY",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-4-and-5/Sound+from+Objects.jpg",
        title: "Sound from Objects",
      },
      {
        videoId: "g9AnimzP3jY",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-4-and-5/Pluto+and+the+Ice+Giants.jpg",
        title: "Pluto and the Ice Giants",
      },
    ],
  },
  bnatTestGrade6to7: {
    row1: [
      {
        videoId: "2YthCDIeuig",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-6-to-7/Factorisation+of+a+Quadratic+Polynomial+-+Visualisation.jpg",
        title: "Factorisation of a Quadratic Polynomial - Visualisation",
      },
      {
        videoId: "YuOIrrxFJL4",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-6-to-7/Symmetry+and+it_s+types.jpg",
        title: "Symmetry and it's types",
      },
      {
        videoId: "vLSkRi5_HKQ",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-6-to-7/Visualising+Exponents+and+Powers.jpg",
        title: "Visualising Exponents and Powers",
      },
    ],
    row2: [
      {
        videoId: "f6DZoX5dKbE",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-6-to-7/Producing+3D+shapes+from+2D+Shapes.jpg",
        title: "Producing 3D shapes from 2D Shapes",
      },
      {
        videoId: "HSuqbqENIek",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-6-to-7/Why+is+Pi+Irrational.jpg",
        title: "Why is Pi Irrational",
      },
      {
        videoId: "OvAIozhNZnE",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-6-to-7/Coriolis+Effect.jpg",
        title: "Coriolis Effect",
      },
    ],
  },
  bnatTestGrade8to9: {
    row1: [
      {
        videoId: "GqdJFFvsZeE",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Visualising+Geometrical+Shapes.jpg",
        title: "Visualising Geometrical Shapes",
      },
      {
        videoId: "HE12r4vCwII",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Visualising+Area+of+a+Circle.jpg",
        title: "Visualising Area of a Circle",
      },
      {
        videoId: "r6PGhrEleU8",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Area+of+a+Trapezium.jpg",
        title: "Area of a Trapezium",
      },
    ],
    row2: [
      {
        videoId: "_bhcR5X3VZ4",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Respiration.jpg",
        title: "Respiration",
      },
      {
        videoId: "KSt9zudEclY",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Life+Processes.jpg",
        title: "Life Processes",
      },
      {
        videoId: "2htSHErOces",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Resistance+and+Resistivity.jpg",
        title: "Resistance and Resistivity",
      },
    ],
  },
  bnatTestGrade10: {
    row1: [
      {
        videoId: "GqdJFFvsZeE",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Visualising+Geometrical+Shapes.jpg",
        title: "Visualising Geometrical Shapes",
      },
      {
        videoId: "HE12r4vCwII",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Visualising+Area+of+a+Circle.jpg",
        title: "Visualising Area of a Circle",
      },
      {
        videoId: "r6PGhrEleU8",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Area+of+a+Trapezium.jpg",
        title: "Area of a Trapezium",
      },
    ],
    row2: [
      {
        videoId: "_bhcR5X3VZ4",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Respiration.jpg",
        title: "Respiration",
      },
      {
        videoId: "KSt9zudEclY",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Life+Processes.jpg",
        title: "Life Processes",
      },
      {
        videoId: "2htSHErOces",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Class-8-9-10/Resistance+and+Resistivity.jpg",
        title: "Resistance and Resistivity",
      },
    ],
  },
  bnatTestMoving10to11: {
    row1: [
      {
        videoId: "gQfO3yeKNZk",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Chemical_Equilibrium.jpg",
        title: "Chemical Equilibrium",
      },
      {
        videoId: "OaAcN_rICoU",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Atomic_Structure.jpg",
        title: "Atomic Structure",
      },
      {
        videoId: "Cp1IAGoDPBE",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Relative_Motion_in_1-D.jpg",
        title: "Relative Motion in 1-D",
      },
    ],
    row2: [
      {
        videoId: "7WGKMDRzjL8",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Kinematics.jpg",
        title: "Kinematics",
      },
      {
        videoId: "BSxBzjbAaD8",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Graph_of_Modulus_Function.jpg",
        title: "Graph of Modulus Function",
      },
      {
        videoId: "HY-iSAk5TAk",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/Mechanism_of_Breathing.jpg",
        title: "Mechanism of Breathing",
      },
    ],
  },
  bnatTestJee: {
    row1: [
      {
        videoId: "KO64vMGmM6g",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Real+Gases.jpg",
        title: "Real Gases | States of Matter",
      },
      {
        videoId: "06SNELa8HJ0",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Application+of+Circular+Motion.jpg",
        title: "Application of Circular Motion",
      },
      {
        videoId: "nWWEDJOZZ-I",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Cardinality+of+Sets.jpg",
        title: "Cardinality of Sets",
      },
    ],
    row2: [
      {
        videoId: "dUKHZaI8byI",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Spectrum-+Definition%2C+Types+and+More.jpg",
        title: "Spectrum- Definition, Types and More",
      },
      {
        videoId: "HDTQmtDAG7Y",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/FBD+of+Combined+Bodies.jpg",
        title: "FBD of Combined Bodies",
      },
      {
        videoId: "xdGdFT2DHp0",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Inequalities+_+Wavy+Curve+Method+.jpg",
        title: "Wavy Curve Methods",
      },
    ],
  },
  bnatTestNeet: {
    row1: [
      {
        videoId: "JAimv7BJmWY",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Real+Gases+_+Compressibility.jpg",
        title: "Real Gases | Compressibility",
      },
      {
        videoId: "o1QmCwgUb6o",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Angular+Momentum+of+Rigid+Body.jpg",
        title: "Angular Momentum of Rigid Body",
      },
      {
        videoId: "KCiLxfCx634",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Aschelminthes+Worms.jpg",
        title: "Aschelminthes Worms",
      },
    ],
    row2: [
      {
        videoId: "CLVgf1bE8NA",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Gay+Lussac_s+Law.jpg",
        title: "Gay Lussac's law",
      },
      {
        videoId: "CNvSFrPqcU8",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Problem+illustration+on+Collision.jpg",
        title: "Collision",
      },
      {
        videoId: "AR7cbMXhmao",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Factors+affecting+Water+potential+.jpg",
        title: "Water Potential",
      },
    ],
  },
  bnatTestJeeNeet: {
    row1: [
      {
        videoId: "KO64vMGmM6g",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Real+Gases.jpg",
        title: "Real Gases | States of Matter",
      },
      {
        videoId: "06SNELa8HJ0",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Application+of+Circular+Motion.jpg",
        title: "Application of Circular Motion",
      },
      {
        videoId: "nWWEDJOZZ-I",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/JEE/Cardinality+of+Sets.jpg",
        title: "Cardinality of Sets",
      },
    ],
    row2: [
      {
        videoId: "CLVgf1bE8NA",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Gay+Lussac_s+Law.jpg",
        title: "Gay Lussac's law",
      },
      {
        videoId: "CNvSFrPqcU8",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Problem+illustration+on+Collision.jpg",
        title: "Collision",
      },
      {
        videoId: "AR7cbMXhmao",
        thumbnail:
          "https://cdn1.byjus.com/byjusweb/img/bnat/thumbnails/nov-2020/NEET/Factors+affecting+Water+potential+.jpg",
        title: "Water Potential",
      },
    ],
  },
  bnatTestIASEnglish: {
    row1: [
      {
        videoId: "WPvFniJGB8c",
        thumbnail:
          "https://cdn1.byjus.com/ctp/bnat/upsc/thumbnails/International-Relations-Prof-Pushpesh-Pant.png",
        title: "International Relations this Week by Prof Pushpesh Pant 17",
      },
      {
        videoId: "2LQFJx1AExg",
        thumbnail:
          "https://cdn1.byjus.com/ctp/bnat/upsc/thumbnails/Anti-Defection-Law.png",
        title: "Explained: Anti Defection Law",
      },
      {
        videoId: "nNfSegUKFEE",
        thumbnail:
          "https://cdn1.byjus.com/ctp/bnat/upsc/thumbnails/Geo-Through-Maps.png",
        title: "UPSC Prelims 2020 Special Lecture: Geography through Maps",
      },
    ],
    row2: [
      {
        videoId: "eGykc_VdDFg",
        thumbnail:
          "https://cdn1.byjus.com/ctp/bnat/upsc/thumbnails/Prelims-Questions-Discussion.png",
        title: "UPSC Prelims 2020 Analysis & Discussion | GS Paper 1",
      },
    ],
  },
  bnatTestIASHindi: {
    row1: [
      {
        videoId: "WPvFniJGB8c",
        thumbnail:
          "https://cdn1.byjus.com/ctp/bnat/upsc/thumbnails/International-Relations-Prof-Pushpesh-Pant.png",
        title: "अंतर्राष्ट्रीय संबंध इस सप्ताह : प्रो. पुष्पेश पंत द्वारा 17",
      },
      {
        videoId: "2LQFJx1AExg",
        thumbnail:
          "https://cdn1.byjus.com/ctp/bnat/upsc/thumbnails/Anti-Defection-Law.png",
        title: "दल बदल विरोधी कानून : विस्तृत विश्लेषण",
      },
      {
        videoId: "nNfSegUKFEE",
        thumbnail:
          "https://cdn1.byjus.com/ctp/bnat/upsc/thumbnails/Geo-Through-Maps.png",
        title:
          "यूपीएससी प्रारंभिक परीक्षा 2020 विशेष व्याख्यान: मानचित्र के माध्यम से भूगोल",
      },
    ],
    row2: [
      {
        videoId: "eGykc_VdDFg",
        thumbnail:
          "https://cdn1.byjus.com/ctp/bnat/upsc/thumbnails/Prelims-Questions-Discussion.png",
        title:
          "यूपीएससी प्रारंभिक परीक्षा 2020 विश्लेषण और चर्चा | जीएस पेपर 1",
      },
    ],
  },
};
