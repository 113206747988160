export default {
  instructionsBoxTable: [
    {
      id: 1,
      status: "notvisited",
      text1: "Not Visited",
      text2: "You have not visited the question yet.",
    },
    {
      id: 2,
      status: "notanswered",
      text1: "Not Answered",
      text2: "You have not answered the question yet.",
    },
    {
      id: 3,
      status: "answered",
      text1: "Answered",
      text2:
        "You have answered the question. All these questions will be evaluated.",
    },
    {
      id: 4,
      status: "markedforreview",
      text1: "Marked for Review",
      text2:
        "You have NOT answered the question but have ONLY marked the question for review.",
    },
    {
      id: 5,
      status: "answeredandmarked",
      text1: "Answered and Marked for Review",
      text2:
        "You have answered the question and have also marked it for review. All these questions will be evaluated.",
    },
  ],
};
