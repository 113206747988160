import { takeLatest, put } from "redux-saga/effects";
import {
  FETCH_COMPREHENSIVE_NOTES,
  UPDATE_COMPREHENSIVE_NOTES_STATE,
} from "../redux/ComprehensiveNotes/ComprehensiveNotes.types";
import fetchComprehensiveNotes from "../services/ComprehensiveNotes.api";

function* ApiComprehensive() {
  const payload = yield fetchComprehensiveNotes();
  yield put({ type: UPDATE_COMPREHENSIVE_NOTES_STATE, payload });
}

function* ComprehensiveNotesSaga() {
  yield takeLatest(FETCH_COMPREHENSIVE_NOTES, ApiComprehensive);
}

export default ComprehensiveNotesSaga;
