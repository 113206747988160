export default {
  "BNAT4-5": "(Duration 45 Mins)",
  "BNAT6-7": "(Duration 60 Mins)",
  "BNAT8-9": "(Duration 90 Mins)",
  BNAT10: "(Duration 90 Mins)",
  BNAT10moving: "(Duration 90 Mins)",
  BNAT11JEE: "(Duration 90 Mins)",
  BNAT11NEET: "(Duration 90 Mins)",
  "BNAT11JEE+NEET": "(Duration 90 Mins)",
  "BNAT12-13JEE": "(Duration 90 Mins)",
  "BNAT12-13NEET": "(Duration 90 Mins)",
  "BNAT12-13JEE+NEET": "(Duration 90 Mins)",
  BNATIASHindi: "(अवधि 120 मिनट)",
  BNATIASEnglish: "(Duration 120 Mins)",
};
