import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import PostTestScreen from "../PostTestScreen/PostTestScreen";
import { postTestViewCallback } from "../Utils/OlapCallback";
import JeeAdvancedExamSummary from "../ExamSummary/JeeAdvancedExamSummary";
import { submitAssignment } from "../Utils/JeeAdvancedTestTaking";

const JeeAdvancedTestSubmissionPage = ({
  examSummaryData,
  finalPage,
  assessmentId,
  handleCancelClick,
  handleExamSubmission,
  webSocket,
  paperTitle,
}) => {
  const [postTestScreen, enablePostTestScreen] = React.useState(false);
  const assignmentObject = useSelector((state) => state.assignment);
  let isSecondPaper = false;
  if (
    assignmentObject &&
    assignmentObject.assignment &&
    assignmentObject.assignment.assessment
  ) {
    isSecondPaper =
      assignmentObject.assignment.assessment.title === "Paper - 2";
  }

  const dispatch = useDispatch();

  const onTestSubmission = () => {
    const assignmentId = assignmentObject.assignment.id;
    submitAssignment(dispatch, assignmentId);
    handleExamSubmission();
    enablePostTestScreen(true);
  };

  const olapViewCallback = (e) => {
    postTestViewCallback(
      finalPage,
      postTestScreen,
      assessmentId,
      "JeeAdvanced",
      e
    );
  };
  return (
    <Grid>
      {finalPage && (
        <PostTestScreen
          postTestViewCallback={olapViewCallback}
          webSocket={webSocket}
          isJeeAdvanced
          isSecondPaper={isSecondPaper}
        />
      )}
      {!finalPage && (
        <>
          {postTestScreen && (
            <PostTestScreen
              postTestViewCallback={olapViewCallback}
              webSocket={webSocket}
              isJeeAdvanced
              isSecondPaper={isSecondPaper}
            />
          )}
          {!postTestScreen && (
            <JeeAdvancedExamSummary
              examData={examSummaryData}
              onSubmitExam={onTestSubmission}
              onSubmitCancel={handleCancelClick}
              postTestViewCallback={postTestViewCallback}
              paperTitle={paperTitle}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default JeeAdvancedTestSubmissionPage;
