import {
  SET_INTERNET_CONNECTION_DISCONNECTED,
  SET_INTERNET_CONNECTION_RESTORED,
} from "./InternetConnection.types";

const initialState = {
  isOnline: true,
};

const InternetConnectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTERNET_CONNECTION_DISCONNECTED:
      return { isOnline: false };
    case SET_INTERNET_CONNECTION_RESTORED:
      return { isOnline: true };
    default:
      return state;
  }
};

export default InternetConnectionReducer;
