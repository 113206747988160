export default {
  aits: [
    {
      aits_name: "Jee Main 23092020 2nd",
      rank: 537920,
      percentage_score: "14.0",
      percentile: "64.139",
      started_at: "2020-09-23T13:05:21.000+05:30",
      marks_obtained: "11.0",
      total_marks: "75.0",
      total_questions: 75,
      correct: 11,
      incorrect: 64,
      skipped: 0,
      assignment_id: "28651f69-e3be-4749-b7fa-003c1b3049e9",
      percentage_increase: 0,
    },
    {
      total_questions: 57,
      correct: 41,
      incorrect: 16,
      skipped: 0,
      aits_name: "JEE Main Test 23092020 3rd",
      rank: 25,
      percentage_score: "67.0",
      percentile: "97.499",
      started_at: "2020-09-23T21:19:31.000+05:30",
      marks_obtained: "155.0",
      total_marks: "228.0",
      assignment_id: "7bf4b458-d5ae-40ab-9baa-0e927b5a5b2d",
      percentage_increase: 1,
    },
    {
      aits_name: "NEET Test 24092020",
      rank: 812002,
      percentage_score: "33.0",
      percentile: "42.0",
      started_at: "2020-09-24T15:44:22.000+05:30",
      marks_obtained: "100.0",
      total_marks: "300.0",
      total_questions: 75,
      correct: 27,
      incorrect: 10,
      skipped: 38,
      assignment_id: "a116e6e4-cb3b-4d3f-b4f8-a1b719bce056",
      percentage_increase: -1,
    },
    {
      total_questions: 75,
      correct: 27,
      incorrect: 28,
      skipped: 20,
      aits_name: "JEE Main 2509",
      rank: 231,
      percentage_score: "36.0",
      percentile: "76.899",
      started_at: "2020-09-25T08:44:08.000+05:30",
      marks_obtained: "27.0",
      total_marks: "75.0",
      assignment_id: "55fdff6c-da62-4799-b049-94406abd3f04",
      percentage_increase: 1,
    },
    {
      total_questions: 75,
      correct: 3,
      incorrect: 3,
      skipped: 69,
      aits_name: "JEE Main 25092020",
      rank: 320010,
      percentage_score: "3.0",
      percentile: "67.999",
      started_at: "2020-09-25T13:20:11.000+05:30",
      marks_obtained: "10.0",
      total_marks: "300.0",
      assignment_id: "e8474f9f-03c1-4833-a789-f3ceb9c30a3a",
      percentage_increase: -1,
    },
    {
      total_questions: 65,
      correct: 8,
      incorrect: 25,
      skipped: 32,
      aits_name: "JEE main 01102020",
      rank: 306,
      percentage_score: "4.63",
      percentile: "69.399",
      started_at: "2020-10-01T13:24:40.000+05:30",
      marks_obtained: "12.0",
      total_marks: "259.0",
      assignment_id: "14d4c3e7-e0ce-4e90-9ed9-601d216b64a8",
      percentage_increase: 1,
    },
    {
      aits_name: "Sanity 03 for JEE main 061020",
      rank: 251,
      percentage_score: "30.67",
      percentile: "74.899",
      started_at: "2020-10-06T09:57:37.000+05:30",
      marks_obtained: "23.0",
      total_marks: "75.0",
      total_questions: 75,
      correct: 23,
      incorrect: 19,
      skipped: 33,
      assignment_id: "d6d9893e-aab6-43df-aa3d-dead594795b7",
      percentage_increase: 1,
    },
    {
      total_questions: 75,
      correct: 8,
      incorrect: 13,
      skipped: 54,
      aits_name: "Sanity 03 for NEET 061020",
      rank: 1193993,
      percentage_score: "10.67",
      percentile: "14.715",
      started_at: "2020-10-06T09:59:26.000+05:30",
      marks_obtained: "8.0",
      total_marks: "75.0",
      assignment_id: "fe828ea5-d47f-4809-bc64-d45c03c32c95",
      percentage_increase: -1,
    },
    {
      aits_name: "Sanity 04 for JEE main 061020",
      rank: 107,
      percentage_score: "29.0",
      percentile: "89.249",
      started_at: "2020-10-06T15:23:13.000+05:30",
      marks_obtained: "87.0",
      total_marks: "300.0",
      total_questions: 75,
      correct: 27,
      incorrect: 28,
      skipped: 20,
      assignment_id: "985c2e14-68f2-400c-b56a-9229516a4113",
      percentage_increase: 1,
    },
    {
      total_questions: 75,
      correct: 24,
      incorrect: 22,
      skipped: 29,
      aits_name: "Sanity 04 for NEET 061020",
      rank: 911993,
      percentage_score: "24.67",
      percentile: "34.858",
      started_at: "2020-10-06T15:25:56.000+05:30",
      marks_obtained: "74.0",
      total_marks: "300.0",
      assignment_id: "34b6c02d-72f9-4ab5-b34f-c7878b0effd1",
      percentage_increase: -1,
    },
    {
      aits_name: "JEE Adv Test 27102020 2nd",
      rank: 11,
      percentage_score: "38.37",
      percentile: "99.249",
      started_at: "2020-10-29T19:13:47.000+05:30",
      marks_obtained: "155.0",
      total_marks: "404.0",
      total_questions: 54,
      correct: 37,
      incorrect: 17,
      skipped: 0,
      assignment_id: "2a37a39e-fc92-4bd2-aa50-03b705053e19",
      percentage_increase: 1,
    },
  ],
  staticData: true,
};
