import React, { useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import api from "../../../services/api";
import {
  PRODUCTION_DATA,
  STAGING_DATA,
  DEVELOPMENT_DATA,
  EXAM_TYPES,
} from "../../../AppConfig";
import { showErrorPage } from "../../Utils/CommonFunctions";
import Loader from "../Loader/Loader";

function Authenticate() {
  const location = useLocation();
  const [skipOAuth, setSkipOAuth] = React.useState(false);
  const [isBnat, enableBnat] = React.useState(false);
  const [bnatGrade, setBnatGrade] = React.useState();

  useEffect(() => {
    let OAuth;
    if (process.env.REACT_APP_BUILD_ENV === "staging") {
      OAuth = STAGING_DATA.OAUTH_URL;
    } else if (process.env.REACT_APP_BUILD_ENV === "production") {
      OAuth = PRODUCTION_DATA.OAUTH_URL;
    } else {
      OAuth = DEVELOPMENT_DATA.OAUTH_URL;
    }
    const authenticate = () => {
      const param = new URLSearchParams(location.search);
      const token = param.get("access_token");
      const assessmentType = param.get("assessment_type");
      const isFreeUser = param.get("is_free_user");
      const isQuiz = param.get("is_quiz");
      const grade = param.get("grade");

      if (grade !== null && grade !== "null") {
        setBnatGrade(grade);
        sessionStorage.setItem("bnat_grade", grade);
      }
      localStorage.setItem("user_token", token);

      if (isFreeUser === "true") {
        localStorage.setItem("is_free_user", isFreeUser);
        const userAssignmentId = param.get("user_assignment_id");
        localStorage.setItem("user_assignment_id", userAssignmentId);
        if (EXAM_TYPES.BNAT === assessmentType) {
          enableBnat(true);
        } else {
          setSkipOAuth(true);
        }
      } else {
        api
          .get(
            `/authenticate?access_token=${token}${
              assessmentType !== null
                ? `&assessment_type=${assessmentType}`
                : ""
            }`
          )
          .then((resp) => {
            if (resp.status > 399) {
              showErrorPage(resp.status);
            } else if (resp.data.redirect_url.length > 0) {
              window.location.replace(resp.data.redirect_url);
            } else if (resp.data.assignment_id === "") {
              sessionStorage.setItem("destination", "comprehensive");
              window.location.replace(OAuth);
            } else {
              localStorage.setItem(
                "user_assignment_id",
                resp.data.assignment_id
              );
              if (isQuiz === "true") {
                sessionStorage.setItem("destination", "quiz_result");
              } else {
                sessionStorage.setItem("destination", "test");
              }
              window.location.replace(OAuth);
            }
          });
      }
    };

    authenticate();
  }, [location.search]);

  return (
    <div>
      {skipOAuth === true && <Redirect to="/test-analysis" />}
      {isBnat === true && (
        <Redirect
          to={`/${
            parseInt(bnatGrade, 10) === 15
              ? EXAM_TYPES.BNST.toLowerCase()
              : EXAM_TYPES.BNAT.toLowerCase()
          }-result`}
        />
      )}
      {skipOAuth === false && isBnat === false && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
    </div>
  );
}

export default Authenticate;
