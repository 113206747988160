import api from "./api";
import { showErrorPage } from "../components/Utils/CommonFunctions";

const generateAssessment = async ({ assessmentId }) => {
  try {
    api.setHeader("Authorization", localStorage.getItem("user_token"));
    const response = await api.post("assignments/generate", {
      assessment_id: assessmentId,
    });
    if (response.status > 399) {
      return showErrorPage(response.status);
    }
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export default generateAssessment;
