import api from "./api";

function* submitTest(assignmentId) {
  let submitStatus;
  yield api
    .post(`/assignments/${assignmentId}/submit`)
    .then((response) => response.data)
    .then((json) => {
      if (json.message === "Assignment Submitted") {
        submitStatus = true;
      } else if (
        json.message === "This assignment is no longer available to submit"
      ) {
        submitStatus = true;
      } else if (json.message === "Assignment not found") {
        submitStatus = "Invalid assignment";
      }
    });
  return submitStatus;
}

export default submitTest;
