import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import BoxContainer from "../Box-Container/BoxContainer";
import { COLORS } from "../Utils/Constants";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: COLORS.ADVANCED_ACCENT,
    marginBottom: "100px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  },
  header: {
    padding: "12px 16px",
    margin: 0,
    backgroundColor: COLORS.ADVANCED_MAIN,
    color: COLORS.WHITE,
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  navigator: {
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

const QuestionNavigator = ({
  section,
  questionData,
  onAttempt,
  currentQuestion,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h2 className={classes.header}>{section}</h2>
      <div className={classes.navigator}>
        <p>Choose a Question</p>
        <BoxContainer
          size={40}
          currentQuestion={currentQuestion}
          data={questionData}
          onAttempt={onAttempt}
        />
      </div>
    </div>
  );
};

QuestionNavigator.propTypes = {
  section: PropTypes.string,
  questionData: PropTypes.arrayOf({
    questionNumber: PropTypes.number,
    status: PropTypes.string,
  }),
};

QuestionNavigator.defaultProps = {
  section: "",
  questionData: [],
};

export default QuestionNavigator;
