import { takeLatest, put } from "redux-saga/effects";
import { showErrorPage } from "../components/Utils/CommonFunctions";

import {
  UPDATE_MOCK_TEST_DETAIL,
  FETCH_MOCK_TEST_DETAIL,
} from "../redux/MockTest/MockTest.types";
import getMockTestData from "../services/MockTest.api";

function* fetchMockTestDetail() {
  const response = yield getMockTestData();
  if (response.status !== 200) {
    showErrorPage(response.status);
  } else {
    const payload = response.data;
    yield put({ type: UPDATE_MOCK_TEST_DETAIL, payload });
  }
}

function* MockTestSaga() {
  yield takeLatest(FETCH_MOCK_TEST_DETAIL, fetchMockTestDetail);
}

export default MockTestSaga;
