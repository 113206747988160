import React from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import { ICONS } from "../../AppConfig";
import { COLORS } from "../Utils/Constants";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: 12,
  },
  pStyled: {
    marginBottom: "0px",
    marginTop: 2,
  },
  container: {
    backgroundColor: ({ type }) => {
      return type === "advanced" ? COLORS.WHITE : COLORS.EXTRA_LIGHT_GREY;
    },
    padding: 20,
    fontSize: "16px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10.5px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "8px",
    },
  },
  profilePic: {
    width: "84px",
    [theme.breakpoints.down("xs")]: {
      width: "52px",
    },
  },
  pill: {
    backgroundColor: "#1899db",
    borderRadius: 20,
    padding: "2px 8px",
    color: "white",
    minWidth: 30,
  },
  details: {
    color: "#f59331",
    marginBottom: 0,
    marginTop: 0,
    fontWeight: "bold",
    marginLeft: 5,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "calc(100% - 210px)",
    [`${theme.breakpoints.down("xs")}`]: {
      width: "calc(100% - 100px)",
    },
  },
  padLeft: {
    paddingLeft: 20,
  },
  keys: {
    [theme.breakpoints.down("xs")]: {
      width: "90px",
    },
    paddingBottom: 6,
  },
  keyContainer: {
    marginLeft: "10px",
    width: "calc(100% - 94px)",
    [`${theme.breakpoints.down("xs")}`]: {
      width: "calc(100% - 62px)",
    },
  },
}));

const UserDetail = ({
  candidateName,
  exam,
  subject,
  remaining,
  picture,
  type,
}) => {
  const classes = useStyles({ type });
  return (
    <Grid
      direction="row"
      container
      alignItems="center"
      className={classes.container}
    >
      <Grid container direction="row">
        <Grid>
          <img
            src={picture === "placeholder" ? ICONS.USER_PLACEHOLDER : picture}
            className={classes.profilePic}
            alt={exam}
          />
        </Grid>
        <Grid className={classes.keyContainer}>
          {type !== "advanced" ? (
            <>
              <Grid container direction="row">
                <Grid className={classes.keys}>Candidate Name</Grid>
                <Grid>:</Grid>
                <Grid className={classes.details}>{candidateName}</Grid>
              </Grid>
              <Grid container direction="row">
                <Grid className={classes.keys}>Exam Name</Grid>
                <Grid>:</Grid>
                <Grid className={classes.details}>{exam}</Grid>
              </Grid>
              <Grid container direction="row">
                <Grid className={classes.keys}>Subject Name</Grid>
                <Grid>:</Grid>
                <Grid className={classes.details}>{subject}</Grid>
              </Grid>
              <Grid container direction="row">
                <Grid className={classes.keys}>Time Remaining</Grid>
                <Grid>:</Grid>
                <Grid className={classes.details}>
                  <span className={classes.pill}>{remaining}</span>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid className={classes.keys}>{candidateName}</Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

UserDetail.propTypes = {
  candidateName: PropTypes.string.isRequired,
  exam: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  remaining: PropTypes.string.isRequired,
  picture: PropTypes.string,
  type: PropTypes.string,
};

UserDetail.defaultProps = {
  picture: "placeholder",
  type: "",
};

export default UserDetail;
