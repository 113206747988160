const gradeCohortKey = {
  4: "BNAT4-5",
  5: "BNAT4-5",
  6: "BNAT6-7",
  7: "BNAT6-7",
  8: "BNAT8-9",
  9: "BNAT8-9",
  10: "BNAT10",
  14: "BNAT10moving",
  "11JEE": "BNAT11JEE",
  "11NEET": "BNAT11NEET",
  "11JEE+NEET": "BNAT11JEE+NEET",
  "12JEE": "BNAT12-13JEE",
  "13JEE": "BNAT12-13JEE",
  "12NEET": "BNAT12-13NEET",
  "13NEET": "BNAT12-13NEET",
  "12JEE+NEET": "BNAT12-13JEE+NEET",
  "13JEE+NEET": "BNAT12-13JEE+NEET",
  "15Hindi": "BNATIASHindi",
  "15English": "BNATIASEnglish",
};

const fetchGradeCohortDetails = ({ grade, cohort, medium }) => {
  if (grade === 15) {
    if (medium === "Hindi") {
      return gradeCohortKey[`${grade}Hindi`];
    }
    return gradeCohortKey[`${grade}English`];
  }
  if (grade === 14 || grade < 11) {
    return gradeCohortKey[grade];
  }
  return gradeCohortKey[grade + cohort];
};

export default fetchGradeCohortDetails;
