import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../../assets/stylesheets/MockDetail.css";
import syllabusData from "../../assets/js/syllabus";
import { EXAM_TYPES, LANGUAGE } from "../../AppConfig";
import testDuration from "../../assets/js/duration";
import initiateIntersectionObserver from "../Utils/IntersectionObserver";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: "#6d7278",
    fontSize: "2rem",
    fontWeight: "600",
    marginBottom: "1.5rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  OrngClr: {
    color: "#c254f5",
    fontFamily: '"GothamBold"',
  },
  resTxt: {
    fontWeight: "700",
    fontFamily: '"GothamBold"',
  },
  refreshMsg: {
    fontSize: "0.8rem",
  },
  reportBtn: {
    padding: "25px",
  },
  subjectTxt: {
    color: "#c254f5",
    fontWeight: 600,
    marginBottom: "0.5rem",
    fontSize: "0.8rem",
    fontFamily: '"GothamBold"',
  },
  subChapter: {
    marginBottom: "0.5rem",
    fontSize: "0.8rem",
  },
  grade: {
    marginBottom: "0.5rem",
    color: "#c254f5",
    fontFamily: '"GothamBold"',
  },
  chapterTitle: {
    marginTop: "15px",
    paddingBottom: "0.5rem",
    fontSize: "1.5rem",
    borderBottom: "1px solid #d8d8d8",
    marginBottom: "1rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  subSubject: {
    fontSize: "1rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));

export default function SyllabusSection({
  examType,
  meta,
  language,
  setActiveNav,
}) {
  const classes = useStyles();
  let data;
  let duration = "";
  if (examType === EXAM_TYPES.JEE_MAIN) {
    data = syllabusData.mockTestJee;
  } else if (examType === EXAM_TYPES.NEET) {
    data = syllabusData.mockTestNeet;
  } else if (examType === EXAM_TYPES.BITSAT) {
    data = syllabusData.mockTestBitsat;
  } else if (examType === EXAM_TYPES.BNAT) {
    data = syllabusData[meta];
    duration = testDuration[meta];
  }

  const renderSyllabus = (syllabus) => (
    <>
      {syllabus.map((syl) => (
        <div key={syl.sub}>
          <Typography className={classes.chapterTitle}>{syl.sub}</Typography>
          {syl.classAndchapters &&
            syl.classAndchapters.map((cdata) => (
              <div key={cdata.class}>
                {cdata.class !== null && (
                  <Typography className={classes.grade}>
                    {`Class ${cdata.class}`}
                  </Typography>
                )}
                <Typography className={classes.subChapter}>
                  {typeof cdata.chapters === "string" && cdata.chapters}
                  {typeof cdata.chapters !== "string" &&
                    cdata.chapters.map((subSubject) => (
                      <>
                        <Typography className={classes.subSubject}>
                          {subSubject.section}
                        </Typography>
                        <Typography className={classes.subChapter}>
                          {subSubject.chapters}
                        </Typography>
                      </>
                    ))}
                </Typography>
              </div>
            ))}
        </div>
      ))}
    </>
  );

  React.useEffect(() => {
    initiateIntersectionObserver(
      document.querySelector("#syllabusSection"),
      0.6,
      setActiveNav,
      "2"
    );
  }, [setActiveNav]);
  return (
    <div id="syllabusSection">
      <Typography variant="h4" className={classes.sectionTitle}>
        {language === LANGUAGE.HINDI
          ? `पाठ्यक्रम ${duration}`
          : `Syllabus ${duration}`}
      </Typography>
      {renderSyllabus(data)}
    </div>
  );
}
