import black from "./assets/fonts/GothamSSm-Black.otf";
import blackItalic from "./assets/fonts/GothamSSm-BlackItalic.otf";
import bold from "./assets/fonts/GothamSSm-Bold.otf";
import boldItalic from "./assets/fonts/GothamSSm-BoldItalic.otf";
import book from "./assets/fonts/GothamSSm-Book.otf";
import bookItalic from "./assets/fonts/GothamSSm-BookItalic.otf";
import light from "./assets/fonts/GothamSSm-Light.otf";
import lightItalic from "./assets/fonts/GothamSSm-LightItalic.otf";
import medium from "./assets/fonts/GothamSSm-Medium.otf";
import mediumItalic from "./assets/fonts/GothamSSm-MediumItalic.otf";
import xLight from "./assets/fonts/GothamSSm-XLight.otf";
import xLightItalic from "./assets/fonts/GothamSSm-XLightItalic.otf";
import error400 from "./assets/icons/400_2x.png";
import error401 from "./assets/icons/401_2x.png";
import error403 from "./assets/icons/403_2x.png";
import error404 from "./assets/icons/404_2x.png";
import error406 from "./assets/icons/406_2x.png";
import error500 from "./assets/icons/500_2x.png";
import answered from "./assets/icons/answered.svg";
import answeredAdvanced from "./assets/icons/answeredAdvanced.svg";
import arrowCircle from "./assets/icons/arrowCircle.svg";
import backArrow from "./assets/icons/backArrow.svg";
import bookmark from "./assets/icons/bookmark.svg";
import bookmarkHighlighted from "./assets/icons/bookmark_highlighted.svg";
import chevron from "./assets/icons/chevron.svg";
import close from "./assets/icons/close.svg";
import closeOverlay from "./assets/icons/closeOverlay.svg";
import correct from "./assets/icons/Correct.svg";
import cup from "./assets/icons/cup.png";
import cupSvg from "./assets/icons/cup.svg";
import cup2x from "./assets/icons/cup_2x.png";
import cup3x from "./assets/icons/cup_3x.png";
import desktopLogo from "./assets/icons/desktopLogo.png";
import desktopLogo2x from "./assets/icons/desktopLogo2x.png";
import desktopLogo3x from "./assets/icons/desktopLogo3x.png";
import done from "./assets/icons/done.svg";
import favicon from "./assets/icons/favicon.svg";
import feedback from "./assets/icons/feedback.svg";
import hideShow from "./assets/icons/hide.svg";
import hourGlass from "./assets/icons/Hourglass.svg";
import incorrect from "./assets/icons/Incorrect.svg";
import info from "./assets/icons/info.svg";
import infoItalic from "./assets/icons/infoItalic.svg";
import markedReview from "./assets/icons/markedReview.svg";
import markedReviewAnswered from "./assets/icons/markedReviewAnswered.svg";
import mobileLogo from "./assets/icons/mobileLogo.png";
import mobileLogo2x from "./assets/icons/mobileLogo2x.png";
import mobileLogo3x from "./assets/icons/mobileLogo3x.png";
import notAnswered from "./assets/icons/notAnswered.svg";
import notAnsweredAdvanced from "./assets/icons/notAnsweredAdvanced.svg";
import notes from "./assets/icons/notes.svg";
import notesHighlighted from "./assets/icons/notes_highlighted.svg";
import notification from "./assets/icons/notification.svg";
import notificationHighlighted from "./assets/icons/notification_highlighted.svg";
import notVisited from "./assets/icons/notVisited.svg";
import notVisitedLarge from "./assets/icons/notVisitedLarge.svg";
import errorInternet from "./assets/icons/no_internet_2x.png";
import skipped from "./assets/icons/Skipped.svg";
import tick from "./assets/icons/tick.svg";
import trash from "./assets/icons/trash.svg";
import trophy from "./assets/icons/trophy.png";
import trophy2x from "./assets/icons/trophy_2x.png";
import trophy3x from "./assets/icons/trophy_3x.png";
import userPlaceholder from "./assets/icons/userPlaceholder.svg";
import notificationCenterIcon from "./assets/icons/NotificationCenter.png";
import whatsAppNotification from "./assets/icons/Whatsapp.png";

export const PRODUCTION_DATA = {
  BASE_URL: "https://api-assess.tllms.com/api/v1",
  LEARN_URL: "https://learn.byjus.com",
  OAUTH_URL:
    "https://students.byjus.com/oauth/authorize?client_id=26997b79aaea3a6120d84246fe9da82db5bf0ad4a2c1bc4303600c4405484ec3&redirect_uri=https%3A%2F%2Fassess.byjus.com%2Fredirect&response_type=token&scope=profile",
  WEBTECH_JEE_MOCK_TEST_URL: "https://byjus.com/jee-mock-test",
  WEBTECH_NEET_MOCK_TEST_URL: "https://byjus.com/neet-mock-test",
  WEBTECH_BITSAT_MOCK_TEST_URL: "https://byjus.com/bitsat-mock-test",
  OLAP_APP_ID: 17,
  OLAP_END_POINT: "https://api.tllms.com/1/stats/web/web.counter",
  PROFILE_API_TLLMS: "https://tllms.com/web/v1/me?access_token=",
  WEB_SOCKET_SERVER:
    "wss://wcgk3tqsmd.execute-api.ap-south-1.amazonaws.com/production",
};

export const STAGING_DATA = {
  BASE_URL: "https://api-assess-stage.tllms.com/api/v1",
  LEARN_URL: "https://learn-staging.byjus.com",
  WEBTECH_JEE_MOCK_TEST_URL: "https://qa.byjusweb.com/jee-mock-test",
  WEBTECH_NEET_MOCK_TEST_URL: "https://qa.byjusweb.com/neet-mock-test",
  WEBTECH_BITSAT_MOCK_TEST_URL: "https://qa.byjusweb.com/bitsat-mock-test",
  OLAP_APP_ID: 117,
  OLAP_END_POINT: "https://api.tllms.com/1/stats/web/web.counterstage",
  OAUTH_URL:
    "https://students-staging.byjus.com/oauth/authorize?client_id=d101f83a6338e41358c5683870215fc23c3dc2094d79be38e04f5494a2262601&redirect_uri=https%3A%2F%2Fassess-stage.tllms.com%2Fredirect&response_type=token&scope=profile",
  PROFILE_API_TLLMS: "https://staging.tllms.com/web/v1/me?access_token=",
  WEB_SOCKET_SERVER:
    "wss://wcgk3tqsmd.execute-api.ap-south-1.amazonaws.com/staging",
};

export const DEVELOPMENT_DATA = {
  BASE_URL: "https://api.assessdev.com/api/v1",
  LEARN_URL: "https://learn-staging.byjus.com",
  WEBTECH_JEE_MOCK_TEST_URL: "https://dev.byjusweb.com/jee-mock-test",
  WEBTECH_NEET_MOCK_TEST_URL: "https://dev.byjusweb.com/neet-mock-test",
  WEBTECH_BITSAT_MOCK_TEST_URL: "https://dev.byjusweb.com/bitsat-mock-test",
  OLAP_APP_ID: 117,
  OLAP_END_POINT: "https://api.tllms.com/1/stats/web/web.counterstage",
  OAUTH_URL:
    "https://students-staging.byjus.com/oauth/authorize?client_id=d101f83a6338e41358c5683870215fc23c3dc2094d79be38e04f5494a2262601&redirect_uri=https%3A%2F%2Fassess-stage.tllms.com%2Fredirect&response_type=token&scope=profile",
  PROFILE_API_TLLMS: "https://staging.tllms.com/web/v1/me?access_token=",
  WEB_SOCKET_SERVER:
    "wss://wr6s1g6qmj.execute-api.ap-south-1.amazonaws.com/development",
};

export const ICONS = {
  ARROW_CIRCLE: arrowCircle,
  NOT_VISITED: notVisited,
  NOT_VISITED_LARGE: notVisitedLarge,
  NOT_ANSWERED: notAnswered,
  NOT_ANSWERED_ADVANCED: notAnsweredAdvanced,
  USER_PLACEHOLDER: userPlaceholder,
  ANSWERED: answered,
  INFO_ITALIC: infoItalic,
  ANSWERED_ADVANCED: answeredAdvanced,
  MARKED_REVIEW: markedReview,
  MARKED_REVIEW_ANSWERED: markedReviewAnswered,
  BOOKMARK: bookmark,
  BOOKMARK_HIGHLIGHTED: bookmarkHighlighted,
  NOTES: notes,
  NOTES_HIGHLIGHTED: notesHighlighted,
  NOTIFICATION: notification,
  NOTIFICATION_HIGHLIGHTED: notificationHighlighted,
  MOBILE_LOGO: mobileLogo,
  MOBILE_LOGO_2X: mobileLogo2x,
  MOBILE_LOGO_3X: mobileLogo3x,
  DESKTOP_LOGO: desktopLogo,
  DESKTOP_LOGO_2X: desktopLogo2x,
  DESKTOP_LOGO_3X: desktopLogo3x,
  CORRECT: correct,
  INCORRECT: incorrect,
  SKIPPED: skipped,
  CHEVRON: chevron,
  CUP_SVG: cupSvg,
  CUP: cup,
  CUP_2X: cup2x,
  CUP_3X: cup3x,
  FAVICON: favicon,
  TRASH: trash,
  BACK_ARROW: backArrow,
  CLOSE_OVERLAY: closeOverlay,
  CLOSE: close,
  DONE: done,
  TICK: tick,
  FEEDBACK: feedback,
  INFO: info,
  HIDE_SHOW: hideShow,
  WHATSAPP: "https://cdn1.byjus.com/byjusweb/img/bnat/whatsapp.svg",
  FACEBOOK: "https://cdn1.byjus.com/byjusweb/img/bnat/facebook.svg",
  TWITTER: "https://cdn1.byjus.com/byjusweb/img/bnat/twitter.svg",
  TELEGRAM: "https://cdn1.byjus.com/byjusweb/img/bnat/telegram.svg",
  SMS: "https://cdn1.byjus.com/byjusweb/img/bnat/message.svg",
  HOURGLASS: hourGlass,
  TROPHY: trophy,
  TROPHY_2X: trophy2x,
  TROPHY_3X: trophy3x,
  WHATSAPP_NOTIFiCATION: whatsAppNotification,
  NOTIFICATION_POPUP_ICON: notificationCenterIcon,
  ERROR_400: error400,
  ERROR_403: error403,
  ERROR_404: error404,
  ERROR_406: error406,
  ERROR_500: error500,
  ERROR_NO_NETWORK: errorInternet,
  ERROR_401: error401,
};

export const FONTS = {
  GothamSSm_Black: black,
  GothamSSm_BlackItalic: blackItalic,
  GothamSSm_Bold: bold,
  GothamSSm_BoldItalic: boldItalic,
  GothamSSm_Book: book,
  GothamSSm_BookItalic: bookItalic,
  GothamSSm_Light: light,
  GothamSSm_LightItalic: lightItalic,
  GothamSSm_Medium: medium,
  GothamSSm_MediumItalic: mediumItalic,
  GothamSSm_XLight: xLight,
  GothamSSm_XLightItalic: xLightItalic,
};

export const TAG_COLORS = {
  PHYSICS: "#f75f1e",
  CHEMISTRY: "#2986ff",
  BIOLOGY: "#02b84d",
  MATHS: "#f2a001",
  CORRECT: "#0d970e",
  INCORRECT: "#da4844",
  SKIPPED: "#4e76d8",
  OTHER: "#a24fd6",
};

export const TRAIL_COLORS = {
  PHYSICS: "#fcdbd8",
  CHEMISTRY: "#baebf8",
  BIOLOGY: "#efffcb",
  MATHS: "#fff6c9",
  OTHER: "#EFD4F7",
  NOTIFICATION_DROPDOWN: "#52d869",
  MODAL: "#ecf7fe",
  BLACK_TWO: "#333333",
  PURPLE_LIGHT: "#8f35df",
  PURPLE_REGULAR: "#c254f5",
  LIGHT_GREY: "#ecf7fe",
  DARK_GREY: "#797878",
  REGULAR_GREY: "#cecccc",
  GREEN_REGULAR: "#52d869",
  WHITE: "#ffffff",
};

export const GRADIENT_COLORS = {
  PHYSICS: { gradientStart: "#ff9231", gradientEnd: "#fc6076" },
  CHEMISTRY: { gradientStart: "#00beeb", gradientEnd: "#007ced" },
  BIOLOGY: { gradientStart: "#92d108", gradientEnd: "#4e9b01" },
  MATHS: { gradientStart: "#ffd200", gradientEnd: "#fc7e00" },
  OTHER: { gradientStart: "#cb71e7", gradientEnd: "#655fff" },
};

export const TIME_TAKEN_COLORS = {
  ABOVE_AVERAGE: "#ecca73",
  BELOW_AVERAGE: "#c3eeaf",
  NA: "#d2d2d2",
};

export const TEST_TAKING_COLORS = {
  LIGHT_BLUE: "#b1eaf7",
  CYAN_BLUE: "#cae3fa",
  LIGHT_GREY: "#dddddd",
  WHITE: "#ffffff",
};

export const COLORS = {
  WHITE: "#ffffff",
};

export const MOCK_TEST_CONSTANTS = {
  JEE_CUSTOMER_SUPPORT_TELEPHONE: "7829211116",
  JEE_CUSTOMER_SUPPORT_EMAIL: "mocktest@byjus.com",
  JEE_YOUTUBE_CHANNEL:
    "https://www.youtube.com/channel/UC82ObtDCmUuWwrWhpQsX-HQ",
  NEET_YOUTUBE_CHANNEL:
    "https://www.youtube.com/channel/UCszNnMo0djZHNnldrbo-u4w",
  BITSAT_YOUTUBE_CHANNEL:
    "https://www.youtube.com/channel/UC82ObtDCmUuWwrWhpQsX-HQ",
};

export const BNAT_TEST_CONSTANTS = {
  BNAT_CUSTOMER_SUPPORT_TELEPHONE: "7829211116",
  BNAT_CUSTOMER_SUPPORT_EMAIL: "bnatqueries@byjus.com",
  BNAT_IAS_CUSTOMER_SUPPORT_EMAIL: "byjusias@byjus.com",
  BNAT_IAS_CUSTOMER_SUPPORT_TELEPHONE: "7829211117",
  JEE_YOUTUBE_CHANNEL:
    "https://www.youtube.com/channel/UC82ObtDCmUuWwrWhpQsX-HQ",
  NEET_YOUTUBE_CHANNEL:
    "https://www.youtube.com/channel/UCszNnMo0djZHNnldrbo-u4w",
  OTHER_YOUTUBE_CHANNEL:
    "https://www.youtube.com/channel/UCkDw-LPU1Nnd2WRsfnDbUcA",
};

export const SOCIAL_SHARE = {
  TITLE: "BYJU'S All-India Mock Test",
  JEE_MOCK_TEST_BASE_URL: "https://byjus.com/jee-mock-test?",
  NEET_MOCK_TEST_BASE_URL: "https://byjus.com/neet-mock-test?",
  WHATSAPP_MOBILE_URL: "https://api.whatsapp.com/send?text=",
  WHATSAPP_WEB_URL: "https://web.whatsapp.com/send?text=",
  FACEBOOK_URL: "https://www.facebook.com/sharer.php?u=",
  TWITTER_URL: "https://twitter.com/intent/tweet?url=",
  TELEGRAM_URL: "https://telegram.me/share/url?url=",
  SMS_URL: "sms:?body=",
  BITSAT_MOCK_TEST_BASE_URL: "https://byjus.com/bitsat-mock-test?",
  BNAT_K10_BASE_URL: "https://byjus.com/bnat-k10?",
  BNAT_K12_BASE_URL: "https://byjus.com/bnat?",
  BNAT_IAS_BASE_URL: "https://byjus.com/bnst-ias?",
};

export const WEB_SOCKET = {
  NEW_SESSION_MESSAGE: "new session registered",
  REGISTER_ACTION_STRING: "register",
  HEARTBEAT_ACTION_STRING: "heartbeat",
  SUBMIT_ACTION_STRING: "submit",
  POPUP_MESSAGE:
    "This test is active at another location as well. Do you want to continue here?",
  POPUP_SUB_MESSAGE:
    "(On selecting No, you can only continue taking the test at the previous location)",
  HEART_BEAT_TIME: 540,
  SOCKET_TIMEOUT_TIME: 7100,
};

export const NOTIFICATION_MODAL = {
  NOTIFICATION_POP_UP:
    "Do you want to be notified of your upcoming AITS Date & Time on your WhatsApp number",
  NOTIFICATION_POPUP_FINAL_MESSAGE:
    "You have successfully subscribed to receive AITS updates over WhatsApp. You can change your settings later by going to the 'Notification dropdown 🔔' menu. Due to approval guidelines the first message might take few days to get delivered.",
  NOTIFICATION_DROPDOWN: "Let us know where would you like to be contacted",
};

export const STREAMING_CONFIG = {
  bufferingGoal: 6,
  bufferBehind: 1,
  switchInterval: 4,
};

export const ABR_CONFIG = {
  restrictions: {
    minBandwidth: 500000,
  },
};

export const NETWORK = {
  OFFLINE_MESSAGE:
    "You appear to be offline. Please check your internet connection settings and then reload the page.",
};

export const EXAM_TYPES = {
  BITSAT: "Bitsat",
  NEET: "Neet",
  JEE_MAIN: "JeeMain",
  BNAT: "Bnat",
  JEE_ADVANCED: "JeeAdvanced",
  BNST: "Bnst",
};

export const BITSAT = {
  ELIGIBLE_EXTRA_QUESTIONS_MESSAGE: `<p>You have answered all the questions. There is still some time left. You have the following options now:</p>
  <ol style="text-align: left"}>
    <li><span style="font-weight:900;color:#010000">Start Extra Paper: </span>Note that you will not be allowed to go back to the Main Paper after starting</li><br>
    <li><span style="font-weight:900;color:#010000"}>Submit: </span>I have completed the test. Consider this for my final score</li><br>
    <li><span style="font-weight:900;color:#010000"}>Cancel: </span>Take me back to the Main Paper</li>
    </ol>
    `,
};

export const STATIC_TEXT = {
  RANK_TOOLTIP:
    "Projected Rank and Percentile are calculated by extrapolating your scores to the recent actual test data, and are indicators of how well you may perform therein",
  COMPREHENSIVE_ANALYSIS:
    "Comprehensive Analysis presents your performance metrics across AITSes on a page. SWOT section goes deeper into analysing both marks and time taken by you in attempting questions from underlying topics and subtopics. It further enumerates actionable areas of focus for you in order to help you build a well-suited exam strategy",
  SWOT_TITLE:
    "It is a detailed analysis of your performance and gives suggestions for improvements",
  DOING_WELL_TOOLTIP:
    "Specifies areas you have performed relatively well in this test as per marks secured. If these areas consistently appear in this category over multiple tests, you are expected to bank on these in the exams",
  NEEDS_IMPROVEMENT_TOOLTIP:
    "Showcases the areas from this test where your performance needs to improve. We recommend that you revise the underlying concepts and practice questions from these areas in order to score higher",
  OPPORTUNITY_TOOLTIP:
    "Highlights areas from the test in which on average your competitors scored higher. These areas are low-hanging fruits for you and relatively easier to score good marks in. You should try and recognize such questions when they appear in the exams and attempt them",
  THREAT_TOOLTIP:
    "Enumerates areas of threats for your performance as per the analysis of this test. Evaluation of threats considers only the time you have spent in the questions from these areas, which is more than you should have ideally. It is highly recommended for you to try and reduce the time taken by practicing more questions from these areas",
  NO_SWOT_ANALYSIS:
    "Your SWOT will not be evaluated for this assessment owing to no or few statistically significant data. Please check future assessments for SWOT analysis",
  BITSAT_SWOT_ANALYSIS_MESSAGE:
    ". Only questions from the Main Paper are considered for the SWOT Analysis. Extra Paper questions are not considered in this analysis",
};

export const LANGUAGE = {
  ENGLISH: "English",
  HINDI: "Hindi",
};

export const COHORT_TYPES = {
  JEE: "JEE",
  NEET: "NEET",
  JeeNeet: "JEE+NEET",
};
