import React from "react";
import Advanced from "../pages/Advanced";
import Mains from "../pages/Mains";

function TestTakingPageRouter() {
  document.title = "Byju's | Test";

  const assessmentType = sessionStorage.getItem("test_assessment_type");
  const regex = /advance/gi;
  const isAdvanced = regex.test(assessmentType);

  return (
    <>
      {isAdvanced && <Advanced />}
      {!isAdvanced && <Mains />}
    </>
  );
}

export default TestTakingPageRouter;
