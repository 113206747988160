import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReactHTMLParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    textAlign: "center",
  },
  msgText: {
    fontSize: "0.8rem",
    fontWeight: "500",
    fontStyle: "normal",
    color: "#333333",
    textAlign: "center",
    maxWidth: "350px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
    },
  },
  subMsgText: {
    fontSize: "0.5rem",
    fontWeight: "500",
    fontStyle: "oblique",
    color: "#333333",
    textAlign: "center",
    maxWidth: "350px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
  button1: {
    width: "60px",
    height: "32px",
    backgroundColor: "#307abf",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "15px",
    color: "#ffffff",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  button2: {
    marginLeft: "5px",
    height: "32px",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "15px",
    color: "#ffffff",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  button3: {
    margin: "2px",
    fontSize: "0.6rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.4rem",
    },
  },
  bitsatButtonContainer: {
    marginTop: "15px",
  },
}));
function PopUp({
  message,
  open,
  close,
  window,
  reload,
  cancelSession,
  subMessage,
  additionalQuestions,
  chooseSubmitBitsat,
  chooseExtraPaperBitsat,
}) {
  const classes = useStyles();
  return (
    <Dialog
      className="popUpContainer"
      disableBackdropClick={!!window || !!additionalQuestions}
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Card style={{ overflow: "scroll" }}>
        <CardContent className={classes.buttonContainer}>
          <ErrorOutlineIcon
            style={{ color: "#f7c58a", fontSize: "60px", marginTop: "0.8rem" }}
          />
          <h3>{additionalQuestions ? "Attention" : "Warning"}</h3>
          <p className={classes.msgText}>{ReactHTMLParser(message)}</p>
          {additionalQuestions && (
            <div className={classes.bitsatButtonContainer}>
              <Button
                className={classes.button3}
                onClick={close}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                className={classes.button3}
                onClick={chooseSubmitBitsat}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
              <Button
                className={classes.button3}
                onClick={chooseExtraPaperBitsat}
                variant="contained"
                style={{
                  backgroundColor: "#60b760",
                  color: "#ffffff",
                }}
              >
                Start Extra Paper
              </Button>
            </div>
          )}

          {window && !additionalQuestions && (
            <>
              <p className={classes.subMsgText}>{subMessage}</p>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button2}
                onClick={cancelSession}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button2}
                onClick={reload}
              >
                Use Here
              </Button>
            </>
          )}
          {!window && !additionalQuestions && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button1}
              onClick={close}
            >
              Ok
            </Button>
          )}
        </CardContent>
      </Card>
    </Dialog>
  );
}

export default PopUp;
