/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as NotificationTypes from "../../redux/NotificationCenter/NotificationCenter.types";

/**
 *
 * @param {Object} preferences
 */
export const formatNotification = (data) => {
  const preferences = Object.keys(data.preferences).map((item) => ({
    label: item,
    isEnabled: data.preferences[item],
  }));
  return { ...data, preferences };
};

/**
 *
 * @param {Array} preferences
 * @returns
 */
export const formatNotificationReverse = (preferences) => {
  if (preferences === null) {
    return { WHATSAPP: null };
  }
  const response = preferences.reduce((a, c) => {
    return { ...a, [c.label]: c.isEnabled };
  }, {});
  return response;
};

export function useNotificationState(isNotificationEnabled = false) {
  const [notificationPreferences, setNotificationPreferences] = useState([]);
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");
  /* Selectors */
  const notificationCenter = useSelector((state) =>
    state.notificationCenter ? state.notificationCenter.data : null
  );
  useEffect(() => {
    const { user_subscription_type } = user ? JSON.parse(user) : {};
    if (user_subscription_type === "premium_byjus_class_active_user") {
      if (isNotificationEnabled) {
        dispatch({ type: NotificationTypes.GET_NOTIFICATION_PREFERENCES });
      }
    }
  }, [user]);
  useEffect(() => {
    if (notificationCenter) {
      setNotificationPreferences(notificationCenter);
    }
  }, [notificationCenter]);
  return notificationPreferences;
}

export function useModalState(props) {
  const [modalState, setModalState] = useState(false);
  const { whatsapp_notification_popup } = props;
  const whatsappNotificationState = JSON.parse(
    sessionStorage.getItem("whatsapp_notification_popup")
  );

  useEffect(() => {
    if (whatsapp_notification_popup && whatsappNotificationState) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  }, [props]);
  return [modalState, setModalState];
}

export const NotificationLabels = {
  WHATSAPP: "WhatsApp",
  SMS: "SMS",
  EMAIL: "Email",
};
