import React, { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Redirect } from "react-router-dom";
import Button from "../Button/Button";
import { submitTestWebSocketAction } from "../Utils/WebSocket";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#ffffff",
    height: "100%",
  },
  primaryText: {
    textAlign: "center",
    margin: "40px 26px 26px",
    fontSize: "20px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.4px",
    color: "var(--black-two)",
    [theme.breakpoints.up("sm")]: {
      fontSize: "26px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      margin: "60px 26px 26px",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: "36px",
      margin: "80px 26px 26px",
    },
  },
  secondaryText: {
    textAlign: "center",
    margin: "0px 70px 25px 70px",
    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.32px",
    color: "var(--black-two)",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      margin: "0px 70px 40px 70px",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: "26px",
      margin: "0px 70px 60px 70px",
    },
  },
  homeButton: {
    textAlign: "center",
    marginBottom: "10px",
  },
}));

const PostTestScreen = ({
  postTestViewCallback,
  webSocket,
  isJeeAdvanced,
  isSecondPaper,
}) => {
  const classes = useStyles();
  const [homePage, redirectHomePage] = React.useState(false);
  const isFreeUser = localStorage.getItem("is_free_user");
  const wasTestAvailable = sessionStorage.getItem("test_no_longer_available");
  let showHomeButton = false;
  if (isJeeAdvanced) {
    if (isSecondPaper) {
      showHomeButton = true;
    }
  } else {
    showHomeButton = true;
  }

  const isAdvancedSecondPaper = isJeeAdvanced && isSecondPaper;
  const goToHomePage = () => {
    redirectHomePage(true);
  };

  useEffect(() => {
    sessionStorage.setItem("test_submitted", "true");
  });

  useEffect(() => {
    if (webSocket) {
      submitTestWebSocketAction(webSocket);
    }
  }, [webSocket]);

  let secondaryText = "You can either close the tab or go to home page.";
  if (isFreeUser || !isAdvancedSecondPaper) {
    secondaryText = "Please close the tab.";
  }

  const ctaButton = () => {
    const buttonTitle = "HOME";
    const buttonAction = goToHomePage;
    return !isFreeUser && showHomeButton ? (
      <Grid className={classes.homeButton}>
        <Button type="white" onClick={buttonAction}>
          {buttonTitle}
        </Button>
      </Grid>
    ) : null;
  };

  return (
    <Grid
      id="postExamCompletion"
      direction="column"
      container
      className={classes.container}
    >
      <Typography className={classes.primaryText}>
        {wasTestAvailable === "true"
          ? "This test is no longer available."
          : "You have completed the assessment."}
      </Typography>
      <Typography className={classes.secondaryText}>{secondaryText}</Typography>
      {ctaButton()}
      {homePage && <Redirect to="/" />}
    </Grid>
  );
};

export default PostTestScreen;
