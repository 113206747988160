import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "../../assets/stylesheets/MockDetail.css";
import ReactHTMLParser from "react-html-parser";
import { BNAT_TEST_CONSTANTS } from "../../AppConfig";
import { extractDateTime } from "../Utils/TimeConvertor";
import initiateIntersectionObserver from "../Utils/IntersectionObserver";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: "#6d7278",
    fontSize: "2rem",
    fontWeight: "600",
    marginBottom: "1.5rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  desCardTitle: {
    paddingBottom: "0.5rem",
    fontSize: "1rem",
    borderBottom: "1px solid #d8d8d8",
    marginBottom: "1rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  descCardDesc: {
    fontSize: "0.7rem",
  },
  OrngClr: {
    color: "#c254f5",
  },
}));
export default function IASFaqSection({
  resultDateTime,
  language,
  setActiveNav,
}) {
  const classes = useStyles();
  const checkIfLanguageisEnglish = language === "English";
  const iasResultDatetimeArr = resultDateTime.split(" ");
  const iasResultDateTime = extractDateTime(iasResultDatetimeArr);

  React.useEffect(() => {
    initiateIntersectionObserver(
      document.querySelector("#iasFaqSection"),
      0.5,
      setActiveNav,
      "3"
    );
  }, [setActiveNav]);

  return (
    <div id="iasFaqSection">
      <Typography variant="h4" className={classes.sectionTitle}>
        {checkIfLanguageisEnglish ? "FAQs" : "प्रायः पूछे जाने वाले प्रश्न"}
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish ? "Marking Scheme" : "मार्किंग पद्धति"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "Every correct answer gets +2 marks."
              : "प्रत्येक सही उत्तर हेतु प्रदत अंक +2 ।"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "There is a penalty of -0.67 for each incorrect answer."
              : "प्रत्येक गलत उत्तर के लिए -0.67 ऋणात्मक अंक का प्रावधान।"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "There is no penalty for unattempted questions"
              : "प्रयास नहीं किए जाने वाले प्रश्नों हेतु कोई अंक नहीं काटा जायेगा।"}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "What if I am not able to login on time?"
              : "यदि मैं समय पर लॉगिन नहीं कर पा रहा हूं तो क्या करूँ?"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "The link to start the test will remain active for 30 mins post the scheduled start time. Please ensure to login within this window to avail the full test duration for attempting the test."
              : "टेस्ट शुरू करने का लिंक निर्धारित समय के 30 मिनट बाद तक सक्रिय रहेगा। टेस्ट में भाग लेने के लिए निर्धारित पूर्ण अवधि का लाभ उठाने हेतु कृपया इस विंडो में लॉगिन करें।"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "*In case of disconnection due to internet problems and power failure, test will resume from where you left."
              : "*इंटरनेट की समस्या और बिजली आपूर्ति की विफलता की स्थिति में, टेस्ट फिर से वहीं से शुरू होगा जहाँ आपने टेस्ट छोड़ा था।"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "System Requirements to take BNST | IAS?"
              : "BNST | IAS में भाग लेने के लिए आवश्यक सिस्टम"}
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              {checkIfLanguageisEnglish
                ? "BNST | IAS can be taken on any Laptop, Desktop, Mobile Phone or Tablet. For best user experience, we recommend an updated Chrome browser."
                : "BNST | IAS में किसी भी लैपटॉप, डेस्कटॉप, मोबाइल फोन या टैबलेट के माध्यम से भाग लिया जा सकता है।बाधारहित टेस्ट हेतु अद्यतन क्रोम ब्राउज़र की अनुशंसा की जाती है।"}
            </Typography>
            <Typography paragraph className={classes.descCardDesc}>
              {checkIfLanguageisEnglish
                ? "Kindly check your device, browser and your internet connection, to make sure you get a hassle free testing experience."
                : " यह सुनिश्चित करने के लिए कि आपका टेस्ट बाधारहित हो, कृपया अपने डिवाइस, ब्राउज़र और इंटरनेट कनेक्शन की जाँच कर लें।"}
            </Typography>
          </div>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "What if I want to appear for BNST | IAS Test at a physical center"
              : "अगर मैं BNST | IAS में किसी केंद्र पर पहुँचकर भाग लेना चाहता हूँ तो क्या करूँ?"}
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              {checkIfLanguageisEnglish
                ? "BYJU'S BNST | IAS Test is online. You can choose any place as per your convenience to login on your phone, tablet, or laptop to appear for the test"
                : "BNST | IAS ऑनलाइन है। आप टेस्ट में शामिल होने के दौरान अपने फोन, टैबलेट या लैपटॉप पर लॉगिन करने के लिए अपनी सुविधा के अनुसार किसी भी स्थान का चयन कर सकते हैं।"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "On which device should I log in to appear in BNST | IAS?"
              : "BNST | IAS में भाग लेने के लिए मुझे किस डिवाइस पर लॉग इन करना चाहिए?"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "Any Android, iOS, Windows or Mac device supporting Chrome, Safari or Mozilla Firefox browser can be used to appear in the exam. We currently do not support any version of Internet Explorer or Edge."
              : "आधुनिक ब्राउज़र को सपोर्ट करने वाले किसी भी एंड्रॉइड, आईओएस, विंडोज़ या मैक डिवाइस का उपयोग टेस्ट में शामिल होने के लिए किया जा सकता है।"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "Though, desktops and laptops are preferred as they offer a stable internet connectivity, and an easier user interface to take an online test."
              : "हालाँकि, डेस्कटॉप और लैपटॉप को प्राथमिकता दी जाती है, क्योंकि ये एक स्थिर इंटरनेट कनेक्टिविटी और ऑनलाइन टेस्ट हेतु एक आसान यूज़र इंटरफेस प्रदान करते हैं।"}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "What if I forget my Login Username and Password?"
              : "यदि मैं अपना लॉगिन यूजरनेम और पासवर्ड भूल जाता हूं तो क्या करूँ?"}
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              {checkIfLanguageisEnglish
                ? "Only way to login is using a registered Mobile number and a system generated password. You can click on forget password to resend the password to you."
                : "लॉगिन करने का एकमात्र तरीका है, पंजीकृत मोबाइल नंबर और सिस्टम जनरेटेड पासवर्ड का प्रयोग। पासवर्ड दोबारा प्राप्त करने के लिए ‘फॉरगॉट पासवर्ड’ पर क्लिक करें।"}
            </Typography>
            <Typography paragraph className={classes.descCardDesc}>
              {checkIfLanguageisEnglish
                ? "Send an email to "
                : "यदि आप अपने पंजीकृत मोबाइल नंबर के साथ एक्सेस करने में असमर्थ हैं, तो "}
              <span>
                <a
                  href={`mailto:${BNAT_TEST_CONSTANTS.BNAT_IAS_CUSTOMER_SUPPORT_EMAIL}`}
                >
                  <b className={classes.OrngClr}>
                    {BNAT_TEST_CONSTANTS.BNAT_IAS_CUSTOMER_SUPPORT_EMAIL}
                  </b>
                </a>
              </span>
              {checkIfLanguageisEnglish
                ? " if you're unable to access with your registered mobile number."
                : "पर हमें ईमेल करें।"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "Will I get a scholarship on top of my existing discount?"
              : "क्या मुझे वर्तमान में मिल रही छूट से अधिक छात्रवृत्ति मिलेगी?"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "No. A student can avail only one scholarship or discount at a time. Lowest calculated fee after availing a scholarship/discount will be applicable."
              : "नहीं। एक छात्र एक समय में केवल एक छात्रवृत्ति या छूट का लाभ उठा सकता है।छात्रवृत्ति / छूट प्राप्त करने के बाद परिकलित न्यूनतम शुल्क लागू होगा।"}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "I am an existing student at BYJU'S, Can I appear for BNST?"
              : "मैं BYJU'S का एक मौजूदा छात्र हूं, क्या मैं BNST | IAS टेस्ट में भाग ले सकता हूं?"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "Yes. All eligible students, even BYJU'S students, can appear for BNST. But the scholarship availed can only be used for enrolling in fresh courses."
              : "हाँ, सभी योग्य छात्र, यहाँ तक कि BYJU'S के छात्र भी, BNST | IAS टेस्ट में भाग ले सकते हैं। ध्यान दें कि छात्रवृत्ति का लाभ केवल नए कोर्स (Fresh Courses) में नामांकन लेने वाले छात्र ही उठा सकते हैं।"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "Can I reschedule my test?"
              : "क्या मैं इस परीक्षा में अन्य समय शामिल हो सकता हूं?"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "No, you can not."
              : "नहीं, सभी प्रतिभागियों के लिए एक ही समय निर्धारित है।"}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "Can I appear for the test in Hindi language?"
              : "क्या मैं हिंदी भाषा में टेस्ट दे सकता हूं?"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? "Yes, the test will be available in both English and Hindi language."
              : "हाँ, टेस्ट अंग्रेजी और हिंदी दोनों भाषाओं में उपलब्ध होगा।"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "When will the results be announced?"
              : "परिणाम कब घोषित किए जाएंगे?"}
          </Typography>
          <Typography paragraph className={classes.descCardDesc}>
            {`${
              checkIfLanguageisEnglish
                ? `Results for BNST | IAS will be announced on`
                : `BNST | IAS टेस्ट के परिणामों की घोषणा`
            } ${iasResultDateTime && iasResultDateTime.date}`}
            <sup>{iasResultDateTime && iasResultDateTime.dateOrdinal}</sup>
            {` ${iasResultDateTime && iasResultDateTime.month} ${
              iasResultDateTime && iasResultDateTime.year
            }, ${iasResultDateTime && iasResultDateTime.time} ${
              iasResultDateTime && iasResultDateTime.ampm
            }. ${
              checkIfLanguageisEnglish
                ? `We recommend students to download their assessment report as it will be available on the portal for only 7 days after it is published.`
                : ` छात्रों को अपनी मूल्यांकन रिपोर्ट डाउनलोड करने की सलाह दी जाती है जो टेस्ट परिणाम जारी होने के बाद केवल 7 दिनों के लिए पोर्टल पर उपलब्ध रहेगी ।`
            }`}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "Can I take BNST from multiple devices?"
              : "क्या मैं BNST | IAS टेस्ट में एक से अधिक डिवाइस का प्रयोग कर भाग ले सकता हूँ?"}
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              {checkIfLanguageisEnglish
                ? "To ensure a transparent and fair experience to all participants, any attempt to change the device or browser will result in exam termination."
                : "सभी प्रतिभागियों के लिए एक पारदर्शी और निष्पक्ष व्यवस्था सुनिश्चित करने हेतु डिवाइस या ब्राउज़र में बदलाव के किसी भी प्रयास से टेस्ट समाप्त हो जाएगा।"}
            </Typography>
            <Typography paragraph className={classes.descCardDesc}>
              {checkIfLanguageisEnglish
                ? "One student can appear for the exam on only one device and one browser. Therefore, before the exam make sure your device, internet connectivity, browser, etc. are functioning properly."
                : "एक छात्र केवल एक डिवाइस और एक ब्राउज़र का प्रयोग कर ही टेस्ट में भाग ले सकता है। इसलिए, परीक्षा से पहले सुनिश्चित कीजिए कि आपका डिवाइस, इंटरनेट कनेक्टिविटी, ब्राउज़र आदि ठीक से काम कर रहा हो।"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "Dos and Don'ts on Mobile/Tablet"
              : "मोबाइल / टेबलेट पर क्या करें और क्या नहीं करे"}
          </Typography>
          <Typography className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? ReactHTMLParser(
                  `<ol style="padding-left:20px;">
                <li>Do not press Home/Back (the test will end)</li>
                <li>Do not switch OFF the device (the test will end)</li>
                <li>Please ensure that your device&apos;s memory and storage are sufficient, battery is charged, and the internet is functioning well, before the test starts</li>
                <li>Do not change the browser on the device (the test will end)</li>
                <li>Do not click on any pop up on your system not related to BNST. All your activities will be tracked during the test</li>
                <li>Do not refresh your page</li>
                </ol>`
                )
              : ReactHTMLParser(
                  `<ol style="padding-left:20px;">
                <li>Home/Back बटन न दबाएँ (टेस्ट समाप्त हो जाएगा)</li>
                <li>डिवाइस को बंद न करें (टेस्ट समाप्त हो जाएगा)</li>
                <li>टेस्ट शुरू होने से पहले कृपया सुनिश्चित करें कि आपके डिवाइस की मेमोरी और स्टोरेज पर्याप्त है, बैटरी चार्ज है और इंटरनेट अच्छी तरह से काम कर रहा है।</li>
                <li>डिवाइस पर ब्राउज़र को न बदलें (टेस्ट समाप्त हो जाएगा)</li>
                <li>अपने सिस्टम परकिसी भी ऐसे पॉप अप पर क्लिक नहीं करें जो BNST | IAS से संबंधित नहींहो | टेस्ट के दौरान की आपकी सभी गतिविधियों पर नज़र रखी जाएगी।</li>
                <li>अपने पेज को रिफ्रेश न करें।</li>
                </ol>`
                )}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "Dos and Don'ts on Laptop/Desktop"
              : "लैपटॉप और डेस्कटॉप पर क्या करें और क्या नहीं करें"}
          </Typography>
          <Typography className={classes.descCardDesc}>
            {checkIfLanguageisEnglish
              ? ReactHTMLParser(
                  `<ol style="padding-left:20px;">
              <li>Do not exit Full Screen (the test will end)</li>
              <li>Do not press Esc (the test will end)</li>
              <li>Alt+TAB will also end the test</li>
              <li>Test your internet connection and browser on your device before starting the test</li>
              <li>Do not change the browser on the device (the test will end)</li>
              <li>Do not click on any pop up on your system not related to BNST. All your activities will be tracked during the test</li>
              <li>Do not refresh your page</li>
              </ol>`
                )
              : ReactHTMLParser(
                  `<ol style="padding-left:20px;">
              <li>फुल स्क्रीन को एग्जिट नहीं करें (टेस्ट समाप्त हो जाएगा)</li>
              <li>Esc बटन नहीं दबाएँ (टेस्ट समाप्त हो जाएगा)</li>
              <li>Alt + TAB बटन दबाने से भी टेस्ट समाप्त हो जाएगा।</li>
              <li>टेस्ट शुरू होने से पहले अपने डिवाइस के इंटरनेट कनेक्शन और ब्राउज़र की जाँच कर लें।</li>
              <li>डिवाइस के ब्राउज़र को न बदलें (टेस्ट समाप्त हो जाएगा)</li>
              <li>अपने सिस्टम पर किसी भी ऐसे पॉप अप पर क्लिक नहीं करें जो BNST | IAS से संबंधित नहीं हो | टेस्ट के दौरान की आपकी सभी गतिविधियों पर नज़र रखी जाएगी।</li>
              <li>अपने पेज को रिफ्रेश न करें।</li>
              </ol>`
                )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Typography className={classes.desCardTitle}>
            {checkIfLanguageisEnglish
              ? "What if I have any further query?"
              : "उपर्युक्त जानकारी के अतिरिक्त अन्य जानकारी प्राप्त करने हेतु क्या करें?"}
          </Typography>
          <div>
            <Typography paragraph className={classes.descCardDesc}>
              {checkIfLanguageisEnglish
                ? "Please send your queries to "
                : "कृपया अपना प्रश्न "}
              <span>
                <a
                  href={`mailto:${BNAT_TEST_CONSTANTS.BNAT_IAS_CUSTOMER_SUPPORT_EMAIL}`}
                >
                  <b className={classes.OrngClr}>
                    {BNAT_TEST_CONSTANTS.BNAT_IAS_CUSTOMER_SUPPORT_EMAIL}
                  </b>
                </a>
              </span>
              {checkIfLanguageisEnglish ? " or call on " : " पर भेजें या "}
              <span>
                <a
                  href={`tel:+91${BNAT_TEST_CONSTANTS.BNAT_IAS_CUSTOMER_SUPPORT_TELEPHONE}`}
                >
                  <b className={classes.OrngClr}>
                    {BNAT_TEST_CONSTANTS.BNAT_IAS_CUSTOMER_SUPPORT_TELEPHONE}
                  </b>
                </a>
              </span>
              {!checkIfLanguageisEnglish && " पर कॉल करें।"}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
