export const formatSubjects = (subjects) => {
  return subjects.map((subject) => ({ id: subject, title: subject }));
};

export const removeTNLFiller = (value) => {
  const reg = /<\/?tnl-filler[^>]*><\/tnl-filler>/;
  return value.replace(reg, "");
};

export const calcualteStatus = ({ seen, flagged, answer }) => {
  if (seen && answer && !flagged) {
    return "answered";
  }
  if (seen && flagged && answer) {
    return "answeredandmarked";
  }
  if (!answer && seen && flagged) {
    return "markedforreview";
  }
  if (!answer && seen && !flagged) {
    return "notanswered";
  }
  return null;
};

export const formatQuestions = (response) => {
  if (response) {
    return response.sub_assignments
      .map((subassess) => {
        const answers = subassess.question_attempts;
        const assessmnetData = subassess.assessment_questions
          .map((item) => {
            const matched = answers.filter(
              (answer) => answer.question_id === item.id
            )[0];
            let status;
            if (matched) {
              status = calcualteStatus({
                answer:
                  matched.answer_string ||
                  matched.answer_id ||
                  matched.answer_array,
                flagged: matched.is_flagged,
                seen: matched.has_seen_question,
              });
            }
            if (item.question.type === "MultipleChoiceQuestion") {
              return {
                id: item.id,
                questionNumber: item.sequence,
                question: item.question.html_title,
                type: "MultipleChoiceQuestion",
                options: item.question.answers.map((option) => ({
                  id: option.answer_id,
                  value: option.title,
                })),
                subject: subassess.assessment.title,
                status: status || null,
                answer: matched
                  ? { type: "MultipleChoiceQuestion", value: matched.answer_id }
                  : null,
              };
            }
            if (item.question.type === "MultiSelectQuestion") {
              return {
                id: item.id,
                questionNumber: item.sequence,
                question: item.question.html_title,
                type: "MultiSelectQuestion",
                options: item.question.answers.map((option) => ({
                  id: option.answer_id,
                  value: option.title,
                })),
                subject: subassess.assessment.title,
                status: status || null,
                answer: matched
                  ? { type: "MultiSelectQuestion", value: matched.answer_array }
                  : null,
              };
            }
            if (item.question.type === "FillInTheBlankQuestion") {
              return {
                id: item.id,
                questionNumber: item.sequence,
                question: removeTNLFiller(item.question.html_title),
                type: "FillInTheBlankQuestion",
                subject: subassess.assessment.title,
                status: status || null,
                answer: matched
                  ? {
                      type: "FillInTheBlankQuestion",
                      value: matched.answer_string,
                    }
                  : null,
              };
            }
            return null;
          })
          .filter((item) => item);
        return {
          questions: assessmnetData,
          subject: {
            title: subassess.assessment.title,
            id: subassess.id,
            max_fib_attempts: subassess.assessment.max_fib_attempts,
          },
        };
      })
      .reduce(
        (a, c) => {
          return {
            subjects: [...a.subjects, c.subject],
            questions: [...a.questions, ...c.questions],
          };
        },
        { subjects: [], questions: [] }
      );
  }
  return null;
};

export const calculateLegend = (meta) => {
  if (meta) {
    return meta.reduce(
      (a, c) => {
        if (c.status === "notanswered") {
          return { ...a, notanswered: a.notanswered + 1 };
        }
        if (c.status === "answered") {
          return { ...a, answered: a.answered + 1 };
        }
        if (c.status === "markedforreview") {
          return { ...a, markedforreview: a.markedforreview + 1 };
        }
        if (c.status === "answeredandmarked") {
          return { ...a, answeredandmarked: a.answeredandmarked + 1 };
        }
        if (c.status === "notvisited") {
          return { ...a, notvisited: a.notvisited + 1 };
        }
        return false;
      },
      {
        notanswered: 0,
        answered: 0,
        markedforreview: 0,
        answeredandmarked: 0,
        notvisited: 0,
      }
    );
  }
  return {
    notanswered: 0,
    answered: 0,
    markedforreview: 0,
    answeredandmarked: 0,
    notvisited: 0,
  };
};

export const formatBoxContainerQuestions = (questions) => {
  if (questions) {
    return questions.map((question) => ({
      questionNumber: question.questionNumber,
      status: question.status || "notvisited",
      id: question.id,
      subject: question.subject,
      question: question.question,
      options: question.options ? question.options : null,
      type: question.type,
      answer: question.answer,
    }));
  }
  return [];
};

export const getCurrentSubjectQuestions = ({ questions, currentSubject }) => {
  return questions.filter((item) => item.subject === currentSubject.title);
};

export const updateTransformQuestion = ({
  type,
  questions,
  id,
  answer,
  flag,
}) => {
  return questions.map((item) => {
    if (item.id === id) {
      if (flag === "cleared") {
        return { ...item, status: "notanswered", answer: null };
      }
      if (flag === "notanswered" && item.status !== "notvisited") {
        return item;
      }
      if (flag === "notanswered") {
        return { ...item, status: "notanswered" };
      }

      if (flag === "markedforreview") {
        return { ...item, status: "markedforreview", answer: null };
      }
      return {
        ...item,
        status: flag,
        answer: { type, value: answer },
      };
    }
    return item;
  });
};

export const formatTime = (seconds) => {
  if (seconds) {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  }
  return "";
};

export const clearTimer = (callback) => {
  callback(0);
  localStorage.setItem("time", 0);
};

const generatePayload = ({
  isMultiChoice,
  isMultiSelect,
  answer,
  time,
  seen,
  flag,
}) => {
  const noAnswer = isMultiSelect ? [] : "";
  const ans = answer === "null" || answer === null ? noAnswer : answer;
  let questionAnswerData = {};
  const commonAnswerAttr = {
    answer_string: isMultiChoice || isMultiSelect ? "" : ans,
    time_spent_on_current_visit: `${time}`,
    has_seen_question: `${seen}`,
    is_flagged: `${flag}`,
  };
  if (isMultiChoice) {
    questionAnswerData = {
      answer_id: ans,
      ...commonAnswerAttr,
    };
  } else if (isMultiSelect) {
    questionAnswerData = {
      answer_array: ans,
      ...commonAnswerAttr,
    };
  } else {
    questionAnswerData = {
      answer_id: "",
      ...commonAnswerAttr,
    };
  }
  return questionAnswerData;
};

export const formatAttemptPayload = ({
  isMultiChoice,
  isMultiSelect,
  answer,
  time,
  type,
  assignmentID,
  questionID,
}) => {
  if (type === "answered") {
    return {
      assignmentID,
      meta: generatePayload({
        isMultiChoice,
        isMultiSelect,
        answer,
        flag: false,
        seen: true,
        time,
      }),
      questionID,
    };
  }
  if (type === "answeredandmarked") {
    return {
      assignmentID,
      meta: generatePayload({
        isMultiChoice,
        isMultiSelect,
        answer,
        flag: true,
        seen: true,
        time,
      }),
      questionID,
    };
  }
  if (type === "markedforreview") {
    return {
      assignmentID,
      meta: generatePayload({
        isMultiChoice,
        isMultiSelect,
        answer,
        flag: true,
        seen: true,
        time,
      }),
      questionID,
    };
  }
  if (type === "notanswered") {
    return {
      assignmentID,
      meta: generatePayload({
        isMultiChoice,
        isMultiSelect,
        answer,
        flag: false,
        seen: true,
        time,
      }),
      questionID,
    };
  }
  if (type === "cleared") {
    return {
      assignmentID,
      meta: generatePayload({
        isMultiChoice,
        isMultiSelect,
        answer: "",
        flag: false,
        seen: true,
        time,
      }),
      questionID,
    };
  }
  return null;
};

export const getNextSubject = ({ subjects, currentSubject, action }) => {
  const currIndex = subjects.findIndex((sub) => sub.id === currentSubject.id);
  if (currIndex === subjects.length - 1) {
    if (action === "next") {
      return subjects[0];
    }
    return subjects[currIndex - 1];
  }
  if (currIndex === 0) {
    if (action === "next") {
      return subjects[currIndex + 1];
    }
    return subjects[subjects.length - 1];
  }
  if (action === "next") {
    return subjects[currIndex + 1];
  }
  return subjects[currIndex - 1];
};

export const getCurrentSubjectStatus = ({ currentSub, subjects }) => {
  if (currentSub) {
    if (
      subjects.findIndex((sub) => currentSub.id === sub.id) ===
      subjects.length - 1
    ) {
      return "last";
    }
    if (subjects.findIndex((sub) => currentSub.id === sub.id) === 0) {
      return "first";
    }
    return "mid";
  }
  return false;
};

export const isLastQuestion = ({
  currentSectionQuestions,
  currentQuestion,
  currentSubject,
  subjects,
}) => {
  if (
    currentSectionQuestions &&
    currentQuestion &&
    currentQuestion.questionNumber ===
      currentSectionQuestions[currentSectionQuestions.length - 1]
        .questionNumber &&
    getCurrentSubjectStatus({ currentSub: currentSubject, subjects }) === "last"
  ) {
    return true;
  }
  return false;
};
