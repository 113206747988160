/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/UtilComponents/Header/Header";
import UserDetail from "../components/UserDetail/UserDetail";
import Instructions from "../components/Instructions/Instructions";
import TestSubmissionPage from "../components/TestSubmissionPage/TestSubmissionPage";
import Question from "../components/Question/Question";
import Legend from "../components/Legend/Legend";
import BoxContainer from "../components/Box-Container/BoxContainer";
import Button from "../components/Button/Button";
import SubjectNavigation from "../components/SubjectNavigation/SubjectNavigation";
import PopUp from "../components/UtilComponents/PopUp/PopUp";
import { GENERATE_ASSESSMENT } from "../redux/GenerateAssessment/GenerateAssessment.types";
import Loader from "../components/UtilComponents/Loader/Loader";
import {
  establishSocketConnection,
  redirectToSessionClosePage,
  startTimer,
} from "../components/Utils/WebSocket";
import { WEB_SOCKET, NETWORK, EXAM_TYPES, BITSAT } from "../AppConfig";

import {
  ATTEMPT_QUESTION,
  LOAD_ASSIGNMENT,
  UPDATE_QUESTIONS,
} from "../redux/TestTaking/TestTaking.types";
import {
  calculateLegend,
  clearTimer,
  formatAttemptPayload,
  formatBoxContainerQuestions,
  formatTime,
  getCurrentSubjectQuestions,
  getCurrentSubjectStatus,
  getNextSubject,
  updateTransformQuestion,
  isLastQuestion,
} from "../components/Utils/TestTaking";

import HideShow from "../components/HideShow/HideShow";
import useStyles from "../assets/stylesheets/Mains";
import { SUBMIT_TEST_USER_REQUEST } from "../redux/PostTest/PostTest.types";
import {
  olapPayloadGenerator,
  OLAPEventIds,
  OLAPKingdoms,
  getUserInfo,
} from "../components/Utils/OlapHelpers";
import { postOLAP } from "../services/OLAP.api";
import enableExtraBitsatQuestions from "../components/Utils/Bitsat";

const Mains = () => {
  const dispatch = useDispatch();
  let interval;
  let timeRemaining;

  /* Selectors */
  const instructions = useSelector((state) => state.instructions);
  const assignmentObj = useSelector((state) => state.assignment);
  const subjects = useSelector((state) => state.testTaking.subjects);
  const questions = useSelector((state) =>
    formatBoxContainerQuestions(state.testTaking.questions)
  );
  const testTakingRed = useSelector((state) => state.testTaking);
  const isOnline = useSelector((state) => state.navigator.isOnline);

  /* States */
  const [instruction, setInstruction] = useState(false);
  const [submitTestConfirmation, setSubmitTestConfirmation] = useState(false);
  const [testTaking, setTestTaking] = useState(false);
  const [currentSubject, setCurrentSubject] = useState();
  const [questionsMeta, setQuestionsMeta] = useState();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentSectionQuestions, setCurrentSectionQuestions] = useState();
  const [testTitle, setTestTitle] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState();
  const [didSubmitAnswer, setDidSubmitAnswer] = useState(false);
  const [timeSpent, setTimeSpent] = useState(0);
  const [popup, setPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [remainingTime, setRemainingTime] = useState();
  const [disabledButton, setDisabledButton] = useState();
  const [subjectQuestionCache, setSubjectSectionCache] = useState();
  const [testType, setTestType] = useState("");
  const [fibSectionAttemptCount, setFibSectionAttemptCount] = useState([]);
  const [ws, setWs] = useState(null);
  const [newSession, setNewSession] = useState(false);
  const [heartbeatTimer, setHeartbeatTimer] = useState(
    WEB_SOCKET.HEART_BEAT_TIME
  );
  const [heartbeatInterval, setHeartbeatInterval] = useState(null);
  const [popupSubMessage, setPopupSubMessage] = useState("");
  const [socketTimeoutTimer, setSocketTimeoutTimer] = useState(
    WEB_SOCKET.SOCKET_TIMEOUT_TIME
  );
  const [socketTimeoutInterval, setSocketTimeoutInterval] = useState(null);
  const testSubmitted = sessionStorage.getItem("test_submitted") || "";
  const [additionalQuestions, setAdditionalQuestions] = useState(false);
  const bnatGrade = sessionStorage.getItem("bnat_grade") || null;

  const record = sessionStorage.getItem("test_client_id") || "";
  let variety = null;
  if (
    assignmentObj &&
    assignmentObj.assignment &&
    assignmentObj.assignment.assessment
  ) {
    variety = assignmentObj.assignment.assessment.type;
  }

  const classes = useStyles();

  const UpdateData = (action) => {
    let nextIndex;
    const nextQuestion = currentSectionQuestions.filter((item, index) => {
      if (
        action === "back" &&
        item.questionNumber === currentQuestion.questionNumber - 1
      ) {
        nextIndex = index - 1;
        return item.questionNumber === currentQuestion.questionNumber - 1;
      }
      if (item.questionNumber === currentQuestion.questionNumber + 1) {
        nextIndex = index - 1;
        return action === "next"
          ? item.questionNumber === currentQuestion.questionNumber + 1
          : item.questionNumber === currentQuestion.questionNumber - 1;
      }
      return action === "next"
        ? item.questionNumber === currentQuestion.questionNumber + 1
        : item.questionNumber === currentQuestion.questionNumber - 1;
    });
    if (
      nextIndex === currentSectionQuestions.length - 1 ||
      nextIndex === undefined ||
      (nextIndex === 0 && action === "back")
    ) {
      const newSubject = getNextSubject({
        subjects,
        currentSubject,
        action,
      });
      setCurrentSubject(newSubject);
      clearTimer(setTimeSpent);
    } else {
      setCurrentQuestion(nextQuestion[0]);
      setCurrentAnswer(nextQuestion[0].answer);
      clearTimer(setTimeSpent);
    }
  };
  useEffect(() => {
    if (
      currentQuestion &&
      currentQuestion.questionNumber === 1 &&
      getCurrentSubjectStatus({
        currentSub: currentSubject,
        subjects,
      }) === "first"
    ) {
      setDisabledButton("first");
    } else if (
      currentSectionQuestions &&
      currentQuestion &&
      currentQuestion.questionNumber ===
        currentSectionQuestions[currentSectionQuestions.length - 1]
          .questionNumber &&
      getCurrentSubjectStatus({ currentSub: currentSubject, subjects }) ===
        "last"
    ) {
      setDisabledButton("last");
    } else {
      setDisabledButton("mid");
    }
  }, [currentQuestion, currentSubject]);

  const showFIBAttemptsAlert = (FIB_ATTEMPTS_ACCEPTED) => {
    setPopup(true);
    setPopupMessage(
      `You have already answered ${FIB_ATTEMPTS_ACCEPTED} numerical value based questions in this section. Please clear a previous answer from this section before submitting answer to this question`
    );
  };

  const fibAttemptVerifier = (type, fibAttempts) => {
    const section = fibSectionAttemptCount.filter(
      (sec) => sec.id === currentSubject.id
    );
    const isQuestionAttemptedBefore = section[0].questions.filter(
      (ques) => ques === currentQuestion.id
    );
    if (type === "Clear") {
      if (isQuestionAttemptedBefore.length !== 0) {
        section[0].count = section[0].count === 0 ? 0 : section[0].count - 1;
        section[0].questions.splice(
          section[0].questions.indexOf(currentQuestion.id),
          1
        );
        setFibSectionAttemptCount([...fibSectionAttemptCount]);
      }
      return true;
    }
    if (section[0].count < fibAttempts) {
      if (isQuestionAttemptedBefore.length === 0) {
        section[0].count += 1;
        section[0].questions.push(currentQuestion.id);
        setFibSectionAttemptCount([...fibSectionAttemptCount]);
      }
      return true;
    }
    if (
      section[0].count === fibAttempts &&
      isQuestionAttemptedBefore.length === 0
    ) {
      showFIBAttemptsAlert(fibAttempts);
      return false;
    }
    return true;
  };

  const updateQuestion = ({ type, next }) => {
    const isLastQuesLastSec = isLastQuestion({
      currentSectionQuestions,
      currentQuestion,
      currentSubject,
      subjects,
    });
    if (type === "SaveAndNext") {
      if (
        currentAnswer !== undefined &&
        currentAnswer !== null &&
        currentAnswer.value !== undefined &&
        currentAnswer.value !== null
      ) {
        if (typeof currentAnswer.value === "string") {
          currentAnswer.value = currentAnswer.value.trim();
        }
        setCurrentAnswer({ ...currentAnswer });
      }
      if (
        !currentAnswer ||
        !currentAnswer.value ||
        currentAnswer.length === 0
      ) {
        setPopup(true);
        setPopupMessage("Please provide answer before proceeding.");
        return;
      }
      if (
        testType === "JeeMain" &&
        currentQuestion.type === "FillInTheBlankQuestion" &&
        currentSubject.max_fib_attempts !== null
      ) {
        const result = fibAttemptVerifier(
          type,
          currentSubject.max_fib_attempts
        );
        if (result === false) {
          return;
        }
      }
      const updatedQuestions = updateTransformQuestion({
        type: currentAnswer.type,
        questions,
        answer: currentAnswer.value,
        id: currentQuestion.id,
        flag: "answered",
      });
      dispatch({
        type: ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer: currentAnswer.value,
          assignmentID: currentSubject.id,
          time: timeSpent / 1000,
          type: "answered",
          questionID: currentQuestion.id,
        }),
      });
      if (!isLastQuesLastSec) {
        setCurrentAnswer();
      } else {
        setCurrentAnswer((val) => ({
          ...val,
          answer: currentAnswer,
          status: "answered",
        }));
        setCurrentQuestion((q) => ({
          ...q,
          status: "answered",
          answer: currentAnswer,
        }));
      }
      dispatch({ type: UPDATE_QUESTIONS, payload: updatedQuestions });
      setDidSubmitAnswer(true);
      if (!isLastQuesLastSec) {
        let nextIndex;
        const nextQuestion = currentSectionQuestions.filter((item, index) => {
          if (item.questionNumber === currentQuestion.questionNumber + 1) {
            nextIndex = index - 1;
            return item.questionNumber === currentQuestion.questionNumber + 1;
          }
          return item.questionNumber === currentQuestion.questionNumber + 1;
        });
        if (
          nextIndex === currentSectionQuestions.length - 1 ||
          nextIndex === undefined
        ) {
          const newSubject = getNextSubject({
            subjects,
            currentSubject,
            action: "next",
          });
          setCurrentSubject(newSubject);
          clearTimer(setTimeSpent);
        } else {
          setCurrentQuestion(nextQuestion[0]);
          setCurrentAnswer(nextQuestion[0].answer);
          clearTimer(setTimeSpent);
        }
      }
    }
    if (type === "Clear") {
      if (
        testType === "JeeMain" &&
        currentQuestion.type === "FillInTheBlankQuestion" &&
        currentSubject.max_fib_attempts !== null
      ) {
        const result = fibAttemptVerifier(
          type,
          currentSubject.max_fib_attempts
        );
        if (result === false) {
          return;
        }
      }
      setCurrentAnswer();
      if (
        currentAnswer !== null &&
        currentAnswer !== undefined &&
        currentAnswer.value !== null &&
        currentAnswer.value !== undefined
      ) {
        dispatch({
          type: ATTEMPT_QUESTION,
          payload: formatAttemptPayload({
            isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
            isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
            answer: currentAnswer.value,
            assignmentID: currentSubject.id,
            time: timeSpent / 1000,
            type: "cleared",
            questionID: currentQuestion.id,
          }),
        });
      }
      setCurrentQuestion((q) => {
        return { ...q, answer: null, status: "notanswered" };
      });
      setCurrentSectionQuestions((q) => {
        return q.map((item) => {
          if (item.id === currentQuestion.id) {
            return { ...item, status: "notanswered", answer: null };
          }
          return item;
        });
      });
      const newQuestions = updateTransformQuestion({
        questions,
        id: currentQuestion.id,
        flag: "cleared",
      });
      dispatch({ type: UPDATE_QUESTIONS, payload: newQuestions });
      setDidSubmitAnswer(true);
    }
    if (type === "SaveAndMark") {
      if (
        currentAnswer !== undefined &&
        currentAnswer !== null &&
        currentAnswer.value !== undefined &&
        currentAnswer.value !== null
      ) {
        if (typeof currentAnswer.value === "string") {
          currentAnswer.value = currentAnswer.value.trim();
        }
        setCurrentAnswer({ ...currentAnswer });
      }
      if (
        !currentAnswer ||
        !currentAnswer.value ||
        currentAnswer.value.length === 0
      ) {
        setPopup(true);
        setPopupMessage("Please provide answer before proceeding.");
        return;
      }
      if (
        testType === "JeeMain" &&
        currentQuestion.type === "FillInTheBlankQuestion" &&
        currentSubject.max_fib_attempts !== null
      ) {
        const result = fibAttemptVerifier(
          type,
          currentSubject.max_fib_attempts
        );
        if (result === false) {
          return;
        }
      }
      const updatedQuestions = updateTransformQuestion({
        type: currentAnswer.type,
        questions,
        answer: currentAnswer.value,
        id: currentQuestion.id,
        flag: "answeredandmarked",
      });
      dispatch({
        type: ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer: currentAnswer.value,
          assignmentID: currentSubject.id,
          time: timeSpent / 1000,
          type: "answeredandmarked",
          questionID: currentQuestion.id,
        }),
      });

      if (!isLastQuesLastSec) {
        setCurrentAnswer();
      } else {
        setCurrentAnswer((val) => ({
          ...val,
          answer: currentAnswer,
          status: "answeredandmarked",
        }));
        setCurrentQuestion((q) => ({
          ...q,
          status: "answeredandmarked",
          answer: currentAnswer,
        }));
      }
      dispatch({ type: UPDATE_QUESTIONS, payload: updatedQuestions });
      setDidSubmitAnswer(true);
      if (!isLastQuesLastSec) {
        UpdateData("next");
      }
    }
    if (type === "MarkForReview") {
      const updatedQuestions = updateTransformQuestion({
        questions,
        id: currentQuestion.id,
        flag: "markedforreview",
      });
      dispatch({
        type: ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer: null,
          assignmentID: currentSubject.id,
          time: timeSpent / 1000,
          type: "markedforreview",
          questionID: currentQuestion.id,
        }),
      });
      setCurrentAnswer();
      if (isLastQuesLastSec) {
        setCurrentQuestion((q) => ({
          ...q,
          status: "markedforreview",
          answer: null,
        }));
      }
      dispatch({ type: UPDATE_QUESTIONS, payload: updatedQuestions });
      setDidSubmitAnswer(true);
      if (!isLastQuesLastSec) {
        UpdateData("next");
      }
    }
    if (type === "Next") {
      const currentStatus =
        currentQuestion.status === "notvisited"
          ? "notanswered"
          : currentQuestion.status;
      const updatedQuestions = updateTransformQuestion({
        questions,
        id: currentQuestion.id,
        flag: "notanswered",
      });
      dispatch({
        type: ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer:
            currentQuestion.status !== "notvisited" &&
            currentQuestion.answer &&
            currentQuestion.answer.value
              ? currentQuestion.answer.value
              : "",
          assignmentID: currentSubject.id,
          time: timeSpent / 1000,
          type: currentStatus,
          questionID: currentQuestion.id,
        }),
      });
      setDidSubmitAnswer();
      dispatch({ type: UPDATE_QUESTIONS, payload: updatedQuestions });
      setDidSubmitAnswer(true);
      UpdateData("next");
    }
    if (type === "Back") {
      const currentStatus =
        currentQuestion.status === "notvisited"
          ? "notanswered"
          : currentQuestion.status;
      const updatedQuestions = updateTransformQuestion({
        questions,
        id: currentQuestion.id,
        flag: "notanswered",
      });
      dispatch({
        type: ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer:
            currentQuestion.status !== "notvisited" &&
            currentQuestion.answer &&
            currentQuestion.answer.value
              ? currentQuestion.answer.value
              : "",
          assignmentID: currentSubject.id,
          time: timeSpent / 1000,
          type: currentStatus,
          questionID: currentQuestion.id,
        }),
      });
      setDidSubmitAnswer();
      dispatch({ type: UPDATE_QUESTIONS, payload: updatedQuestions });
      setDidSubmitAnswer(true);
      UpdateData("back");
    }

    if (type === "Jump") {
      if (next.questionNumber === currentQuestion.questionNumber) {
        return;
      }
      const currentStatus =
        currentQuestion.status === "notvisited"
          ? "notanswered"
          : currentQuestion.status;
      const updatedQuestions = updateTransformQuestion({
        type: currentQuestion.type,
        questions,
        id: currentQuestion.id,
        flag: currentStatus,
        answer:
          currentQuestion.status !== "notvisited" &&
          currentQuestion.answer &&
          currentQuestion.answer.value
            ? currentQuestion.answer.value
            : "",
      });
      dispatch({
        type: ATTEMPT_QUESTION,
        payload: formatAttemptPayload({
          isMultiChoice: currentQuestion.type === "MultipleChoiceQuestion",
          isMultiSelect: currentQuestion.type === "MultiSelectQuestion",
          answer:
            currentQuestion.status !== "notvisited" &&
            currentQuestion.answer &&
            currentQuestion.answer.value
              ? currentQuestion.answer.value
              : "",
          assignmentID: currentSubject.id,
          time: timeSpent / 1000,
          type: currentStatus,
          questionID: currentQuestion.id,
        }),
      });
      setDidSubmitAnswer();
      dispatch({ type: UPDATE_QUESTIONS, payload: updatedQuestions });
      setDidSubmitAnswer(true);
      setCurrentQuestion(next);
      setCurrentAnswer(next.answer);
      clearTimer(setTimeSpent);
    }
  };

  const changeSubject = (sub) => {
    setSubjectSectionCache((val) => {
      return { ...val, [currentSubject.id]: currentQuestion };
    });
    setCurrentSubject(sub);
    setCurrentQuestion();
    setCurrentAnswer();
  };

  const userProfile = JSON.parse(localStorage.getItem("user"));

  const closePopup = () => {
    setPopup(false);
    setTimeout(() => {
      setAdditionalQuestions(false);
      setPopupMessage("");
      setPopupSubMessage("");
    }, 500);
  };

  const [showFinalScreen, setFinalScreen] = useState(false);

  const reload = () => {
    setPopup(false);
    ws.close();
    window.location.reload();
  };

  const callOlapApi = (
    uEventId,
    description,
    kingdom,
    counter,
    species,
    type
  ) => {
    const { premium } = getUserInfo();
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      uEventId,
      type,
      description,
      kingdom,
      counter,
      null,
      null,
      species,
      null,
      record,
      null,
      null,
      variety,
      premium ? null : JSON.parse(localStorage.getItem("user")).id,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  };

  useEffect(() => {
    const cachedTime = parseInt(sessionStorage.getItem("time"), 10);
    if (cachedTime) {
      setTimeSpent(cachedTime);
    }
    if (testTakingRed.redirect) {
      setInstruction(false);
      setTestTaking(false);
      setSubmitTestConfirmation(true);
      setFinalScreen(true);
      dispatch({
        type: SUBMIT_TEST_USER_REQUEST,
        payload: sessionStorage.getItem("test_assignment_id"),
      });
    }

    timeRemaining = setInterval(() => {
      setRemainingTime((value) => {
        if (value) {
          if (value <= 1) {
            setPopup(false);
            setInstruction(false);
            setTestTaking(false);
            setSubmitTestConfirmation(true);
            setFinalScreen(true);
            dispatch({
              type: SUBMIT_TEST_USER_REQUEST,
              payload: sessionStorage.getItem("test_assignment_id"),
            });
          }
          return value - 1;
        }
        return value;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (!currentAnswer && currentQuestion && currentQuestion.answer) {
      setCurrentAnswer(currentQuestion.answer);
    }
  }, [currentAnswer, currentQuestion]);

  useEffect(() => {
    if (!timeRemaining && testTakingRed.timeRemaining) {
      setRemainingTime(testTakingRed.timeRemaining);
    }
  }, [testTakingRed.timeRemaining]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (testTaking) {
      interval = setInterval(() => {
        setTimeSpent((t) => {
          const myTime = t + 100;
          sessionStorage.setItem("time", myTime);
          return myTime;
        });
      }, 100);
    }
  }, [testTaking]);

  const onSelectAnswer = (answer) => {
    setCurrentAnswer(answer);
  };

  const userRefusesTestSubmission = () => {
    setSubmitTestConfirmation(false);
    setTestTaking(true);
    callOlapApi(
      OLAPEventIds.CLICK_EXAM_SUBMIT_CANCEL,
      "click on exam submit cancel",
      OLAPKingdoms.CLICK_EXAM_SUBMIT_CANCEL,
      "exam_submit_cancel",
      null,
      "click"
    );
  };

  const submissionDone = () => {
    setFinalScreen(true);
    callOlapApi(
      OLAPEventIds.CLICK_EXAM_SUBMIT_CONFIRM,
      "click on exam submit confirm",
      OLAPKingdoms.CLICK_EXAM_SUBMIT_CONFIRM,
      "exam_submit_confirm",
      null,
      "click"
    );
  };

  useEffect(() => {
    dispatch({
      type: GENERATE_ASSESSMENT,
      payload: sessionStorage.getItem("test_client_id"),
    });
  }, [dispatch]);

  const updateFIBQuestionCount = (sections) => {
    const countArr = sections.map((sec) => ({
      ...sec,
      count: 0,
      questions: [],
    }));
    if (questions.length !== 0 && testType === "JeeMain") {
      questions.forEach((ques) => {
        if (
          ques.type === "FillInTheBlankQuestion" &&
          (ques.status === "answered" || ques.status === "answeredandmarked")
        ) {
          const index = countArr.findIndex((sec) => sec.title === ques.subject);
          countArr[index].count += 1;
          countArr[index].questions.push(ques.id);
        }
      });
    }
    setFibSectionAttemptCount(countArr);
  };

  useEffect(() => {
    if (!currentSubject && subjects.length) {
      setCurrentSubject(subjects[0]);
      updateFIBQuestionCount(subjects);
    }
  }, [subjects]);

  const loader = assignmentObj.loading;

  useEffect(() => {
    if (!currentQuestion && questions.length) {
      setCurrentQuestion(questions[0]);
    }
    if (didSubmitAnswer) {
      const x = getCurrentSubjectQuestions({
        questions,
        currentSubject,
      });
      setCurrentSectionQuestions(x);
      setDidSubmitAnswer(false);
      const y = calculateLegend(questions);
      setQuestionsMeta(y);
    }
    if (!questionsMeta && questions.length) {
      const y = calculateLegend(questions);
      setQuestionsMeta(y);
    }
  }, [questions]);

  useEffect(() => {
    if (loader === undefined || loader === true) {
      setInstruction(false);
      setTestTaking(false);
      setSubmitTestConfirmation(false);
      setFinalScreen(false);
    } else if (loader === "redirect" || testSubmitted === "true") {
      setInstruction(false);
      setTestTaking(false);
      setSubmitTestConfirmation(true);
      setFinalScreen(true);
    } else {
      setInstruction(true);
    }
  }, [loader]);

  useEffect(() => {
    if (assignmentObj && assignmentObj.assignment) {
      setTestTitle(assignmentObj.assignment.assessment.title);
      setTestType(assignmentObj.assignment.assessment.type);
    }
  }, [assignmentObj]);

  useEffect(() => {
    if (currentSubject && questions.length) {
      const x = getCurrentSubjectQuestions({
        questions,
        currentSubject,
      });
      setCurrentSectionQuestions(x);
      if (subjectQuestionCache && subjectQuestionCache[currentSubject.id]) {
        setCurrentQuestion(subjectQuestionCache[currentSubject.id]);
        setCurrentAnswer(subjectQuestionCache[currentSubject.id].answer);
      } else {
        setCurrentQuestion(x[0]);
        setCurrentAnswer(x[0].answer);
      }
    }
  }, [currentSubject, subjectQuestionCache]);

  const onIntructionsChecked = (checked) => {
    callOlapApi(
      OLAPEventIds.CLICK_INSTRUCTION_CHECKBOX,
      `click on instructions checkbox`,
      OLAPKingdoms.CLICK_INSTRUCTION_SUBMIT,
      "click_instruction_checkbox",
      { instructions_checked: checked },
      "click"
    );
  };

  const onAcceptInstructions = () => {
    const assignmentId = sessionStorage.getItem("test_assignment_id");
    setInstruction(false);
    setTestTaking(true);
    window.scrollTo({ behavior: "smooth", top: 0 });
    dispatch({
      type: LOAD_ASSIGNMENT,
      payload: assignmentId,
      meta: { isAdvanced: false },
    });
    setTimeSpent(0);
    sessionStorage.setItem("time", 0);
    callOlapApi(
      OLAPEventIds.CLICK_INSTRUCTION_SUBMIT,
      `click on proceed to start the test`,
      OLAPKingdoms.CLICK_INSTRUCTION_SUBMIT,
      "click_proceed_test",
      null,
      "click"
    );
  };

  const submitTestCommonFlow = () => {
    setSubmitTestConfirmation(true);
    setTestTaking(false);
    callOlapApi(
      OLAPEventIds.CLICK_EXAM_SUBMIT,
      `click on exam submit`,
      OLAPKingdoms.CLICK_EXAM_SUBMIT,
      "click_exam_submit",
      null,
      "click"
    );
  };

  const submitSelectBitsatAdditionalPopup = () => {
    closePopup();
    submitTestCommonFlow();
    callOlapApi(
      OLAPEventIds.CLICK_SUBMIT_BITSAT_POPUP,
      "click on BITSAT Submit popup",
      OLAPKingdoms.CLICK_SUBMIT_BITSAT_POPUP,
      "click_submit_bitsat_popup",
      null,
      "click"
    );
  };

  const closeBitsatExtraQuestionPopup = () => {
    closePopup();
    callOlapApi(
      OLAPEventIds.CLICK_CANCEL_BITSAT_POPUP,
      "click on BITSAT cancel popup",
      OLAPKingdoms.CLICK_CANCEL_BITSAT_POPUP,
      "click_cancel_bitsat_popup",
      null,
      "click"
    );
  };

  const clearOldTestTakingState = () => {
    setCurrentSubject();
    setSubjectSectionCache();
    setCurrentSectionQuestions();
    setCurrentQuestion();
    setCurrentAnswer();
    setQuestionsMeta();
    setRemainingTime();
  };

  const startExtraPaperBitsat = () => {
    const assignmentId = sessionStorage.getItem("test_assignment_id");
    closePopup();
    clearOldTestTakingState();
    dispatch({
      type: LOAD_ASSIGNMENT,
      payload: assignmentId,
      enableExtraSection: true,
      meta: { isAdvanced: false },
    });
    setTimeSpent(0);
    callOlapApi(
      OLAPEventIds.CLICK_START_EXTRA_PAPER_BITSAT_POPUP,
      "click on BITSAT start extra paper",
      OLAPKingdoms.CLICK_START_EXTRA_PAPER_BITSAT_POPUP,
      "click_start_extra_paper_bitsat_popup",
      null,
      "click"
    );
  };

  const onSubmitTest = () => {
    if (
      testType === EXAM_TYPES.BITSAT &&
      testTakingRed.extraSectionsEnabled === false
    ) {
      const resp = enableExtraBitsatQuestions(questionsMeta);
      if (resp === true) {
        setAdditionalQuestions(true);
        setPopup(true);
        setPopupMessage(BITSAT.ELIGIBLE_EXTRA_QUESTIONS_MESSAGE);
      } else {
        submitTestCommonFlow();
      }
    } else {
      submitTestCommonFlow();
    }
  };

  const [showHide, setShowHide] = useState(true);

  const enableShowHide = () => {
    if (showHide) {
      setShowHide(false);
    } else {
      setShowHide(true);
    }
  };

  useEffect(() => {
    if (testTakingRed.redirect) {
      setInstruction(false);
      setTestTaking(false);
      setSubmitTestConfirmation(true);
      setFinalScreen(true);
    }
  }, [testTakingRed.redirect]);

  const initiateSocketConnection = () => {
    const data = {
      action: WEB_SOCKET.REGISTER_ACTION_STRING,
      assignment_id: assignmentObj.assignment.id,
    };
    establishSocketConnection(
      JSON.stringify(data),
      setNewSession,
      setHeartbeatTimer,
      setWs,
      setHeartbeatInterval,
      setSocketTimeoutTimer,
      setSocketTimeoutInterval
    );
  };

  const recycleHeartbeat = () => {
    clearInterval(heartbeatInterval);
    setHeartbeatTimer(WEB_SOCKET.HEART_BEAT_TIME);
  };

  const recycleSocketTimeout = () => {
    clearInterval(socketTimeoutInterval);
    setSocketTimeoutTimer(WEB_SOCKET.SOCKET_TIMEOUT_TIME);
  };

  useEffect(() => {
    if (
      assignmentObj.loading === false &&
      assignmentObj.assignment.id &&
      testSubmitted === ""
    ) {
      initiateSocketConnection();
    }
  }, [assignmentObj.loading]);

  useEffect(() => {
    if (newSession === true) {
      setPopup(true);
      setPopupMessage(WEB_SOCKET.POPUP_MESSAGE);
      setPopupSubMessage(WEB_SOCKET.POPUP_SUB_MESSAGE);
    }
  }, [newSession]);

  useEffect(() => {
    if (heartbeatTimer < 1) {
      recycleHeartbeat();
      ws.send(JSON.stringify({ action: WEB_SOCKET.HEARTBEAT_ACTION_STRING }));
      startTimer(setHeartbeatTimer, setHeartbeatInterval);
    }
  }, [heartbeatTimer]);

  useEffect(() => {
    if (socketTimeoutTimer < 1) {
      recycleHeartbeat();
      recycleSocketTimeout();
      ws.close();
      initiateSocketConnection();
    }
  }, [socketTimeoutTimer]);

  useEffect(() => {
    if (!isOnline) {
      setPopup(true);
      setPopupMessage(NETWORK.OFFLINE_MESSAGE);
    } else {
      setPopup(false);
    }
  }, [isOnline]);

  return (
    <>
      {(assignmentObj.loading === undefined ||
        assignmentObj.loading === true) && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}

      {(assignmentObj.loading === false ||
        assignmentObj.loading === "redirect") && (
        <Grid className={classes.pageContainer}>
          <Grid>
            <Header
              examType={testType}
              disableLink
              noNotes
              bnatGrade={bnatGrade}
            />
            <PopUp
              message={popupMessage}
              open={popup}
              close={
                additionalQuestions ? closeBitsatExtraQuestionPopup : closePopup
              }
              window={newSession}
              reload={reload}
              cancelSession={() => {
                redirectToSessionClosePage(ws);
              }}
              subMessage={popupSubMessage}
              additionalQuestions={additionalQuestions}
              chooseSubmitBitsat={submitSelectBitsatAdditionalPopup}
              chooseExtraPaperBitsat={startExtraPaperBitsat}
            />
            {instruction && (
              <Grid>
                <Instructions
                  submitInstructions={onAcceptInstructions}
                  checkInstructions={onIntructionsChecked}
                  testInstructions={instructions.instructions}
                  assessmentId={record}
                  examType={variety}
                  enableHindiInstruction={bnatGrade === "15"}
                />
              </Grid>
            )}

            {!instruction && (
              <>
                <UserDetail
                  candidateName={userProfile ? userProfile.full_name : ""}
                  picture={
                    userProfile
                      ? userProfile.user_profiles[0].avatar
                      : "placeholder"
                  }
                  exam={testTitle}
                  subject={
                    showFinalScreen
                      ? ""
                      : currentSubject && currentSubject.title
                  }
                  remaining={
                    showFinalScreen ? "" : formatTime(remainingTime || null)
                  }
                />
                {testTaking && (
                  <Grid>
                    <SubjectNavigation
                      onChange={changeSubject}
                      examType="mains"
                      subjectArray={subjects}
                      selected={currentSubject && currentSubject.id}
                    />
                    <div style={{ padding: 20 }}>
                      <Grid container>
                        <Grid item xs={12} md={showHide ? 8 : 11}>
                          <div className={classes.questionContainer}>
                            <Question
                              question={
                                currentQuestion && currentQuestion.question
                              }
                              options={
                                currentQuestion && currentQuestion.options
                              }
                              type={currentQuestion && currentQuestion.type}
                              onChangeOption={(option) => {
                                onSelectAnswer(option);
                              }}
                              value={currentAnswer}
                              sequence={
                                currentQuestion &&
                                currentQuestion.questionNumber
                              }
                            />
                          </div>
                          <hr className={classes.ruler} />
                          <Grid>
                            <Button
                              type="green"
                              onClick={() => {
                                updateQuestion({
                                  type: "SaveAndNext",
                                  id: currentQuestion.id,
                                });
                              }}
                              customStyle={classes.marginButton}
                            >
                              SAVE AND NEXT
                            </Button>
                            <Button
                              type="white"
                              customStyle={classes.marginButton}
                              onClick={() => {
                                updateQuestion({
                                  type: "Clear",
                                  id: currentQuestion.id,
                                });
                              }}
                            >
                              CLEAR
                            </Button>
                            <Button
                              extend
                              type="orange"
                              customStyle={classes.marginButton}
                              onClick={() => {
                                updateQuestion({
                                  type: "SaveAndMark",
                                  id: currentQuestion.id,
                                });
                              }}
                            >
                              SAVE AND MARK FOR REVIEW
                            </Button>
                            <Button
                              extend
                              type="blue"
                              customStyle={classes.marginButton}
                              onClick={() => {
                                updateQuestion({
                                  type: "MarkForReview",
                                  id: currentQuestion.id,
                                });
                              }}
                            >
                              MARK FOR REVIEW AND NEXT
                            </Button>
                          </Grid>
                          <Grid
                            className={classes.navButtonsContainer}
                            direction="row"
                          >
                            <div className={classes.btnGroup}>
                              <div>
                                <Button
                                  type="white"
                                  onClick={() => {
                                    updateQuestion({ type: "Back" });
                                  }}
                                  disabled={disabledButton === "first"}
                                >
                                  {"<< BACK"}
                                </Button>
                              </div>
                              <div>
                                <Button
                                  type="white"
                                  onClick={() => {
                                    updateQuestion({ type: "Next" });
                                  }}
                                  disabled={disabledButton === "last"}
                                >
                                  {"NEXT >>"}
                                </Button>
                              </div>
                            </div>
                            <div>
                              <Button type="green" onClick={onSubmitTest}>
                                SUBMIT
                              </Button>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={showHide ? 4 : 1}
                          sm={12}
                          className={classes.centeredCss}
                        >
                          <div
                            style={{
                              alignSelf: "center",
                              padding: "12px",
                              minWidth: "26px",
                              maxWidth: "26px",
                            }}
                            className={classes.showHide}
                          >
                            <HideShow
                              show={showHide}
                              onClick={enableShowHide}
                            />
                          </div>

                          {showHide && (
                            <div>
                              <Legend
                                answered={
                                  questionsMeta && questionsMeta.answered
                                }
                                answeredAndMarked={
                                  questionsMeta &&
                                  questionsMeta.answeredandmarked
                                }
                                markedForReview={
                                  questionsMeta && questionsMeta.markedforreview
                                }
                                notAnswered={
                                  questionsMeta && questionsMeta.notanswered
                                }
                                notVisited={
                                  questionsMeta && questionsMeta.notvisited
                                }
                                iconType="mains"
                              />
                              <div style={{ marginTop: 15 }}>
                                <BoxContainer
                                  data={currentSectionQuestions}
                                  currentQuestion={currentQuestion}
                                  onAttempt={(e) => {
                                    updateQuestion({ type: "Jump", next: e });
                                  }}
                                  iconType="mains"
                                />
                              </div>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                )}
                <Grid>
                  {submitTestConfirmation && (
                    <TestSubmissionPage
                      userDetails={questionsMeta}
                      finalPage={showFinalScreen}
                      noSubmission={userRefusesTestSubmission}
                      submissionDone={submissionDone}
                      assessmentId={record}
                      examType={variety}
                      webSocket={ws}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

Mains.propTypes = {};

export default Mains;
