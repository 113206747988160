import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import "../../assets/stylesheets/QuestionStepper.css";

const useStyles = makeStyles(() => ({
  stepperContainer: {
    overflowX: "scroll",
    width: "100%",
  },
  stepper: {
    backgroundColor: "rgb(240, 245, 249)",
  },
}));

function QuestionStepper({ questionCount, activeStep }) {
  const classes = useStyles();
  const steps = Array(questionCount).fill("");

  return (
    <div className={classes.stepperContainer}>
      <Stepper className={classes.stepper} activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={`step${index + 1}`}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export default QuestionStepper;
