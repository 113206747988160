/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ReactHTMLParser from "react-html-parser";
import Box from "../Box/Box";
import Button from "../Button/Button";
import "../../index.css";
import { ICONS } from "../../AppConfig";
import { instructionsViewCallback } from "../Utils/OlapCallback";
import { useViewOlap } from "../Utils/UseOlapHooks";
import AdvancedInstructions from "./AdvancedInstructions";

const useStyles = makeStyles((theme) => {
  return {
    boldText: {
      textDecoration: "underline",
      fontFamily: '"GothamBold"',
      marginBottom: 5,
    },
    instructionsContainer: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#ffffff",
    },
    text: {
      fontWeight: 100,
      marginTop: 12,
      fontSize: 12,
    },
    centered: {
      display: "flex",
      alignItems: "center",
    },
    note: {
      color: "#a84545",
      textAlign: "center",
    },
    customHr: {
      height: 1,
      backgroundColor: "#e3e3e3",
      border: "none",
    },
    centeredCss: {
      display: "flex",
      justifyContent: "center",
    },
    content: {
      width: "70%",
      marginBottom: "20px",
    },
    icons: {
      marginTop: 10,
    },
    arrowCircle: {
      height: 20,
      margin: "0px 5px 0px 5px ",
    },
    arrowCircleUp: {
      height: 20,
      transform: "rotate(180deg)",
      margin: "0px 5px 0px 5px ",
    },
    titleInstruction: {
      fontFamily: '"GothamBold"',
      textAlign: "center",
      marginTop: "1.5rem",
      marginBottom: "0.5rem",
      [theme.breakpoints.up("md")]: {
        marginBottom: "2.5rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      text: {
        fontSize: 8,
      },
      boldText: {
        fontSize: 10,
      },
      titleInstruction: {
        fontSize: 10,
      },
      content: {
        width: "85%",
      },
      note: {
        fontSize: 10,
      },
      arrowCircle: {
        height: 15,
      },
      arrowCircleUp: {
        height: 15,
      },
    },
  };
});
const Instructions = ({
  testInstructions,
  submitInstructions,
  checkInstructions,
  assessmentId,
  examType,
  isAdvanced,
  enableHindiInstruction,
}) => {
  const [accepted, setAccepted] = useState(false);
  const classes = useStyles();

  const onInstructionCheckoxClick = (checked) => {
    setAccepted(checked);
    checkInstructions(checked);
  };

  const viewOlapCallback = (e) => {
    instructionsViewCallback(assessmentId, examType, e);
  };

  useViewOlap("examInstructions", viewOlapCallback);

  return (
    <div id="examInstructions" className={classes.instructionsContainer}>
      <div className={classes.content}>
        <div className={classes.titleInstruction}>
          {`Please read the instructions carefully ${
            enableHindiInstruction === true
              ? "(कृपया निर्देशों को ध्यान से पढ़ें)"
              : ""
          }`}
        </div>

        <ol>
          <p className={classes.boldText}>
            {`General Instructions ${
              enableHindiInstruction === true ? "(सामान्य निर्देश)" : ""
            }`}
          </p>
          {testInstructions ? (
            <li className={classes.text}>
              {ReactHTMLParser(testInstructions)}
            </li>
          ) : null}
          <>
            {!isAdvanced ? (
              <>
                <li className={classes.text}>
                  {`The clock will be set at the server. The countdown timer in
                  the top right corner of screen will display the remaining time
                  available for you to complete the examination. When the timer
                  reaches zero, the examination will end by itself. You will not
                  be required to end or submit your examination ${
                    enableHindiInstruction === true
                      ? "(सर्वर पर घड़ी सेट कर दी जाएगी। स्क्रीन के ऊपरी दाएं कोने में स्थित काउंटडाउन टाइमर आपको बताएगा कि परीक्षा को पूरा करने के लिए आपके पास कितना समय शेष रह गया है। जब टाइमर शून्य पर पहुंच जाएगा, तो परीक्षा स्वतः समाप्त हो जाएगी। आपको अपनी परीक्षा समाप्त करने या सबमिट करने की आवश्यकता नहीं होगी)"
                      : ""
                  }`}
                </li>
                <li className={classes.text}>
                  {`The Questions Palette displayed on the right side of screen
                  will show the status of each question using one of the
                  following symbols ${
                    enableHindiInstruction === true
                      ? "(स्क्रीन के दाईं ओर प्रदर्शित प्रश्न पट निम्नलिखित चिन्हों में से किसी एक का उपयोग करके प्रत्येक प्रश्न की स्थिति दिखाएगा)"
                      : ""
                  }:`}

                  <ol className={classes.icons}>
                    <li className={classes.centered}>
                      a).
                      <span style={{ marginRight: 10 }}>
                        <Box status="notvisited" size={30} />
                      </span>
                      {`You have not visited the question yet ${
                        enableHindiInstruction === true
                          ? "(आप अभी तक इस प्रश्न पर नहीं आए हैं)"
                          : ""
                      }`}
                    </li>
                    <li className={classes.centered}>
                      b).
                      <span style={{ marginRight: 10 }}>
                        <Box status="notanswered" size={30} />
                      </span>
                      {`You have not answered the question ${
                        enableHindiInstruction === true
                          ? "(आपने प्रश्न का उत्तर नहीं दिया है)"
                          : ""
                      }`}
                    </li>
                    <li className={classes.centered}>
                      c).
                      <span style={{ marginRight: 10 }}>
                        <Box status="answered" size={30} />
                      </span>
                      {`You have answered the question ${
                        enableHindiInstruction === true
                          ? "(आपने प्रश्न का उत्तर दिया है)"
                          : ""
                      }`}
                    </li>
                    <li className={classes.centered}>
                      d).
                      <span style={{ marginRight: 10 }}>
                        <Box status="markedforreview" size={30} />
                      </span>
                      {`You have NOT answered the question, but have marked the
                      question for review ${
                        enableHindiInstruction === true
                          ? "(आपने प्रश्न का उत्तर नहीं दिया है, लेकिन प्रश्न पर पुनर्विचार के लिए आपने इसे चिह्नित किया है)"
                          : ""
                      }`}
                    </li>
                    <li className={classes.centered}>
                      e).
                      <span style={{ marginRight: 10 }}>
                        <Box status="answeredandmarked" size={30} />
                      </span>
                      {`The question(s) "Answered and Marked for Review"
                      will be considered for evaluation ${
                        enableHindiInstruction === true
                          ? `(प्रश्न/प्रश्नों जिसका आपने "उत्तर दिया है और जिसे पुनर्विचार के लिए चिह्नित किया है" का मूल्यांकन किया जाएगा)`
                          : ""
                      }`}
                    </li>
                  </ol>
                </li>
                <li className={classes.text}>
                  {`You can click on the arrow which appears to the left of
                  question palette to collapse the question palette thereby
                  maximizing the question window. To view the question palette
                  again, you can click on the same arrow which appears on the
                  right side of the question window ${
                    enableHindiInstruction === true
                      ? `(आप प्रश्न पट के बाईं ओर दिखाई देने वाले तीर पर क्लिक करके प्रश्न पट को छोटा कर सकते हैं जिससे संपूर्ण प्रश्न विंडो दिखाई देगा। प्रश्न पट को फिर से देखने के लिए, आप उसी तीर पर क्लिक कर सकते हैं जो प्रश्न विंडो के दाईं ओर प्रदर्शित है)`
                      : ""
                  }`}
                </li>
                <li className={classes.text}>
                  You can click on to navigate to the bottom
                  <span>
                    <img
                      src={ICONS.ARROW_CIRCLE}
                      alt="Down Circle"
                      className={classes.arrowCircle}
                    />
                  </span>
                  and to navigate to the top
                  <span>
                    <img
                      src={ICONS.ARROW_CIRCLE}
                      alt="Up Circle"
                      className={classes.arrowCircleUp}
                    />
                  </span>
                  of the question, without scrolling
                  {enableHindiInstruction === true &&
                    ` (आप स्क्रॉल किए बिना प्रश्न के नीचे और प्रश्न के ऊपरी हिस्से पर नेविगेट करने के लिए क्लिक कर सकते हैं)`}
                </li>

                <p className={classes.boldText}>
                  {`Navigating to a Question ${
                    enableHindiInstruction === true
                      ? "(किसी प्रश्न के लिए नेविगेट करना)"
                      : ""
                  }:`}
                </p>
                <li className={classes.text}>
                  {`Procedure for answering a multiple choice type question ${
                    enableHindiInstruction === true
                      ? "(बहुविकल्पीय प्रकार के प्रश्न हेतु उत्तर देने की विधि)"
                      : ""
                  }:`}

                  <ol type="a">
                    <li className={classes.text}>
                      {`To select your answer, click on the button of one of the
                      options ${
                        enableHindiInstruction === true
                          ? "(अपना उत्तर चुनने के लिए किसी एक विकल्प के बटन पर क्लिक करें)"
                          : ""
                      }`}
                    </li>
                    <li className={classes.text}>
                      {`To deselect your chosen answer, click on the button of the
                      chosen option again or click on the Clear Response button ${
                        enableHindiInstruction === true
                          ? "(अपने चुने हुए उत्तर को अचयनित करने के लिए, चुने गए विकल्प के बटन पर पुनः क्लिक करें या क्लियर रिस्पांस बटन पर क्लिक करें)"
                          : ""
                      }`}
                    </li>
                    <li className={classes.text}>
                      {`To change your chosen answer, click on the button of
                      another option ${
                        enableHindiInstruction === true
                          ? "(अपने चुने हुए उत्तर को बदलने के लिए दूसरे विकल्प के बटन पर क्लिक करें)"
                          : ""
                      }`}
                    </li>
                    <li className={classes.text}>
                      {`To save your answer, you MUST click on the Save & Next
                      button ${
                        enableHindiInstruction === true
                          ? "(अपना उत्तर सेव करने के लिए आपको “सेव एंड नेक्स्ट” बटन पर क्लिक करना होगा)"
                          : ""
                      }`}
                    </li>
                    <li className={classes.text}>
                      {`To mark the question for review, click on the Mark for
                      Review & Next button ${
                        enableHindiInstruction === true
                          ? "(प्रश्न को पुनर्विचार हेतु चिह्नित करने के लिए, “मार्क फॉर रिव्यू एंड नेक्स्ट” बटन पर क्लिक करें)"
                          : ""
                      }`}
                    </li>
                  </ol>
                </li>
                <li className={classes.text}>
                  {`To change your answer to a question that has already been
                  answered, first select that question for answering and then
                  follow the procedure for answering that type of question ${
                    enableHindiInstruction === true
                      ? "(किसी ऐसे प्रश्न जिसका आपने उत्तर दे दिया है, का उत्तर बदलने के लिए पहले उस प्रश्न का चयन करें और फिर उस प्रकार के प्रश्न का उत्तर देने की विधि का पालन करें)"
                      : ""
                  }`}
                </li>

                <p className={classes.boldText}>
                  {`Navigating through sections ${
                    enableHindiInstruction === true
                      ? "(किसी खंड के लिए नेविगेट करना)"
                      : ""
                  }:`}
                </p>
                <li className={classes.text}>
                  {`Sections in this question paper are displayed on the top bar
                  of the screen. Questions in a section can be viewed by
                  clicking on the section name. The section you are currently
                  viewing is highlighted ${
                    enableHindiInstruction === true
                      ? "(इस प्रश्न पत्र के खंड स्क्रीन के शीर्ष पट्टी पर प्रदर्शित होंगें। खंड के नाम पर क्लिक करके किसी खंड के प्रश्नों को देखा जा सकता है। वर्तमान में आप जिस खंड को देख रहे हैं, उसे हाइलाइटेड किया गया है)"
                      : ""
                  }`}
                </li>
                <li className={classes.text}>
                  {`After clicking the Save & Next button on the last question for
                  a section, you will automatically be taken to the first
                  question of the next section ${
                    enableHindiInstruction === true
                      ? "(किसी खंड के अंतिम प्रश्न पर “सेव और नेक्स्ट” बटन पर क्लिक करने के बाद, आप स्वतः अगले खंड के पहले प्रश्न पर पहुंच जाएंगे)"
                      : ""
                  }`}
                </li>
                <li className={classes.text}>
                  {`You can shuffle between sections and questions anytime during
                  the examination as per your convenience only during the time
                  stipulated ${
                    enableHindiInstruction === true
                      ? "(आप परीक्षा के दौरान कभी भी खण्डों और प्रश्नों के बीच अपनी सुविधा के अनुसार (केवल नियत समय अवधि के दौरान) फेरबदल कर सकते हैं)"
                      : ""
                  }`}
                </li>
                <li className={classes.text}>
                  {`Candidates can view the corresponding section summary as part
                  of the legend that appears in every section above the question
                  palette ${
                    enableHindiInstruction === true
                      ? "(उम्मीदवार संबंधित खंड के सारांश को प्रश्न पट के ऊपर प्रत्येक खंड में दिखाई देने वाली संकेतिका के भाग के रूप में देख सकते हैं)"
                      : ""
                  }`}
                </li>
              </>
            ) : (
              <AdvancedInstructions classes={classes} />
            )}
          </>
        </ol>
        <hr className={classes.customHr} />
        {!isAdvanced ? (
          <>
            <p className={classes.note}>
              {`Please note all questions will appear in your default language ${
                enableHindiInstruction === true
                  ? "(कृपया ध्यान दें कि सभी प्रश्न आपकी डिफ़ॉल्ट भाषा में दिखाई देंगे)"
                  : ""
              }`}
            </p>
            <hr className={classes.customHr} />

            <p className={classes.text} onClick={() => setAccepted(true)}>
              <span>
                <input
                  type="checkbox"
                  checked={accepted}
                  onChange={(data) =>
                    onInstructionCheckoxClick(data.target.checked)}
                />
              </span>
              {`I have read and understood the instructions. All computer hardware
              allotted to me is in proper working condition. I declare that I am
              not in possession of / not wearing / not carrying any prohibited
              gadget like mobile phone, bluetooth devices etc. /any prohibited
              material with me into the Examination Hall.I agree that in case of
              not adhering to the instructions, I shall be liable to be debarred
              from this Test and/or to disciplinary action, which may include
              ban from future Tests / Examinations ${
                enableHindiInstruction === true
                  ? "(मैंने निर्देशों को पढ़ और समझ लिया है। मुझे आवंटित सभी कंप्यूटर हार्डवेयर ठीक से काम करने की स्थिति में हैं। मैं घोषणा करता/करती हूं कि परीक्षा हॉल में मेरे द्वारा कोई निषिद्ध गैजेट जैसे मोबाइल फोन, ब्लूटूथ डिवाइस आदि/ नहीं रखा/धारण/वहन किया गया है/कोई निषिद्ध सामग्री नहीं है/नहीं ले जा रहा/रही हूं। मैं सहमत हूं कि निर्देशों का पालन नहीं करने की स्थिति में, मुझे इस परीक्षा से वंचित किया जा सकता है और/या मेरे विरुद्ध अनुशासनात्मक कार्रवाई की जा सकती है, जिसमें भविष्य में टेस्ट/परीक्षाओं में बैठने से प्रतिबंधित करना भी शामिल हो सकता है)"
                  : ""
              }`}
            </p>
            <hr className={classes.customHr} />
            <div className={classes.centeredCss}>
              <Button
                type="green"
                disabled={!accepted}
                onClick={submitInstructions}
              >
                PROCEED
              </Button>
            </div>
          </>
        ) : (
          <div>
            <div className={classes.centeredCss}>
              <Button type="white" onClick={submitInstructions}>
                Next
              </Button>
            </div>
          </div>
        )}
        <hr className={classes.customHr} />
      </div>
    </div>
  );
};

Instructions.propTypes = {
  submitInstructions: PropTypes.func.isRequired,
  testInstructions: PropTypes.string,
};
Instructions.defaultProps = {
  testInstructions: "",
};

export default Instructions;
