import React from "react";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { ICONS } from "../../AppConfig";
import { secConvertor, greeting } from "../Utils/TimeConvertor";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "linear-gradient(to top, #da71eb, #810096)",
    padding: 20,
    color: "white",
    borderRadius: 12,
    margin: "10px 0",
  },
  spaceBetween: {
    display: "flex",
  },
  heading: {
    margin: "12px 0 0 0",
    fontSize: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  bolderText: {
    fontSize: 32,
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  completeMesssage: {
    fontSize: 12,
  },
  scoreText: {
    marginLeft: 40,
  },
  trophy: {
    height: 100,
  },
}));

const ResultCard = ({ timeTaken, score, availableStarting }) => {
  const classes = useStyles();
  const time = secConvertor(Number(timeTaken));
  const greetingText = greeting();
  const userProfile = JSON.parse(localStorage.getItem("user"));
  const quizDate = moment(availableStarting).format("MMMM D, YYYY");
  return (
    <div className={classes.root}>
      <div className={classes.spaceBetween}>
        <img src={ICONS.TROPHY_2X} alt="Trophy" className={classes.trophy} />
        <div style={{ marginLeft: 20 }}>
          <h1 className={classes.heading}>
            {`${greetingText}, ${userProfile ? userProfile.full_name : ""}`}
          </h1>
          <p className={classes.completeMesssage}>
            {`You've completed the quiz held on ${quizDate}`}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: 10,
        }}
      >
        <div className={classes.column}>
          <span className={classes.bolderText}>{time}</span>
          <span>Time Taken</span>
        </div>
        <div className={`${classes.column} ${classes.scoreText}`}>
          <span className={classes.bolderText}>{score}</span>
          <span>Score</span>
        </div>
      </div>
    </div>
  );
};

ResultCard.propTypes = {};

export default ResultCard;
