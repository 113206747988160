import { PRODUCTION_DATA, STAGING_DATA, DEVELOPMENT_DATA } from "../AppConfig";

let profileURL;

if (process.env.REACT_APP_BUILD_ENV === "staging") {
  profileURL = STAGING_DATA.PROFILE_API_TLLMS;
} else if (process.env.REACT_APP_BUILD_ENV === "production") {
  profileURL = PRODUCTION_DATA.PROFILE_API_TLLMS;
} else {
  profileURL = DEVELOPMENT_DATA.PROFILE_API_TLLMS;
}

const getProfileData = () => {
  const profileToken = sessionStorage.getItem("profile_token");
  const tokenType = sessionStorage.getItem("token_type");
  return fetch(`${profileURL}${profileToken}`, {
    headers: {
      Authorization: `${tokenType} ${profileToken}`,
    },
  }).then(async (resp) => {
    const data = await resp.json();
    localStorage.setItem("user", JSON.stringify(data));
    return data;
  });
};

export default getProfileData;
