import React, { useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import api from "../../../services/api";
import {
  PRODUCTION_DATA,
  STAGING_DATA,
  DEVELOPMENT_DATA,
} from "../../../AppConfig";
import { showErrorPage } from "../../Utils/CommonFunctions";
import Loader from "../Loader/Loader";

function StartTest() {
  const location = useLocation();
  const [skipOAuth, setSkipOAuth] = React.useState(false);

  useEffect(() => {
    let OAuth;
    if (process.env.REACT_APP_BUILD_ENV === "staging") {
      OAuth = STAGING_DATA.OAUTH_URL;
    } else if (process.env.REACT_APP_BUILD_ENV === "production") {
      OAuth = PRODUCTION_DATA.OAUTH_URL;
    } else {
      OAuth = DEVELOPMENT_DATA.OAUTH_URL;
    }

    const authenticate = () => {
      const param = new URLSearchParams(location.search);
      const token = param.get("access_token");
      const clientId = param.get("assessment_id");
      const isFreeUser = param.get("is_free_user");
      const isQuiz = param.get("is_quiz");
      const bnatGrade = param.get("bnat_grade");
      const bnatCohort = param.get("bnat_cohort");
      const bnatMedium = param.get("bnat_medium");
      const assessmentType = param.get("assessment_type");
      localStorage.setItem("user_token", token);
      sessionStorage.setItem("test_client_id", clientId);
      sessionStorage.setItem("test_assessment_type", assessmentType);
      if (isFreeUser === "true") {
        localStorage.setItem("is_free_user", isFreeUser);
        if (bnatGrade !== null) {
          sessionStorage.setItem("bnat_grade", bnatGrade);
        }
        if (bnatCohort !== null) {
          sessionStorage.setItem("bnat_cohort", bnatCohort);
        }
        if (bnatMedium !== null) {
          sessionStorage.setItem("bnat_medium", bnatMedium);
        }
        setSkipOAuth(true);
      } else {
        api.get(`/authenticate?access_token=${token}`).then((resp) => {
          if (resp.status === 200) {
            if (isQuiz === "true") {
              sessionStorage.setItem("destination", "start_quiz");
            } else {
              sessionStorage.setItem("destination", "start_test");
            }
            window.location.replace(OAuth);
          } else if (resp.status > 399) {
            showErrorPage(resp.status);
          }
        });
      }
    };
    authenticate();
  }, [location.search]);

  return (
    <div>
      {skipOAuth === true && <Redirect to="/take-test" />}
      {skipOAuth === false && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
    </div>
  );
}

export default StartTest;
