import * as NotificationTypes from "./NotificationCenter.types";

const INITIAL_STATE = {
  loading: false,
};

const NotificationCenterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /* Get Notification Actions */
    case NotificationTypes.GET_NOTIFICATION_PREFERENCES:
      return { ...state, loading: true };
    case NotificationTypes.GET_NOTIFICATION_PREFERENCES_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case NotificationTypes.GET_NOTIFICATION_PREFERENCES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    /* Update Notification Actions */
    case NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES:
      return { ...state, loading: true };
    case NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, preferences: action.payload },
      };
    case NotificationTypes.UPDATE_NOTIFICATION_PREFERENCES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default NotificationCenterReducer;
