export default {
  mockTestJee: [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: null,
          chapters: `Physics and measurement; Rotational motion; Thermodynamics; Kinematics; Work, energy and power; Properties of solids and liquids; Gravitation; Laws of motion; Oscillations and waves; Electronic devices; Kinetic theory of gases; Current electricity; Communication systems; Electromagnetic induction and alternating currents; Magnetic effects of current and magnetism; Optics; Electromagnetic waves; Atoms and nuclei; Electrostatics; Dual nature of matter and radiation; Experimental Skills.`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: null,
          chapters: [
            {
              section: "Physical Chemistry",
              chapters:
                "Some basic concepts in chemistry; States of matter; Atomic structure; Chemical bonding and molecular structure; Chemical thermodynamics; Solutions; Equilibrium; Redox reactions and electrochemistry; Chemical kinetics; Surface chemistry.",
            },
            {
              section: "Organic Chemistry",
              chapters:
                "Purification and characterisation of organic compounds; Hydrocarbons; Chemistry in everyday life; Principles related to practical chemistry; Organic compounds containing halogens; Organic compounds containing oxygen; Organic compounds containing nitrogen; Polymers; Some basic principles of organic chemistry; Biomolecules.",
            },
            {
              section: "Inorganic Chemistry",
              chapters:
                "Classification of elements and periodicity in properties; Hydrogen; Block elements (alkali and alkaline earth metals); P Block elements group 13 to group 18 elements; d- and f - block elements; Co-ordination compounds; Environmental chemistry; General principles and processes of isolation of metals.",
            },
          ],
        },
      ],
    },
    {
      sub: "Mathematics",
      classAndchapters: [
        {
          class: null,
          chapters: `Complex numbers and quadratic equations; Matrices and determinants; Sets, relations and functions; Mathematical induction; Permutations and combinations; Mathematical reasoning; Limit, continuity and differentiability; Integral calculus; Three-dimensional geometry; Differential equations; Binomial theorem and its simple applications; Sequence and Series; Vector algebra; Statistics and probability; Trigonometry; Coordinate geometry.`,
        },
      ],
    },
  ],
  mockTestNeet: [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Physical world and measurement; Kinematics; Laws of Motion; Work, Energy and Power; Motion of System of Particles and Rigid Body; Gravitation; Properties of Bulk Matter; Thermodynamics; Behaviour of Perfect Gas and Kinetic Theory; Oscillations and Waves`,
        },
        {
          class: 12,
          chapters: `Electrostatics; Current Electricity; Magnetic Effects of Current and Magnetism; Electromagnetic Induction and Alternating Currents; Electromagnetic Waves; Optics; Dual Nature of Matter and Radiation; Atoms and Nuclei; Electronic Devices`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: 11,
          chapters: `Some Basic Concepts of Chemistry; Structure of Atom; Classification of Elements and Periodicity in Properties; Chemical Bonding and Molecular Structure; States of Matter: Gases and Liquids; Thermodynamics; Equilibrium; Redox Reactions; Hydrogen; s-Block Element (Alkali and Alkaline earth metals); Some p-Block Elements; Organic Chemistry- Some Basic Principles and Techniques; Hydrocarbons; Environmental Chemistry`,
        },
        {
          class: 12,
          chapters: `Solid State; Solutions; Electrochemistry; Chemical Kinetics; Surface Chemistry; General Principles and Processes of Isolation of Elements; p- Block Elements; d and f Block Elements; Coordination Compounds; Haloalkanes and Haloarenes; Alcohols, Phenols and Ethers; Aldehydes, Ketones and Carboxylic Acids; Organic Compounds Containing Nitrogen; Biomolecules; Polymers; Chemistry in EverydayLife`,
        },
      ],
    },
    {
      sub: "Biology",
      classAndchapters: [
        {
          class: 11,
          chapters: `Diversity in Living World; Structural Organisation in Animals and Plants; Cell Structure and Function; Plant Physiology; Human physiology`,
        },
        {
          class: 12,
          chapters: `Reproduction; Genetics and Evolution; Biology and Human Welfare; Biotechnology and Its Applications; Ecology and environment`,
        },
      ],
    },
  ],
  mockTestBitsat: [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: null,
          chapters: `Units & Measurements, Kinematics, Newton's Laws of Motion, Impulse & Momentum, Work & Energy, Rotational Motion, Gravitation, Mechanics of Solids & Fluids, Oscillations, Waves, Heat & Thermodynamics, Electrostatics, Current Electricity, Magnetic Effect of Current, Electromagnetic Induction, Optics, Modern Physics, Electronic Devices`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: null,
          chapters: `States of Matter, Atomic Structure, Periodicity, Thermodynamics, Physical & Chemical Equilibrium, Electrochemistry, Chemical Kinetics, Hydrogen & s-Block elements, p-, d- and f- Block elements, Principles of Organic Chemistry & Hydrocarbons, Stereochemistry, Organic Compounds with Functional Groups Containing Oxygen & Nitrogen, Biological, Industrial & Environmental Chemistry, Theoretical Principles of Experimental Chemistry`,
        },
      ],
    },
    {
      sub: "English Proficiency & Logical Reasoning",
      classAndchapters: [
        {
          class: null,
          chapters: [
            {
              section: "English Proficiency",
              chapters: `Grammar, Vocabulary, Reading Comprehension, Composition`,
            },
            {
              section: "Logical Reasoning",
              chapters: `Verbal Reasoning, Non-verbal Reasoning`,
            },
          ],
        },
      ],
    },
    {
      sub: "Mathematics",
      classAndchapters: [
        {
          class: null,
          chapters: `Algebra, Trigonometry, Two-dimensional Coordinate Geometry, Three dimensional Coordinate Geometry, Differential Calculus, Integral Calculus, Ordinary Differential Equations, Probability, Vectors, Statistics, Linear Programming, Mathematical Modeling		
           `,
        },
      ],
    },
  ],
  "BNAT4-5": [
    {
      sub: "Mathematical Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Numerical operations; Everyday Maths; Lines and shapes; Measurements of figures; Data Handling`,
        },
      ],
    },
    {
      sub: "Verbal Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Noun, Pronoun, Verbs; Prepositions, Conjunctions, Punctuations; Tenses; Gap Filling/Sentence; Word Meanings`,
        },
      ],
    },
    {
      sub: "Logical Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Patterns; Mirror Images; Blood Relations; Odd one out; Dates and Calenders; Direction sense and distances; Notational and logic based Mathematics`,
        },
      ],
    },
    {
      sub: "Environmental Science",
      classAndchapters: [
        {
          class: null,
          chapters: `Plants and Animals; Biology in Human Welfare; Natural Resources; Society and Lifestyle; Everyday Science`,
        },
      ],
    },
  ],
  "BNAT6-7": [
    {
      sub: "Mathematical Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Number System; Arithmetic; Algebra; Geometry; Mensuration; Data Handling`,
        },
      ],
    },
    {
      sub: "Verbal Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Noun, Pronoun,Verbs; Prepositions, Conjunctions, Punctuations. Clauses, Modal Auxiliaries; Gap Filling/ Sentence; Dialogue Completion / Framing; Sentence or it's part - Reordering/ Omission; Active and Passive Voice; Reported speech; Tenses; Phrases and Idioms`,
        },
      ],
    },
    {
      sub: "Logical Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Patterns; Mirror/ Water Images; Blood Relations; Odd one out; Clocks; Venn Diagrams; Dates and Calenders; Direction sense and distances; Notational and logic based Mathematics`,
        },
      ],
    },
    {
      sub: "Science",
      classAndchapters: [
        {
          class: null,
          chapters: `Motion, Time and Distance; Electricity & Magnetism; Light: Reflection, Mirrors and Lenses; Heat and Temperature; Nutrition in Plants and Animals; Ecology: Environment and Waste Management; Everyday Science; Acids,bases and salts; Body Movements; Human Body: Respiration, Transportation, Reproduction; General chemistry`,
        },
      ],
    },
    {
      sub: "Social Science",
      classAndchapters: [
        {
          class: null,
          chapters: `Mauryan and Mughal Empire; Gupta Empire and Post-gupta Period; Delhi Sultanate; Rig Vedic Period and Iron Age; Diversity; Types of Government; Equality; Public Health; Universe and Solar System; Latitudes and Longitudes; Rotation and Revolutions of the Earth; Domains and Landforms of the Earth; India - Geographical Features, Climate, Vegetation and Wildlife; Plate Tectonics; Maps, Transport and Communication`,
        },
      ],
    },
  ],
  "BNAT8-9": [
    {
      sub: "Mathematical Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Number System; Arithmetic; Algebra; Geometry; Mensuration; Data Science`,
        },
      ],
    },
    {
      sub: "Verbal Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Noun, Pronoun,Verbs, Adverbs; Prepositions, Conjunctions, Punctuations. Clauses, Modal Auxiliaries; Order of Words and Clauses; Direct,Indirect and Reported Speech; Active and Passive Voice; Phrases and Idioms; Tenses; Gap Filling/ Sentence; Dialogue Completion / Framing; Sentence or it's part - Reordering/ Omission; Determiners and Modals`,
        },
      ],
    },
    {
      sub: "Logical Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Patterns; Mirror/ Water Images; Blood Relations; Odd one out; Speed and Distance Problems; Clocks; Venn Diagrams; Coding-Decoding; Dates and Calenders; Direction sense and distances; Figure Matrices; Notational and logic based Mathematics`,
        },
      ],
    },
    {
      sub: "Science",
      classAndchapters: [
        {
          class: null,
          chapters: `Mechanics; Electricity & its Effect; Light; Sound, Oscillations and Waves; Cell and Tissues; Natural Phenomena; Control and Coordination; Heredity and Evolution; Microorganisms; Crop Production and Management; Matter; Metals and Non- Metals; Natural Resources and Sources of Energy; General chemistry; Environmental Science`,
        },
      ],
    },
    {
      sub: "Social Science",
      classAndchapters: [
        {
          class: null,
          chapters: `Indian Freedom Struggle; India Post-independence; The World Wars; French and Russian Revolution; Indian Constitution and Its Design/Philosophy; Democracy; Role of Gender, Religion and Caste in Democracy; Elections and Political Parties; Marginalization and Social Justice; Power Sharing and Federalism; Resources and Development; India - Geographical Features, Climate, Vegetation and Wildlife; River Systems and their Classification; Agriculture; Mineral and Energy Sources; Industries`,
        },
      ],
    },
  ],
  BNAT10: [
    {
      sub: "Mathematical Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Arithmetic; Algebra; Statstics and Probablity; Mensuration; Trigonometry; Data Science`,
        },
      ],
    },
    {
      sub: "Verbal Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Verb Tenses; Modals; Subject – verb concord; Reported speech; Determiners; Clauses; Use of passive voice; Prepositions; Articles; Subject Verb Agreement; Parajumbles; Syllogism; Para completion; Work usage`,
        },
      ],
    },
    {
      sub: "Logical Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Patterns; Mirror/ Water Images; Blood Relations; Odd one out; Speed and Distance Problems; Clocks; Venn Diagrams; Coding-Decoding; Dates and Calenders; Direction sense and distances; Figure Matrices; Notational and logic based Mathematics`,
        },
      ],
    },
    {
      sub: "Science",
      classAndchapters: [
        {
          class: null,
          chapters: `Electrostats & Magnetism; Optics; Natural resources; Life Processes in Plants and Animals; Ecology; Chemical Reactions and Equations; Acids, Bases and Salts; Metals and Non-Metals; Carbon and its Compounds; Periodic Classification of Elements`,
        },
      ],
    },
    {
      sub: "Social Science",
      classAndchapters: [
        {
          class: null,
          chapters: `Federalism; Social Problems; Social Movements; Political Parties; Outcomes and Challenges to Democracy; Agriculture; Resources and their Development; Industries; Transport and Communication; World events; Independence movement; Economic and Social History; Globalisation and the Indian Economy; Money and Credit; Consumer Rights`,
        },
      ],
    },
  ],
  BNAT10moving: [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: 9,
          chapters: `Motion; Force & Laws of Motion; Gravitation; Work & Energy; Sound`,
        },
        {
          class: 10,
          chapters: `Light-Reflection & Refraction; The Human Eye & The Colourful World; Electricity; Magnetic Effects of Electric Current; Sources of Energy`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: 9,
          chapters: `Matter in Our Surroundings; Is Matter Around Us Pure; Atoms & Molecules; Structure of Atoms`,
        },
        {
          class: 10,
          chapters: `Chemical Reactions & Equations; Acid, Bases & Salts; Metals & Non-Metals; Carbon & Its Compounds; Periodic Classification of Elements`,
        },
      ],
    },
    {
      sub: "Mental Ability",
      classAndchapters: [
        {
          class: null,
          chapters: `Quantitative Aptitude; Logical Reasoning; Data Interpretation, Verbal Ability`,
        },
      ],
    },
  ],
  BNAT11JEE: [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Physical World; Units and Measurements; Motion in a Straight Line; Motion in a Plane; Laws of Motion; Work, Energy and Power; System of Particles and Rotational Motion; Gravitation`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: 11,
          chapters: `Some Basic Concepts of Chemistry; Structure of Atom; Classification of Elements and Periodicity in Properties; Chemical Bonding and Molecular Structure; State of Matter; Thermodynamics; Equilibrium`,
        },
      ],
    },
    {
      sub: "Mathematics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Sets; Relations and Functions; Trigonometric Functions; Complex Numbers and Quadratic Equations; Linear Inequalities; Permutation and Combinations; Binomial Theorem; Sequences and Series`,
        },
      ],
    },
  ],
  BNAT11NEET: [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Physical World; Units and Measurements; Motion in a Straight Line; Motion in a Plane; Laws of Motion; Work, Energy and Power; System of Particles and Rotational Motion; Gravitation`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: 11,
          chapters: `Some Basic Concepts of Chemistry; Structure of Atom; Classification of Elements and Periodicity in Properties; Chemical Bonding and Molecular Structure; State of Matter; Thermodynamics; Equilibrium`,
        },
      ],
    },
    {
      sub: "Biology",
      classAndchapters: [
        {
          class: 11,
          chapters: `The Living World; Biological Classification; Plant Kingdom; Animal Kingdom; Morphology of Flowering Plants; Anatomy of Flowering Plants; Structural Organisation in Animals; Cell: The Unit of Life; Biomolecules; Cell Cycle and Cell Division; Transport in Plants`,
        },
      ],
    },
  ],
  "BNAT11JEE+NEET": [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Physical World; Units and Measurements; Motion in a Straight Line; Motion in a Plane; Laws of Motion; Work, Energy and Power; System of Particles and Rotational Motion; Gravitation`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: 11,
          chapters: `Some Basic Concepts of Chemistry; Structure of Atom; Classification of Elements and Periodicity in Properties; Chemical Bonding and Molecular Structure; State of Matter; Thermodynamics; Equilibrium`,
        },
      ],
    },
    {
      sub: "Mathematics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Sets; Relations and Functions; Trigonometric Functions; Complex Numbers and Quadratic Equations; Linear Inequalities; Permutation and Combinations; Binomial Theorem; Sequences and Series`,
        },
      ],
    },
    {
      sub: "Biology",
      classAndchapters: [
        {
          class: 11,
          chapters: `The Living World; Biological Classification; Plant Kingdom; Animal Kingdom; Morphology of Flowering Plants; Anatomy of Flowering Plants; Structural Organisation in Animals; Cell: The Unit of Life; Biomolecules; Cell Cycle and Cell Division; Transport in Plants`,
        },
      ],
    },
  ],
  "BNAT12-13JEE": [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Physical World; Units and Measurements; Motion in a Straight Line; Motion in a Plane; Laws of Motion; Work, Energy and Power; System of Particles and Rotational Motion; Gravitation; Mechanical Properties of Solids; Mechanical Properties of Fluids; Thermal Properties of Matter; Thermodynamics; Kinetic Theory; Oscillations; Waves`,
        },
        {
          class: 12,
          chapters: `Electric Charges and Fields; Electrostatic Potential and Capacitance; Current Electricity; Moving Charges and Magnetism; Magnetism and Matter; Electromagnetic Induction; Alternating Current; Electromagnetic Waves; Ray Optics and Optical Instruments; Wave Optics; Dual Nature of Radiation and Matter; Atoms; Nuclei; Semiconductor Electronics : Materials, Devices and Simple Circuits; Communication Systems`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: 11,
          chapters: `Some Basic Concepts of Chemistry; Structure of Atom; Classification of Elements and Periodicity in Properties; Chemical Bonding and Molecular Structure; States of Matter; Thermodynamics; Equilibrium; Redox Reactions; Hydrogen; The s-Block Elements; The p-Block Elements; Organic Chemistry - Some Basic Principles and Techniques; Hydrocarbons; Environmental Chemistry`,
        },
        {
          class: 12,
          chapters: `The Solid State; Solutions; Electrochemistry; Chemical Kinetics; Surface Chemistry; General Principles and Processes of Isolation of Elements; The p-Block Elements; The d-and f-Block Elements; Coordination Compounds; Haloalkanes and Haloarenes; Alcohols, Phenols and Ethers; Aldehydes, Ketones and Carboxylic Acids; Amines; Biomolecules; Polymers; Chemistry in Everyday Life`,
        },
      ],
    },
    {
      sub: "Mathematics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Sets; Relations and Functions; Trigonometric Functions; Principle of Mathematical Induction; Complex Numbers and Quadratic Equations; Linear Inequalities; Permutation and Combinations; Binomial Theorem; Sequences and Series; Straight Lines; Conic Sections; Introduction to Three Dimensional Geometry; Limits and Derivatives; Mathematical Reasoning; Statistics; Probability`,
        },
        {
          class: 12,
          chapters: `Relation and Functions; Inverse Trigonometric Functions; Matrices; Determinants; Continuity and Differentiability; Application of Derivatives; Integrals; Application of Integrals; Differential Equations; Vector Algebra; Three Dimensional Geometry; Linear Programming; Probability`,
        },
      ],
    },
  ],
  "BNAT12-13NEET": [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Physical World; Units and Measurements; Motion in a Straight Line; Motion in a Plane; Laws of Motion; Work, Energy and Power; System of Particles and Rotational Motion; Gravitation; Mechanical Properties of Solids; Mechanical Properties of Fluids; Thermal Properties of Matter; Thermodynamics; Kinetic Theory; Oscillations; Waves`,
        },
        {
          class: 12,
          chapters: `Electric Charges and Fields; Electrostatic Potential and Capacitance; Current Electricity; Moving Charges and Magnetism; Magnetism and Matter; Electromagnetic Induction; Alternating Current; Electromagnetic Waves; Ray Optics and Optical Instruments; Wave Optics; Dual Nature of Radiation and Matter; Atoms; Nuclei; Semiconductor Electronics : Materials, Devices and Simple Circuits; Communication Systems`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: 11,
          chapters: `Some Basic Concepts of Chemistry; Structure of Atom; Classification of Elements and Periodicity in Properties; Chemical Bonding and Molecular Structure; States of Matter; Thermodynamics; Equilibrium; Redox Reactions; Hydrogen; The s-Block Elements; The p-Block Elements; Organic Chemistry - Some Basic Principles and Techniques; Hydrocarbons; Environmental Chemistry`,
        },
        {
          class: 12,
          chapters: `The Solid State; Solutions; Electrochemistry; Chemical Kinetics; Surface Chemistry; General Principles and Processes of Isolation of Elements; The p-Block Elements; The d-and f-Block Elements; Coordination Compounds; Haloalkanes and Haloarenes; Alcohols, Phenols and Ethers; Aldehydes, Ketones and Carboxylic Acids; Amines; Biomolecules; Polymers; Chemistry in Everyday Life`,
        },
      ],
    },
    {
      sub: "Biology",
      classAndchapters: [
        {
          class: 11,
          chapters: `The Living World; Biological Classification; Plant Kingdom; Animal Kingdom; Morphology of Flowering Plants; Anatomy of Flowering Plants; Structural Organisation in Animals; Cell: The Unit of Life; Biomolecules; Cell Cycle and Cell Division; Transport in Plants; Mineral Nutrition; Photosynthesis in Higher Plants; Respiration in Plants; Plant Growth and Development; Digestion and Absorption; Breathing and Exchange of Gases; Excretory Products and Their Elimination; Body Fluids and Circulation; Locomotion and Movement; Neural Control and Coordination; Chemical Coordination and Integration`,
        },
        {
          class: 12,
          chapters: `Reproduction in Organisms; Sexual Reproduction in Flowering plants; Human Reproduction; Reproductive Health; Principles of Inheritance and Variations; Molecular Basis of Inheritance; Evolution; Human Health and Disease; Strategies for Enhancement in Food Production; Microbes in Human Welfare; Biotechnology: Principles and processes; Biotechnology and its applications; Organisms and Populations; Ecosystem; Biodiversity and Conservation; Environmental Issues`,
        },
      ],
    },
  ],
  "BNAT12-13JEE+NEET": [
    {
      sub: "Physics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Physical World; Units and Measurements; Motion in a Straight Line; Motion in a Plane; Laws of Motion; Work, Energy and Power; System of Particles and Rotational Motion; Gravitation; Mechanical Properties of Solids; Mechanical Properties of Fluids; Thermal Properties of Matter; Thermodynamics; Kinetic Theory; Oscillations; Waves`,
        },
        {
          class: 12,
          chapters: `Electric Charges and Fields; Electrostatic Potential and Capacitance; Current Electricity; Moving Charges and Magnetism; Magnetism and Matter; Electromagnetic Induction; Alternating Current; Electromagnetic Waves; Ray Optics and Optical Instruments; Wave Optics; Dual Nature of Radiation and Matter; Atoms; Nuclei; Semiconductor Electronics : Materials, Devices and Simple Circuits; Communication Systems`,
        },
      ],
    },
    {
      sub: "Chemistry",
      classAndchapters: [
        {
          class: 11,
          chapters: `Some Basic Concepts of Chemistry; Structure of Atom; Classification of Elements and Periodicity in Properties; Chemical Bonding and Molecular Structure; States of Matter; Thermodynamics; Equilibrium; Redox Reactions; Hydrogen; The s-Block Elements; The p-Block Elements; Organic Chemistry - Some Basic Principles and Techniques; Hydrocarbons; Environmental Chemistry`,
        },
        {
          class: 12,
          chapters: `The Solid State; Solutions; Electrochemistry; Chemical Kinetics; Surface Chemistry; General Principles and Processes of Isolation of Elements; The p-Block Elements; The d-and f-Block Elements; Coordination Compounds; Haloalkanes and Haloarenes; Alcohols, Phenols and Ethers; Aldehydes, Ketones and Carboxylic Acids; Amines; Biomolecules; Polymers; Chemistry in Everyday Life`,
        },
      ],
    },
    {
      sub: "Mathematics",
      classAndchapters: [
        {
          class: 11,
          chapters: `Sets; Relations and Functions; Trigonometric Functions; Principle of Mathematical Induction; Complex Numbers and Quadratic Equations; Linear Inequalities; Permutation and Combinations; Binomial Theorem; Sequences and Series; Straight Lines; Conic Sections; Introduction to Three Dimensional Geometry; Limits and Derivatives; Mathematical Reasoning; Statistics; Probability`,
        },
        {
          class: 12,
          chapters: `Relation and Functions; Inverse Trigonometric Functions; Matrices; Determinants; Continuity and Differentiability; Application of Derivatives; Integrals; Application of Integrals; Differential Equations; Vector Algebra; Three Dimensional Geometry; Linear Programming; Probability`,
        },
      ],
    },
    {
      sub: "Biology",
      classAndchapters: [
        {
          class: 11,
          chapters: `The Living World; Biological Classification; Plant Kingdom; Animal Kingdom; Morphology of Flowering Plants; Anatomy of Flowering Plants; Structural Organisation in Animals; Cell: The Unit of Life; Biomolecules; Cell Cycle and Cell Division; Transport in Plants; Mineral Nutrition; Photosynthesis in Higher Plants; Respiration in Plants; Plant Growth and Development; Digestion and Absorption; Breathing and Exchange of Gases; Excretory Products and Their Elimination; Body Fluids and Circulation; Locomotion and Movement; Neural Control and Coordination; Chemical Coordination and Integration`,
        },
        {
          class: 12,
          chapters: `Reproduction in Organisms; Sexual Reproduction in Flowering plants; Human Reproduction; Reproductive Health; Principles of Inheritance and Variations; Molecular Basis of Inheritance; Evolution; Human Health and Disease; Strategies for Enhancement in Food Production; Microbes in Human Welfare; Biotechnology: Principles and processes; Biotechnology and its applications; Organisms and Populations; Ecosystem; Biodiversity and Conservation; Environmental Issues`,
        },
      ],
    },
  ],
  BNATIASHindi: [
    {
      sub: "समसामयिक घटना",
      classAndchapters: [
        {
          class: null,
          chapters: `राष्ट्रीय और अंतर्राष्ट्रीय महत्व की सामयिक घटनाएं।`,
        },
      ],
    },
    {
      sub: "भारत का इतिहास और भारतीय राष्ट्रीय आन्दोलन",
      classAndchapters: [
        {
          class: null,
          chapters: `भारत का प्राचीन इतिहास,मध्यकालीन भारतीय इतिहास,आधुनिक भारत-भारतीय राष्ट्रीय आंदोलन।`,
        },
      ],
    },
    {
      sub: "भारत एवं विश्व भूगोल",
      classAndchapters: [
        {
          class: null,
          chapters: `भारत एवं विश्व का प्राकृतिक, सामाजिक, आर्थिक भूगोल।`,
        },
      ],
    },
    {
      sub: "भारतीय राज्यतन्त्र और शासन",
      classAndchapters: [
        {
          class: null,
          chapters: `संविधान, राजनैतिक प्रणाली, पंचायती राज, लोक  नीति, अधिकारों संबंधी मुद्दे आदि।`,
        },
      ],
    },
    {
      sub: "भारतीय अर्थव्यवस्था",
      classAndchapters: [
        {
          class: null,
          chapters: `आर्थिक और सामाजिक विकास - सतत विकास, गरीबी, समावेशन, जनसांख्यिकी, सामाजिक क्षेत्र में की गई पहल आदि।`,
        },
      ],
    },
    {
      sub:
        "पर्यावरणीय पारिस्थितिकी जैव-विविधता और मौसम परिवर्तन संबंधी सामान्य मुद्दे",
      classAndchapters: [
        {
          class: null,
          chapters: `इसके लिए विषयगत विशेषज्ञता आवश्यक नहीं है।`,
        },
      ],
    },
    {
      sub: "सामान्य विज्ञान",
      classAndchapters: [
        {
          class: null,
          chapters: ``,
        },
      ],
    },
  ],
  BNATIASEnglish: [
    {
      sub: "Current Affairs",
      classAndchapters: [
        {
          class: null,
          chapters: `Current events of national and international importance.`,
        },
      ],
    },
    {
      sub: "History of India and Indian National Movement",
      classAndchapters: [
        {
          class: null,
          chapters: `Ancient History of India, Medieval Indian History, Modern India -Indian National Movement`,
        },
      ],
    },
    {
      sub: "Indian and World Geography",
      classAndchapters: [
        {
          class: null,
          chapters: `Physical, Social, Economic Geography of India and the World`,
        },
      ],
    },
    {
      sub: "Indian Polity and Governance",
      classAndchapters: [
        {
          class: null,
          chapters: `Constitution, Political System, Panchayati Raj, Public Policy, Rights Issues, etc`,
        },
      ],
    },
    {
      sub: "Indian Economy",
      classAndchapters: [
        {
          class: null,
          chapters: `Economic and Social Development-Sustainable Development, Poverty, Inclusion, Demographics, Social Sector Initiatives, etc`,
        },
      ],
    },
    {
      sub:
        "General issues on Environmental ecology, Biodiversity and Climate Change",
      classAndchapters: [
        {
          class: null,
          chapters: `Do not require subject specialization`,
        },
      ],
    },
    {
      sub: "General Science",
      classAndchapters: [
        {
          class: null,
          chapters: ``,
        },
      ],
    },
  ],
};
