import {
  PRODUCTION_DATA,
  STAGING_DATA,
  DEVELOPMENT_DATA,
} from "../../AppConfig";

export const navigateToLearn = () => {
  if (process.env.REACT_APP_BUILD_ENV === "staging") {
    window.location.replace(STAGING_DATA.LEARN_URL);
  } else if (process.env.REACT_APP_BUILD_ENV === "production") {
    window.location.replace(PRODUCTION_DATA.LEARN_URL);
  } else {
    window.location.replace(DEVELOPMENT_DATA.LEARN_URL);
  }
};

export const showErrorPage = (code) => {
  window.location.replace(`${window.location.origin}/error?code=${code}`);
};
