/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/UtilComponents/Loader/Loader";
import Header from "../components/UtilComponents/Header/Header";
import "../assets/stylesheets/MockDetail.css";
import BnatWelcomeSection from "../components/BNAT/BnatWelcomeSection";
import FaqSection from "../components/MockTestDetail/FaqSection";
import IASFaqSection from "../components/MockTestDetail/IasFaqSection";
import VideoSection from "../components/MockTestDetail/VideoSection";
import ResultSection from "../components/MockTestDetail/ResultSection";
import SyllabusSection from "../components/MockTestDetail/SyllabusSection";
import { GET_BNAT_DETAILS } from "../redux/BNAT/BNAT.types";
import fetchGradeCohortDetails from "../components/Utils/BNATHelpers";
import { EXAM_TYPES, LANGUAGE } from "../AppConfig";
import { showErrorPage } from "../components/Utils/CommonFunctions";
import SideMenu from "../components/UtilComponents/SideMenu/SideMenu";

const useStyles = makeStyles((theme) => ({
  vThumbnail: {
    width: "30px",
    height: "30px",
  },
  sections: {
    marginTop: "5%",
    paddingRight: "5%",
    paddingLeft: "10%",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5%",
    },
  },
  detailContainer: {
    width: "100%",
  },
  fullList: {
    width: "auto",
  },
  drawerTxt: {
    textAlign: "center",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },
}));

export default function BNAT() {
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("user_token");

  // Selectors
  const presentState = useSelector((state) => state.bnat);

  if (presentState.bnat && presentState.bnat.grade === 15) {
    document.title = "Byju's | BNST IAS Detail";
  } else if (presentState.bnat) {
    document.title = "Byju's | BNAT Detail";
  }

  // States
  const [activeNav, setActiveNav] = React.useState("1");
  const [drawerState, setdrawerState] = React.useState({
    bottom: false,
  });
  const [language, setLanguage] = React.useState(LANGUAGE.ENGLISH);

  // Variables
  let isIas = presentState.bnat && presentState.bnat.grade === 15;
  const classes = useStyles();

  // Functions
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setdrawerState({ ...drawerState, [anchor]: open });
  };

  const executeScroll = (sectionId) => {
    let section = "";
    switch (sectionId) {
      case "1":
        section = "welcome-section";
        break;
      case "2":
        section = "syllabus-section";
        break;
      case "3":
        section = "faq-section";
        break;
      case "4":
        section = "video-section";
        break;
      case "5":
        section = "results-section";
        break;
      default:
    }
    const element = document.getElementById(section);
    const offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const executeScrollAndToggleDrawer = (sectionId) => {
    executeScroll(sectionId);
    setdrawerState({ ...drawerState, bottom: false });
  };

  const englishMenu = [
    `Welcome to ${isIas ? "BNST" : "BNAT"}`,
    "Syllabus",
    "FAQs",
    "BYJU'S Videos",
    "Result",
  ];

  const hindiMenu = [
    "BNST में आपका स्वागत है",
    "पाठ्यक्रम",
    "प्रायः पूछे जाने वाले प्रश्न",
    "BYJU'S वीडियो",
    "परिणाम",
  ];

  const menu = () => {
    if (language === LANGUAGE.ENGLISH) {
      return englishMenu;
    }
    return hindiMenu;
  };

  const list = (anchor) => (
    <div
      className={classes.fullList}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menu().map((text, index) => (
          <ListItem
            button
            key={text}
            onClick={() => executeScrollAndToggleDrawer(`${index + 1}`)}
          >
            <ListItemText primary={text} className={classes.drawerTxt} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  useEffect(() => {
    if (userToken === null) {
      showErrorPage(401);
    } else {
      dispatch({ type: GET_BNAT_DETAILS });
    }
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(presentState).length !== 0) {
      if (presentState.bnat.result_enabled === true) {
        setTimeout(() => executeScroll("5"), 100);
      }
      if (presentState.bnat.grade === 15 && presentState.bnat.medium) {
        isIas = true;
        setLanguage(presentState.bnat.medium);
      }
      sessionStorage.setItem("bnat_grade", presentState.bnat.grade);
      sessionStorage.setItem("bnat_cohort", presentState.bnat.cohort_type);
      sessionStorage.setItem("bnat_medium", presentState.bnat.medium);
    }
  }, [presentState]);

  return (
    <Grid>
      {Object.keys(presentState).length === 0 && (
        <div style={{ textAlign: "center" }}>
          <Loader />
        </div>
      )}
      {Object.keys(presentState).length !== 0 && (
        <>
          <Header
            disableLink
            noNotes
            examType={isIas ? EXAM_TYPES.BNST : EXAM_TYPES.BNAT}
            language={language}
            languageChange={setLanguage}
          />
          <Grid container>
            <SideMenu
              activeNav={activeNav}
              executeScroll={executeScroll}
              toggleDrawer={toggleDrawer}
              language={language}
              isIas={isIas}
              isBnat
            />
            <Grid item xs={12} md={9}>
              <div className={classes.sections}>
                <section id="welcome-section" className="section">
                  {presentState ? (
                    <BnatWelcomeSection
                      examType={presentState.bnat.exam_type}
                      examState={presentState}
                      language={language}
                      setActiveNav={setActiveNav}
                    />
                  ) : null}
                </section>
                <section id="syllabus-section" className="section">
                  <SyllabusSection
                    meta={fetchGradeCohortDetails({
                      grade: presentState.bnat.grade,
                      cohort: presentState.bnat.cohort_type,
                      medium: language,
                    })}
                    language={language}
                    examType={presentState.bnat.exam_type}
                    setActiveNav={setActiveNav}
                  />
                </section>
                <section id="faq-section" className="section">
                  {isIas ? (
                    <IASFaqSection
                      language={language}
                      resultDateTime={presentState.bnat.result_date_time}
                      setActiveNav={setActiveNav}
                    />
                  ) : (
                    <FaqSection
                      isMockTest={false}
                      setActiveNav={setActiveNav}
                    />
                  )}
                </section>
                <section id="video-section" className="section">
                  <VideoSection
                    isMockTest={false}
                    examType={presentState.bnat.exam_type}
                    language={language}
                    setActiveNav={setActiveNav}
                  />
                </section>
                <section id="results-section" className="section">
                  <ResultSection
                    isMockTest={false}
                    language={language}
                    setActiveNav={setActiveNav}
                  />
                </section>
              </div>
            </Grid>
          </Grid>
          <Drawer
            anchor="bottom"
            open={drawerState.bottom}
            onClose={toggleDrawer("bottom", false)}
          >
            {list("bottom")}
          </Drawer>
        </>
      )}
    </Grid>
  );
}
