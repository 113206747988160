/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { ICONS } from "../../AppConfig";

const useStyles = makeStyles(() => {
  return {
    image: {
      height: 42,
      transform: ({ show }) => (!show ? "rotate(180deg)" : ""),
      cursor: "default",
      transition: "0.2s",
    },
  };
});

const HideShow = ({ show, onClick }) => {
  const classes = useStyles({ show });
  return (
    <img
      onClick={onClick}
      src={ICONS.HIDE_SHOW}
      className={classes.image}
      alt="Hide Show"
    />
  );
};

HideShow.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
HideShow.defaultProps = {
  show: true,
};

export default HideShow;
