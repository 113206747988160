/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "../../assets/stylesheets/MockDetail.css";
import { useSelector } from "react-redux";
import ReactHTMLParser from "react-html-parser";
import descriptionText from "../../assets/js/resultDescription";
import { extractDateTime } from "../Utils/TimeConvertor";
import {
  olapPayloadGenerator,
  OLAPEventIds,
  OLAPKingdoms,
  getUserInfo,
} from "../Utils/OlapHelpers";
import { postOLAP } from "../../services/OLAP.api";
import { EXAM_TYPES, COHORT_TYPES, LANGUAGE } from "../../AppConfig";
import initiateIntersectionObserver from "../Utils/IntersectionObserver";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: "#6d7278",
    fontSize: "2rem",
    fontWeight: "600",
    marginBottom: "1.5rem",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  OrngClr: {
    color: "#c254f5",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  resTxt: {
    fontWeight: "700",
    fontFamily: '"GothamBold"',
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  descrText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  refreshMsg: {
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  reportBtn: {
    paddingTop: "25px",
    paddingBottom: "25px",
  },
  viewResBtn: {
    color: "#ffffff !important",
    textTransform: "Capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.82rem",
      minWidth: "15rem",
      whiteSpace: "nowrap",
    },
  },
  viewRepBtn: {
    [`${theme.breakpoints.down("xs")} and (orientation: portrait)`]: {
      width: "288px",
    },
  },
  regularTxt: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  disableBtn: {
    cursor: "not-allowed !important",
    boxShadow: "none",
    opacity: 0.65,
    pointerEvents: "all !important",
  },
}));
export default function ResultSection({ isMockTest, language, setActiveNav }) {
  const classes = useStyles();
  const presentState = useSelector((state) =>
    isMockTest ? state.mockTest : state.bnat
  );

  const [ifResBtnDisabled, setIfResBtnDisabled] = React.useState(true);
  const [ifRepBtnDisabled, setIfRepBtnDisabled] = React.useState(true);
  let resultDescription = null;

  const resBtnState = () => {
    if (isMockTest && presentState.mock_test.result_enabled) {
      setIfResBtnDisabled(false);
    } else if (!isMockTest && presentState.bnat.result_enabled) {
      setIfResBtnDisabled(false);
    }
  };

  const repBtnState = () => {
    if (!isMockTest && presentState.bnat.report_enabled) {
      setIfRepBtnDisabled(false);
    }
  };

  const getResultDescription = () => {
    if (presentState.bnat.exam_type === EXAM_TYPES.BNAT) {
      let bnatResultDes = {};
      const { grade } = presentState.bnat;
      if (grade >= 4 && grade <= 10) {
        bnatResultDes = descriptionText.bnatTest;
      } else if (grade >= 11 && grade <= 13) {
        const cohort = presentState.bnat.cohort_type;
        if (cohort === COHORT_TYPES.JEE) {
          bnatResultDes = descriptionText.bnatTestJee;
        } else if (cohort === COHORT_TYPES.NEET) {
          bnatResultDes = descriptionText.bnatTestNeet;
        } else if (cohort === COHORT_TYPES.JeeNeet) {
          bnatResultDes = descriptionText.bnatTestJeeNeet;
        }
      } else if (grade === 14) {
        bnatResultDes = descriptionText.bnatTest;
      } else if (grade === 15) {
        if (language === LANGUAGE.ENGLISH) {
          bnatResultDes = descriptionText.bnatTestIASEnglish;
        } else if (language === LANGUAGE.HINDI) {
          bnatResultDes = descriptionText.bnatTestIASHindi;
        }
      }
      if (
        Object.keys(bnatResultDes).length > 0 &&
        Array.isArray(bnatResultDes.classAndResultDescriptions)
      ) {
        resultDescription = bnatResultDes.classAndResultDescriptions.find(
          (res) => res.class === grade
        ).description;
      }
    }
    return resultDescription;
  };

  const userToken = localStorage.getItem("user_token");

  const callOlapApi = (uEventId, description, kingdom, counter) => {
    const { premium } = getUserInfo();
    const olapPayload = olapPayloadGenerator(
      null,
      null,
      null,
      null,
      uEventId,
      "click",
      description,
      kingdom,
      counter,
      null,
      null,
      null,
      null,
      isMockTest
        ? presentState.mock_test.assessment_client_id
        : presentState.bnat.assessment_client_id,
      null,
      null,
      isMockTest
        ? presentState.mock_test.exam_type
        : presentState.bnat.assessment_client_id,
      premium ? null : JSON.parse(localStorage.getItem("user")).id,
      null,
      null,
      null
    );
    postOLAP(olapPayload);
  };

  useEffect(() => {
    resBtnState();
    repBtnState();
  }, []);

  useEffect(() => {
    initiateIntersectionObserver(
      document.querySelector("#resultSection"),
      1,
      setActiveNav,
      "5"
    );
  }, [setActiveNav]);

  const openResult = () => {
    const baseURL = window.location.origin;
    const examType = isMockTest
      ? presentState.mock_test.exam_type
      : presentState.bnat.exam_type;
    const userAssignmentId = isMockTest
      ? presentState.mock_test.assignment_id
      : presentState.bnat.assignment_id;
    const url = `${baseURL}/authenticate?access_token=${userToken}&assessment_type=${examType}&is_free_user=true&user_assignment_id=${userAssignmentId}${
      isMockTest ? "" : `&grade=${presentState.bnat.grade}`
    }`;

    callOlapApi(
      isMockTest
        ? OLAPEventIds.CLICK_MOCK_TEST_RESULT
        : OLAPEventIds.CLICK_BNAT_TEST_RESULT,
      isMockTest
        ? "click to view mock test result"
        : "click to view bnat test result",
      isMockTest
        ? OLAPKingdoms.CLICK_MOCK_TEST_RESULT
        : OLAPKingdoms.CLICK_BNAT_TEST_RESULT,
      isMockTest ? "click_mocktest_result" : "click_bnattest_result"
    );
    window.open(url, "mock_test");
  };

  const downloadPdfReport = () => {
    window.open(presentState.bnat.result_pdf_url, "pdf_report_tab");

    callOlapApi(
      OLAPEventIds.CLICK_BNAT_TEST_REPORT,
      "click to view bnat test report",
      OLAPKingdoms.CLICK_BNAT_TEST_REPORT,
      "download_bnattest_report"
    );
  };

  const mockResultDatetimeArr = isMockTest
    ? presentState.mock_test.result_date_time.split(" ")
    : presentState.bnat.result_date_time.split(" ");

  const mockResultDatetime = extractDateTime(mockResultDatetimeArr);
  const checkIfLanguageisEnglish = language === LANGUAGE.ENGLISH;

  return (
    <div id="resultSection">
      <Typography variant="h4" className={classes.sectionTitle}>
        {checkIfLanguageisEnglish ? "Result" : "परिणाम"}
      </Typography>
      <Typography className={classes.resTxt}>
        {isMockTest &&
          "BYJU'S All India Mock Test Result will be available here on"}
        {!isMockTest &&
          (checkIfLanguageisEnglish
            ? `${
                presentState.bnat.grade === 15 ? "BNST | IAS" : "BNAT"
              } Assessment Report and Result will be available here on`
            : "BNST | IAS मूल्यांकन रिपोर्ट और परिणाम यहाँ उपलब्ध होगा")}
      </Typography>
      <Typography className={classes.OrngClr}>
        {` ${mockResultDatetime && mockResultDatetime.date}`}
        <sup>{mockResultDatetime && mockResultDatetime.dateOrdinal}</sup>
        {` ${mockResultDatetime && mockResultDatetime.month} ${
          mockResultDatetime && mockResultDatetime.year
        }, ${mockResultDatetime && mockResultDatetime.time} ${
          mockResultDatetime && mockResultDatetime.ampm
        }`}
      </Typography>
      <br />
      {!isMockTest && getResultDescription() && (
        <Typography className={classes.descrText}>
          {ReactHTMLParser(getResultDescription())}
        </Typography>
      )}
      <div className={classes.reportBtn}>
        <Button
          onClick={() => openResult()}
          className={ClassNames(classes.viewResBtn, "mocktest-btn", {
            [classes.disableBtn]: ifResBtnDisabled,
          })}
          disabled={ifResBtnDisabled}
        >
          Click here to view the result
        </Button>
        {!isMockTest && (
          <div>
            <br />
            <Typography className={classes.descrText}>
              {checkIfLanguageisEnglish
                ? "Our academic experts have created your micro analysis on the basis of your performance across several learning pattern categories."
                : "BNST मूल्यांकन रिपोर्ट में (a)राजव्यवस्था (b)इतिहास (c)भूगोल (d)अर्थव्यवस्था (e)पर्यावरण तथा विज्ञान और प्रौद्योगिकी (f)समसामयिकी का एक विश्लेषण होगा।"}
            </Typography>
            <br />
            <Button
              onClick={() => downloadPdfReport()}
              className={ClassNames(
                classes.viewResBtn,
                "mocktest-btn",
                classes.viewRepBtn,
                {
                  [classes.disableBtn]: ifRepBtnDisabled,
                }
              )}
              disabled={ifRepBtnDisabled}
            >
              Download comprehensive report
            </Button>
          </div>
        )}
      </div>
      <Typography className={classes.refreshMsg}>
        {checkIfLanguageisEnglish
          ? "(Kindly refresh the page if the button is not enabled on the result date)"
          : "(यदि परिणाम जारी किए जाने हेतु निर्धारित तिथि को बटन सक्रिय नहीं हो तो कृपया पेज को रिफ्रेश करें)"}
      </Typography>
      <p />
    </div>
  );
}
