import { GET_BNAT_DETAILS_SUCCESS } from "./BNAT.types";

const initialState = {};

const BnatReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BNAT_DETAILS_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
};

export default BnatReducer;
