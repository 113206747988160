import {
  UPDATE_COMPREHENSIVE_TEST_DATA,
  UPDATE_TEST_SPECIFIC_SWOT,
} from "./ComprehensiveTest.types";

const OVERALL_TEST_META_DATA = {};

const ComprehensiveTestReducer = (state = OVERALL_TEST_META_DATA, action) => {
  switch (action.type) {
    case UPDATE_COMPREHENSIVE_TEST_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_TEST_SPECIFIC_SWOT:
      return {
        ...state,
        swot: action.payload,
      };

    default:
      return state;
  }
};

export default ComprehensiveTestReducer;
