export const FETCH_ASSIGNMENT = "FETCH_ASSIGNMENT";
export const UPDATE_ASSIGNMENT_STATE = "UPDATE_ASSIGNMENT_STATE";
export const FETCH_SOLUTION = "FETCH_SOLUTION";
export const FETCH_SOLUTION_DONE = "FETCH_SOLUTION_DONE";
export const FETCH_QUESTION_NOTES = "FETCH_QUESTION_NOTES";
export const FETCH_QUESTION_NOTES_DONE = "FETCH_QUESTION_NOTES_DONE";
export const FETCH_QUESTION_FEEDBACK = "FETCH_QUESTION_FEEDBACK";
export const FETCH_QUESTION_FEEDBACK_DONE = "FETCH_QUESTION_FEEDBACK_DONE";
export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_DONE = "DELETE_NOTE_DONE";
export const POST_NEW_NOTE = "POST_NEW_NOTE";
export const POST_NEW_NOTE_DONE = "POST_NEW_NOTE_DONE";
export const EDIT_NOTE = "EDIT_NOTE";
export const EDIT_NOTE_DONE = "EDIT_NOTE_DONE";
export const CLEAR_ASSIGNMENT = "CLEAR_ASSIGNMENT";
export const FETCH_VIDEO_SOLUTION = "FETCH_VIDEO_SOLUTION";
export const FETCH_VIDEO_SOLUTION_DONE = "FETCH_VIDEO_SOLUTION_DONE";
export const CLEAR_VIDEO_SOLUTION = "CLEAR_VIDEO_SOLUTION";
