/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { ICONS } from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  notvisited: {
    backgroundImage: `url(${ICONS.NOT_VISITED_LARGE})`,
    backgroundRepeat: "no-repeat",
    margin: ({ margin }) => margin,
    backgroundSize: ({ height, width }) => `${height}px ${width}px`,
    height: ({ height }) => height,
    width: ({ width }) => width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    transition: "0.2s",
    color: "#333333",
    "&:hover": {
      filter: "brightness(1.2)",
      transition: "0.2s",
    },
  },
  notansweredadv: {
    backgroundImage: `url(${ICONS.NOT_ANSWERED_ADVANCED})`,
    backgroundRepeat: "no-repeat",
    margin: ({ margin }) => margin,
    backgroundSize: ({ height, width }) => `${height}px ${width}px`,
    height: ({ height }) => height,
    width: ({ width }) => width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    transition: "0.2s",
    "&:hover": {
      filter: "brightness(1.2)",
      transition: "0.2s",
    },
    color: "white",
  },
  answeredadv: {
    backgroundImage: `url(${ICONS.ANSWERED_ADVANCED})`,
    backgroundRepeat: "no-repeat",
    margin: ({ margin }) => margin,
    backgroundSize: ({ height, width }) => `${height}px ${width}px`,
    height: ({ height }) => height,
    width: ({ width }) => width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    transition: "0.2s",
    "&:hover": {
      filter: "brightness(1.2)",
      transition: "0.2s",
    },
    color: "white",
  },
  notanswered: {
    backgroundImage: ({ iconType }) =>
      `url(${
        iconType === "mains" ? ICONS.NOT_ANSWERED : ICONS.NOT_ANSWERED_ADVANCED
      })`,
    backgroundRepeat: "no-repeat",
    margin: ({ margin }) => margin,
    backgroundSize: ({ height, width }) => `${height}px ${width}px`,
    height: ({ height }) => height,
    width: ({ width }) => width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    transition: "0.2s",
    "&:hover": {
      filter: "brightness(1.2)",
      transition: "0.2s",
    },
    color: "white",
  },
  answered: {
    backgroundImage: ({ iconType }) =>
      `url(${iconType === "mains" ? ICONS.ANSWERED : ICONS.ANSWERED_ADVANCED})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: ({ height, width }) => `${height}px ${width}px`,
    margin: ({ margin }) => margin,
    height: ({ height }) => height,
    width: ({ width }) => width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    transition: "0.2s",
    "&:hover": {
      filter: "brightness(1.2)",
      transition: "0.2s",
    },
    color: "white",
  },
  markedforreview: {
    backgroundImage: `url(${ICONS.MARKED_REVIEW})`,
    backgroundRepeat: "no-repeat",
    margin: ({ margin }) => margin,
    backgroundSize: ({ height, width }) => `${height}px ${width}px`,
    height: ({ height }) => height,
    width: ({ width }) => width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    transition: "0.2s",
    "&:hover": {
      filter: "brightness(1.2)",
      transition: "0.2s",
    },
    color: "white",
  },
  answeredandmarked: {
    backgroundImage: `url(${ICONS.MARKED_REVIEW_ANSWERED})`,
    backgroundRepeat: "no-repeat",
    margin: ({ margin }) => margin,
    backgroundSize: ({ height, width }) => `${height}px ${width}px`,
    height: ({ height }) => height,
    width: ({ width }) => width,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
    transition: "0.2s",
    "&:hover": {
      filter: "brightness(1.2)",
      transition: "0.2s",
    },
    color: "white",
  },
  inlineStyle: {
    display: "inline-block",
    fontFamily: "gotham",
    filter: ({ selected }) =>
      selected ? "drop-shadow(black 0px 0px 1.5px)" : "",
    transition: "0.2s",
  },
  [theme.breakpoints.down("xs")]: {
    notvisited: {
      backgroundSize: ({ height, width }) =>
        `${height * 0.7}px ${width * 0.7}px`,
      height: ({ height }) => height * 0.7,
      width: ({ width }) => width * 0.7,
    },
    notanswered: {
      backgroundSize: ({ height, width }) =>
        `${height * 0.7}px ${width * 0.7}px`,
      height: ({ height }) => height * 0.7,
      width: ({ width }) => width * 0.7,
    },
    answeredandmarked: {
      backgroundSize: ({ height, width }) =>
        `${height * 0.7}px ${width * 0.7}px`,
      height: ({ height }) => height * 0.7,
      width: ({ width }) => width * 0.7,
    },
    markedforreview: {
      backgroundSize: ({ height, width }) =>
        `${height * 0.7}px ${width * 0.7}px`,
      height: ({ height }) => height * 0.7,
      width: ({ width }) => width * 0.7,
    },
    answered: {
      backgroundSize: ({ height, width }) =>
        `${height * 0.7}px ${width * 0.7}px`,
      height: ({ height }) => height * 0.7,
      width: ({ width }) => width * 0.7,
    },
  },
}));

const Box = ({
  questionNumber,
  status,
  size = 20,
  margin,
  onClick,
  selected,
  iconType,
}) => {
  const classes = useStyles({
    height: size,
    width: size,
    margin,
    selected,
    iconType,
  });
  return (
    <div
      className={classes.inlineStyle}
      onClick={() => onClick(questionNumber)}
    >
      <div className={classes[status]}>{questionNumber}</div>
    </div>
  );
};

Box.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  status: PropTypes.string,
  size: PropTypes.number,
  margin: PropTypes.number,
  onClick: PropTypes.func,
};

Box.defaultProps = {
  status: "notvisited",
  size: 38,
  margin: 2,
  onClick: () => {},
};

export default Box;
